import React, { useEffect, useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Slider,
  Dropdown,
  Button,
  Space,
  Tooltip,
  Spin,
  Pagination,
  Modal,
  Empty,
} from "antd";
import Filter_icon from "../../assets/images/filter_icon.png";
import Rectangle from "../../assets/images/Rectangle.png";
import Info_icon from "../../assets/images/info_icon.png";
import Eye_icon from "../../assets/images/eye__icon.svg";
import Video_icon from "../../assets/images/icons/video_icon.svg";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import Create_icon from "../../assets/images/create_icon.png";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import space__icon from "../../assets/images/space__icon.png";
import Close from "../../assets/images/close.png";
import { createBootstrapComponent } from "react-bootstrap/esm/ThemeProvider";
// import MultiRangeSlider from "../contacts/MultiRangeSlider";

import Bypass__img from "../../assets/images/choose-setting/Bypass__img.png";
import fancy__shape from "../../assets/images/choose-setting/fancy__shape.png";
import Halo__img from "../../assets/images/choose-setting/Halo__img.png";
import MultiRow from "../../assets/images/choose-setting/MultiRow__img.png";
import Pave from "../../assets/images/choose-setting/Pave.png";
import SingleRow from "../../assets/images/choose-setting/singleRow.png";
import Solitaire from "../../assets/images/choose-setting/Solitaire.png";
import ThreeStone from "../../assets/images/choose-setting/ThreeStone.png";
import Trellis from "../../assets/images/choose-setting/Trellis.png";
import Vintage from "../../assets/images/choose-setting/Vintage.png";

import Round from "../../assets/images/choose-setting/round.svg";
import Radiant from "../../assets/images/choose-setting/Radiant.svg";
import Asscher from "../../assets/images/choose-setting/Asscher.svg";
import Cushion from "../../assets/images/choose-setting/Cushion.svg";
import Emerald from "../../assets/images/choose-setting/Emerald.svg";
import Heart from "../../assets/images/choose-setting/Heart.svg";
import Marquise from "../../assets/images/choose-setting/Marquise.svg";
import Oval from "../../assets/images/choose-setting/Oval.svg";
import Princess from "../../assets/images/choose-setting/Princess.svg";
import Pear from "../../assets/images/choose-setting/Radiant.svg";
import ChooseSettingItemDetails from "./ChooseSettingItemDetails";
import { useSelector, useDispatch } from "react-redux";
import { handleViewDetailsPage } from "./../../actions/chooseYourSetting/chooseYourSettingActions";
import chooseSettingsService from "../../services/choose-settings.service";
import {
  handleAddSettingViewEdit,
  handleActiveTab,
} from "../../actions/header/headerActions";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import headerService from "../../services/header.service";
import colorPanelService from "../../services/color-panel.service";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";

const ChooseYourSetting = ({}) => {
  const search = useLocation().search;
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const paramDiamondId = new URLSearchParams(search).get("diamondId");
  const paramSettingShape = new URLSearchParams(search).get("settingShape");
  const paramPageName = new URLSearchParams(search).get("pageName");
  const navigate = useNavigate();
  const { Search } = Input;
  const [productChange, setProductChange] = useState({});
  const [viewItem, setViewItem] = useState("list");
  const [hideShowFilter, setHideShowFilter] = useState(true);
  const [settingFilter, setSettingFilter] = useState({
    priceMin: 0,
    priceMax: 100000,
    shapes: "",
    ringType: "",
    metalColor: "",
  });
  const [priceValue, setPriceValue] = useState([0, 100000]);
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [dealerId, setDealerId] = useState(0);
  const [chooseYourSettingTableFilters, setChooseYourSettingTableFilters] =
    useState({
      recordsPerPage: 10,
      price: "lowHigh",
      search: "",
    });
  const [pageNumber, setPageNumber] = useState(1);
  const [sortColumnName, setSortColumnName] = useState("");
  const [sortType, setSortType] = useState("asc");
  // const [pageSize, setPageSize] = useState(10);

  const [settingsGridData, setSettingsGridData] = useState([]);
  const [settingsFilterData, setSettingsFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [totalRecord, setTotalRecord] = useState(0);
  const [videoModal, setVideoModal] = useState(false);
  const [shapeNameArray, setShapeArray] = useState([]);
  const [settingInfo, setSettingInfo] = useState({});

  const pageSizeData = ["10", "20", "50", "100"];

  const [hoverInfo, setHoverInfo] = useState(false);
  const [dealerHoverId, setDealerHoverId] = useState(0);

  const dispatch = useDispatch();
  const chooseYourSetting = useSelector(
    (state) => state.chooseYourSettingReducer
  );
  const [sortBy, setSortBy] = useState("");
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const diamondDetails = useSelector((state) => state.selectYourDiamondReducer);
  const [tableClassAllow, setTableClassAllow] = useState(false);
  const [ViewOptionData, setViewOptionData] = useState({
    RingBuilderUrl: "",
    InternalUseLink: true,
    RingSizeType: null,
    InitiallySortbox1: null,
    InitiallySortbox2: null,
    DefaultMetalType: null,
    ShowAdvancedSearch: true,
    RingsShow: true,
    ShowAddress: true,
    ShowCenterStone: true,
    ShowPrice: true,
    GSTTax: true,
    GSTTaxValue: "",
    PinterestShare: true,
    TwitterShare: true,
    FacebookShare: true,
    FacebookLike: true,
    GooglePlus: true,
    InstagramShare: true,
    AddToCart: true,
    AltShoppingCart: "",
    ShowBuySetting: true,
    ShowBuyDiamond: true,
    ShowViewCart: true,
  });

  const options = {
    margin: 20,
    loop: false,
    responsiveClass: true,
    nav: true,
    dots: false,
    // autoplay: false,
    // autoplayTimeout: 2000,
    // autoplayHoverPause: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items:
          settingsFilterData &&
          settingsFilterData.rbCollectionlst &&
          settingsFilterData.rbCollectionlst.length > 8
            ? 8
            : settingsFilterData && settingsFilterData.rbCollectionlst
            ? settingsFilterData.rbCollectionlst.length
            : 3,
      },
    },
  };

  const gender = (
    <span>
      Gender is not a required field for vendor data, so by leaving this blank,
      the majority of product results will be considered Ladies.
    </span>
  );

  const collection = (
    <span>Please select a vendor before choosing a collection.</span>
  );
  const AllShapeList = [
    {
      allowableShapeName: "ROUND",
      enable: "false",
      imagePath:
        "https://gemfindtwostorage.blob.core.windows.net/gemfind/Apps/Images/p1.png",
      productCount: "1",
    },
    {
      allowableShapeName: "OVAL",
      enable: "false",
      imagePath:
        "https://gemfindtwostorage.blob.core.windows.net/gemfind/Apps/Images/p5.png",
      productCount: "1",
    },
    {
      allowableShapeName: "EMERALD",
      enable: "false",
      imagePath:
        "https://gemfindtwostorage.blob.core.windows.net/gemfind/Apps/Images/p8.png",
      productCount: "1",
    },
    {
      allowableShapeName: "ASSCHER",
      enable: "false",
      imagePath:
        "https://gemfindtwostorage.blob.core.windows.net/gemfind/Apps/Images/p10.png",
      productCount: "1",
    },
    {
      allowableShapeName: "PRINCESS",
      enable: "false",
      imagePath:
        "https://gemfindtwostorage.blob.core.windows.net/gemfind/Apps/Images/p3.png",
      productCount: "1",
    },
    {
      allowableShapeName: "MARQUISE",
      enable: "false",
      imagePath:
        "https://gemfindtwostorage.blob.core.windows.net/gemfind/Apps/Images/p6.png",
      productCount: "1",
    },
    {
      allowableShapeName: "PEAR",
      enable: "false",
      imagePath:
        "https://gemfindtwostorage.blob.core.windows.net/gemfind/Apps/Images/p4.png",
      productCount: "1",
    },
    {
      allowableShapeName: "RADIANT",
      enable: "false",
      imagePath:
        "https://gemfindtwostorage.blob.core.windows.net/gemfind/Apps/Images/p2.png",
      productCount: "1",
    },
    {
      allowableShapeName: "CUSHION",
      enable: "false",
      imagePath:
        "https://gemfindtwostorage.blob.core.windows.net/gemfind/Apps/Images/p9.png",
      productCount: "1",
    },
    {
      allowableShapeName: "HEART",
      enable: "false",
      imagePath:
        "https://gemfindtwostorage.blob.core.windows.net/gemfind/Apps/Images/p7.png",
      productCount: "1",
    },
  ];

  const columns = [
    // {
    //   title: "",
    //   // dataIndex: "checkinpt",
    //   width: 30,
    //   render: (row) => {
    //     return <input type="checkbox" />;
    //   },
    // },
    // {
    //   title: "",
    //   // dataIndex: "image",
    //   width: 60,
    //   render: (row) => {
    //     return <img src={Rectangle} className="prod__img" alt="" />;
    //   },
    // },
    {
      title: "Retail Stock #",
      dataIndex: "settingName",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      width: 300,
    },

    {
      title: "Setting ID",
      dataIndex: "settingID",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },

    {
      title: "Category",
      dataIndex: "category",
      className: "hideInMobile",
      // sorter: (a, b) => {},
      // showSorterTooltip: false,
    },
    {
      title: "Vendor",
      dataIndex: "vendorName",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Collection",
      dataIndex: "collection",
      className: "hideInMobile",
      // sorter: (a, b) => {},
      // showSorterTooltip: false,
    },
    {
      title: "$ cost",
      // dataIndex: "cost",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (row) => {
        return (
          <div>
            {row.cost == "0" ? (
              <>
                Call <span className="hideInMobile">For Price</span>
              </>
            ) : (
              <span>
                $
                {parseInt(row.cost)
                  .toFixed()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "$ Retail",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      // dataIndex: "retailPrice",
      // render: (row) => {
      //   return <span>$ {row.retailPrice}</span>;
      // },
      render: (item, row) => {
        return ViewOptionData.ShowPrice == false ? (
          <>
            Call <span className="hideInMobile">For Price</span>
          </>
        ) : (
          <>
            <div>
              {row.retailPrice == "0" ? (
                <>
                  Call <span className="hideInMobile">For Price</span>
                </>
              ) : (
                <span>
                  $
                  {parseInt(row.retailPrice)
                    .toFixed()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Action",
      // dataIndex: "action",
      width: 100,
      render: (row) => {
        return (
          <div className="action__btns">
            {/* {!settingInfo[row.settingID] ? ( */}
            <div className="image__block hideInMobile">
              <img
                src={Info_icon}
                alt=""
                onMouseEnter={() => handleOpenSettingInfo(row.settingID)}
                onMouseOut={() => handleCloseSettingInfo(row.settingID)}
              />
            </div>
            {/* {settingInfo[row.settingID] && ( */}
            <div
              className={
                hoverInfo == true && dealerHoverId == row.settingID
                  ? "info__tooltip"
                  : "display-none"
              }
              // className="info__tooltip"
            >
              <div
                className="close__div"
                onClick={() => handleCloseSettingInfo(row.settingID)}
              >
                <img src={Close} alt="" />
              </div>
              <p>
                <span>Setting Number</span>
                <span>{row.settingNumber}</span>
              </p>
              <p>
                <span>Fitting Center Stone</span>
                <span>{row.centerStonesThatFit}</span>
              </p>
              <p>
                <span>Center Stone Minimum Carat</span>
                <span>{row.centerStoneMinimumCarat}</span>
              </p>
              <p>
                <span>Center Stone Maximum Carat</span>
                <span>{row.centerStoneMaximumCarat}</span>
              </p>
            </div>
            {/* )} */}
            {/* )
            // )}
            } */}

            <div
              className="image__block hideInMobile"
              onClick={() => handleOpenVideoModal(row)}
            >
              <img src={Video_icon} alt="" />
            </div>
            <div
              className="image__block"
              onClick={() => handleClickToView(row)}
            >
              <img src={Eye_icon} alt="" />
            </div>
            {}
          </div>
        );
      },
    },
  ];

  const data = [
    {
      key: "1",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },

    {
      key: "2",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },

    {
      key: "3",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },

    {
      key: "4",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      retailstock: (
        <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
      ),
      category: <span>Pendants, Gemstone Pendant</span>,
      vendor: <span>Parlé Jewelry Design</span>,
      collection: <span>Opal</span>,
      cost: <span>$95.00</span>,
      retail: <span>$190.00</span>,
    },
  ];

  const { Option } = Select;

  useEffect(() => {
    if (chooseYourSetting) {
      if (chooseYourSetting.isViewDetails) {
        setIsViewDetails(chooseYourSetting.isViewDetails);
      }
    }
  }, [chooseYourSetting]);

  //UseEffect for onload apis

  useEffect(() => {
    if (paramSettingShape) {
      setSettingFilter({
        ...settingFilter,
        shapes: paramSettingShape,
      });
    }
  }, []);

  useEffect(() => {
    let filters = settingFilter;
    if (paramdealerId) {
      handleGetOptionsDetails(paramdealerId);
      if (paramSettingShape) {
        filters["shapes"] = paramSettingShape;
        setSettingFilter(filters);
      }

      handleGetSettingsGridData(
        paramdealerId,
        pageNumber,
        chooseYourSettingTableFilters.recordsPerPage,
        filters
      );
      handleGetSettingFilterData(
        paramdealerId,
        pageNumber,
        chooseYourSettingTableFilters.recordsPerPage,
        filters
      );
      setDealerId(loginDetails.dealerId);
    }
  }, []);

  const handleGetOptionsDetails = (id) => {
    let inputData = {
      dealerID: id,
      Type: "RingBuilder",
    };

    try {
      headerService
        .GetRingBuilderOptionDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          if (message == "Success") {
            if (responseData) {
              debugger;
              setViewOptionData((prevState) => ({
                ...prevState,
                RingBuilderUrl: responseData.ringBuilderURL,
                InternalUseLink: responseData.internalLinkRB,
                RingSizeType: responseData.ringSizeType,
                InitiallySortbox1: responseData.ringInitiallySort1,
                InitiallySortbox2: responseData.ringInitiallySortDesc,
                DefaultMetalType: responseData.defaultMetalType,
                ShowAdvancedSearch: responseData.showAdvanceRingBuilder,
                RingsShow: responseData.showScheduleView,
                ShowAddress: responseData.showAddresses,
                ShowCenterStone: responseData.showCenterStoneSize,
                ShowPrice: responseData.showPricesOnSearchPage,
                GSTTax: responseData.showGstPrice,
                GSTTaxValue: responseData.taxRate,
                PinterestShare: responseData.showPinterestShare,
                TwitterShare: responseData.showTwitterShare,
                FacebookShare: responseData.showFacebookShare,
                FacebookLike: responseData.showFacebookLike,
                GooglePlus: responseData.showGooglePlus,
                InstagramShare: responseData.showInstagramShare,
                AddToCart: responseData.showAddToCartButton,
                AltShoppingCart: responseData.shoppingCartURL,
                ShowBuySetting: responseData.showBuyWithoutCenterStone,
                ShowBuyDiamond: responseData.buyDiamondOnly,
                ShowViewCart: responseData.showViewCartButtonRB,
              }));
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }
  const handleProductChangeOpen = (id) => {
    let obj = productChange;
    obj[id] = true;
    setProductChange((prevState) => ({
      ...prevState,
      ...obj,
    }));
  };

  const handleProductChangeClose = (id) => {
    let obj = productChange;
    obj[id] = false;
    setProductChange((prevState) => ({
      ...prevState,
      ...obj,
    }));
  };

  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  const handleViewChange = (viewname) => {
    setViewItem(viewname);
  };
  const firstSliderPriceValue = (e, num) => {
    let newArr = [...priceValue];
    var Value = e.target.value && e.target.value.replace("$", "");

    if (Number(parseFloat(Value.replace(/,/g, ""))) > settingFilter.priceMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setPriceValue(newArr);
      } else {
        newArr[0] = Number(parseFloat(Value.replace(/,/g, "")));
        setPriceValue(newArr);
        handleGetSettingsGridData(
          dealerId,
          pageNumber,
          chooseYourSettingTableFilters.recordsPerPage,
          settingFilter,
          newArr
        );
      }
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPriceValue(newArr);
      } else {
        newArr[1] = Number(parseFloat(Value.replace(/,/g, "")));
        setPriceValue(newArr);
        handleGetSettingsGridData(
          dealerId,
          pageNumber,
          chooseYourSettingTableFilters.recordsPerPage,
          settingFilter,
          newArr
        );
      }
    }
  };
  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleSettingFilterChange = (e, name, allow) => {
    if (name == "shapes" && !shapeNameArray.includes(e)) {
      return false;
    } else {
      let allFilters = settingFilter;
      if (allFilters[name] == e) {
        allFilters[name] = "";
      } else {
        allFilters[name] = e;
      }

      setSettingFilter(allFilters);

      handleGetSettingFilterData(
        dealerId,
        pageNumber,
        chooseYourSettingTableFilters.recordsPerPage,
        allFilters
      );
      handleGetSettingsGridData(
        dealerId,
        pageNumber,
        chooseYourSettingTableFilters.recordsPerPage,
        allFilters
      );
    }
  };

  const handleClickToView = (row) => {
    debugger;
    window.parent.postMessage("submitted form", "*");
    console.log(row);

    if (paramDiamondId) {
      navigate(
        "/ChooseSettingItemDetails" +
          `?isSocialPage=True&DealerLink=${paramdealerId}&pageNo=1&isAllowed=True&settingId=${row.settingID}&settingNumber=${row.settingNumber}&vendorID=${row.vendorID}&diamondId=${paramDiamondId}&pageName=${paramPageName}&headerTab=chooseYourSetting`
      );
    } else {
      navigate(
        "/ChooseSettingItemDetails" +
          `?isSocialPage=True&DealerLink=${paramdealerId}&pageNo=1&isAllowed=True&settingId=${row.settingID}&settingNumber=${row.settingNumber}&diamondId=${paramDiamondId}&vendorID=${row.vendorID}&pageName=${paramPageName}&headerTab=chooseYourSetting`
      );
    }

    // dispatch(handleViewDetailsPage(row));
    // dispatch(handleAddSettingViewEdit());

    setSelectedRow(row);
  };

  const handleTableChange = (e, name, type) => {
    let tableFilters = chooseYourSettingTableFilters;
    if (type == "select") {
      tableFilters[name] = e;
      setChooseYourSettingTableFilters({
        ...chooseYourSettingTableFilters,
        [name]: e,
      });
    } else {
      tableFilters[name] = e.target.value;
      setChooseYourSettingTableFilters({
        ...chooseYourSettingTableFilters,
        [name]: e.target.value,
      });
    }
    handleGetSettingsGridData(
      dealerId,
      pageNumber,
      chooseYourSettingTableFilters.recordsPerPage,
      settingFilter,
      priceValue,
      tableFilters
    );
  };

  const handleGetSettingsGridData = async (
    id,
    pageNumber,
    pageSize,
    filters,
    priceFilter,
    tableFilters,
    sortby
  ) => {
    let inputData = {
      dealerID: id,
      dealerLink: id,
      metalType: filters ? filters.metalColor : settingFilter.metalColor,
      priceMin: priceFilter
        ? priceFilter[0].toString()
        : priceValue[0].toString(),
      priceMax: priceFilter
        ? priceFilter[1].toString()
        : priceValue[1].toString(),
      orderBy: tableFilters
        ? tableFilters.price == "lowHigh"
          ? "cost asc"
          : "cost desc"
        : sortby,
      pageNumber: pageNumber.toString(),
      recordOnPage: tableFilters
        ? tableFilters.recordsPerPage.toString()
        : pageSize.toString(),
      centerStone: filters ? filters.shapes : settingFilter.shapes,
      collection: filters ? filters.ringType : settingFilter.ringType,
      gridViewId: "",
      showPrice: "",
      did: "",
      themeName: "",
      settingSearch: tableFilters ? tableFilters.search : "",
      isdiamond: "",
      custID: "",
      countryTaxRate: "",
      countryCode: "",
    };

    setTableLoading(true);
    try {
      await chooseSettingsService
        .GetAppPrevData(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData.appPrevRespLst;
          let totalRecord = response.data.responseData.totalRecord;
          if (msg == "Success") {
            setSettingsGridData(responseData);
            setTotalRecord(totalRecord);
          }
          setTableLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setTableLoading(false);
        });
    } catch (error) {
      console.log(error);
      setTableLoading(false);
    }
  };

  const handleGetSettingFilterData = async (
    id,
    pageNumber,
    pageSize,
    filters
  ) => {
    let inputData = {
      metalType: filters ? filters.metalColor : settingFilter.metalColor,
      priceMin: priceValue[0].toString(),
      priceMax: priceValue[1].toString(),
      orderBy: "Cost asc",
      dealerId: id.toString(),
      pageNo: pageNumber.toString(),
      recordOnPage: pageSize.toString(),
      recordOnPageMore: "0",
      shape: filters ? filters.shapes : settingFilter.shapes,
      carat: "",
      collection: filters ? filters.ringType : settingFilter.ringType,
      skuValue: "",
      styleName: "",
      brandName: "",
    };
    setLoading(true);
    try {
      chooseSettingsService
        .GetFilterData(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            setSettingsFilterData(responseData);
          }

          if (responseData.rbShapelst.length > 0) {
            let shapeNameArray = [];
            for (let i = 0; i < responseData.rbShapelst.length; i++) {
              for (let j = 0; j < AllShapeList.length; j++) {
                if (
                  responseData.rbShapelst[i].allowableShapeName.toUpperCase() ==
                  AllShapeList[j].allowableShapeName
                ) {
                  shapeNameArray.push(AllShapeList[j].allowableShapeName);
                }
              }
            }
            setShapeArray(shapeNameArray);
          } else {
            setShapeArray([]);
          }
          var rbMetallst = responseData.rbMetallst.split(",");
          var filtered = rbMetallst.filter(function (item) {
            return item != "";
          });

          setSettingsFilterData({
            ...responseData,
            rbShapelst: AllShapeList,
            newrbMetallst: filtered,
          });
          // setSettingsFilterData({
          //   ...settingsFilterData,
          //   rbShapelst: AllShapeList,
          // });
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSliderChange = (e) => {
    setPriceValue(e);
    handleGetSettingsGridData(
      dealerId,
      pageNumber,
      chooseYourSettingTableFilters.recordsPerPage,
      settingFilter,
      e
    );
  };

  const handleSelectRow = (row) => {
    setSelectedRow(row);
  };

  function onShowSizeChange(current, pageSize) {
    setPageNumber(current);
    setChooseYourSettingTableFilters({
      ...chooseYourSettingTableFilters,
      recordsPerPage: pageSize,
    });

    handleGetSettingsGridData(dealerId, current, pageSize);
  }

  const handleOpenVideoModal = (row) => {
    setSelectedRow(row);
    setVideoModal(true);
  };
  const handleCloseVideoModal = (row) => {
    setVideoModal(false);
  };

  const handleOpenSettingInfo = (settingId) => {
    // let tempSettingInfo = settingInfo;
    // tempSettingInfo[settingId] = true;

    // setSettingInfo(prevState => ({
    //   ...prevState,
    //   ...tempSettingInfo
    // }));
    setHoverInfo(true);
    setDealerHoverId(settingId);
    setTableClassAllow(true);
  };
  const handleCloseSettingInfo = (settingId) => {
    // let tempSettingInfo = settingInfo;
    // tempSettingInfo[settingId] = false;
    // setSettingInfo(prevState => ({
    //   ...prevState,
    //   ...tempSettingInfo
    // }));

    setHoverInfo(false);
    setDealerHoverId();
    setTableClassAllow(false);
  };

  var newparamSettingShape =
    paramSettingShape == null ? null : paramSettingShape;

  const handleResetFilters = () => {
    let filters = settingFilter;
    if (newparamSettingShape == null) {
      filters["shapes"] = "";
      filters["priceMin"] = 0;
      filters["priceMax"] = 100000;
      filters["ringType"] = "";
      filters["metalColor"] = "";
      setSettingFilter(filters);
      setPriceValue([0, 100000]);
      handleGetSettingsGridData(dealerId, 1, 10, filters);
      handleGetSettingFilterData(dealerId, 1, 10, filters);
    } else {
      filters["shapes"] = paramSettingShape;
      filters["priceMin"] = 0;
      filters["priceMax"] = 100000;
      filters["ringType"] = "";
      filters["metalColor"] = "";
      setSettingFilter(filters);
      setPriceValue([0, 100000]);
      handleGetSettingsGridData(dealerId, 1, 10, filters);
      handleGetSettingFilterData(dealerId, 1, 10, filters);
    }
  };

  // Sorting

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    var filter = [];
    var obj = {};
    var sortby = "";

    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
      if (sorter.field == "settingName") {
        sortby = "retail desc";
        setSortBy(sortby);
      } else if (sorter.field == "settingID") {
        sortby = "settingid desc";
        setSortBy(sortby);
      }
      // else if(sorter.field == "category"){
      //   sortby = "metal desc"
      //   setSortBy(sortby)
      // }
      else if (sorter.field == "vendorName") {
        sortby = "vendor desc";
        setSortBy(sortby);
      }
      // else if(sorter.field == "collection"){
      //   sortby = "settingid asc"
      //   setSortBy(sortby)
      // }
      else if (sorter.field == "cost") {
        sortby = "cost desc";
        setSortBy(sortby);
      } else if (sorter.field == "retailPrice") {
        sortby = "retailprice desc";
        setSortBy(sortby);
      } else {
        sortby = "cost desc";
        setSortBy(sortby);
      }
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
      if (sorter.field == "settingName") {
        sortby = "retail asc";
        setSortBy(sortby);
      } else if (sorter.field == "settingID") {
        sortby = "settingid asc";
        setSortBy(sortby);
      }
      // else if(sorter.field == "category"){
      //   sortby = "metal asc"
      //   setSortBy(sortby)
      // }
      else if (sorter.field == "vendorName") {
        sortby = "vendor asc";
        setSortBy(sortby);
      }
      // else if(sorter.field == "collection"){
      //   sortby = "settingid asc"
      //   setSortBy(sortby)
      // }
      else if (sorter.field == "cost") {
        sortby = "cost asc";
        setSortBy(sortby);
      } else if (sorter.field == "retailPrice") {
        sortby = "retailprice asc";
        setSortBy(sortby);
      } else {
        sortby = "cost asc";
        setSortBy(sortby);
      }
    } else {
      type = "ASC";
      column = "dealerCompany";
      setSortColumnName(column);
      setSortType(type);

      sortby = "cost asc";
      setSortBy(sortby);
    }
    console.log(pagination);
    // setPageNo(pagination.current);
    // setPageSize(pagination.pageSize);
    handleGetSettingsGridData(
      dealerId,
      pageNumber,
      chooseYourSettingTableFilters.recordsPerPage,
      settingFilter,
      "",
      "",
      sortby
    );
    // document.getElementsByClassName("content__area")[0].scroll(0, 0);
  };

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [wrapperLoading, setWrapperLoading] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [toggle, setToggle] = useState(true);
  useEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    }
  }, [previewcolor.toogle]);
  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "RingBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              var tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              debugger;
              console.log(tempobj);
              console.log(initialColorObj);
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
              // dispatch(handleSwitchChange());
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  /* Color Individually Ends */

  return (
    <React.Fragment>
      <div className="allprod_section jwlsrch__section app__preview choose__setting">
        <div className="row">
          <Spin spinning={loading}>
            <div className="col-md-12">
              <div className="filter_section">
                <Wrapper>
                  <div className="col-md-12 d-none">
                    <div class="img__block ring__type">
                      {settingsFilterData &&
                        settingsFilterData.rbCollectionlst &&
                        settingsFilterData.rbCollectionlst.map((item) => {
                          return (
                            <div
                              className={
                                settingFilter.ringType
                                  ? settingFilter.ringType
                                      .toString()
                                      .includes(item.collection)
                                    ? "imgselct__div selected"
                                    : "imgselct__div"
                                  : "imgselct__div"
                              }
                              onClick={() =>
                                handleSettingFilterChange(
                                  item.collection,
                                  "ringType"
                                )
                              }
                            >
                              <img
                                src={item.imagePath}
                                // style={{ width: "100px" }}
                              />
                              {/* <img src={item.url} alt="" /> */}
                              <p>{item.collection}</p>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="slider__section shape__slider">
                      <OwlCarousel className="owl-theme" {...options}>
                        {settingsFilterData &&
                          settingsFilterData.rbCollectionlst &&
                          settingsFilterData.rbCollectionlst.map((item) => {
                            return (
                              <div
                                class={
                                  settingFilter.ringType
                                    ? settingFilter.ringType
                                        .toString()
                                        .includes(item.collection)
                                      ? "item imgselct__div selected"
                                      : "item imgselct__div"
                                    : "item imgselct__div"
                                }
                                onClick={() =>
                                  handleSettingFilterChange(
                                    item.collection,
                                    "ringType"
                                  )
                                }
                              >
                                <div className="slider__block">
                                  <img src={item.imagePath} />
                                  <p>{item.collection}</p>
                                </div>
                              </div>
                            );
                          })}

                        {/* <div class="item">
                            <div className="slider__block">
                              <img src={Bypass__img} />
                              <p>Halo</p>
                            </div>
                          </div> */}
                      </OwlCarousel>
                    </div>
                  </div>
                </Wrapper>

                <div className="col-md-12">
                  <div className="form__fields filter_inpsection border-0">
                    <div className="col-md-12">
                      <div className="row">
                        <Wrapper>
                          <div className="whole__shapes col-lg-12">
                            <div className="input__block mr-2 label__inline">
                              <label> Shape</label>
                            </div>
                            {settingsFilterData &&
                              settingsFilterData.rbShapelst &&
                              settingsFilterData.rbShapelst.map((item) => {
                                return (
                                  <div
                                    className={
                                      settingFilter.shapes
                                        ? settingFilter.shapes
                                            .toString()
                                            .includes(item.allowableShapeName)
                                          ? "shapes__block selected"
                                          : "shapes__block"
                                        : "shapes__block"
                                    }
                                    onClick={() =>
                                      handleSettingFilterChange(
                                        item.allowableShapeName,
                                        "shapes"
                                      )
                                    }
                                    disabled={
                                      shapeNameArray.includes(
                                        item.allowableShapeName
                                      )
                                        ? false
                                        : true
                                    }
                                  >
                                    <div
                                      className={
                                        shapeNameArray.includes(
                                          item.allowableShapeName
                                        )
                                          ? "image"
                                          : "image-disabled"
                                      }
                                    >
                                      {item.imagePath !== "" ? (
                                        <img src={item.imagePath} />
                                      ) : (
                                        <img src={Round} />
                                      )}
                                    </div>
                                    <p>{item.allowableShapeName}</p>
                                  </div>
                                );
                              })}
                          </div>
                        </Wrapper>
                        <div className="col-lg-6">
                          <div className="sliderrange__maindiv">
                            <label> {"Price Range"} </label>
                            <div className="w-100">
                              <Slider
                                range
                                value={priceValue}
                                min={settingFilter.priceMin}
                                max={settingFilter.priceMax}
                                onChange={(e) => {
                                  handleSliderChange(e);
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={
                                      "$" +
                                      priceValue[0].toLocaleString("en-US")
                                    }
                                    onChange={(e) => {
                                      firstSliderPriceValue(e, 0);
                                    }}
                                  />
                                </div>
                                <div>
                                  <img
                                    src={space__icon}
                                    className="img-fluid space__icon"
                                  />
                                </div>
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={
                                      "$" +
                                      priceValue[1].toLocaleString("en-US")
                                    }
                                    onChange={(e) => {
                                      firstSliderPriceValue(e, 1);
                                    }}
                                    onKeyPress={onKeyPressEvent1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <Wrapper>
                            <div className="whole__shapes color__block">
                              <div className="input__block mr-2 label__inline">
                                <label> Metal</label>
                              </div>
                              {settingsFilterData &&
                                settingsFilterData.newrbMetallst &&
                                settingsFilterData.newrbMetallst.map((item) => {
                                  return (
                                    <div
                                      className={
                                        settingFilter.metalColor
                                          ? settingFilter.metalColor
                                              .toString()
                                              .includes(item)
                                            ? "shapes__block selected"
                                            : "shapes__block"
                                          : "shapes__block"
                                      }
                                      onClick={() =>
                                        handleSettingFilterChange(
                                          item,
                                          "metalColor"
                                        )
                                      }
                                    >
                                      <div
                                        className={
                                          settingFilter.metalColor &&
                                          settingFilter.metalColor
                                            .toString()
                                            .includes(item)
                                            ? "image"
                                            : `image ${
                                                item.includes("Rose")
                                                  ? "rose__metal"
                                                  : item.includes("Silver")
                                                  ? "white__gold"
                                                  : item.includes("Platinum")
                                                  ? "white__gold"
                                                  : item.includes("Palladium")
                                                  ? "white__gold"
                                                  : item.includes("Yellow")
                                                  ? "yellow__gold"
                                                  : item.includes("White")
                                                  ? "white__gold"
                                                  : "yellow__gold"
                                              }`
                                        }
                                      >
                                        <span>{item.split(" ")[0]}</span>
                                      </div>
                                      <p>
                                        {item.split(" ")[0] == "Platinum"
                                          ? "Platinum"
                                          : item.split(" ")[1]}
                                      </p>
                                    </div>
                                  );
                                })}
                            </div>
                          </Wrapper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Wrapper>
                  <div className="col-md-12 mt-2 mb-2">
                    <button
                      className="primary-btn"
                      onClick={handleResetFilters}
                    >
                      {" "}
                      Reset Filters
                    </button>
                  </div>

                  <div className="col-md-12 desktop__version">
                    <div className="filt_optndiv">
                      <div className="gview__div">
                        <span className="font__bold mr-2 similar__text">
                          {totalRecord} SETTINGS
                        </span>
                        <span
                          className={
                            viewItem === "grid"
                              ? "fa fa-th grid_icon active"
                              : "fa fa-th grid_icon"
                          }
                          onClick={() => handleViewChange("grid")}
                        ></span>
                        <span
                          className={
                            viewItem === "list"
                              ? "fa fa-th-list list_icon active"
                              : "fa fa-th-list list_icon"
                          }
                          onClick={() => handleViewChange("list")}
                        ></span>
                      </div>

                      <div className="drpdwn__div">
                        <span className="mr-2 font__bold">Price </span>
                        <Select
                          className="select_inpt"
                          placeholder="Low - High"
                          onChange={(e) =>
                            handleTableChange(e, "price", "select")
                          }
                        >
                          <option value="lowHigh">Low - High</option>
                          <option value="highLow">High - Low</option>
                        </Select>
                      </div>

                      <Search
                        placeholder="Search Setting #"
                        className="my-3"
                        onSearch={(e) =>
                          handleTableChange(e, "search", "select")
                        }
                        allowClear
                        style={{ width: 250 }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mobile__version">
                    <div className="filt_optndiv">
                      <div className="gview__div">
                        <div className="row">
                          <div className="col-4">
                            <span className="font__bold mr-2 similar__text text-left-mobile">
                              {totalRecord} SETTINGS
                            </span>
                          </div>

                          <div className="col-4">
                            <span
                              className={
                                viewItem === "grid"
                                  ? "fa fa-th grid_icon active"
                                  : "fa fa-th grid_icon"
                              }
                              onClick={() => handleViewChange("grid")}
                            ></span>
                            <span
                              className={
                                viewItem === "list"
                                  ? "fa fa-th-list list_icon active"
                                  : "fa fa-th-list list_icon"
                              }
                              onClick={() => handleViewChange("list")}
                            ></span>
                          </div>

                          <div className="col-4">
                            <div className="drpdwn__div text-right-mobile">
                              <span className="mr-2 font__bold">Price </span>
                              <Select
                                className="select_inpt"
                                placeholder="Low - High"
                                onChange={(e) =>
                                  handleTableChange(e, "price", "select")
                                }
                              >
                                <option value="lowHigh">Low - High</option>
                                <option value="highLow">High - Low</option>
                              </Select>
                            </div>
                          </div>

                          <div className="col-12">
                            <Search
                              className="my-3"
                              placeholder="Search Setting #"
                              onSearch={(e) =>
                                handleTableChange(e, "search", "select")
                              }
                              allowClear
                              style={{ width: 250 }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Wrapper>
                <Wrapper>
                  <Spin spinning={tableLoading}>
                    {viewItem === "grid" && (
                      <div className="col-md-12">
                        <div className="prod__section">
                          {settingsGridData.length > 0 ? (
                            <div className="row">
                              {settingsGridData.map((item) => {
                                return (
                                  <div className="col-lg-3 col-md-6 mb-4">
                                    <div className="prod__maindiv">
                                      {/* <div className="chkbx__div">
                                    <input type="checkbox" />
                                  </div> */}

                                      <div
                                        className="prodimg__div linkText"
                                        onClick={() => handleClickToView(item)}
                                      >
                                        <img src={item.imageURL} alt="" />
                                      </div>
                                      <div
                                        className="details__div linkText"
                                        onClick={() => handleClickToView(item)}
                                      >
                                        <span className="style__txt linkText">
                                          {item.settingName}
                                        </span>
                                        <span className="price__txt">
                                          {ViewOptionData.ShowPrice == false ? (
                                            "Call For Price"
                                          ) : (
                                            <>US ${item.retailPrice}</>
                                          )}
                                        </span>
                                      </div>

                                      <div className="editdel__div">
                                        <div
                                          className="video__icon"
                                          onClick={() =>
                                            handleOpenVideoModal(item)
                                          }
                                        >
                                          <img src={Video_icon} alt="" />
                                        </div>
                                      </div>

                                      {!productChange[item.settingID] ? (
                                        <div
                                          className="info__div"
                                          onClick={() =>
                                            handleProductChangeOpen(
                                              item.settingID
                                            )
                                          }
                                        >
                                          <img src={Info_icon} alt="" />
                                        </div>
                                      ) : (
                                        <div className="descr__div">
                                          <div>
                                            <p>
                                              <span>Retail Stock #</span>
                                              <span>{item.settingName}</span>
                                            </p>
                                            <p>
                                              <span>Category</span>
                                              <span>{item.category}</span>
                                            </p>
                                            <p>
                                              <span>Collection</span>
                                              <span>{item.collection}</span>
                                            </p>
                                            <p>
                                              <span>Vendor</span>
                                              <span>{item.vendorName}</span>
                                            </p>
                                            <p>
                                              <span>Cost</span>
                                              <span>${item.cost}</span>
                                            </p>
                                            <p>
                                              <span>Retail</span>
                                              <span>${item.retailPrice}</span>
                                            </p>
                                            <p>
                                              <span>Metal Type</span>
                                              <span>{item.metal}</span>
                                            </p>
                                            <p>
                                              <span>Metal Color</span>
                                              <span>Yellow</span>
                                            </p>
                                          </div>
                                          <div
                                            className="close__div"
                                            onClick={() =>
                                              handleProductChangeClose(
                                                item.settingID
                                              )
                                            }
                                          >
                                            <img src={Close} alt="" />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <div>
                              <Empty />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {viewItem === "list" && (
                      <div className="col-md-12">
                        <div className="lviewprod__tbl info__tool">
                          <Table
                            columns={columns}
                            onChange={handleTableASCDES}
                            className={
                              tableClassAllow == true
                                ? "info__tooltip__table overflow-visible"
                                : "info__tooltip__table"
                            }
                            dataSource={settingsGridData}
                            //scroll={{ y: 300 }}
                            // pagination={{
                            //   showSizeChanger: false,
                            //   total: totalRecord,
                            //   pageSize:
                            //     chooseYourSettingTableFilters.recordsPerPage,
                            //   pageNo: pageNumber,
                            //   onChange: onShowSizeChange,
                            //   onShowSizeChange: onShowSizeChange,
                            // }}
                            pagination={false}
                          />
                        </div>
                        <div className="table__bottom__btn single__item">
                          <div>
                            <Pagination
                              current={pageNumber}
                              pageSize={
                                chooseYourSettingTableFilters.recordsPerPage
                              }
                              total={totalRecord}
                              onChange={onShowSizeChange}
                              onShowSizeChange={onShowSizeChange}
                              showSizeChanger="true"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </Spin>
                </Wrapper>
              </div>
            </div>
          </Spin>
        </div>
      </div>

      <Modal
        className="modalconsupld__section"
        style={{ top: 20 }}
        title={"Video"}
        width={500}
        visible={videoModal}
        onCancel={() => handleCloseVideoModal()}
        footer={[
          <Button onClick={() => handleCloseVideoModal()}>{"Cancel"}</Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  {selectedRow.videoURL ? (
                    <iframe
                      width="100%"
                      height="450"
                      title="diamond-video"
                      className="VideoIframe"
                      src={selectedRow.videoURL}
                      allowfullscreen="true"
                    ></iframe>
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ChooseYourSetting;
