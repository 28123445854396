import config from "./config";
import axios from "axios";

export const httpApps = axios.create({
  baseURL: config.AppsAPIURL,
  headers: {
    "Content-Type": "application/json,multipart/form-data",
    "Access-Control-Allow-Origin": "*"
  }
});

export const httpAdmin = axios.create({
  baseURL: config.AdminAPIURL,
  headers: {
    "Content-Type": "application/json,multipart/form-data",
    "Access-Control-Allow-Origin": "*"
  }
});
