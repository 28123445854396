import React, { useEffect, useState } from "react";

import { Tabs, Tab } from "react-bootstrap";
// import MultiRangeSlider from "../../contacts/MultiRangeSlider"

import SaveIcon from "../../assets/images/choose-setting/save__icon.svg";
import ResetIcon from "../../assets/images/choose-setting/reset__icon.svg";

import MinedDiamond from "./../common/SelectYourDiamond/MinedDiamond";

import LabGrownDiamond from "../common/SelectYourDiamond/LabGrown";
import Compare from "../common/SelectYourDiamond/Compare";
import selectYourDiamondService from "../../services/select-your-diamond.service";
import { useSelector } from "react-redux";

import ViewDiamondDetails from "../common/SelectYourDiamond/ViewDiamondDetails";
import Request from "../common/SelectYourDiamond/Request";
import { useDispatch } from "react-redux";
import {
  handleResetMinedPage,
  handleResetLabPage,
  handleResetFancyPage,
  handleResetRequestPage,
  handleSetSaveSearchMinedTab,
  handleSetSaveFilterMinedTab,
  handleSetSwitchFilter,
  handleSetSaveSearchLabTab,
  handleSetSaveFilterLabTab,
  handleSetSwitchLabFilter,
  handleSetSaveSearchFancyTab,
  handleSetSaveFilterFancyTab,
  handleSetSwitchFancyFilter,
  handleSetDiamondActiveTab,
  handleComaparePageIds,
} from "../../actions/selectYourDiamond/selectYourDiamond";
import { NotificationManager } from "react-notifications";
import colorPanelService from "../../services/color-panel.service";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";

const SelectYourDiamond = ({}) => {
  const search = useLocation().search;
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const isChange = new URLSearchParams(search).get("isChange");
  const setParamValues = new URLSearchParams(search).get("setParamValues");
  const paramsettingShape = new URLSearchParams(search).get("settingShape");
  const paramcenterStoneMinimumCarat = new URLSearchParams(search).get(
    "centerStoneMinimumCarat"
  );
  const paramcenterStoneMaximumCarat = new URLSearchParams(search).get(
    "centerStoneMaximumCarat"
  );
  const paramSettingId = new URLSearchParams(search).get("settingId");
  const paramSettingNumber = new URLSearchParams(search).get("settingNumber");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Mined");
  const [isMinedReset, setIsMinedReset] = useState(false);
  const [isMinedSaveSearch, setIsMinedSaveSearch] = useState(false);
  const [isLabGrownReset, setIsLabGrownReset] = useState(false);
  const [isLabGrownSaveSearch, setIsLabGrownSaveSearch] = useState(false);
  const [navigationData, setNavigationData] = useState([]);
  const [orderNumbers, setOrderNumbers] = useState([]);
  const [isAdvance, setIsAdvance] = useState(false);

  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const pageDetails = useSelector((state) => state.selectYourDiamondReducer);
  const CompareIds = useSelector(
    (state) => state.selectYourDiamondReducer.compareIds.ids
  );
  const dispatch = useDispatch();

  const handleTabChange = (val) => {
    debugger;
    setActiveTab(val);
    if (setParamValues == "True") {
      if (val == "Mined") {
        navigate(
          "/Mined" +
            `?DealerLink=${paramdealerId}&showHeader=True&setParamValues=True&settingShape=${paramsettingShape}&centerStoneMinimumCarat=${paramcenterStoneMinimumCarat}&centerStoneMaximumCarat=${paramcenterStoneMaximumCarat}&headerTab=selectYourDiamond&settingViewEdit=True&settingId=${paramSettingId}&settingNumber=${paramSettingNumber}`
        );
      } else if (val == "Lab") {
        navigate(
          "/Lab" +
            `?DealerLink=${paramdealerId}&showHeader=True&setParamValues=True&settingShape=${paramsettingShape}&centerStoneMinimumCarat=${paramcenterStoneMinimumCarat}&centerStoneMaximumCarat=${paramcenterStoneMaximumCarat}&headerTab=selectYourDiamond&settingViewEdit=True&settingId=${paramSettingId}&settingNumber=${paramSettingNumber}`
        );
      } else if (val == "Request") {
        navigate(
          "/Request" +
            `?DealerLink=${paramdealerId}&showHeader=True&setParamValues=True&settingShape=${paramsettingShape}&centerStoneMinimumCarat=${paramcenterStoneMinimumCarat}&centerStoneMaximumCarat=${paramcenterStoneMaximumCarat}&headerTab=selectYourDiamond&settingViewEdit=True&settingId=${paramSettingId}&settingNumber=${paramSettingNumber}`
        );
      } else if (val == "Compare") {
        var compareIds = CompareIds && CompareIds.toString();
        var pageName = "";
        if (window.location.href.indexOf("Mined") > -1) {
          pageName = "Mined";
        } else {
          pageName = "Lab";
        }
        navigate(
          "/Compare" +
            `?DealerLink=${paramdealerId}&CompareIds=${compareIds}&pageName=${pageName}&showHeader=True&setParamValues=True&settingShape=${paramsettingShape}&centerStoneMinimumCarat=${paramcenterStoneMinimumCarat}&centerStoneMaximumCarat=${paramcenterStoneMaximumCarat}&headerTab=selectYourDiamond&settingViewEdit=True&settingId=${paramSettingId}&settingNumber=${paramSettingNumber}`
        );
      } else {
        navigate(
          "/Mined" +
            `?DealerLink=${paramdealerId}&showHeader=True&setParamValues=True&settingShape=${paramsettingShape}&centerStoneMinimumCarat=${paramcenterStoneMinimumCarat}&centerStoneMaximumCarat=${paramcenterStoneMaximumCarat}&headerTab=selectYourDiamond&settingViewEdit=True&settingId=${paramSettingId}&settingNumber=${paramSettingNumber}`
        );
      }
    } else {
      if (val == "Mined") {
        navigate(
          "/Mined" +
            `?DealerLink=${paramdealerId}&showHeader=True&headerTab=selectYourDiamond`
        );
      } else if (val == "Lab") {
        navigate(
          "/Lab" +
            `?DealerLink=${paramdealerId}&showHeader=True&headerTab=selectYourDiamond`
        );
      } else if (val == "Request") {
        navigate(
          "/Request" +
            `?DealerLink=${paramdealerId}&showHeader=True&headerTab=selectYourDiamond`
        );
      } else if (val == "Compare") {
        var compareIds = CompareIds && CompareIds.toString();
        var pageName = "";
        if (window.location.href.indexOf("Mined") > -1) {
          pageName = "Mined";
        } else {
          pageName = "Lab";
        }
        navigate(
          "/Compare" +
            `?DealerLink=${paramdealerId}&CompareIds=${compareIds}&pageName=${pageName}&showHeader=True&headerTab=selectYourDiamond`
        );
      } else {
        navigate(
          "/Mined" +
            `?DealerLink=${paramdealerId}&showHeader=True&headerTab=selectYourDiamond`
        );
      }
    }
    dispatch(handleSetDiamondActiveTab(val));

    if (val !== "Compare") {
      dispatch(handleComaparePageIds(""));
    }
  };

  const handleReset = () => {
    if (activeTab == "Mined") {
      setIsMinedReset(true);
    } else {
      setIsLabGrownReset(true);
    }
  };

  const handleGetReset = (reset) => {
    if (activeTab == "Mined") {
      setIsMinedReset(reset);
    } else {
      setIsLabGrownReset(reset);
    }
  };

  const handleSaveSearchForPage = () => {
    if (activeTab == "Mined") {
      console.log("RESETMINED");
      //setSaveSearchMined(true);
      dispatch(handleSetSaveSearchMinedTab(true));
      dispatch(handleSetSwitchFilter());
    }
    if (activeTab == "Lab") {
      console.log("RESETLAB");
      //setSaveSearchMined(true);
      dispatch(handleSetSaveSearchLabTab(true));
      dispatch(handleSetSwitchLabFilter());
    }
    if (activeTab == "FancyColor") {
      console.log("RESETFANCY");
      //setSaveSearchMined(true);
      dispatch(handleSetSaveSearchFancyTab(true));
      dispatch(handleSetSwitchFancyFilter());
    }
    NotificationManager.success("Search Saved Successfully.");
  };

  useEffect(() => {
    if (paramdealerId) {
      handleGetNavigationData(paramdealerId);
    }
  }, []);

  useEffect(() => {
    if (pageDetails) {
      if (pageDetails.activeDiamondTab) {
        setActiveTab(pageDetails.activeDiamondTab);
      }
    }
  }, [pageDetails.activeDiamondTab]);

  const handleGetNavigationData = async (id) => {
    let inputData = {
      dealerId: id.toString(),
    };
    // setLoading(true);
    try {
      await selectYourDiamondService
        .GetRingBuilderOpeningPage(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (responseData) {
            let navigationArray = [];
            if (responseData.advancedSearchData) {
              // navigationArray.push(responseData.advancedSearchData[0]);
              if (responseData.advancedSearchData[0].setting == "True") {
                setIsAdvance(true);
              }
            }
            if (responseData.labData) {
              navigationArray.push(responseData.labData[0]);
            }
            if (responseData.minedData) {
              navigationArray.push(responseData.minedData[0]);
            }
            if (responseData.requestDiamondData) {
              navigationArray.push(responseData.requestDiamondData[0]);
            }
            if (responseData.compareDiamondData) {
              navigationArray.push(responseData.compareDiamondData[0]);
            }

            let newArray = [];
            for (let i = 0; i < navigationArray.length + 1; i++) {
              newArray.push((i + 1).toString());
            }

            // let newArray = navigationArray.map((item, index) => {
            //   return index.toString();
            // });

            setOrderNumbers(newArray);

            let newminArr = navigationArray
              .filter((x) => x.setting == "True")
              .map((x) => {
                return x.order;
              });
            console.log(newminArr);
            var val = Math.min(...newminArr);
            console.log(val);
            let newActiveTab = navigationArray
              .filter((item) => {
                return item.setting == "True" && item.order == val.toString();
              })[0]
              .name.toString();
            // setActiveTab(newActiveTab);
            // dispatch(handleSetDiamondActiveTab(newActiveTab));
            setNavigationData(navigationArray);
            debugger;

            if (setParamValues == "True") {
              navigate(
                "/Mined" +
                  `?DealerLink=${paramdealerId}&showHeader=True&setParamValues=True&settingShape=${paramsettingShape}&centerStoneMinimumCarat=${paramcenterStoneMinimumCarat}&centerStoneMaximumCarat=${paramcenterStoneMaximumCarat}&headerTab=selectYourDiamond&settingViewEdit=True&settingId=${paramSettingId}&settingNumber=${paramSettingNumber}`
              );
            } else {
              debugger;
              if (isChange == "True") {
                if (newActiveTab == "Mined") {
                  navigate(
                    "/Mined" +
                      `?DealerLink=${paramdealerId}&showHeader=True&headerTab=selectYourDiamond`
                  );
                  setActiveTab("Mined");
                } else if (newActiveTab == "Lab") {
                  navigate(
                    "/Lab" +
                      `?DealerLink=${paramdealerId}&showHeader=True&headerTab=selectYourDiamond`
                  );
                  setActiveTab("Lab");
                } else if (newActiveTab == "Request") {
                  navigate(
                    "/Request" +
                      `?DealerLink=${paramdealerId}&showHeader=True&headerTab=selectYourDiamond`
                  );
                  setActiveTab(Request);
                } else if (newActiveTab == "Compare") {
                  navigate(
                    "/Compare" +
                      `?DealerLink=${paramdealerId}&showHeader=True&headerTab=selectYourDiamond`
                  );
                  setActiveTab(Compare);
                } else {
                  navigate(
                    "/Mined" +
                      `?DealerLink=${paramdealerId}&showHeader=True&headerTab=selectYourDiamond`
                  );
                  setActiveTab("Mined");
                }
                setActiveTab(newActiveTab);
                dispatch(handleSetDiamondActiveTab(newActiveTab));
              } else {
              }
            }
          }
          // setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
        });
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };
  function getTextStyle(name) {
    if (activeTab == name) {
      return {
        color: "#fff",
        background: `${varColorObj.columnHeaderAccentColor}`,
      };
    }
  }

  const handleResetForPage = () => {
    if (activeTab == "Mined") {
      console.log("RESETMINED");
      dispatch(handleResetMinedPage());
      dispatch(handleSetSaveSearchMinedTab(false));
      //dispatch(handleSetSaveFilterMinedTab(obj));
    }
    if (activeTab == "Lab") {
      console.log("RESETLAB");
      dispatch(handleResetLabPage());
      dispatch(handleSetSaveSearchLabTab(false));
      //dispatch(handleSetSaveFilterLabTab(obj));
    }
    if (activeTab == "FancyColor") {
      console.log("RESETFANCY");
      dispatch(handleResetFancyPage());
      dispatch(handleSetSaveSearchFancyTab(false));
      //dispatch(handleSetSaveFilterFancyTab(objFancy));
    }
    if (activeTab == "Request") {
      console.log("RESETREQUEST");
      dispatch(handleResetRequestPage());
    }
    NotificationManager.success("Search Reset Successfully.");
  };

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [wrapperLoading, setWrapperLoading] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [toggle, setToggle] = useState(true);
  useEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "RingBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              var tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              debugger;
              console.log(tempobj);
              console.log(initialColorObj);
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
              // dispatch(handleSwitchChange());
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  /* Color Individually Ends */

  return (
    <React.Fragment>
      {/* <span className="back__btn"> {"<< Back To Item Details"} </span> */}
      <div className="allprod_section jwlsrch__section app__preview choose__setting">
        <div className="row">
          <div className="col-md-12">
            <div className="prodetail__section">
              <div className="col-md-12">
                <div className="prod__maindiv app__preview p-0 border-0">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="prodesc__div">
                        <div className="tright__btns">
                          {activeTab != "Compare"
                            ? activeTab !== "Request" && (
                                <>
                                  <p onClick={handleSaveSearchForPage}>
                                    <img src={SaveIcon} />
                                    <span>Save Search</span>
                                  </p>
                                  <p onClick={handleResetForPage}>
                                    <img src={ResetIcon} />
                                    <span>Reset</span>
                                  </p>
                                </>
                              )
                            : null}
                        </div>

                        <div className="custom__tab">
                          <ul>
                            {orderNumbers.map((order) => {
                              return navigationData.map((tab) => {
                                return (
                                  tab.setting == "True" &&
                                  order == tab.order && (
                                    <li
                                      style={getTextStyle(tab.name)}
                                      onClick={() => handleTabChange(tab.name)}
                                      title={tab.name}
                                    >
                                      <div className="mr-2 nav-btn">
                                        {tab.name == "Compare"
                                          ? activeTab == "Compare"
                                            ? "Compare"
                                            : "Compare" +
                                              " " +
                                              "(" +
                                              //   CompareIds
                                              // ? CompareIds.length
                                              // : 0 +

                                              ")"
                                          : tab.name == "Fancy Colored"
                                          ? tab.name.split(/(?=[A-Z])/)[0] +
                                            " " +
                                            tab.name.split(/(?=[A-Z])/)[1]
                                          : tab.name}
                                      </div>
                                    </li>
                                  )
                                );
                              });
                            })}
                          </ul>
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SelectYourDiamond;
