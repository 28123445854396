import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import ChooseYourSetting from "./component/ApplicationPreview/ChooseYourSetting";
import SelectYourDiamond from "./component/ApplicationPreview/SelectYourDiamond";
import CompleteYourRing from "./component/ApplicationPreview/CompleteYourRing";
import Layout from "./component/Layout";
import ChooseSettingItemDetails from "./component/ApplicationPreview/ChooseSettingItemDetails";
import MinedDiamond from "./component/common/SelectYourDiamond/MinedDiamond";
import LabGrown from "./component/common/SelectYourDiamond/LabGrown";
import Compare from "./component/common/SelectYourDiamond/Compare";
import Request from "./component/common/SelectYourDiamond/Request";
import ViewDiamondDetails from "./component/common/SelectYourDiamond/ViewDiamondDetails";

const PrivateRoute = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<PrivateRoute />}>
            <Route path="/SelectYourDiamond" element={<SelectYourDiamond />} />
            <Route path="/" element={<ChooseYourSetting />} />
            <Route
              path="/ChooseSettingItemDetails"
              element={<ChooseSettingItemDetails />}
            />
            <Route
              path="/viewDiamondDetails"
              element={<ViewDiamondDetails />}
            />

            <Route path="/CompleteYourRing" element={<CompleteYourRing />} />

            <Route path="/Mined" element={<MinedDiamond />} />
            <Route path="/Lab" element={<LabGrown />} />
            <Route path="/Request" element={<Request />} />
            <Route path="/Compare" element={<Compare />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
