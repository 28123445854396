import { RESET_APP, SET_LOGIN_DETAILS, SET_OPTION_DATA } from "./types";

export const handleSetLoginDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOGIN_DETAILS,
      payload: data,
    });
  } catch (error) {}
};

export const handleSetOptionData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_OPTION_DATA,
      payload: data,
    });
  } catch (error) {}
};

export const handleResetApp = (data) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_APP,
    });
  } catch (error) {}
};
