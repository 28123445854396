import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Tooltip,
  Select,
  Table,
  Slider,
  Checkbox,
  Spin,
  Pagination,
  Modal,
  Button,
  Empty,
  Input,
} from "antd";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import roundDiamond from "../../../assets/images/daimond-link.jpg";

import Round from "../../../assets/images/choose-setting/round.svg";
import Radiant from "../../../assets/images/choose-setting/Radiant.svg";
import Asscher from "../../../assets/images/choose-setting/Asscher.svg";
import Cushion from "../../../assets/images/choose-setting/Cushion.svg";
import Emerald from "../../../assets/images/choose-setting/Emerald.svg";
import Heart from "../../../assets/images/choose-setting/Heart.svg";
import Marquise from "../../../assets/images/choose-setting/Marquise.svg";
import Oval from "../../../assets/images/choose-setting/Oval.svg";
import Princess from "../../../assets/images/choose-setting/Princess.svg";
import Pear from "../../../assets/images/choose-setting/Radiant.svg";
import Info_icon from "../../../assets/images/info_icon.png";
import Video_icon from "../../../assets/images/icons/video_icon.svg";
import Close from "../../../assets/images/close.png";
import Eye_icon from "../../../assets/images/eye__icon.svg";
import Rectangle from "../../../assets/images/Rectangle.png";
import NoDiamond from "../../../assets/images/no_pre.png";
import {
  handleComaparePageIds,
  handleSetDiamondDetails,
  handleSetSaveEveryTimeLabTab,
  handleSetSaveFilterLabTab,
} from "../../../actions/selectYourDiamond/selectYourDiamond";
import {
  handleViewDetailsPage,
  handleSetDiamondActiveTab,
} from "../../../actions/selectYourDiamond/selectYourDiamond";
import { useDispatch, useSelector } from "react-redux";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import {
  handleActiveTab,
  handleAddDiamondViewEdit,
} from "../../../actions/header/headerActions";
import { NotificationManager } from "react-notifications";
import headerService from "../../../services/header.service";
import styled from "styled-components";
import colorPanelService from "../../../services/color-panel.service";
import SelectYourDiamond from "../../ApplicationPreview/SelectYourDiamond";
import { useNavigate, useLocation } from "react-router-dom";

const LabGrown = () => {
  const search = useLocation().search;
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const paramsettingShape = new URLSearchParams(search).get("settingShape");
  const paramcenterStoneMinimumCarat = new URLSearchParams(search).get(
    "centerStoneMinimumCarat"
  );
  const paramcenterStoneMaximumCarat = new URLSearchParams(search).get(
    "centerStoneMaximumCarat"
  );
  const paramSettingId = new URLSearchParams(search).get("settingId");
  const paramSettingNumber = new URLSearchParams(search).get("settingNumber");
  const navigate = useNavigate();
  const { Search } = Input;
  const resetVal = useSelector((state) => state.selectYourDiamondReducer);
  const loginDetails = useSelector((state) => state.loginDetailsReducer);

  const settingDetails = useSelector((state) => state.chooseYourSettingReducer);
  const dispatch = useDispatch();
  const [productChange, setProductChange] = useState({});
  const [viewItem, setViewItem] = useState("list");
  const [hideShowFilter, setHideShowFilter] = useState(true);
  const [priceValue, setPriceValue] = useState([0, 9999999]);
  const [caratSlideValue, setCaratSlideValue] = useState([0, 22]);
  const [priceValue1, setPriceValue1] = useState([0, 9999999]);
  const [caratSlideValue1, setCaratSlideValue1] = useState([0, 22]);
  const [loading, setLoading] = useState(false);
  const [filterloading, setFilterLoading] = useState(false);
  const [reqDiamondloading, setReqDiamondLoading] = useState(false);
  const [labDiamondList, setLabDiamondList] = useState([]);
  const [isVideo, setIsVideo] = useState(false);
  const [videoImageModal, setVideoImageModal] = useState(false);
  const [selectedRowDetails, setSelectedRowDetails] = useState({});
  const [searchSku, setSearchSku] = useState("");
  const [isFilter, setIsFilter] = useState(false);

  const [state, setState] = useState({
    productChange: {},
    PriceMin: 0,
    PriceMax: 9999999,
    CaratMin: 0,
    CaratMax: 22,
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    Certificates: [],
  });
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [diamondFormData, setDiamondFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    comments: "",
  });

  const [validations, setValidations] = useState({
    firstNameVal: "",
    lastNameVal: "",
    phoneNumberVal: "",
    emailAddressVal: "",
    commentsVal: "",
  });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalRowCount, setTotalRowCount] = useState(0);
  const [dealerId, setDealerId] = useState();
  const [rowDetails, setRowDetails] = useState({});
  const [filterVals, setFilterVals] = useState({
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    PriceMin: 0,
    PriceMax: 9999999,
    CaratMin: 0,
    CaratMax: 22,
    Certificates: [],
  });

  const [name, setName] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const [diamondInfo, setDiamondInfo] = useState({});
  const [hoverInfo, setHoverInfo] = useState(false);
  const [dealerHoverId, setDealerHoverId] = useState(0);
  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [sortColumnName, setSortColumnName] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [settingShape, setSettingShape] = useState("");
  const [isdisableShape, setDisableShape] = useState(false);
  const [tableClassAllow, setTableClassAllow] = useState(false);
  // useEffect(() => {
  //   setState({
  //     PriceMin: 0,
  //     PriceMax: 9999999,
  //     CaratMin: 0,
  //     CaratMax: 22,
  //     Shape: [],
  //     Cut: [],
  //     Color: [],
  //     Polish: [],
  //     Fluorescence: [],
  //     Symmetry: [],
  //     productChange: {},
  //   });
  //   setPriceValue([0, 9999999]);
  //   setCaratSlideValue([0, 22]);
  // }, [resetVal.resetMined]);

  // Options API

  const [ViewOptionData, setViewOptionData] = useState({
    RingBuilderUrl: "",
    InternalUseLink: true,
    RingSizeType: null,
    InitiallySortbox1: null,
    InitiallySortbox2: null,
    DefaultMetalType: null,
    ShowAdvancedSearch: true,
    RingsShow: true,
    ShowAddress: true,
    ShowCenterStone: true,
    ShowPrice: true,
    GSTTax: true,
    GSTTaxValue: "",
    PinterestShare: true,
    TwitterShare: true,
    FacebookShare: true,
    FacebookLike: true,
    GooglePlus: true,
    InstagramShare: true,
    AddToCart: true,
    AltShoppingCart: "",
    ShowBuySetting: true,
    ShowBuyDiamond: true,
    ShowViewCart: true,
  });

  const handleGetOptionsDetails = (id) => {
    let inputData = {
      dealerID: id,
      Type: "RingBuilder",
    };

    try {
      headerService
        .GetRingBuilderOptionDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          if (message == "Success") {
            if (responseData) {
              debugger;
              setViewOptionData((prevState) => ({
                ...prevState,
                RingBuilderUrl: responseData.ringBuilderURL,
                InternalUseLink: responseData.internalLinkRB,
                RingSizeType: responseData.ringSizeType,
                InitiallySortbox1: responseData.ringInitiallySort1,
                InitiallySortbox2: responseData.ringInitiallySortDesc,
                DefaultMetalType: responseData.defaultMetalType,
                ShowAdvancedSearch: responseData.showAdvanceRingBuilder,
                RingsShow: responseData.showScheduleView,
                ShowAddress: responseData.showAddresses,
                ShowCenterStone: responseData.showCenterStoneSize,
                ShowPrice: responseData.showPricesOnSearchPage,
                GSTTax: responseData.showGstPrice,
                GSTTaxValue: responseData.taxRate,
                PinterestShare: responseData.showPinterestShare,
                TwitterShare: responseData.showTwitterShare,
                FacebookShare: responseData.showFacebookShare,
                FacebookLike: responseData.showFacebookLike,
                GooglePlus: responseData.showGooglePlus,
                InstagramShare: responseData.showInstagramShare,
                AddToCart: responseData.showAddToCartButton,
                AltShoppingCart: responseData.shoppingCartURL,
                ShowBuySetting: responseData.showBuyWithoutCenterStone,
                ShowBuyDiamond: responseData.buyDiamondOnly,
                ShowViewCart: responseData.showViewCartButtonRB,
              }));
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (paramdealerId) {
      let filters = filterVals;
      let statefilters = state;

      if (paramsettingShape) {
        setDisableShape(true);
        let shapes = paramsettingShape;
        setSettingShape(shapes);
        filters["Shape"] = shapes;
        if (paramcenterStoneMinimumCarat || paramcenterStoneMaximumCarat) {
          filters["CaratMin"] = Number(paramcenterStoneMinimumCarat);
          filters["CaratMax"] = Number(paramcenterStoneMaximumCarat);
          statefilters["CaratMin"] = Number(paramcenterStoneMinimumCarat);
          statefilters["CaratMax"] = Number(paramcenterStoneMaximumCarat);
          setState(statefilters);
          setCaratSlideValue([
            Number(paramcenterStoneMinimumCarat),
            Number(paramcenterStoneMaximumCarat),
          ]);
        }
        setFilterVals(filters);
      }
      handleGetOptionsDetails(paramdealerId);
      handleGetMinedDiamondFilters(
        paramdealerId,
        pageNo,
        pageSize,
        resetVal.saveSearchLab,
        filters
      );

      setDealerId(paramdealerId);
    }
  }, [resetVal.resetLab, resetVal.saveSearchLab]);

  const handleOnChange = (row, e) => {
    const temp = labDiamondList.map((x) => {
      if (x.intDiamondId == row.intDiamondId) {
        return { ...x, checkbox: e == true ? true : false };
      } else return x;
    });

    let saveRow = temp.filter((obj) => obj.checkbox == true);
    setLabDiamondList(temp);
    var CompareIds = saveRow.map((x) => x.intDiamondId);
    let compareData = {
      ids: CompareIds,
      pageName: "Lab",
    };
    dispatch(handleComaparePageIds(compareData));
  };

  const handleCompareRedirect = (row, e) => {
    let compareIds = [];

    if (resetVal) {
      if (resetVal.compareIds) {
        compareIds = resetVal.compareIds.ids;
        compareIds.push(row.intDiamondId);
      }
    }
    const distanctIds = [...new Set(compareIds)];

    if (compareIds.length > 0) {
      let compareData = {
        ids: distanctIds,
        pageName: "Lab",
      };
      dispatch(handleComaparePageIds(compareData));
      dispatch(handleSetDiamondActiveTab("Compare"));
      navigate(
        "/Compare" +
          `?DealerLink=${paramdealerId}&CompareIds=${distanctIds}&pageName="Lab"&headerTab=selectYourDiamond`
      );
    } else {
      NotificationManager.error("Kindly Select a diamond to Compare");
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "checkbox",
      width: 30,
      render: (item, row) => {
        return (
          <div
            className="action__btns"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Checkbox
              name="checkbox"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.checkbox == true ? true : false}
              onChange={(e) =>
                handleOnChange(row, e.target.checked, "checkbox")
              }
            />
          </div>
        );
      },
    },

    {
      title: "Shape",
      dataIndex: "txtShape",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div className="shape__img">
            <img src={row.imageFileName} alt="" />
            <span>{row.txtShape}</span>
          </div>
        );
      },
    },
    // {
    //   title: "SKU",
    //   dataIndex: "intDiamondId"
    // },
    {
      title: "Carats",
      dataIndex: "fltCarat",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Color",
      dataIndex: "txtColor",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Clarity",
      dataIndex: "txtClarity",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Cut",
      dataIndex: "txtCutGrade",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    // {
    //   title: "In House",
    //   dataIndex: "txtInhouse",
    //   width: 150
    // },
    {
      title: "Depth",
      dataIndex: "fltDepth",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Table",
      dataIndex: "fltTable",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Polish",
      dataIndex: "txtPolish",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Symmetry",
      dataIndex: "txtSymmetry",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Measurement",
      dataIndex: "txtMeasurements",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Cert.",
      dataIndex: "txtCertificate",
      sorter: (a, b) => {},
      className: "my-hover-pointer linkText",
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            {row.certlink !== "" ? (
              <span onClick={() => handleOpenCertificate(row.certlink)}>
                {row.txtCertificate}
              </span>
            ) : (
              <span></span>
            )}
          </div>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "realPrice",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return ViewOptionData.ShowPrice == false ? (
          <span>Call</span>
        ) : (
          <>
            <>
              <div>
                {row.realPrice == "0" ? (
                  <>
                    Call <span className="hideInMobile">For Price</span>
                  </>
                ) : (
                  <span>
                    $
                    {parseInt(row.realPrice)
                      .toFixed()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                )}
              </div>
            </>
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (index, row) => (
        <div>
          <div className="action__btns">
            {/* {!diamondInfo[row.intDiamondId] ? ( */}
            <div
              className="image__block hideInMobile"
              // onMouseEnter={() => handleOpenDiamondInfo(row.intDiamondId)}
              // onMouseOut={() => handleCloseDiamondInfo(row.intDiamondId)}
            >
              <img
                src={Info_icon}
                alt=""
                onMouseEnter={() => handleOpenDiamondInfo(row.intDiamondId)}
                onMouseOut={() => handleCloseDiamondInfo(row.intDiamondId)}
              />
            </div>
            {/* ) : ( */}
            {/* {diamondInfo[row.intDiamondId] && ( */}
            <div
              className={
                hoverInfo == true && dealerHoverId == row.intDiamondId
                  ? "info__tooltip"
                  : "display-none"
              }
            >
              <div
                className="close__div"
                onClick={() => handleCloseDiamondInfo(row.intDiamondId)}
              >
                <img src={Close} alt="" />
              </div>
              {/* <p>
                <span>Depth</span>
                <span>{row.fltDepth}</span>
              </p>
              <p>
                <span>Table</span>
                <span>{row.fltTable}</span>
              </p>
              <p>
                <span>Polish</span>
                <span>{row.txtPolish}</span>
              </p>
              <p>
                <span>Symmetry</span>
                <span>{row.txtSymmetry}</span>
              </p>
              <p>
                <span>Measurement</span>
                <span>{row.txtMeasurements}</span>
              </p> */}
              <p>
                <span> {"Shape"} </span>
                {/* <span>:</span> */}
                <span>: {row.txtShape}</span>
              </p>
              <p>
                <span> {"Carats"} </span>
                {/* <span>:</span> */}
                <span>: {row.fltCarat}</span>
              </p>
              <p>
                <span> {"Color"} </span>
                {/* <span>:</span> */}
                <span>: {row.txtColor}</span>
              </p>
              <p>
                <span> {"Clarity"} </span>
                {/* <span>:</span> */}
                <span>: {row.txtClarity}</span>
              </p>
              <p>
                <span> {"Cut"} </span>
                {/* <span>:</span> */}
                <span>: {row.txtCutGrade}</span>
              </p>
              <p>
                <span> {"In House"} </span>
                {/* <span>:</span> */}
                <span>: {row.inhouse}</span>
              </p>

              <p>
                <span> {"Depth"} </span>
                {/* <span>:</span> */}
                <span>: {row.fltDepth}</span>
              </p>
              <p>
                <span> {"Table"} </span>
                {/* <span>:</span> */}
                <span>: {row.fltTable}</span>
              </p>
              <p>
                <span> {"Certificate"} </span>
                {/* <span>:</span> */}
                <span>: {row.txtCertificate}</span>
              </p>
              <p>
                <span> {"Polish"} </span>
                {/* <span>:</span> */}
                <span>: {row.txtPolish}</span>
              </p>
              <p>
                <span> {"Measurement"} </span>
                {/* <span>:</span> */}
                <span>: {row.txtMeasurements}</span>
              </p>
              <p>
                <span> {"Retail Price"} </span>
                {/* <span>:</span> */}
                <span>
                  {row.realPrice == "0" ? (
                    ":   Call For Price"
                  ) : (
                    <span>
                      : $
                      {parseInt(row.realPrice)
                        .toFixed()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  )}
                </span>
              </p>
            </div>
            {/* )} */}
            {/* )} */}

            <div
              className={
                row.videoFileName == ""
                  ? "image__block__canhover hideInMobile"
                  : "image__block hideInMobile"
              }
              onClick={
                row.videoFileName != ""
                  ? () => {
                      handleOpenVideoImageModal(row, true);
                    }
                  : ""
              }
            >
              <img
                src={Video_icon}
                alt=""
                className={row.videoFileName == "" ? "display-none" : ""}
              />
            </div>
            <div className="image__block">
              <img
                src={Eye_icon}
                alt=""
                onClick={() => handleViewDetails(row)}
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" width="50px" />,
      Shape: <span>Oval</span>,
      SKU: <span>176152702</span>,
      Carat: <span>2.53</span>,
      Color: <span>G</span>,
      Clarity: <span>VVS2</span>,
      Cut: <span>Excellent</span>,
      InHouse: <span>Yes</span>,
      Depth: <span>60.2</span>,
      Table: <span>58</span>,
      Polish: <span>Excellent</span>,
      Measurement: <span>10.35X7.84X4.80</span>,
      Cert: <span>GIA</span>,
      Price: <span>$190.00</span>,
    },
  ];

  const { Option } = Select;

  const handleProductChangeOpen = (intDiamondId) => {
    var newproductChange = productChange;
    newproductChange[intDiamondId] = true;
    setProductChange((prevState) => ({
      ...prevState,
      ...newproductChange,
    }));
  };

  const handleProductChangeClose = (intDiamondId) => {
    var newproductChange = productChange;
    newproductChange[intDiamondId] = false;
    setProductChange((prevState) => ({
      ...prevState,
      ...newproductChange,
    }));
  };

  const handleViewChange = (viewname) => {
    setViewItem(viewname);
  };

  const handleViewDetails = (details, type) => {
    debugger;
    window.parent.postMessage("submitted form", "*");
    let obj = details;
    obj["pageName"] = "Lab";
    obj["shapeFilters"] = filterVals.Shape;
    dispatch(handleViewDetailsPage(obj));

    if (type == "addSetting") {
      navigate(
        "/" +
          `?DealerLink=${paramdealerId}&settingShape=${rowDetails.txtShape}&diamondId=${rowDetails.intDiamondId}&pageName="Lab"&headerTab=chooseYourSetting`
      );
      dispatch(handleActiveTab("chooseYourSetting")); // require navigate with &headerTab=chooseYourSetting&settingViewEdit=True&diamondViewEdit=True
      dispatch(handleAddDiamondViewEdit());
    } else {
      dispatch(handleActiveTab("selectYourDiamond"));

      if (paramSettingId) {
        dispatch(handleActiveTab("selectYourDiamond"));
        navigate(
          "/viewDiamondDetails" +
            `?isSocialPage=True&DealerLink=${obj.gfLinkID}&diamondId=${obj.intDiamondId}&pageNo=1&isAllowed=True&pageName=${obj.pageName}&shapeFilters=${obj.shapeFilters}&txtCutlet=${obj.txtCulet}&txtClarity=${obj.txtClarity}&intColorPriority=${obj.intColorPriority}&settingId=${paramSettingId}&settingNumber=${paramSettingNumber}&headerTab=selectYourDiamond&settingViewEdit=True&diamondViewEdit=True`
        );
      } else {
        dispatch(handleActiveTab("selectYourDiamond"));
        navigate(
          "/viewDiamondDetails" +
            `?isSocialPage=True&DealerLink=${obj.gfLinkID}&diamondId=${obj.intDiamondId}&pageNo=1&isAllowed=True&pageName=${obj.pageName}&shapeFilters=${obj.shapeFilters}&txtCutlet=${obj.txtCulet}&txtClarity=${obj.txtClarity}&intColorPriority=${obj.intColorPriority}&headerTab=selectYourDiamond&diamondViewEdit=True`
        );
      }
    }
    // dispatch(handleSetDiamondDetails(details));
  };
  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const firstSliderPriceValue = (e, num) => {
    setIsFilter(true);
    let newArr = [...priceValue];
    var StrValue = e.target.value && e.target.value.replace("$", "");
    var Value = StrValue.replace(/,/g, "");

    if (Number(parseFloat(Value.replace(/,/g, ""))) > priceValue1[1]) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setPriceValue(newArr);
        var tempArray = filterVals;
        tempArray["PriceMin"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      }
      // else {
      //   newArr[0] = Value.replace(/,/g, "");
      //   setPriceValue(newArr);
      //   var tempArray = filterVals;
      //   tempArray["PriceMin"] = Value.replace(/,/g, "");
      //   setFilterVals(tempArray);
      //   dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      // }
      else {
        if (newArr[0] == 0 && Value.includes(".")) {
          newArr[0] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[0] = Value;
            setPriceValue(newArr);
          }
        }

        var tempArray = filterVals;
        tempArray["PriceMin"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      }

      handleGetLabDiamondList(
        dealerId,
        pageNo,
        pageSize,
        filterVals.Shape,
        newArr[0] == "" ? 0 : newArr[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificate, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        true // isFilter
      );
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPriceValue(newArr);
        var tempArray = filterVals;
        tempArray["PriceMax"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      }
      // else {
      //   newArr[1] = Value.replace(/,/g, "");
      //   setPriceValue(newArr);
      //   var tempArray = filterVals;
      //   // tempArray["PriceMax"] = 0;
      //   tempArray["PriceMax"] = Value.replace(/,/g, "");
      //   setFilterVals(tempArray);
      //   dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      // }
      else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[1] = Value;
            setPriceValue(newArr);
          }
        }

        var tempArray = filterVals;
        tempArray["PriceMax"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      }
      handleGetLabDiamondList(
        dealerId,
        pageNo,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        newArr[1] == "" ? 0 : newArr[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificate, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        true // isFilter
      );
    }
  };

  const firstsetCaratSlideValue = (e, num) => {
    setIsFilter(true);
    let newArr = [...caratSlideValue];
    var StrValue = e.target.value && e.target.value.replace("$", "");
    var Value = StrValue.replace(/,/g, "");
    if (
      Number(parseFloat(e.target.value.replace(/,/g, ""))) > caratSlideValue1[1]
    ) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "") {
        newArr[0] = 0;
        setCaratSlideValue(newArr);
        var tempArray = filterVals;
        tempArray["CaratMin"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      }
      // else {
      //   newArr[0] = e.target.value.replace(/,/g, "");
      //   setCaratSlideValue(newArr);
      //   var tempArray = filterVals;
      //   tempArray["CaratMin"] = e.target.value.replace(/,/g, "");
      //   setFilterVals(tempArray);
      //   dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      // }
      else {
        if (newArr[0] == 0 && Value.includes(".")) {
          newArr[0] = Value;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[0] = Value;
            setCaratSlideValue(newArr);
          }
        }

        var tempArray = filterVals;
        tempArray["CaratMin"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      }

      handleGetLabDiamondList(
        dealerId,
        pageNo,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        newArr[0] == "" ? 0 : newArr[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificate, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        true // isFilter
      );
      console.log(caratSlideValue);
    } else if (Number(num) == 1) {
      if (e.target.value == "") {
        newArr[1] = 0;
        setCaratSlideValue(newArr);
        var tempArray = filterVals;
        tempArray["CaratMax"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      }
      // else {
      //   newArr[1] = e.target.value.replace(/,/g, "");
      //   setCaratSlideValue(newArr);
      //   var tempArray = filterVals;
      //   tempArray["CaratMax"] = e.target.value.replace(/,/g, "");
      //   setFilterVals(tempArray);
      //   dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      // }
      else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[1] = Value;
            setCaratSlideValue(newArr);
          }
        }

        var tempArray = filterVals;
        tempArray["CaratMax"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeLabTab(tempArray));
      }
      handleGetLabDiamondList(
        dealerId,
        pageNo,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        newArr[1] == "" ? 0 : newArr[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificate, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        true // isFilter
      );
    }
  };

  const handleLabGrownFilterChange = (val, name, type) => {
    if (name == "Shape" && isdisableShape == true) {
      return false;
    } else {
      setIsFilter(true);
      if (type == "filter") {
        let tempArray = filterVals[name];
        if (tempArray.includes(val)) {
          const index = tempArray.indexOf(val);
          tempArray.splice(index, 1);
        } else {
          tempArray.push(val);
        }
        setFilterVals({
          ...filterVals,
          [name]: tempArray,
        });
        if (name == "Shape") {
          handleGetLabDiamondList(
            dealerId,
            pageNo,
            pageSize,
            tempArray,
            priceValue[0],
            priceValue[1],
            caratSlideValue[0],
            caratSlideValue[1],
            state.Color,
            state.Clarity,
            state.Cut,
            state.Polish,
            state.Symmetry,
            state.Fluorescence,
            filterVals.DepthMin,
            filterVals.DepthMax,
            filterVals.TableMin,
            filterVals.TableMax,
            filterVals.Certificate, // Certificate
            searchSku, //Sku
            filterVals.Color, // color
            filterVals.Clarity, // clarity
            filterVals.Cut, // cut
            filterVals.Polish, // polish
            filterVals.Symmetry, //symmetry
            filterVals.Fluorescence, //fluorescence
            true // isFilter
          );
        }

        if (name == "Cut") {
          handleGetLabDiamondList(
            dealerId,
            pageNo,
            pageSize,
            filterVals.Shape,
            priceValue[0],
            priceValue[1],
            caratSlideValue[0],
            caratSlideValue[1],
            state.Color,
            state.Clarity,
            state.Cut,
            state.Polish,
            state.Symmetry,
            state.Fluorescence,
            filterVals.DepthMin,
            filterVals.DepthMax,
            filterVals.TableMin,
            filterVals.TableMax,
            filterVals.Certificate, // Certificate
            searchSku, //Sku
            filterVals.Color, // color
            filterVals.Clarity, // clarity
            filterVals.Cut, // cut
            filterVals.Polish, // polish
            filterVals.Symmetry, //symmetry
            filterVals.Fluorescence, //fluorescence
            true // isFilter
          );
        }
        if (name == "Color") {
          handleGetLabDiamondList(
            dealerId,
            pageNo,
            pageSize,
            filterVals.Shape,
            priceValue[0],
            priceValue[1],
            caratSlideValue[0],
            caratSlideValue[1],
            state.Color,
            state.Clarity,
            state.Cut,
            state.Polish,
            state.Symmetry,
            state.Fluorescence,
            filterVals.DepthMin,
            filterVals.DepthMax,
            filterVals.TableMin,
            filterVals.TableMax,
            filterVals.Certificate, // Certificate
            searchSku, //Sku
            tempArray, // color
            filterVals.Clarity, // clarity
            filterVals.Cut, // cut
            filterVals.Polish, // polish
            filterVals.Symmetry, //symmetry
            filterVals.Fluorescence, //fluorescence
            true // isFilter
          );
        }
        if (name == "Clarity") {
          handleGetLabDiamondList(
            dealerId,
            pageNo,
            pageSize,
            filterVals.Shape,
            priceValue[0],
            priceValue[1],
            caratSlideValue[0],
            caratSlideValue[1],
            state.Color,
            state.Clarity,
            state.Cut,
            state.Polish,
            state.Symmetry,
            state.Fluorescence,
            filterVals.DepthMin,
            filterVals.DepthMax,
            filterVals.TableMin,
            filterVals.TableMax,
            filterVals.Certificate, // Certificate
            searchSku, //Sku
            filterVals.Color, // color
            tempArray, // clarity
            filterVals.Cut, // cut
            filterVals.Polish, // polish
            filterVals.Symmetry, //symmetry
            filterVals.Fluorescence, //fluorescence
            true // isFilter
          );
        }
        if (name == "Polish") {
          handleGetLabDiamondList(
            dealerId,
            pageNo,
            pageSize,
            filterVals.Shape,
            priceValue[0],
            priceValue[1],
            caratSlideValue[0],
            caratSlideValue[1],
            state.Color,
            state.Clarity,
            state.Cut,
            state.Polish,
            state.Symmetry,
            state.Fluorescence,
            filterVals.DepthMin,
            filterVals.DepthMax,
            filterVals.TableMin,
            filterVals.TableMax,
            filterVals.Certificate, // Certificate
            searchSku, //Sku
            filterVals.Color, // color
            filterVals.Clarity, // clarity
            filterVals.Cut, // cut
            tempArray, // polish
            filterVals.Symmetry, //symmetry
            filterVals.Fluorescence, //fluorescence
            true // isFilter
          );
        }
        if (name == "Fluorescence") {
          handleGetLabDiamondList(
            dealerId,
            pageNo,
            pageSize,
            filterVals.Shape,
            priceValue[0],
            priceValue[1],
            caratSlideValue[0],
            caratSlideValue[1],
            state.Color,
            state.Clarity,
            state.Cut,
            state.Polish,
            state.Symmetry,
            state.Fluorescence,
            filterVals.DepthMin,
            filterVals.DepthMax,
            filterVals.TableMin,
            filterVals.TableMax,
            filterVals.Certificate, // Certificate
            searchSku, //Sku
            filterVals.Color, // color
            filterVals.Clarity, // clarity
            filterVals.Cut, // cut
            filterVals.Polish, // polish
            filterVals.Symmetry, //symmetry
            tempArray, //fluorescence
            true // isFilter
          );
        }
        if (name == "Symmetry") {
          handleGetLabDiamondList(
            dealerId,
            pageNo,
            pageSize,
            filterVals.Shape,
            priceValue[0],
            priceValue[1],
            caratSlideValue[0],
            caratSlideValue[1],
            state.Color,
            state.Clarity,
            state.Cut,
            state.Polish,
            state.Symmetry,
            state.Fluorescence,
            filterVals.DepthMin,
            filterVals.DepthMax,
            filterVals.TableMin,
            filterVals.TableMax,
            filterVals.Certificate, // Certificate
            searchSku, //Sku
            filterVals.Color, // color
            filterVals.Clarity, // clarity
            filterVals.Cut, // cut
            filterVals.Polish, // polish
            tempArray, //symmetry
            filterVals.Fluorescence, //fluorescence
            true // isFilter
          );
        }
      } else {
        setFilterVals({
          ...filterVals,
          [name]: val,
        });
        if (name == "DepthMin") {
          handleGetLabDiamondList(
            dealerId,
            pageNo,
            pageSize,
            filterVals.Shape,
            priceValue[0],
            priceValue[1],
            caratSlideValue[0],
            caratSlideValue[1],
            state.Color,
            state.Clarity,
            state.Cut,
            state.Polish,
            state.Symmetry,
            state.Fluorescence,
            val,
            filterVals.DepthMax,
            filterVals.TableMin,
            filterVals.TableMax,
            filterVals.Certificates, // Certificate
            searchSku, //Sku
            filterVals.Color, // color
            filterVals.Clarity, // clarity
            filterVals.Cut, // cut
            filterVals.Polish, // polish
            filterVals.Symmetry, //symmetry
            filterVals.Fluorescence, //fluorescence
            true // isFilter
          );
        }
        if (name == "Certificates") {
          let allFilters = [];

          if (val.includes("all")) {
            allFilters = state.Certificates.map((cert) => {
              return cert.value;
            });
            allFilters.push("all");
            setSelectedCertificates(allFilters);
          } else if (val.length == state.Certificates.length) {
            allFilters = state.Certificates.map((cert) => {
              return cert.value;
            });
            allFilters.push("all");
            setSelectedCertificates(allFilters);
          } else {
            setSelectedCertificates(val);
          }
          handleGetLabDiamondList(
            dealerId,
            pageNo,
            pageSize,
            filterVals.Shape,
            priceValue[0],
            priceValue[1],
            caratSlideValue[0],
            caratSlideValue[1],
            state.Color,
            state.Clarity,
            state.Cut,
            state.Polish,
            state.Symmetry,
            state.Fluorescence,
            filterVals.DepthMin,
            filterVals.DepthMax,
            filterVals.TableMin,
            filterVals.TableMax,
            allFilters.length > 0 ? allFilters : val, // Certificate
            searchSku, //Sku
            filterVals.Color, // color
            filterVals.Clarity, // clarity
            filterVals.Cut, // cut
            filterVals.Polish, // polish
            filterVals.Symmetry, //symmetry
            filterVals.Fluorescence, //fluorescence
            true // isFilter
          );
        }

        if (name == "DepthMax") {
          handleGetLabDiamondList(
            dealerId,
            pageNo,
            pageSize,
            filterVals.Shape,
            priceValue[0],
            priceValue[1],
            caratSlideValue[0],
            caratSlideValue[1],
            state.Color,
            state.Clarity,
            state.Cut,
            state.Polish,
            state.Symmetry,
            state.Fluorescence,
            filterVals.DepthMin,
            val,
            filterVals.TableMin,
            filterVals.TableMax,
            filterVals.Certificates, // Certificate
            searchSku, //Sku
            filterVals.Color, // color
            filterVals.Clarity, // clarity
            filterVals.Cut, // cut
            filterVals.Polish, // polish
            filterVals.Symmetry, //symmetry
            filterVals.Fluorescence, //fluorescence
            true // isFilter
          );
        }
        if (name == "TableMin") {
          handleGetLabDiamondList(
            dealerId,
            pageNo,
            pageSize,
            filterVals.Shape,
            priceValue[0],
            priceValue[1],
            caratSlideValue[0],
            caratSlideValue[1],
            state.Color,
            state.Clarity,
            state.Cut,
            state.Polish,
            state.Symmetry,
            state.Fluorescence,
            filterVals.DepthMin,
            filterVals.DepthMax,
            val,
            filterVals.TableMax,
            filterVals.Certificates, // Certificate
            searchSku, //Sku
            filterVals.Color, // color
            filterVals.Clarity, // clarity
            filterVals.Cut, // cut
            filterVals.Polish, // polish
            filterVals.Symmetry, //symmetry
            filterVals.Fluorescence, //fluorescence
            true // isFilter
          );
        }
        if (name == "TableMax") {
          handleGetLabDiamondList(
            dealerId,
            pageNo,
            pageSize,
            filterVals.Shape,
            priceValue[0],
            priceValue[1],
            caratSlideValue[0],
            caratSlideValue[1],
            state.Color,
            state.Clarity,
            state.Cut,
            state.Polish,
            state.Symmetry,
            state.Fluorescence,
            filterVals.DepthMin,
            filterVals.DepthMax,
            filterVals.TableMin,
            val,
            filterVals.Certificates, // Certificate
            searchSku, //Sku
            filterVals.Color, // color
            filterVals.Clarity, // clarity
            filterVals.Cut, // cut
            filterVals.Polish, // polish
            filterVals.Symmetry, //symmetry
            filterVals.Fluorescence, //fluorescence
            true // isFilter
          );
        }
      }
      dispatch(handleSetSaveEveryTimeLabTab(filterVals));
    }
  };

  const handleInputDiamondDataChange = (e, name) => {
    setDiamondFormData({
      ...diamondFormData,
      [e.target.name]: e.target.value,
    });
    if (e.target.name == "firstName") {
      setValidations((prevdata) => ({
        ...prevdata,
        firstNameVal: "",
      }));
    }
    if (e.target.name == "lastName") {
      setValidations((prevdata) => ({
        ...prevdata,
        lastNameVal: "",
      }));
    }
    if (e.target.name == "phoneNumber") {
      setValidations((prevdata) => ({
        ...prevdata,
        phoneNumberVal: "",
      }));
    }
    if (e.target.name == "emailAddress") {
      setValidations((prevdata) => ({
        ...prevdata,
        emailAddressVal: "",
      }));
    }
    if (e.target.name == "comments") {
      setValidations((prevdata) => ({
        ...prevdata,
        commentsVal: "",
      }));
    }
  };

  const handleInputDiamondDataReset = () => {
    // handleInputDiamondDataRequest();
    setDiamondFormData({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailAddress: "",
      comments: "",
    });
    setValidations({
      firstNameVal: "",
      lastNameVal: "",
      phoneNumberVal: "",
      emailAddressVal: "",
      commentsVal: "",
    });
  };

  const handleInputDiamondDataRequest = () => {
    let arrShapes =
      filterVals.Shape.length > 0 ? filterVals.Shape.map((x) => x) : null;
    let arrCutGrades =
      state.Cut.length > 0 ? state.Cut.map((x) => Number(x.value)) : null;
    let arrColors =
      state.Color.length > 0 ? state.Color.map((x) => Number(x.value)) : null;
    let arrPolishs =
      state.Polish.length > 0 ? state.Polish.map((x) => Number(x.value)) : null;
    let arrClaritys =
      state.Clarity.length > 0
        ? state.Clarity.map((x) => Number(x.value))
        : null;
    let arrSymmetrys =
      state.Symmetry.length > 0
        ? state.Symmetry.map((x) => Number(x.value))
        : null;
    let arrFluorescences =
      state.Fluorescence.length > 0
        ? state.Fluorescence.map((x) => Number(x.value))
        : null;

    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }

    try {
      let inputData = {
        dealerID: dealerId.toString(),
        firstName: diamondFormData.firstName,
        lastName: diamondFormData.lastName,
        phoneNumber: diamondFormData.phoneNumber,
        emailAddress: diamondFormData.emailAddress,
        comment: diamondFormData.comments,
        intDealerID: dealerId.toString(),
        subject: "",
        shapeList: arrShapes !== null ? arrShapes.toString() : "",
        priceMin: filterVals.PriceMin.toString(),
        priceMax: filterVals.PriceMax.toString(),
        caratMin: filterVals.CaratMin.toString(),
        caratMax: filterVals.CaratMax.toString(),
        colorMin: arrColors !== null ? Math.min(...arrColors).toString() : "",
        colorMax: arrColors !== null ? Math.max(...arrColors).toString() : "",
        clarityMin:
          arrClaritys !== null ? Math.min(...arrClaritys).toString() : "",
        clarityMax:
          arrClaritys !== null ? Math.max(...arrClaritys).toString() : "",
        cutgradeMin:
          arrCutGrades !== null ? Math.min(...arrCutGrades).toString() : "",
        cutgradeMax:
          arrCutGrades !== null ? Math.max(...arrCutGrades).toString() : "",
        certificate: "", //Certificate
        depthMin: filterVals.DepthMin.toString(),
        depthMax: filterVals.DepthMax.toString(),
        tableMin: filterVals.TableMin.toString(),
        tableMax: filterVals.TableMax.toString(),
        polishList:
          filterVals.Polish.length > 0 ? filterVals.Polish.toString() : "",
        symmetryList:
          filterVals.Symmetry.length > 0 ? filterVals.Symmetry.toString() : "",
        fluorescenceList:
          filterVals.Fluorescence.length > 0
            ? filterVals.Fluorescence.toString()
            : "",
        cutGradeList:
          filterVals.Cut.length > 0 ? filterVals.Cut.toString() : "",
        colorList:
          filterVals.Color.length > 0 ? filterVals.Color.toString() : "",
        clarityList:
          filterVals.Clarity.length > 0 ? filterVals.Clarity.toString() : "",
      };
      setReqDiamondLoading(true);
      SelectYourDiamondService.SendMailToRetailerForNoResults(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            NotificationManager.success("Mail has Been Sent Successfully.");
            setReqDiamondLoading(false);
            setDiamondFormData({
              firstName: "",
              lastName: "",
              phoneNumber: "",
              emailAddress: "",
              comments: "",
            });
          } else {
            NotificationManager.error("Mail has Not Send.");
            setReqDiamondLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setReqDiamondLoading(false);
        });
    } catch (error) {
      console.log(error);
      setReqDiamondLoading(false);
    }
  };
  const handleGetMinedDiamondFilters = (id, pageNo, pageSize, val, filters) => {
    try {
      let inputData = {
        dealerID: id.toString(),
      };
      setFilterLoading(true);
      setLoading(true);
      SelectYourDiamondService.GetAvailableRingbuilderFilters(inputData)
        .then((response) => {
          var message = response.data.message;
          var ResponseData = response.data.responseData;
          var responseData =
            response.data.responseData.availableFilters_Response;
          var tempAvailableFilterData = state;
          var tempAvailableFilterValData = filterVals;
          if (message == "Success") {
            if (responseData) {
              // tempAvailableFilterData.PriceMin = Number(responseData.priceMin);
              // tempAvailableFilterData.PriceMax = Number(responseData.priceMax);
              // tempAvailableFilterData.CaratMin = Number(responseData.caratMin);
              // tempAvailableFilterData.CaratMax = Number(responseData.caratMax);
              tempAvailableFilterData.Shape =
                responseData.ringBuilderSettingsShape;
              tempAvailableFilterData.Cut =
                responseData.ringBuildersettingsCuts;
              tempAvailableFilterData.Color =
                responseData.ringBuildersettingsColor;
              tempAvailableFilterData.Clarity =
                responseData.ringBuildersettingsClarity;
              tempAvailableFilterData.Polish =
                responseData.ringBuildersettingsPolish;
              tempAvailableFilterData.Fluorescence =
                responseData.ringBuildersettingsFluorescence;
              tempAvailableFilterData.Symmetry =
                responseData.ringBuildersettingsSymmetry;
              tempAvailableFilterData.Certificates =
                responseData.ringBuildersettingsCertificates;
              tempAvailableFilterData.Clarity =
                responseData.ringBuildersettingsClarity;
              // tempAvailableFilterData.TableMin = responseData.tableMin;
              // tempAvailableFilterData.TableMax = responseData.tableMax;
              // tempAvailableFilterData.DepthMin = responseData.depthMin;
              // tempAvailableFilterData.DepthMax = responseData.depthMax;

              // tempAvailableFilterValData.PriceMin = Number(
              //   responseData.priceMin
              // );
              // tempAvailableFilterValData.PriceMax = Number(
              //   responseData.priceMax
              // );
              // tempAvailableFilterValData.CaratMin = Number(
              //   responseData.caratMin
              // );
              // tempAvailableFilterValData.CaratMax = Number(
              //   responseData.caratMax
              // );
              // tempAvailableFilterValData.TableMin = responseData.tableMin;
              // tempAvailableFilterValData.TableMax = responseData.tableMax;
              // tempAvailableFilterValData.DepthMin = responseData.depthMin;
              // tempAvailableFilterValData.DepthMax = responseData.depthMax;
              setState({
                ...state,
                ...tempAvailableFilterData,
              });

              setFilterVals({
                ...filterVals,
                ...tempAvailableFilterValData,
              });

              // setPriceValue([responseData.priceMin, responseData.priceMax]);
              // setCaratSlideValue([
              //   responseData.caratMin,
              //   responseData.caratMax,
              // ]);

              var saveSearchObj = resetVal.LabDiamondFilter;

              if (val == true) {
                setFilterVals(saveSearchObj);
                setPriceValue([saveSearchObj.PriceMin, saveSearchObj.PriceMax]);
                setCaratSlideValue([
                  saveSearchObj.CaratMin,
                  saveSearchObj.CaratMax,
                ]);
              } else {
                setFilterVals({
                  ...filterVals,
                  ...tempAvailableFilterValData,
                });
                // setPriceValue([filterPricemin, filterPricemax]);
                // setCaratSlideValue([filterCaratmin, filterCaratmax]);
                var tempObj = tempAvailableFilterValData;
                if (paramsettingShape) {
                  let shapes = paramsettingShape;

                  tempAvailableFilterValData.Shape = new Array(shapes);
                } else {
                  tempAvailableFilterValData.Shape = [];
                }
                if (
                  paramcenterStoneMinimumCarat ||
                  paramcenterStoneMaximumCarat
                ) {
                  tempAvailableFilterValData.CaratMin =
                    paramcenterStoneMinimumCarat;
                  tempAvailableFilterValData.CaratMax =
                    paramcenterStoneMaximumCarat;
                } else {
                  tempAvailableFilterValData.CaratMin = 0;
                  tempAvailableFilterValData.CaratMax = 22;
                }

                tempAvailableFilterValData.Cut = [];
                tempAvailableFilterValData.Color = [];
                tempAvailableFilterValData.Clarity = [];
                tempAvailableFilterValData.Polish = [];
                tempAvailableFilterValData.Fluorescence = [];
                tempAvailableFilterValData.Symmetry = [];
                tempAvailableFilterValData.Intensity = [];
                tempAvailableFilterValData.Certificates = [];
                tempAvailableFilterValData.PriceMin = 0;
                tempAvailableFilterValData.PriceMax = 9999999;
                tempAvailableFilterValData.CaratMin = 0;
                tempAvailableFilterValData.CaratMax = 22;
                tempAvailableFilterValData.TableMin = 0;
                tempAvailableFilterValData.TableMax = 100;
                tempAvailableFilterValData.DepthMin = 0;
                tempAvailableFilterValData.DepthMax = 100;

                tempObj["disabled"] = {};
                setFilterVals({
                  ...filterVals,
                  ...tempObj,
                });

                dispatch(handleSetSaveFilterLabTab(tempObj));
              }

              setFilterLoading(false);
              if (val == true) {
                debugger;
                handleGetLabDiamondList(
                  id,
                  pageNo,
                  pageSize,
                  saveSearchObj.Shape, //shapes
                  saveSearchObj.PriceMin,
                  saveSearchObj.PriceMax,
                  saveSearchObj.CaratMin,
                  saveSearchObj.CaratMax,
                  responseData.sldColorMax,
                  responseData.clarityMax,
                  responseData.sldCutGradeMax,
                  responseData.sldPolishMax,
                  responseData.sldSymmetryMax,
                  responseData.sldFluorescenceMax,
                  saveSearchObj.DepthMin,
                  saveSearchObj.DepthMax,
                  saveSearchObj.TableMin,
                  saveSearchObj.TableMax,
                  saveSearchObj.Certificate, // Certificate
                  searchSku, //Sku
                  saveSearchObj.Color, // color
                  saveSearchObj.Clarity, // clarity
                  saveSearchObj.Cut, // cut
                  saveSearchObj.Polish, // polish
                  saveSearchObj.Symmetry, //symmetry
                  saveSearchObj.Fluorescence, //fluorescence
                  true
                );
              } else {
                console.log(responseData);
                handleGetLabDiamondList(
                  id,
                  pageNo,
                  pageSize,
                  filters ? filters.Shape : [], //shapes
                  0,
                  9999999,
                  filterVals.CaratMin,
                  filterVals.CaratMax,
                  responseData.ringBuildersettingsColor,
                  responseData.ringBuildersettingsClarity,
                  responseData.ringBuildersettingsCuts,
                  responseData.ringBuildersettingsPolish,
                  responseData.ringBuildersettingsSymmetry,
                  responseData.ringBuildersettingsFluorescence,
                  0,
                  100,
                  0,
                  100,
                  "", // Certificate
                  searchSku, //Sku
                  [], // color
                  [], // clarity
                  [], // cut
                  [], // polish
                  [], //symmetry
                  [], //fluorescence
                  false, // isFilter
                  ResponseData.searchSortName == "Carat"
                    ? "fltCarat"
                    : ResponseData.searchSortName == "Price"
                    ? "realPrice"
                    : ResponseData.searchSortName == "Color"
                    ? "txtColor"
                    : ResponseData.searchSortName == "Clarity"
                    ? "txtColor"
                    : ResponseData.searchSortName == "Cut Grade"
                    ? "txtCutGrade"
                    : ResponseData.searchSortName == "Certificate"
                    ? "txtCertificate"
                    : "",
                  ResponseData.searchSortOrder
                );
                ResponseData.searchSortName == "Carat"
                  ? setSortColumnName("fltCarat")
                  : ResponseData.searchSortName == "Price"
                  ? setSortColumnName("realPrice")
                  : ResponseData.searchSortName == "Color"
                  ? setSortColumnName("txtColor")
                  : ResponseData.searchSortName == "Clarity"
                  ? setSortColumnName("txtColor")
                  : ResponseData.searchSortName == "Cut Grade"
                  ? setSortColumnName("txtCutGrade")
                  : ResponseData.searchSortName == "Certificate"
                  ? setSortColumnName("txtCertificate")
                  : setSortColumnName("");
                setSortType(ResponseData.searchSortOrder);
              }
            } else {
            }
          } else {
            setLabDiamondList([]);
            setTotalRowCount(0);
            setFilterLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setFilterLoading(false);
        });
    } catch (error) {
      console.log(error);
      setFilterLoading(false);
    }
  };

  const handleGetLabDiamondList = (
    id,
    PageNo,
    PageSize,
    shapes,
    priceMin,
    priceMax,
    caratmin,
    caratmax,
    allColor,
    allClarity,
    allCut,
    allPolish,
    allSymmetry,
    allFluorescence,
    depthmin,
    depthmax,
    tablemin,
    tablemax,
    Certificate, // Certificate
    Sku, //Sku
    colorVal, // color
    clarityVal, // clarity
    cutVal, // cut
    polishVal, // polish
    symmetryVal, //symmetry
    fluorescenceVal, //fluorescence
    filter, // isFilter
    column,
    type
  ) => {
    let arrShapes = shapes.length > 0 ? shapes.map((x) => x) : null;
    let arrCutGrades =
      allCut && allCut.length > 0 ? allCut.map((x) => Number(x.value)) : null;
    let arrColors =
      allColor && allColor.length > 0
        ? allColor.map((x) => Number(x.value))
        : null;
    let arrPolishs =
      allPolish && allPolish.length > 0
        ? allPolish.map((x) => Number(x.value))
        : null;
    let arrClaritys =
      allClarity && allClarity.length > 0
        ? allClarity.map((x) => Number(x.value))
        : null;
    let arrSymmetrys =
      allSymmetry && allSymmetry.length > 0
        ? allSymmetry.map((x) => Number(x.value))
        : null;
    let arrFluorescences =
      allFluorescence && allFluorescence.length > 0
        ? allFluorescence.map((x) => Number(x.value))
        : null;

    let certificateList = "";

    if (Certificate) {
      if (Certificate.length > 0) {
        if (Certificate.includes("all")) {
          certificateList = Certificate.toString().replace(",all", "");
        } else {
          certificateList = Certificate.toString();
        }
      }
    }

    try {
      let inputData = {
        dealerID: id.toString(),
        strCheckFilter: filter ? "Filter" : "Filter",
        shapes: arrShapes !== null ? arrShapes.toString() : "",
        colorMin: arrColors !== null ? Math.min(...arrColors).toString() : "",
        colorMax: arrColors !== null ? Math.max(...arrColors).toString() : "",
        clarityMin:
          arrClaritys !== null && arrClaritys.length
            ? Math.min(...arrClaritys).toString()
            : "",
        clarityMax:
          arrClaritys !== null && arrClaritys.length
            ? Math.max(...arrClaritys).toString()
            : "",
        caratMin: caratmin.toString(),
        caratMax: caratmax.toString(),
        priceMin: priceMin.toString(),
        priceMax: priceMax.toString(),
        certificate:
          // Certificate && Certificate.length > 0 ?  Certificate.toString() : "",
          certificateList,
        cutGradeMin:
          arrCutGrades !== null && arrCutGrades
            ? Math.min(...arrCutGrades).toString()
            : "",
        cutGradeMax:
          arrCutGrades !== null && arrCutGrades
            ? Math.max(...arrCutGrades).toString()
            : "",
        symmetryMin:
          arrSymmetrys !== null && arrSymmetrys
            ? Math.min(...arrSymmetrys).toString()
            : "",
        symmetryMax:
          arrSymmetrys !== null && arrSymmetrys
            ? Math.max(...arrSymmetrys).toString()
            : "",
        polishMin:
          arrPolishs !== null && arrPolishs
            ? Math.min(...arrPolishs).toString()
            : "",
        polishMax:
          arrPolishs !== null && arrPolishs
            ? Math.max(...arrPolishs).toString()
            : "",
        depthMin: depthmin ? depthmin.toString() : "",
        depthMax: depthmax ? depthmax.toString() : "",
        tableMin: tablemin ? tablemin.toString() : "",
        tableMax: tablemax ? tablemax.toString() : "",
        fluorescenceMin:
          arrFluorescences !== null && arrFluorescences
            ? Math.min(...arrFluorescences).toString()
            : "",
        fluorescenceMax:
          arrFluorescences !== null && arrFluorescences
            ? Math.max(...arrFluorescences).toString()
            : "",
        pairCode: "",
        orderBy:
          column == "txtShape"
            ? "Cut"
            : column == "fltCarat"
            ? "Size"
            : column == "txtColor"
            ? "Color"
            : column == "txtClarity"
            ? "Clarity"
            : column == "txtCutGrade"
            ? "CutGrade"
            : column == "fltDepth"
            ? "Depth"
            : column == "fltTable"
            ? "TableMeasure"
            : column == "txtPolish"
            ? "Polish"
            : column == "txtSymmetry"
            ? "Symmetry"
            : column == "txtMeasurements"
            ? "Measurements"
            : column == "txtCertificate"
            ? "Certificate"
            : column == "realPrice"
            ? "FltPrice"
            : "FltPrice",
        orderType: type ? type : "ASC",
        pageNumber: PageNo.toString(),
        pageSize: PageSize.toString(),
        invenID: "",
        strDetailLinkURL: "",
        did: "",
        caratPriceMin: "",
        caratPriceMax: "",
        polishList:
          polishVal && polishVal.length > 0 ? polishVal.toString() : "",
        symmetryList:
          symmetryVal && symmetryVal.length > 0 ? symmetryVal.toString() : "",
        fluorescenceList:
          fluorescenceVal && fluorescenceVal.length > 0
            ? fluorescenceVal.toString()
            : "",
        cutGradeList: cutVal && cutVal.length > 0 ? cutVal.toString() : "",
        colorList: colorVal && colorVal.length > 0 ? colorVal.toString() : "",
        clarityList:
          clarityVal && clarityVal.length > 0 ? clarityVal.toString() : "",
        sOrigin: "",
        countryTaxRate: "",
        skuNo: Sku.toString(),
      };

      // let inputData = {
      //   dealerId: id.toString(),
      //   strCheckFilter: filter ? "Filter" : "DealerID",
      //   shapeList: arrShapes !== null ? arrShapes.toString() : "",
      //   priceMin: priceMin.toString(),
      //   priceMax: priceMax.toString(),
      //   caratMin: caratmin.toString(),
      //   caratMax: caratmax.toString(),
      //   colorMax: arrColors !== null ? Math.max(...arrColors).toString() : "",
      //   colorMin: arrColors !== null ? Math.min(...arrColors).toString() : "",
      //   clarityMin:
      //     arrClaritys !== null && arrClaritys.length
      //       ? Math.min(...arrClaritys).toString()
      //       : "",
      //   clarityMax:
      //     arrClaritys !== null && arrClaritys.length
      //       ? Math.max(...arrClaritys).toString()
      //       : "",
      //   cutGradeMin:
      //     arrCutGrades !== null && arrCutGrades
      //       ? Math.min(...arrCutGrades).toString()
      //       : "",
      //   cutGradeMax:
      //     arrCutGrades !== null && arrCutGrades
      //       ? Math.max(...arrCutGrades).toString()
      //       : "",
      //   symmetryMin:
      //     arrSymmetrys !== null && arrSymmetrys
      //       ? Math.min(...arrSymmetrys).toString()
      //       : "",
      //   symmetryMax:
      //     arrSymmetrys !== null && arrSymmetrys
      //       ? Math.max(...arrSymmetrys).toString()
      //       : "",
      //   symmetryList:
      //     symmetryVal && symmetryVal.length > 0 ? symmetryVal.toString() : "",
      //   polishMin:
      //     arrPolishs !== null && arrPolishs
      //       ? Math.min(...arrPolishs).toString()
      //       : "",
      //   polishMax:
      //     arrPolishs !== null && arrPolishs
      //       ? Math.max(...arrPolishs).toString()
      //       : "",
      //   polishList:
      //     polishVal && polishVal.length > 0 ? polishVal.toString() : "",
      //   fluorescenceMin:
      //     arrFluorescences !== null && arrFluorescences
      //       ? Math.min(...arrFluorescences).toString()
      //       : "",
      //   fluorescenceMax:
      //     arrFluorescences !== null && arrFluorescences
      //       ? Math.max(...arrFluorescences).toString()
      //       : "",
      //   fluorescenceList:
      //     fluorescenceVal && fluorescenceVal.length > 0
      //       ? fluorescenceVal.toString()
      //       : "",
      //   certificate:
      //     Certificate && Certificate.length > 0 ? Certificate.toString() : "",
      //   skuNo: Sku.toString(),
      //   pairCode: "",
      //   detailsLink: "",
      //   did: "",
      //   depthMin: depthmin ? depthmin.toString() : "",
      //   depthMax: depthmax ? depthmax.toString() : "",
      //   tableMin: tablemin ? tablemin.toString() : "",
      //   tableMax: tablemax ? tablemax.toString() : "",
      //   caratPriceMin: "",
      //   caratPriceMax: "",
      //   themeName: "",
      //   showPrice: "",
      //   bindWhat: "",
      //   cutGradeList: cutVal && cutVal.length > 0 ? cutVal.toString() : "",
      //   colorList: colorVal && colorVal.length > 0 ? colorVal.toString() : "",
      //   clarityList:
      //     clarityVal && clarityVal.length > 0 ? clarityVal.toString() : "",
      //   bindCheckbox: "",
      //   sOrigin: "",
      //   isDiamond: "",
      //   custID: "",
      //   countryTaxRate: "",
      //   countryCode: "",
      //   track: "",
      //   isFB: "",
      //   isMLRB: "",
      //   settingCenterStonCaratMin: "",
      //   settingCenterStonCaratMax: "",
      //   selectedDealerConunt: 0,
      //   strSelectedDealers: "",
      //   extraQStr: "",
      //   pageSize: PageSize.toString(),
      //   pageNumber: PageNo.toString(),
      //   orderBy: "FltPrice",
      //   orderType: "ASC",
      //   strDInventoryId: "",
      //   flagDD: "",
      //   ratioMin: "",
      //   ratioMax: "",
      //   invenID: "",
      //   fancyColor: "",
      //   intIntensity: 0,
      //   overtone: "",
      //   overtone2: "",
      //   country: "",
      //   shade: "",
      //   treatment: "",
      //   keytoSymbol: "",
      //   fluorescenceColor: "",
      //   blackInclusion: "",
      //   centralInclusion: "",
      //   milkyInclusion: "",
      //   eyeclean: "",
      //   ratioType: "",
      //   permission: "",
      //   video: "",
      //   strCertSolrQuery: "",
      //   strDetailLinkURL: "",
      //   pageName: "",
      // };

      setLoading(true);
      SelectYourDiamondService.GetRingBuilderSelectLabDiamond(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var FirstRow = response.data.responseData[0];
          if (message == "Success") {
            if (responseData.length > 0) {
              setLabDiamondList(responseData);
              setTotalRowCount(responseData[0].intTotalRecords);
              setRowDetails(FirstRow);
              setLoading(false);
            } else {
              setLabDiamondList([]);
              setTotalRowCount(0);
              setLoading(false);
            }
          } else {
            setLabDiamondList([]);
            setTotalRowCount(0);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function onChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      handleGetLabDiamondList(
        dealerId,
        page,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        "", // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        "DealerID",
        sortColumnName,
        sortType
      );
    }
  }
  function onShowSizeChange(current, pageNewSize) {
    if (pageNewSize !== pageSize) {
      setPageSize(pageNewSize);
      handleGetLabDiamondList(
        dealerId,
        current,
        pageNewSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        "", // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        "DealerID",
        sortColumnName,
        sortType
      );
    }
  }

  const handleSetRowDetils = (e, record) => {
    console.log(e, record);
    setRowDetails(record);
  };

  const handleOpenVideoImageModal = (row, isVideo) => {
    setIsVideo(isVideo);
    setSelectedRowDetails(row);
    setVideoImageModal(true);
  };
  const handleCloseVideoImageModal = () => {
    setVideoImageModal(false);
  };

  const handleSearchDiamond = (value) => {
    setSearchSku(value);
    handleGetLabDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      "", // Certificate
      value, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence //fluorescence
    );
  };

  const handleFilterChangeCaratVal = (e) => {
    setCaratSlideValue(e);
  };

  const handleFilterChangePriceVal = (e) => {
    setPriceValue(e);
  };

  const handleValidation = () => {
    const { firstName, lastName, phoneNumber, emailAddress, comments } =
      diamondFormData;
    const validations = {
      firstNameVal: "",
      lastNameVal: "",
      phoneNumberVal: "",
      emailAddressVal: "",
      commentsVal: "",
    };
    let isValid = true;

    if (!firstName.trim()) {
      isValid = false;
      validations.firstNameVal = "First Name is Compulsory";
    }
    if (!lastName.trim()) {
      isValid = false;
      validations.lastNameVal = "Last Name is Compulsory";
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      validations.phoneNumberVal = "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      validations.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      validations.phoneNumberVal = "Phone number is invalid";
    } else {
      validations.phoneNumberVal = "";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!emailAddress.trim()) {
      isValid = false;
      validations.emailAddressVal = "Email address is compulsory";
    } else if (!emailAddress.match(validRegex)) {
      isValid = false;
      validations.emailAddressVal = "Email address is invalid";
    } else {
      validations.emailAddressVal = "";
    }
    if (!comments.trim()) {
      isValid = false;
      validations.commentsVal = "Comments is Compulsory";
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const handleOpenDiamondInfo = (dimaondId) => {
    // let tempDimaondInfo = diamondInfo;
    // tempDimaondInfo[dimaondId] = true;

    // setDiamondInfo(prevState => ({
    //   ...prevState,
    //   ...tempDimaondInfo
    // }));
    setHoverInfo(true);
    setDealerHoverId(dimaondId);
    setTableClassAllow(true);
  };
  const handleCloseDiamondInfo = (diamondId) => {
    //
    // let tempDimaondInfo = diamondInfo;
    // tempDimaondInfo[diamondId] = false;
    // setDiamondInfo(prevState => ({
    //   ...prevState,
    //   ...tempDimaondInfo
    // }));
    setHoverInfo(false);
    setDealerHoverId();
    setTableClassAllow(false);
  };

  // Sorting

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    var filter = [];
    var obj = {};

    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerCompany";
      setSortColumnName(column);
      setSortType(type);
    }
    console.log(pagination);
    // setPageNo(pagination.current);
    // setPageSize(pagination.pageSize);
    handleGetLabDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      "", // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      filter,
      column,
      type
    );

    // document.getElementsByClassName("content__area")[0].scroll(0, 0);
  };

  // certificate

  const handleOpenCertificate = (link) => {
    window.open(
      link,
      "",
      "width=500,height=500, top=' + top + ', left=' + left"
    );
  };

  const handleAfterChange = (e) => {
    handleGetLabDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      e[0],
      e[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificates, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      true //isFilter
    );
    var tempArray = filterVals;
    tempArray["CaratMin"] = e[0];
    tempArray["CaratMax"] = e[1];
    setFilterVals(tempArray);
    dispatch(handleSetSaveEveryTimeLabTab(tempArray));
  };

  const handleAfterChangePrice = (e) => {
    handleGetLabDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      e[0],
      e[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificates, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      true //isFilter
    );
    var tempArray = filterVals;
    tempArray["PriceMin"] = e[0];
    tempArray["PriceMax"] = e[1];
    setFilterVals(tempArray);
    dispatch(handleSetSaveEveryTimeLabTab(tempArray));
  };

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [wrapperLoading, setWrapperLoading] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [toggle, setToggle] = useState(true);
  useEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "RingBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              var tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              debugger;
              console.log(tempobj);
              console.log(initialColorObj);
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
              // dispatch(handleSwitchChange());
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  /* Color Individually Ends */
  return (
    <React.Fragment>
      <div className="allprod_section jwlsrch__section app__preview choose__setting">
        <div className="tabs__content">
          <div className="diamond__details">
            <div className="row border__block">
              <div className="col-md-9">
                <div className="row">
                  <Spin spinning={filterloading}>
                    <div className="form__fields filter_inpsection mh-auto border-0 p-0 mt-3">
                      <div className="col-md-12">
                        <div className="row">
                          <Wrapper>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Shape
                                  {/* <Tooltip
                                    placement="right"
                                    title="A diamond’s shape is not the same as a diamond’s cut. The shape refers to the general outline of the stone, and not its light refractive qualities. Look for a shape that best suits the ring setting you have chosen, as well as the recipient’s preference and personality."
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip> */}
                                </label>
                              </div>
                            </div>

                            <div className="whole__shapes col-lg-12">
                              {state.Shape &&
                                state.Shape.map((item, i) => {
                                  return (
                                    <Fragment>
                                      <div
                                        className={
                                          paramsettingShape
                                            ? paramsettingShape
                                                .toString()
                                                .includes(
                                                  item.allowableShapeName
                                                )
                                              ? "shapes__block"
                                              : filterVals.Shape.includes(
                                                  item.value ? item.value : ""
                                                )
                                              ? "shapes__block selected"
                                              : "shapes__block disabled__shapes"
                                            : filterVals.Shape.includes(
                                                item.value ? item.value : ""
                                              )
                                            ? "shapes__block selected"
                                            : "shapes__block"
                                        }
                                        disabled={
                                          paramsettingShape
                                            ? paramsettingShape
                                                .toString()
                                                .includes(
                                                  item.allowableShapeName
                                                )
                                              ? false
                                              : filterVals.Shape.includes(
                                                  item.value ? item.value : ""
                                                )
                                              ? false
                                              : true
                                            : filterVals.Shape.includes(
                                                item.value ? item.value : ""
                                              )
                                            ? false
                                            : false
                                        }
                                        onClick={() =>
                                          handleLabGrownFilterChange(
                                            item.value ? item.value : "",
                                            "Shape",
                                            "filter"
                                          )
                                        }
                                      >
                                        <div className="image">
                                          <img src={item.shapeImage} />
                                        </div>
                                        <p>{item.name}</p>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                            </div>
                            <div className="col-md-12">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Cut
                                  {/* <Tooltip
                                    placement="right"
                                    title="Not to be confused with shape, a diamond’s cut rating tells you how well its proportions interact with light. By evaluating the angles and proportions of the diamond, the cut grade is designed to tell you how sparkly and brilliant your stone is. Cut grading is usually not available for fancy shapes (any shape that is not round), because the mathematical formula that determines light return becomes less reliable when different length to width ratios are factored in."
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip> */}
                                </label>
                              </div>
                              <div className="selection__block row">
                                {state.Cut &&
                                  state.Cut.map((item, i) => {
                                    return (
                                      <Fragment>
                                        <div className="col">
                                          <div
                                            className={
                                              filterVals.Cut &&
                                              filterVals.Cut.includes(
                                                item.value
                                              )
                                                ? "selection__box selected"
                                                : "selection__box"
                                            }
                                            onClick={() =>
                                              handleLabGrownFilterChange(
                                                item.value,
                                                "Cut",
                                                "filter"
                                              )
                                            }
                                          ></div>
                                          <p>{item.name}</p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Color
                                  {/* <Tooltip
                                    placement="right"
                                    title="The color scale measures the degree of colorlessness in a diamond. D is the highest and most colorless grade, but also the most expensive. To get the most value for your budget, look for an eye colorless stone. For most diamonds, this is in the F-H range."
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip> */}
                                </label>
                              </div>
                              <div className="selection__block row">
                                {state.Color &&
                                  state.Color.map((item, i) => {
                                    return (
                                      <Fragment>
                                        <div className="col">
                                          <div
                                            className={
                                              filterVals.Color &&
                                              filterVals.Color.includes(
                                                item.value
                                              )
                                                ? "selection__box selected"
                                                : "selection__box"
                                            }
                                            onClick={() =>
                                              handleLabGrownFilterChange(
                                                item.value,
                                                "Color",
                                                "filter"
                                              )
                                            }
                                          ></div>
                                          <p>{item.name}</p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Clarity
                                  {/* <Tooltip
                                    placement="right"
                                    title="The color scale measures the degree of colorlessness in a diamond. D is the highest and most colorless grade, but also the most expensive. To get the most value for your budget, look for an eye colorless stone. For most diamonds, this is in the F-H range."
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip> */}
                                </label>
                              </div>
                              <div className="selection__block row">
                                {state.Clarity &&
                                  state.Clarity.map((item, i) => {
                                    return (
                                      <Fragment>
                                        <div className="col">
                                          <div
                                            className={
                                              filterVals.Clarity &&
                                              filterVals.Clarity.includes(
                                                item.value
                                              )
                                                ? "selection__box selected"
                                                : "selection__box"
                                            }
                                            onClick={() =>
                                              handleLabGrownFilterChange(
                                                item.value,
                                                "Clarity",
                                                "filter"
                                              )
                                            }
                                          ></div>
                                          <p>{item.name}</p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            </div>
                          </Wrapper>
                          <div className="col-lg-6 col-md-6">
                            <div className="sliderrange__maindiv mobile__bottomspace d-block">
                              <label> {"Carats"} </label>
                              <div className="col-md-12 mt-1">
                                <Slider
                                  range
                                  value={caratSlideValue}
                                  min={state.CaratMin}
                                  max={state.CaratMax}
                                  step={0.01}
                                  onChange={(e) => {
                                    handleFilterChangeCaratVal(e);
                                  }}
                                  onAfterChange={(e) => {
                                    handleAfterChange(e);
                                  }}
                                />
                                <div className="rangeSlider__values">
                                  <div className="input__block">
                                    <input
                                      type="text"
                                      value={caratSlideValue[0].toLocaleString(
                                        "en-US"
                                      )}
                                      onChange={(e) => {
                                        firstsetCaratSlideValue(e, 0);
                                      }}
                                      onKeyPress={onKeyPressEvent1}
                                    />
                                  </div>

                                  <div className="input__block">
                                    <input
                                      type="text"
                                      value={caratSlideValue[1].toLocaleString(
                                        "en-US"
                                      )}
                                      onChange={(e) => {
                                        firstsetCaratSlideValue(e, 1);
                                      }}
                                      onKeyPress={onKeyPressEvent1}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="sliderrange__maindiv mobile__bottomspace d-block">
                              <label> {"Price Range"} </label>
                              <div className="col-md-12 mt-1">
                                <Slider
                                  range
                                  value={priceValue}
                                  min={state.PriceMin}
                                  max={state.PriceMax}
                                  onChange={(e) => {
                                    handleFilterChangePriceVal(e);
                                  }}
                                  onAfterChange={(e) => {
                                    handleAfterChangePrice(e);
                                  }}
                                />
                                <div className="rangeSlider__values">
                                  <div className="input__block">
                                    <input
                                      type="text"
                                      // value={
                                      //   "$" +
                                      //   priceValue[0].toLocaleString("en-US")
                                      // }
                                      value={priceValue[0]
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                      onChange={(e) => {
                                        firstSliderPriceValue(e, 0);
                                      }}
                                    />
                                  </div>

                                  <div className="input__block">
                                    <input
                                      type="text"
                                      // value={
                                      //   "$" +
                                      //   priceValue[1].toLocaleString("en-US")
                                      // }
                                      value={
                                        "$" +
                                        priceValue[1]
                                          .toString()
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      }
                                      onChange={(e) => {
                                        firstSliderPriceValue(e, 1);
                                        // handleSliderCaratValue(e);
                                        // setCaratSlideValue(e);
                                      }}
                                      onKeyPress={onKeyPressEvent1}
                                    />
                                    {/* <span className="dollar__symbol">$</span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Wrapper>
                            {"isAdvance" && (
                              <div className="col-md-12 mt-4">
                                <div className="input__block d-flex align-item-center">
                                  <button
                                    className="primary-btn mr-2"
                                    onClick={() =>
                                      setShowAdvanced(!showAdvanced)
                                    }
                                  >
                                    +
                                  </button>
                                  <label>Advanced</label>
                                </div>
                              </div>
                            )}

                            {showAdvanced == true ? (
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="input__block mb-1">
                                    <label>
                                      {" "}
                                      Polish
                                      {/* <Tooltip placement="right" title="">
                                        <img src={UnionIcon} alt="" />
                                      </Tooltip> */}
                                    </label>
                                  </div>
                                  <div className="selection__block row">
                                    {state.Polish &&
                                      state.Polish.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div className="col">
                                              <div
                                                className={
                                                  filterVals.Polish &&
                                                  filterVals.Polish.includes(
                                                    item.value
                                                  )
                                                    ? "selection__box selected"
                                                    : "selection__box"
                                                }
                                                onClick={() =>
                                                  handleLabGrownFilterChange(
                                                    item.value,
                                                    "Polish",
                                                    "filter"
                                                  )
                                                }
                                              ></div>
                                              <p>{item.name}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="input__block mb-1">
                                    <label>
                                      {" "}
                                      Fluorescence
                                      {/* <Tooltip placement="right" title="">
                                        <img src={UnionIcon} alt="" />
                                      </Tooltip> */}
                                    </label>
                                  </div>
                                  <div className="selection__block row">
                                    {state.Fluorescence &&
                                      state.Fluorescence.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div className="col">
                                              <div
                                                className={
                                                  filterVals.Fluorescence &&
                                                  filterVals.Fluorescence.includes(
                                                    item.value
                                                  )
                                                    ? "selection__box selected"
                                                    : "selection__box"
                                                }
                                                onClick={() =>
                                                  handleLabGrownFilterChange(
                                                    item.value,
                                                    "Fluorescence",
                                                    "filter"
                                                  )
                                                }
                                              ></div>
                                              <p>{item.name}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="input__block mb-1">
                                    <label>
                                      {" "}
                                      Symmetry
                                      {/* <Tooltip placement="right" title="">
                                        <img src={UnionIcon} alt="" />
                                      </Tooltip> */}
                                    </label>
                                  </div>
                                  <div className="selection__block row">
                                    {state.Symmetry &&
                                      state.Symmetry.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div className="col">
                                              <div
                                                className={
                                                  filterVals.Symmetry &&
                                                  filterVals.Symmetry.includes(
                                                    item.value
                                                  )
                                                    ? "selection__box selected"
                                                    : "selection__box"
                                                }
                                                onClick={() =>
                                                  handleLabGrownFilterChange(
                                                    item.value,
                                                    "Symmetry",
                                                    "filter"
                                                  )
                                                }
                                              ></div>
                                              <p>{item.name}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="input__block mb-1">
                                    <label>Depth</label>
                                    <div className="row">
                                      <div className="col-md-6 mobile__bottomspace">
                                        <input
                                          type="number"
                                          value={filterVals.DepthMin}
                                          onChange={(e) =>
                                            handleLabGrownFilterChange(
                                              e.target.value,
                                              "DepthMin",
                                              "input"
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <input
                                          type="number"
                                          value={filterVals.DepthMax}
                                          onChange={(e) =>
                                            handleLabGrownFilterChange(
                                              e.target.value,
                                              "DepthMax",
                                              "input"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="input__block">
                                    <label>Table</label>
                                    <div className="row">
                                      <div className="col-md-6 mobile__bottomspace">
                                        <input
                                          type="number"
                                          value={filterVals.TableMin}
                                          onChange={(e) =>
                                            handleLabGrownFilterChange(
                                              e.target.value,
                                              "TableMin",
                                              "input"
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <input
                                          type="number"
                                          value={filterVals.TableMax}
                                          onChange={(e) =>
                                            handleLabGrownFilterChange(
                                              e.target.value,
                                              "TableMax",
                                              "input"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="input__block">
                                    <label className="label__space">
                                      Certificates
                                    </label>
                                    <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder="Show All Certificates"
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        handleLabGrownFilterChange(
                                          e,
                                          "Certificates",
                                          "input"
                                        )
                                      }
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      mode="multiple"
                                      showArrow
                                      value={
                                        selectedCertificates.includes("all")
                                          ? "all"
                                          : selectedCertificates
                                      }
                                    >
                                      <Option value="all">
                                        Show All Certificates
                                      </Option>
                                      {state.Certificates.map((cert) => {
                                        return (
                                          <Option
                                            disabled={
                                              selectedCertificates
                                                ? selectedCertificates.includes(
                                                    "all"
                                                  )
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            value={cert.value}
                                          >
                                            {cert.name}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="input__block">
                                    <label>Origin</label>
                                    <Select className="select_inpt border__grey">
                                      <option>Option 1</option>
                                      <option>Option 2</option>
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </Wrapper>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>

              {labDiamondList.length > 0 ? (
                <div className="col-md-3 diamondimage__block">
                  <div className="">
                    <Spin spinning={loading}>
                      <h3 className="subheading">Diamond Preview</h3>
                      <img
                        src={
                          rowDetails.bigImageFileName
                            ? rowDetails.bigImageFileName
                            : ""
                        }
                      />
                      {/* <h4>US${rowDetails.realPrice && rowDetails.realPrice}</h4> */}
                      <h4 className="subheading">
                        {ViewOptionData.ShowPrice == false ? (
                          <>Call</>
                        ) : (
                          <>
                            {" "}
                            {rowDetails.realPrice == "0" ? (
                              "Call For Price"
                            ) : (
                              <>
                                US$
                                {parseInt(rowDetails.realPrice)
                                  .toFixed()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </>
                            )}
                          </>
                        )}{" "}
                      </h4>
                      <p>
                        Stock Number:{" "}
                        {rowDetails.intDiamondId
                          ? rowDetails.intDiamondId
                          : null}
                      </p>

                      {paramSettingId == "null" ||
                        (paramSettingId == null && (
                          <div className="mb-3">
                            <button
                              className="primary-btn mr-2"
                              onClick={() =>
                                handleViewDetails(rowDetails, "addSetting")
                              }
                            >
                              {" "}
                              Add Your Setting
                            </button>
                          </div>
                        ))}

                      <div className="mb-3">
                        <button
                          className="primary-btn mr-2"
                          onClick={() => handleViewDetails(rowDetails)}
                        >
                          {" "}
                          Select Diamond
                        </button>
                      </div>
                    </Spin>
                  </div>
                </div>
              ) : (
                <div className="col-md-3 diamondimage__block">
                  <Spin spinning={loading}>
                    <div className="">
                      <h3 className="subheading">Diamond Preview</h3>
                      <img src={NoDiamond} />
                      <h3 className="subheading">No Results Found</h3>
                      <h3 className="subheading">Use The Form Below</h3>
                    </div>
                  </Spin>
                </div>
              )}
            </div>
          </div>
          <Wrapper>
            {labDiamondList.length > 0 ? (
              <div className="col-md-12">
                <div className="filter_section pl-0 pr-0 pt-0">
                  <div className="col-md-12 desktop__version">
                    <div className="filt_optndiv">
                      <div className="gview__div">
                        <span className="font__bold mr-2 similar__text">
                          {TotalRowCount} Similar Diamonds
                        </span>
                        <span
                          className={
                            viewItem === "grid"
                              ? "fa fa-th grid_icon active"
                              : "fa fa-th grid_icon"
                          }
                          onClick={() => handleViewChange("grid")}
                        ></span>
                        <span
                          className={
                            viewItem === "list"
                              ? "fa fa-th-list list_icon active"
                              : "fa fa-th-list list_icon"
                          }
                          onClick={() => handleViewChange("list")}
                        ></span>

                        <span className="font__bold ml-2">
                          Compare Items (
                          {
                            labDiamondList.filter((obj) => obj.checkbox == true)
                              .length
                          }
                          )
                        </span>
                      </div>

                      <div className="my-3 mobile-w-100">
                        <Search
                          placeholder="Search Diamond Stock #"
                          onSearch={(e) => handleSearchDiamond(e)}
                          allowClear
                          style={{ width: 250 }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mobile__version">
                    <div className="filt_optndiv">
                      <div className="gview__div">
                        <div className="row">
                          <div className="col-4">
                            <span className="font__bold mr-2 similar__text text-left-mobile">
                              {TotalRowCount} Similar Diamonds
                            </span>
                          </div>
                          <div className="col-4">
                            <span
                              className={
                                viewItem === "grid"
                                  ? "fa fa-th grid_icon active"
                                  : "fa fa-th grid_icon"
                              }
                              onClick={() => handleViewChange("grid")}
                            ></span>
                            <span
                              className={
                                viewItem === "list"
                                  ? "fa fa-th-list list_icon active"
                                  : "fa fa-th-list list_icon"
                              }
                              onClick={() => handleViewChange("list")}
                            ></span>
                          </div>

                          <div className="col-4">
                            <span className="font__bold">
                              Compare Items (
                              {
                                labDiamondList.filter(
                                  (obj) => obj.checkbox == true
                                ).length
                              }
                              )
                            </span>
                          </div>
                          <div className="col-12">
                            <Search
                              placeholder="Search Diamond Stock #"
                              onSearch={(e) => handleSearchDiamond(e)}
                              allowClear
                              style={{ width: 250 }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {viewItem === "grid" && (
                    <Spin spinning={loading}>
                      <div className="col-md-12">
                        <div className="prod__section">
                          <div className="row">
                            {labDiamondList.length > 0 ? (
                              labDiamondList.map((item, i) => {
                                return (
                                  <div className="col-lg-3 col-md-6 mb-3">
                                    {/* <Link
                                  to={"/productDetails"}
                                  state={{ productData: item }}
                                > */}
                                    <div className="prod__maindiv">
                                      <div className="display-block chkbx__div">
                                        {/* <Checkbox
                                          name="checkbox"
                                          checkedChildren="Yes"
                                          unCheckedChildren="No"
                                          checked={
                                            item.checkbox == true ? true : false
                                          }
                                          onChange={(e) =>
                                            handleOnChange(
                                              item,
                                              e.target.checked,
                                              "checkbox"
                                            )
                                          }
                                        /> */}
                                      </div>
                                      <div className="prodimg__div">
                                        {/* <img src={Rectangle} alt="" /> */}
                                        <img
                                          src={item.bigImageFileName}
                                          alt=""
                                        />
                                      </div>
                                      <div className="details__div">
                                        <span className="style__txt">
                                          {"Style No"}: {item.intDiamondId}
                                        </span>
                                        <span className="price__txt">
                                          $
                                          {Number(
                                            item.realPrice
                                          ).toLocaleString("en-US")}
                                        </span>
                                      </div>
                                      <div className="display-block editdel__div">
                                        <div
                                          onClick={() => {
                                            handleOpenVideoImageModal(
                                              item,
                                              true
                                            );
                                          }}
                                        >
                                          <img src={Video_icon} alt="" />
                                        </div>
                                      </div>
                                      {!productChange[item.intDiamondId] ? (
                                        <div
                                          className="info__div"
                                          onClick={() =>
                                            handleProductChangeOpen(
                                              item.intDiamondId
                                            )
                                          }
                                        >
                                          <img src={Info_icon} alt="" />
                                        </div>
                                      ) : (
                                        <div className="descr__div">
                                          <div className="overlay__block">
                                            <p>
                                              <span> {"Shape"} </span>
                                              <span>:</span>
                                              <span>{item.txtShape}</span>
                                            </p>
                                            <p>
                                              <span> {"Carats"} </span>
                                              <span>:</span>
                                              <span>{item.fltCarat}</span>
                                            </p>
                                            <p>
                                              <span> {"Color"} </span>
                                              <span>:</span>
                                              <span>{item.txtColor}</span>
                                            </p>
                                            <p>
                                              <span> {"Clarity"} </span>
                                              <span>:</span>
                                              <span> {item.txtClarity}</span>
                                            </p>
                                            <p>
                                              <span> {"Cut"} </span>
                                              <span>:</span>
                                              <span> {item.txtCutGrade}</span>
                                            </p>
                                            <p>
                                              <span> {"In House"} </span>
                                              <span>:</span>
                                              <span> {item.inhouse}</span>
                                            </p>

                                            <p>
                                              <span> {"Depth"} </span>
                                              <span>:</span>
                                              <span>{item.fltDepth}</span>
                                            </p>
                                            <p>
                                              <span> {"Table"} </span>
                                              <span>:</span>
                                              <span>{item.fltTable}</span>
                                            </p>
                                            <p>
                                              <span> {"Certificate"} </span>
                                              <span>:</span>
                                              <span>{item.txtCertificate}</span>
                                            </p>
                                            <p>
                                              <span> {"Polish"} </span>
                                              <span>:</span>
                                              <span>{item.txtPolish}</span>
                                            </p>
                                            <p>
                                              <span> {"Measurement"} </span>
                                              <span>:</span>
                                              <span>
                                                {item.txtMeasurements}
                                              </span>
                                            </p>
                                            <p>
                                              <span> {"Retail Price"} </span>
                                              <span>:</span>
                                              <span>
                                                {item.realPrice == "0" ? (
                                                  " Call For Price"
                                                ) : (
                                                  <span>
                                                    $
                                                    {parseInt(item.realPrice)
                                                      .toFixed()
                                                      .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )}
                                                  </span>
                                                )}
                                              </span>
                                            </p>
                                          </div>
                                          <div
                                            className="close__div"
                                            onClick={() =>
                                              handleProductChangeClose(
                                                item.intDiamondId
                                              )
                                            }
                                          >
                                            <img src={Close} alt="" />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {/* </Link> */}
                                  </div>
                                );
                              })
                            ) : (
                              <div>
                                <span className="mr-2">
                                  <span
                                    className="linkText"
                                    onClick={() => setHideShowFilter(true)}
                                  >
                                    reset
                                  </span>{" "}
                                  your filters
                                </span>
                                <div>
                                  <Empty />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Spin>
                  )}
                  {viewItem === "list" && (
                    <Spin spinning={loading}>
                      <div className="col-md-12">
                        <div className="lviewprod__tbl info__tool">
                          <Table
                            className={
                              tableClassAllow == true
                                ? "info__tooltip__table overflow-visible"
                                : "info__tooltip__table"
                            }
                            columns={columns}
                            onChange={handleTableASCDES}
                            onRow={(record, recordIndex) => ({
                              onClick: (event) => {
                                handleSetRowDetils(event, record);
                              },
                            })}
                            rowClassName={(record) =>
                              record.intDiamondId == rowDetails.intDiamondId
                                ? "row-active"
                                : ""
                            }
                            dataSource={labDiamondList}
                            // scroll={{ x: 600, y: 300 }}
                            pagination={false}
                          />
                        </div>
                        <div className="table__bottom__btn">
                          <div>
                            <button
                              className="primary-btn mr-2"
                              onClick={() =>
                                handleCompareRedirect(
                                  rowDetails,
                                  true,
                                  "compareButton"
                                )
                              }
                            >
                              Add To Compare
                            </button>
                          </div>

                          <Pagination
                            current={pageNo}
                            pageSize={pageSize}
                            total={TotalRowCount}
                            onChange={onChange}
                            onShowSizeChange={onShowSizeChange}
                            showSizeChanger="true"
                          />
                        </div>
                      </div>
                    </Spin>
                  )}
                </div>
              </div>
            ) : (
              <div class="col-md-12">
                <Spin spinning={loading}>
                  <div class="table__block filter_section pr-0 pl-0">
                    <h5 className="subheading">
                      {" "}
                      No Diamonds Listed In That Range{" "}
                    </h5>
                    <p>
                      {" "}
                      Please enter your contact information. One of our diamond
                      specialists will contact you and assist you with your
                      needs.{" "}
                    </p>
                    <div class="row p-0 border-0 form__fields mh-auto">
                      <div class="col-md-6">
                        <div class="input__block">
                          <label> First Name </label>
                          <input
                            type="text"
                            placeholder="Enter First Name"
                            name="firstName"
                            value={diamondFormData.firstName}
                            onChange={handleInputDiamondDataChange}
                          />
                          <div>
                            {validations.firstNameVal && (
                              <p className="error-color-red">
                                {validations.firstNameVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input__block">
                          <label> Last Name </label>
                          <input
                            type="text"
                            placeholder="Enter Last Name"
                            name="lastName"
                            value={diamondFormData.lastName}
                            onChange={handleInputDiamondDataChange}
                          />
                          <div>
                            {validations.lastNameVal && (
                              <p className="error-color-red">
                                {validations.lastNameVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input__block">
                          <label> Phone Number </label>
                          <input
                            type="text"
                            placeholder="Enter Phone Number"
                            name="phoneNumber"
                            value={diamondFormData.phoneNumber}
                            onChange={handleInputDiamondDataChange}
                          />
                          <div>
                            {validations.phoneNumberVal && (
                              <p className="error-color-red">
                                {validations.phoneNumberVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input__block">
                          <label> Email Address </label>
                          <input
                            type="text"
                            placeholder="Enter Email Address"
                            name="emailAddress"
                            value={diamondFormData.emailAddress}
                            onChange={handleInputDiamondDataChange}
                          />
                          <div>
                            {validations.emailAddressVal && (
                              <p className="error-color-red">
                                {validations.emailAddressVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="input__block">
                          <label> Comments </label>
                          <textarea
                            name="comments"
                            value={diamondFormData.comments}
                            onChange={handleInputDiamondDataChange}
                            placeholder="Add Comments Here"
                          ></textarea>
                          <div>
                            {validations.commentsVal && (
                              <p className="error-color-red">
                                {validations.commentsVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="save__form">
                          <Button
                            loading={reqDiamondloading}
                            className="mr-1"
                            type="primary"
                            onClick={handleInputDiamondDataRequest}
                          >
                            {" "}
                            Request{" "}
                          </Button>
                          <Button
                            className="primary-btn mr-2"
                            type="primary"
                            class="primary-btn"
                            onClick={handleInputDiamondDataReset}
                          >
                            {" "}
                            Reset{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </div>
            )}
          </Wrapper>
        </div>
      </div>

      <Modal
        className="modalconsupld__section"
        style={{ top: 20 }}
        title={"Video"}
        width={500}
        visible={videoImageModal}
        onCancel={() => handleCloseVideoImageModal()}
        footer={[
          <Button onClick={() => handleCloseVideoImageModal()}>
            {"Cancel"}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  {isVideo ? (
                    selectedRowDetails.videoFileName ? (
                      <iframe
                        width="100%"
                        height="450"
                        title="diamond-video"
                        className="VideoIframe"
                        src={selectedRowDetails.videoFileName}
                      ></iframe>
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  ) : selectedRowDetails.imageFileName ? (
                    <img
                      src={selectedRowDetails.imageFileName}
                      alt="img-diamond"
                    />
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default LabGrown;
