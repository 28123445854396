import { httpApps } from "../helper/http-common";

class ChooseSettingServices {
  GetAppPrevData(inputData) {
    return httpApps.post("RingBuilder/GetAppPrevData", inputData);
  }
  GetAppPrevDataonSelectYourSetting(inputData) {
    return httpApps.post(
      "RingBuilder/GetAppPrevDataonSelectYourSetting",
      inputData
    );
  }
  GetFilterData(inputData) {
    return httpApps.post("RingBuilder/GetFilterData", inputData);
  }
}

export default new ChooseSettingServices();
