import React, { useState, useEffect } from "react";
import { Input, Row, Col, Select, Checkbox, Spin } from "antd";

import Eye_icon from "../../../assets/images/icons/eye_icon.svg";

import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import roundDiamond from "../../../assets/images/daimond-link.jpg";
import compareDiamond from "../../../assets/images/compare-diamond.png";
import { useDispatch, useSelector } from "react-redux";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import {
  handleViewDetailsPage,
  handleSetDiamondActiveTab,
} from "../../../actions/selectYourDiamond/selectYourDiamond";
import {
  handleActiveTab,
  handleAddDiamondViewEdit,
} from "../../../actions/header/headerActions";
import moment from "moment";
import SelectYourDiamond from "../../ApplicationPreview/SelectYourDiamond";
import { useNavigate, useLocation } from "react-router-dom";

export const Compare = ({}) => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const ParamCompareIds = new URLSearchParams(search).get("CompareIds");
  const PageName = new URLSearchParams(search).get("pageName");
  const CompareData = useSelector(
    (state) => state.selectYourDiamondReducer.compareIds
  );
  const dispatch = useDispatch();
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const [keyValue, setKeyValue] = useState([
    "image",
    "txtShape",
    "intDiamondId",
    "fltCarat",
    "fltTable",
    "modifiedDate",
    "txtPolish",
    "txtSymmetry",
    "txtClarity",
    "txtFluorescence",
    "fltDepth",
    "txtMeasurements",
    "txtCertificate",
    "txtCutGrade",
    "realPrice",
    "action",
  ]);
  const [loading, setLoading] = useState(false);
  const [compareList, setCompareList] = useState([]);
  const [dealerId, setDealerId] = useState();

  useEffect(() => {
    if (paramdealerId) {
      if (ParamCompareIds) {
        if (PageName == "Mined") {
          handleLoadCompareMinedDiamondsList(
            loginDetails.dealerId,
            ParamCompareIds
          );
        } else {
          handleLoadCompareLabDiamondsList(
            loginDetails.dealerId,
            ParamCompareIds
          );
        }
      }
      setDealerId(paramdealerId);
    }
  }, []);

  // useEffect(() => {
  //   if (loginDetails) {
  //     if (loginDetails.dealerId) {
  //       if (CompareData) {
  //         if (CompareData.pageName) {
  //           if (CompareData.pageName == "Mined") {
  //             if (CompareData.ids) {
  //               if (CompareData.ids.length > 0) {
  //                 handleLoadCompareMinedDiamondsList(
  //                   loginDetails.dealerId,
  //                   CompareData.ids
  //                 );
  //               }
  //             }
  //           } else {
  //             if (CompareData.ids) {
  //               if (CompareData.ids.length > 0) {
  //                 handleLoadCompareLabDiamondsList(
  //                   loginDetails.dealerId,
  //                   CompareData.ids
  //                 );
  //               }
  //             }
  //           }
  //         }
  //       }
  //       setDealerId(loginDetails.dealerId);
  //     }
  //   }
  // }, []);

  const handleLoadCompareMinedDiamondsList = (id, CompareData) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        diamondId: CompareData.toString(),
        flag: "",
        orderBy: "",
        orderType: "",
      };

      setLoading(true);
      SelectYourDiamondService.GetRingBuilderMinedCompareData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            if (responseData) {
              setCompareList(responseData);
              setLoading(false);
            } else {
              setCompareList([]);
              setLoading(false);
            }
          } else {
            setCompareList([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleLoadCompareLabDiamondsList = (id, CompareData) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        diamondId: CompareData.toString(),
        flag: "",
        orderBy: "",
        orderType: "",
      };

      setLoading(true);
      SelectYourDiamondService.GetRingBuilderLabCompareData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            if (responseData) {
              setCompareList(responseData);
              setLoading(false);
            } else {
              setCompareList([]);
              setLoading(false);
            }
          } else {
            setCompareList([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDeleteCol = (id) => {
    var filteredArr = compareList.filter((item) => item.intDiamondId !== id);
    setCompareList(filteredArr);
  };

  const handleDelRow = (val) => {
    var filteredArr = keyValue.filter((item) => item !== val);
    setKeyValue(filteredArr);
  };

  const handleViewDiamond = (details) => {
    window.parent.postMessage("submitted form", "*");
    let obj = details;
    obj["pageName"] = CompareData.pageName;
    navigate(
      "/viewDiamondDetails" +
        `?isSocialPage=True&DealerLink=${obj.gfLinkID}&diamondId=${obj.intDiamondId}&pageNo=1&isAllowed=True&pageName=${obj.pageName}&shapeFilters=${obj.shapeFilters}&txtCutlet=${obj.txtCulet}&txtClarity=${obj.txtClarity}&intColorPriority=${obj.intColorPriority}`
    );
    // obj["shapeFilters"] = filterVals.Shape;
    dispatch(handleViewDetailsPage(obj));
    dispatch(handleAddDiamondViewEdit());
  };

  return (
    <React.Fragment>
      {/* <span className="back__btn"> {"<< Change Diamond"} </span> */}
      <div className="allprod_section jwlsrch__section app__preview choose__setting">
        <div className="tabs__content border__block">
          <div className="diamond__details filter_section p-0 mt-3">
            <Spin spinning={loading}>
              <div className="row">
                <div className="col-md-12">
                  <div className="compare__table custom__table">
                    <table class="table  table-striped">
                      <tbody>
                        {keyValue.map((val) => (
                          <tr>
                            <th>
                              <div className="headingtable">
                                {val == "image" ? (
                                  ""
                                ) : val == "action" ? (
                                  ""
                                ) : (
                                  <React.Fragment>
                                    <i
                                      class="fa fa-times-circle-o"
                                      aria-hidden="true"
                                      onClick={() => handleDelRow(val)}
                                    ></i>

                                    <span>
                                      {val == "txtShape"
                                        ? "Shape"
                                        : val == "intDiamondId"
                                        ? "SKU"
                                        : val == "fltCarat"
                                        ? "Carat"
                                        : val == "fltTable"
                                        ? "Table"
                                        : val == "modifiedDate"
                                        ? "Date Added"
                                        : val == "fltDepth"
                                        ? "Depth"
                                        : val == "fltTable"
                                        ? "Table"
                                        : val == "txtPolish"
                                        ? "Polish"
                                        : val == "txtSymmetry"
                                        ? "Symmetry"
                                        : val == "txtClarity"
                                        ? "Clarity"
                                        : val == "txtFluorescence"
                                        ? "Fluorescence"
                                        : val == "txtFluorescence"
                                        ? "Fluorescence"
                                        : val == "txtMeasurements"
                                        ? "Measurements"
                                        : val == "txtCertificate"
                                        ? "Certificate"
                                        : val == "txtCutGrade"
                                        ? "Cut Grade"
                                        : val == "realPrice"
                                        ? "Price"
                                        : val}
                                    </span>
                                  </React.Fragment>
                                )}
                              </div>
                            </th>
                            {compareList.map((item) => {
                              return val == "image" ? (
                                <React.Fragment>
                                  <td>
                                    <img
                                      style={{ width: "120px" }}
                                      src={item[val]}
                                    />
                                  </td>
                                </React.Fragment>
                              ) : val == "modifiedDate" ? (
                                <React.Fragment>
                                  <td>
                                    {moment(item[val]).format("MM/DD/YYYY")}
                                  </td>
                                </React.Fragment>
                              ) : val == "action" ? (
                                <React.Fragment>
                                  <td>
                                    <div className="action__btns">
                                      <div className="image__block">
                                        <img
                                          src={Eye_icon}
                                          onClick={() => handleViewDiamond(item)}
                                        />
                                      </div>
                                      <div className="image__block">
                                        <img
                                          src={Delete_iconbl}
                                          onClick={() =>
                                            handleDeleteCol(item.intDiamondId)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <td>{item[val]}</td>
                                </React.Fragment>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Spin>
            <div className="row">
              <div className="col-md-8">
                <div className="text__block">
                  <p>
                    Use your diamond comparison to save all the details of the
                    diamonds you are considering and view them all together.
                  </p>
                  <p>This makes it easy to choose the right diamond.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="image__block">
                  <img src={compareDiamond} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Compare;
