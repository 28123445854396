import React, { useState, useEffect } from "react";
import { Select, Button, Modal, Tooltip, TimePicker } from "antd";

import UnionIcon from "../../assets/images/icons/Union_5.svg";

import { Tabs, Tab } from "react-bootstrap";
// import MultiRangeSlider from "../../contacts/MultiRangeSlider"

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Parse from "html-react-parser";
import drophint from "../../assets/images/choose-setting/drophint.png";
import calender from "../../assets/images/choose-setting/calender.svg";
import emailicon from "../../assets/images/choose-setting/emailicon.svg";
import infoblack from "../../assets/images/choose-setting/infoblack.svg";

import Round from "../../assets/images/choose-setting/round.svg";
import Radiant from "../../assets/images/choose-setting/Radiant.svg";
import Asscher from "../../assets/images/choose-setting/Asscher.svg";
import Cushion from "../../assets/images/choose-setting/Cushion.svg";
import Emerald from "../../assets/images/choose-setting/Emerald.svg";
import Heart from "../../assets/images/choose-setting/Heart.svg";
import Oval from "../../assets/images/choose-setting/Oval.svg";
import Princess from "../../assets/images/choose-setting/Princess.svg";
import Pear from "../../assets/images/choose-setting/Radiant.svg";
import headerService from "../../services/header.service";
import leftArrow from "../../assets/images/choose-setting/left__arrow.png";
import rightArrow from "../../assets/images/choose-setting/right__arrow.png";

import shareBlue from "../../assets/images/choose-setting/share__blue.png";
import shareFacebook from "../../assets/images/choose-setting/shareIcons_02.png";
import sharePintrest from "../../assets/images/choose-setting/shareIcons_03.png";
import shareTwitter from "../../assets/images/choose-setting/shareIcons_04.png";

import DtlFacebook from "../../assets/images/fb__icon.svg";
import DtlTwitter from "../../assets/images/twitter__icon.svg";
import DtlPintrest from "../../assets/images/pintrest__icon.svg";
import DtlInstagram from "../../assets/images/instragm__icon.svg";
import DtlGooglePlus from "../../assets/images/google__plus__icon.svg";

import LikeFacebook from "../../assets/images/icons/facebook-like.jpg";
import { useDispatch, useSelector } from "react-redux";
import { handleHideDetailsPage } from "../../actions/chooseYourSetting/chooseYourSettingActions";
import itemDetailsService from "../../services/item-details.service";
import {
  handleRemoveSettingViewEdit,
  handleActiveTab,
} from "../../actions/header/headerActions";
import LocationService from "../../services/select-your-diamond-location";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import chooseSettingsService from "../../services/choose-settings.service";
import { Empty, Spin } from "antd";
import SelectYourDiamondService from "../../services/select-your-diamond.service";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import colorPanelService from "../../services/color-panel.service";
import { useNavigate } from "react-router-dom";
export const ChooseSettingItemDetails = () => {
  const navigate = useNavigate();
  const { Option } = Select;
  const dispatch = useDispatch();

  // Options API

  const [ViewOptionData, setViewOptionData] = useState({
    RingBuilderUrl: "",
    InternalUseLink: true,
    RingSizeType: null,
    InitiallySortbox1: null,
    InitiallySortbox2: null,
    DefaultMetalType: null,
    ShowAdvancedSearch: true,
    RingsShow: true,
    ShowAddress: true,
    ShowCenterStone: true,
    ShowPrice: true,
    GSTTax: true,
    GSTTaxValue: "",
    PinterestShare: true,
    TwitterShare: true,
    FacebookShare: true,
    FacebookLike: true,
    GooglePlus: true,
    InstagramShare: true,
    AddToCart: true,
    AltShoppingCart: "",
    ShowBuySetting: true,
    ShowBuyDiamond: true,
    ShowViewCart: true,
  });

  const handleGetOptionsDetails = (id) => {
    let inputData = {
      dealerID: id,
      Type: "RingBuilder",
    };

    try {
      headerService
        .GetRingBuilderOptionDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          if (message == "Success") {
            if (responseData) {
              debugger;
              setViewOptionData((prevState) => ({
                ...prevState,
                RingBuilderUrl: responseData.ringBuilderURL,
                InternalUseLink: responseData.internalLinkRB,
                RingSizeType: responseData.ringSizeType,
                InitiallySortbox1: responseData.ringInitiallySort1,
                InitiallySortbox2: responseData.ringInitiallySortDesc,
                DefaultMetalType: responseData.defaultMetalType,
                ShowAdvancedSearch: responseData.showAdvanceRingBuilder,
                RingsShow: responseData.showScheduleView,
                ShowAddress: responseData.showAddresses,
                ShowCenterStone: responseData.showCenterStoneSize,
                ShowPrice: responseData.showPricesOnSearchPage,
                GSTTax: responseData.showGstPrice,
                GSTTaxValue: responseData.taxRate,
                PinterestShare: responseData.showPinterestShare,
                TwitterShare: responseData.showTwitterShare,
                FacebookShare: responseData.showFacebookShare,
                FacebookLike: responseData.showFacebookLike,
                GooglePlus: responseData.showGooglePlus,
                InstagramShare: responseData.showInstagramShare,
                AddToCart: responseData.showAddToCartButton,
                AltShoppingCart: responseData.shoppingCartURL,
                ShowBuySetting: responseData.showBuyWithoutCenterStone,
                ShowBuyDiamond: responseData.buyDiamondOnly,
                ShowViewCart: responseData.showViewCartButtonRB,
              }));
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const images = [
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.alt.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.alt.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.alt.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.alt.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
    },
  ];

  const showModalFullscreen = () => {
    setIsModalVisibleFullscreen(true);
  };

  const handleCancelFullscreen = () => {
    setIsModalVisibleFullscreen(false);
  };

  const [isModalVisibleFullscreen, setIsModalVisibleFullscreen] =
    useState(false);

  const [dealerId, setDealerId] = useState(0);
  const [itemDetailsData, setItemDetailsData] = useState({});
  const [settingData, setSettingData] = useState("");
  const [settingId, setSettingId] = useState("");
  const [socialData, setSocialData] = useState({});
  const [showSocialLinks, setShowSocialLinks] = useState(false);
  const [locationData1, setLocationData1] = useState([]);
  const [settingDropdownData, setSettingDropdownData] = useState({
    ringSize: [],
    sideStoneQuality: [],
    centerStoneSize: [],
    metalType: [],
  });

  const [dropHintInputData, setDropHintInputData] = useState({
    name: "",
    email: "",
    recipientName: "",
    recipientEmail: "",
    giftReason: "",
    giftDeadline: "",
    personalMessage: "",
  });
  const [dropHintValidation, setDropHintValidation] = useState({
    nameVal: "",
    emailVal: "",
    recipientNameVal: "",
    recipientEmailVal: "",
    giftReasonVal: "",
    giftDeadlineVal: "",
    personalMessageVal: "",
  });

  const [requestMoreInfoInputData, setRequestMoreInfoInputData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    contactPreference: "byEmail",
    personalMessage: "",
  });

  const [requestMoreInfoValidation, setRequestMoreInfoValidation] = useState({
    nameVal: "",
    emailVal: "",
    phoneNumberVal: "",
    contactPreferenceVal: "",
    personalMessageVal: "",
  });

  const [emailFriendInputData, setEmailFriendInputData] = useState({
    name: "",
    email: "",
    friendName: "",
    friendEmail: "",
    personalMessage: "",
  });
  const [emailFriendInputValidation, setEmailFriendInputDataValidation] =
    useState({
      nameVal: "",
      emailVal: "",
      friendNameVal: "",
      friendEmailVal: "",
      personalMessageVal: "",
    });

  const [scheduleViewingInputData, setScheduleViewingInputData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    location: "",
    appointmentDate: "",
    appointmentTime: "",
    address: "",
    message: "",
  });

  const [scheduleViewingInputValidation, setScheduleViewingInputValidation] =
    useState({
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      locationVal: "",
      appointmentDateVal: "",
      appointmentTimeVal: "",
      addressVal: "",
      messageVal: "",
    });

  const [availableShapes, setAvailableShapes] = useState([]);
  const [availableShapesData, setAvailableShapesData] = useState([]);
  const [locationData, setLocationData] = useState({});
  const [settingImages, setSettingImages] = useState([]);

  //Modal Buttons Loading
  const [dropAHintLoading, setDropAHintLoading] = useState(false);
  const [requestMoreInfoLoading, setRequestMoreInfoLoading] = useState(false);
  const [emailAFriendLoading, setEmailAFriendLoading] = useState(false);
  const [scheduleViewingLoading, setScheduleViewingLoading] = useState(false);

  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const currentTab = useSelector((state) => state.headerReducer);
  const chooseSettingDetails = useSelector(
    (state) => state.chooseYourSettingReducer
  );

  function renderLeftNav(onClick, disabled) {
    return (
      <button
        type="button"
        className="image-gallery-left-nav"
        aria-label="Prev Slide"
        disabled={disabled}
        onClick={onClick}
      >
        <img src={leftArrow} />
      </button>
    );
  }

  function renderRightNav(onClick, disabled) {
    return (
      <button
        type="button"
        className="image-gallery-right-nav"
        aria-label="Next Slide"
        disabled={disabled}
        onClick={onClick}
      >
        <img src={rightArrow} />
      </button>
    );
  }

  // Modal Functions
  const [requestMoreModal, setRequestMoreModal] = useState(false);
  const [dropHintModal, setDropHintModal] = useState(false);
  const [emailFriendModal, setEmailFriendModal] = useState(false);
  const [isModalVisible4, setIsModalVisible4] = useState(false);
  const [scheduleViewingModal, setScheduleViewingModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemDetailsInputData, setItemDetailsInputData] = useState({
    metalType: "",
    centerStoneSize: "",
    ringSize: "",
    sideStoneQuality: "",
  });

  const [optionData, setOptionData] = useState();

  // Social Link

  const search = useLocation().search;
  const isSocialPage = new URLSearchParams(search).get("isSocialPage");
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const paramSettingId = new URLSearchParams(search).get("settingId");
  const settingNumber = new URLSearchParams(search).get("settingNumber");
  const paramDiamondId = new URLSearchParams(search).get("diamondId");
  const pageName = new URLSearchParams(search).get("pageName");
  const childType = new URLSearchParams(search).get("childType");
  const vendorID = new URLSearchParams(search).get("vendorID");
  // const isAllowed = new URLSearchParams(search).get("isAllowed");
  // const socialpageNo = new URLSearchParams(search).get("pageNo");
  useEffect(() => {
    if (isSocialPage == "True") {
      debugger;
      handleGetItemDetails(paramdealerId, paramSettingId, settingNumber);
      handleLocationLoadDetails(paramdealerId);
      handleGetOptionsDetails(paramdealerId);
      setDealerId(paramdealerId);
      setSettingId(paramSettingId);
    } else {
      if (loginDetails) {
        if (loginDetails.dealerId) {
          if (chooseSettingDetails) {
            if (chooseSettingDetails.selectedRow) {
              if (chooseSettingDetails.selectedRow.settingID)
                handleGetItemDetails(
                  loginDetails.dealerId,
                  chooseSettingDetails.selectedRow.settingID,
                  chooseSettingDetails.selectedRow.settingNumber
                );
              handleGetOptionsDetails(loginDetails.dealerId);
              setSettingId(chooseSettingDetails.selectedRow.settingID);
              handleLocationLoadDetails(loginDetails.dealerId);
            }
          }
          setDealerId(loginDetails.dealerId);
        }
      }
    }
  }, []);

  // //Scroll to top when gets load
  // useEffect(() => {
  //   document.body.scrollTop = 0; // For Safari
  //   document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  // }, []);

  const openRequestMoreModal = () => {
    setRequestMoreModal(true);
  };
  const closeRequestMoreModal = () => {
    setRequestMoreModal(false);
    setRequestMoreInfoInputData({
      name: "",
      email: "",
      phoneNumber: "",
      contactPreference: "",
      personalMessage: "",
    });
    setRequestMoreInfoValidation({
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      contactPreferenceVal: "",
      personalMessageVal: "",
    });
  };

  const openDropAHintModal = () => {
    setDropHintModal(true);
  };
  const closeDropHintModal = () => {
    setDropHintModal(false);
    setDropHintInputData({
      name: "",
      email: "",
      recipientName: "",
      recipientEmail: "",
      giftReason: "",
      giftDeadline: "",
      personalMessage: "",
    });
    setDropHintValidation({
      nameVal: "",
      emailVal: "",
      recipientNameVal: "",
      recipientEmailVal: "",
      giftReasonVal: "",
      giftDeadlineVal: "",
      personalMessageVal: "",
    });
  };

  const openEmailFriendModal = () => {
    setEmailFriendModal(true);
  };
  const closeEmailFriendModal = () => {
    setEmailFriendModal(false);
    setEmailFriendInputData({
      name: "",
      email: "",
      friendName: "",
      friendEmail: "",
      personalMessage: "",
    });
    setEmailFriendInputDataValidation({
      nameVal: "",
      emailVal: "",
      friendNameVal: "",
      friendEmailVal: "",
      personalMessageVal: "",
    });
  };

  const showModal4 = () => {
    setIsModalVisible4(true);
  };
  const handleCancel4 = () => {
    setIsModalVisible4(false);
  };

  const openScheduleViewingModal = () => {
    handleLocationLoadDetails(dealerId);
    setScheduleViewingModal(true);
  };
  const closeScheduleViewingModal = () => {
    setScheduleViewingModal(false);
    setScheduleViewingInputData({
      name: "",
      email: "",
      phoneNumber: "",
      location: "",
      appointmentDate: "",
      appointmentTime: "",
      address: "",
      message: "",
    });
    setScheduleViewingInputValidation({
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      locationVal: "",
      appointmentDateVal: "",
      appointmentTimeVal: "",
      addressVal: "",
      messageVal: "",
    });
  };

  const showVideoModal = () => {
    setVideoModal(true);
  };
  const closeVideoModal = () => {
    setVideoModal(false);
  };

  // const handleBuySetting = () => {
  //
  //   dispatch(handleHideDetailsPage());
  //   dispatch(handleRemoveSettingViewEdit());
  // };

  const handleItemDetailsInputChange = (e, name) => {
    setItemDetailsInputData({
      ...itemDetailsInputData,
      [name]: e,
    });
  };

  const handleDropHintChange = (e, name, type) => {
    setDropHintInputData({
      ...dropHintInputData,
      [name]: e.target.value,
    });
  };

  const handleSubmitDropHint = () => {
    const isValid = handleDropHintValidation();
    if (isValid == false) {
      return false;
    } else {
      handleSaveDropHint();
    }
  };

  const handleRequestMoreInfoChange = (e, name, type) => {
    setRequestMoreInfoInputData({
      ...requestMoreInfoInputData,
      [name]: e.target.value,
    });
    if (name == "name") {
      setRequestMoreInfoValidation((prevdata) => ({
        ...prevdata,
        nameVal: "",
      }));
    }
    if (name == "email") {
      setRequestMoreInfoValidation((prevdata) => ({
        ...prevdata,
        emailVal: "",
      }));
    }
    if (name == "phoneNumber") {
      setRequestMoreInfoValidation((prevdata) => ({
        ...prevdata,
        phoneNumberVal: "",
      }));
    }
    if (name == "personalMessage") {
      setRequestMoreInfoValidation((prevdata) => ({
        ...prevdata,
        personalMessageVal: "",
      }));
    }
  };

  const handleSubmitRequestMoreInfo = () => {
    const isValid = handleRequestMoreInfoValidation();
    if (isValid == false) {
      return false;
    } else {
      handleSaveRequestMoreInfo();
    }
  };

  const handleSubmitEmailFriend = () => {
    const isValid = handleEmailToFriendValidation();
    if (isValid == false) {
      return false;
    } else {
      handleSaveEmailFriend();
    }
  };

  const handleSubmitSheduleViewing = () => {
    const isValid = handleScheduleviewingValidation();
    if (isValid == false) {
      return false;
    } else {
      handleSaveScheduleViewing();
    }
  };

  const handleEmailFriendChange = (e, name) => {
    setEmailFriendInputData({
      ...emailFriendInputData,
      [name]: e.target.value,
    });
  };

  const handleScheduleViewingChange = (e, name, type) => {
    if (type == "select") {
      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e,
      });
    } else {
      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e.target.value,
      });
    }
  };

  const handleSettingFilterChange = (e) => {
    let shapes = availableShapes;
    if (shapes.includes(e)) {
      shapes.pop(e);
    } else {
      shapes.push(e);
    }
    setAvailableShapes(shapes);
  };

  const handleSaveDropHint = async () => {
    let inputData = {
      sid: itemDetailsData.settingID.toString(),
      dealerLink: dealerId.toString(),
      tmpCustID: "",
      orderBy: "",
      yourName: dropHintInputData.name,
      hintRecptName: dropHintInputData.recipientName,
      hintRecptMessage: dropHintInputData.personalMessage,
      productType: "",
      toolType: "",
      yourEmail: dropHintInputData.email,
      hintRecptEmail: dropHintInputData.recipientEmail,
      hintReasoneForGift: dropHintInputData.giftReason,
      hintGiftDeadLine: dropHintInputData.giftDeadline,
    };

    setDropAHintLoading(true);

    try {
      itemDetailsService
        .SaveDropHint(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            console.log(responseData);
            NotificationManager.success("Mail has been sent successfully.");

            closeDropHintModal();
            setDropAHintLoading(false);
          } else {
            NotificationManager.error("Mail has not been sent.");
            setDropAHintLoading(false);
          }
        })
        .catch((error) => {
          NotificationManager.error("Sorry we had an error");
          setDropAHintLoading(false);

          console.log(error);
        });
    } catch (error) {
      NotificationManager.error("Sorry we had an error");
      setDropAHintLoading(false);

      console.log(error);
    }
  };

  console.log(itemDetailsData);
  const handleSaveRequestMoreInfo = async () => {
    let inputData = {
      diamondVendorId: "",
      did: "",
      sid: itemDetailsData.settingID.toString(),
      settingVendorId: itemDetailsData.vendorID.toString(),
      productID: "",
      dealerID: dealerId.toString(),
      dealerEmail: "",
      customerId: "",
      customerName: requestMoreInfoInputData.name,
      customerEmail: requestMoreInfoInputData.email,
      customerPhone: requestMoreInfoInputData.phoneNumber,
      customerMessage: requestMoreInfoInputData.personalMessage,
      customerPreference: requestMoreInfoInputData.contactPreference,
      productType: "",
      toolType: "",
      isEmail: "",
      isDiamond: false,
      isSetting: true,
    };

    setRequestMoreInfoLoading(true);
    try {
      itemDetailsService
        .SaveRequestInfo(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            console.log(responseData);
            NotificationManager.success("Mail has been sent successfully.");
            closeRequestMoreModal();
            setRequestMoreInfoLoading(false);
          } else {
            NotificationManager.error("Mail has not been sent.");
            setRequestMoreInfoLoading(false);
          }
        })
        .catch((error) => {
          NotificationManager.error("Sorry we had an error");
          setRequestMoreInfoLoading(false);

          console.log(error);
        });
    } catch (error) {
      NotificationManager.error("Sorry we had an error");
      setRequestMoreInfoLoading(false);

      console.log(error);
    }
  };

  const handleSaveEmailFriend = async () => {
    // let inputData = {
    //   sid: itemDetailsData.settingID.toString(),
    //   productID: "",
    //   dealerID: dealerId.toString(),
    //   customerId: "",
    //   customerName: emailFriendInputData.name,
    //   customerEmail: emailFriendInputData.email,
    //   friendName: emailFriendInputData.friendName,
    //   friendEmail: emailFriendInputData.friendEmail,
    //   customerMessage: emailFriendInputData.personalMessage,
    //   productType: "",
    //   toolType: "",
    // };

    let inputData = {
      sid: itemDetailsData.settingID.toString(),
      did: "",
      productID: "",
      dealerID: dealerId.toString(),
      customerId: "",
      customerName: emailFriendInputData.name,
      customerEmail: emailFriendInputData.email,
      friendName: emailFriendInputData.friendName,
      friendEmail: emailFriendInputData.friendEmail,
      customerMessage: emailFriendInputData.personalMessage,
      productType: "",
      toolType: "",
      isEmail: "",
      dealerEmail: "",
      settingVendorId: itemDetailsData.vendorID.toString(),
      diamondVendorId: "",
      isDiamond: false,
      isSetting: true,
    };

    setEmailAFriendLoading(true);

    try {
      itemDetailsService
        .SaveEmailAFriend(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            console.log(responseData);
            NotificationManager.success(" Mail has been sent successfully.");
            closeEmailFriendModal();
            setEmailAFriendLoading(false);
          } else {
            NotificationManager.error("Mail has not been sent.");
            setEmailAFriendLoading(false);
          }
        })
        .catch((error) => {
          NotificationManager.error("Sorry we had an error");

          console.log(error);
          setEmailAFriendLoading(false);
        });
    } catch (error) {
      NotificationManager.error("Sorry we had an error");

      console.log(error);
      setEmailAFriendLoading(false);
    }
  };

  const handleSaveScheduleViewing = async () => {
    let inputData = {
      diamondVendorId: "",
      did: "",
      sid: itemDetailsData.settingID.toString(),
      settingVendorId: itemDetailsData.vendorID.toString(),
      dealerID: dealerId.toString(),
      customerId: "",
      name: scheduleViewingInputData.name,
      email: scheduleViewingInputData.email,
      phone: scheduleViewingInputData.phoneNumber,
      customerMessage: scheduleViewingInputData.message,
      appType: "",
      appDate: scheduleViewingInputData.appointmentDate,
      appTime: scheduleViewingInputData.appointmentTime,
      productids: "",
      productType: "",
      toolType: "",
      isDiamond: false,
      isSetting: true,
    };

    setScheduleViewingLoading(true);

    try {
      itemDetailsService
        .SaveScheduleAViewing(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            console.log(responseData);
            NotificationManager.success("Mail has been sent successfully.");

            closeScheduleViewingModal();
            setScheduleViewingLoading(false);
          } else {
            NotificationManager.error("Mail has not been sent.");
            setScheduleViewingLoading(false);
          }
        })
        .catch((error) => {
          NotificationManager.error("Could Not Add Record");
          setScheduleViewingLoading(false);

          console.log(error);
        });
    } catch (error) {
      NotificationManager.error("Sorry we had an error");
      setScheduleViewingLoading(false);

      console.log(error);
    }
  };

  const handleGetItemDetails = async (id, settingId, settingNumber) => {
    let inputData = {
      gfInventoryID: settingId.toString(),
      dealerLink: id.toString(),
      metalType: "",
      metalColor: "",
      styleNumber: settingNumber.toString(),
      url: "",
      qurShape: "",
      qurCTW: "",
      themeName: "",
      qurDID: "",
      bindWhat: "",
      countryTaxRate: "",
      gemstoneQuality: "",
      gemstoneSize: "",
      caratMin: "",
      gemstoneShape: "",
      type: childType == "null" || childType == null ? "" : "RingChildId",
    };
    setLoading(true);
    try {
      await chooseSettingsService
        .GetAppPrevDataonSelectYourSetting(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData =
            response.data.responseData
              .getAppPrevDataonSelectYourSettingClass[0];

          if (msg == "Success") {
            debugger;
            let tempAvailableShapesData =
              responseData.availableShapes !== ""
                ? responseData.availableShapes
                : "";

            setItemDetailsData(responseData);
            localStorage.setItem(
              "SettingDetails",
              JSON.stringify(responseData)
            );
            debugger;
            setSettingData(responseData.setting);
            // handleGetRingList(id);
            handleGetItemDetailsPageDropdown(id, settingId);

            debugger;
            handleGetSocialData(id, settingId);
            handleGetDealerLocationData(dealerId, vendorID);

            let newSettingImages = [];
            debugger;
            let obj1 = {
              original: responseData.additionalImageURL1,
              thumbnail: responseData.additionalImageURL1,
            };
            let obj2 = {
              original: responseData.additionalImageURL2,
              thumbnail: responseData.additionalImageURL2,
            };
            let obj3 = {
              original: responseData.additionalImageURL3,
              thumbnail: responseData.additionalImageURL3,
            };
            let obj4 = {
              original: responseData.additionalImageURL4,
              thumbnail: responseData.additionalImageURL4,
            };
            let obj5 = {
              original: responseData.additionalImageURL5,
              thumbnail: responseData.additionalImageURL5,
            };
            let obj6 = {
              original: responseData.additionalImageURL6,
              thumbnail: responseData.additionalImageURL6,
            };

            newSettingImages.push(obj1);
            newSettingImages.push(obj2);
            newSettingImages.push(obj3);
            newSettingImages.push(obj4);
            newSettingImages.push(obj5);
            newSettingImages.push(obj6);

            setSettingImages(newSettingImages);
            debugger;
            setAvailableShapesData(tempAvailableShapesData);
            var firstVal = responseData.productRingSizeList[0].ringSizeVal;
            setItemDetailsInputData({
              ...itemDetailsInputData,
              ringSize: firstVal,
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleAddYourDiamond = () => {
    console.log(itemDetailsData);
    debugger;
    navigate(
      "/SelectYourDiamond" +
        `?DealerLink=${paramdealerId}&setParamValues=True&settingShape=${itemDetailsData.setting}&centerStoneMinimumCarat=${itemDetailsData.centerStoneMinimumCarat}&centerStoneMaximumCarat=${itemDetailsData.centerStoneMaximumCarat}&settingId=${itemDetailsData.settingID}&settingNumber=${itemDetailsData.settingNumber}&headerTab=selectYourDiamond&settingViewEdit=True`
    );
  };

  const handleCompleteYourRing = () => {
    navigate(
      "/CompleteYourRing" +
        `?isSocialPage=True&DealerLink=${paramdealerId}&diamondId=${paramDiamondId}&pageNo=1&isAllowed=True&pageName=${pageName}&settingId=${itemDetailsData.settingID}&settingNumber=${itemDetailsData.settingNumber}&headerTab=completeYourRing&childType="RingChildId`
    );
  };

  const handleBackButton = () => {
    navigate("/" + `?DealerLink=${paramdealerId}`);
    // dispatch(handleHideDetailsPage());
    // dispatch(handleRemoveSettingViewEdit());
  };

  const handleGetItemDetailsPageDropdown = async (id, settingId) => {
    // let inputData = {
    //   dealerlink: "720",
    //   sku: "F1626",
    //   settingDealerID: "720",
    //   metaltype: "",
    //   gemstoneQuality: "",
    //   gemstoneSize: "",
    // };

    let inputData = {
      dealerlink: id,
      sku: settingId,
      settingDealerID: id,
      metaltype: "",
      gemstoneQuality: "",
      gemstoneSize: "",
    };

    // let inputData = {
    //   dealerlink: "720",
    //   sku: "50552-E",
    //   settingDealerID: "720",
    //   metaltype: "",
    //   gemstoneQuality: "",
    //   gemstoneSize: "",
    // };

    try {
      itemDetailsService
        .SettingItemDetailPageDropDown(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            if (responseData) {
              let sideStoneQuality =
                responseData.gemStoneQualityLst.length > 0
                  ? responseData.gemStoneQualityLst[0].gemstoneQuality
                  : "";
              let centerStoneSize =
                responseData.gemStoneSizeLst.length > 0
                  ? responseData.gemStoneSizeLst[0].gemstoneSize
                  : "";
              let metalType =
                responseData.metalTypeLst.length > 0
                  ? responseData.metalTypeLst[0].metalType
                  : "";
              setItemDetailsInputData((prevState) => ({
                ...prevState,
                centerStoneSize,
                metalType,
                sideStoneQuality,
              }));

              setSettingDropdownData((prevState) => ({
                ...prevState,
                sideStoneQuality: responseData.gemStoneQualityLst,
                centerStoneSize: responseData.gemStoneSizeLst,
                metalType: responseData.metalTypeLst,
              }));
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetRingList = async (id) => {
    let inputData = {
      dealerID: id.toString(),
    };

    try {
      itemDetailsService
        .GetRingList(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            if (responseData) {
              let ringSize = "";
              if (responseData[0]) {
                if (responseData[0].ringId) {
                  ringSize = responseData[0].ringId;
                }
              }
              setItemDetailsInputData((prevState) => ({
                ...prevState,
                ringSize: ringSize,
              }));

              setSettingDropdownData((prevState) => ({
                ...prevState,
                ringSize: responseData,
              }));
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDealerLocationData = (id, retailerId) => {
    debugger;
    try {
      let inputData = {
        dealerID: id.toString(),
        myRetailerID: retailerId,
      };
      // setViewStateLoading(true);
      itemDetailsService
        .GetDealerLocationData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];

          if (message == "Success") {
            if (responseData) {
              setLocationData(responseData);

              // setViewStateLoading(false);
            } else {
              // setViewStateLoading(false);
            }
          } else {
            // setViewStateLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          // setViewStateLoading(false);
        });
    } catch (error) {
      console.log(error);
      // setViewStateLoading(false);
    }
  };

  const handleDropHintValidation = () => {
    const {
      name,
      email,
      recipientName,
      recipientEmail,
      giftReason,
      giftDeadline,
      personalMessage,
    } = dropHintInputData;
    const dropHintValidation = {
      nameVal: "",
      emailVal: "",
      recipientNameVal: "",
      recipientEmailVal: "",
      giftReasonVal: "",
      giftDeadlineVal: "",
      personalMessageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      dropHintValidation.nameVal = "FirstName is Compulsory";
    }
    if (!recipientName.trim()) {
      isValid = false;
      dropHintValidation.recipientNameVal = "RecipentName is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email.trim()) {
      isValid = false;
      dropHintValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      dropHintValidation.emailVal = "Email address is invalid";
    } else {
      dropHintValidation.emailVal = "";
    }

    if (!recipientEmail.trim()) {
      isValid = false;
      dropHintValidation.recipientEmailVal = "Email address is compulsory";
    } else if (!recipientEmail.match(validRegex)) {
      isValid = false;
      dropHintValidation.recipientEmailVal = "Email address is invalid";
    } else {
      dropHintValidation.recipientEmailVal = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      dropHintValidation.personalMessageVal = "Personal Message is Compulsory";
    }
    if (!giftReason.trim()) {
      isValid = false;
      dropHintValidation.giftReasonVal = "Gift Reason is Compulsory";
    }
    if (!giftDeadline.trim()) {
      isValid = false;
      dropHintValidation.giftDeadlineVal = "Gift Deadline is Compulsory";
    }

    // if (!isValid) {
    //   setDropHintValidation(dropHintValidation);
    // }

    setDropHintValidation(dropHintValidation);

    return isValid;
  };

  const handleRequestMoreInfoValidation = () => {
    const { name, email, phoneNumber, contactPreference, personalMessage } =
      requestMoreInfoInputData;
    const requestMoreInfoValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      contactPreferenceVal: "",
      personalMessageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      requestMoreInfoValidation.nameVal = "First Name is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email) {
      isValid = false;
      requestMoreInfoValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      requestMoreInfoValidation.emailVal = "Email address is invalid";
    } else {
      requestMoreInfoValidation.emailVal = "";
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      requestMoreInfoValidation.phoneNumberVal = "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      requestMoreInfoValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      requestMoreInfoValidation.phoneNumberVal = "Phone number is invalid";
    } else {
      requestMoreInfoValidation.phoneNumberVal = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      requestMoreInfoValidation.personalMessageVal =
        "Personal Message is Compulsory";
    }

    // if (!isValid) {
    //   setRequestMoreInfoValidation(requestMoreInfoValidation);
    // }

    setRequestMoreInfoValidation(requestMoreInfoValidation);

    return isValid;
  };

  const handleEmailToFriendValidation = () => {
    const { name, email, friendName, friendEmail, personalMessage } =
      emailFriendInputData;
    const emailFriendInputValidation = {
      nameVal: "",
      emailVal: "",
      friendNameVal: "",
      friendEmailVal: "",
      personalMessageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      emailFriendInputValidation.nameVal = "Name is Compulsory";
    }

    if (!friendName.trim()) {
      isValid = false;
      emailFriendInputValidation.friendNameVal = "Friend Name is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email.trim()) {
      isValid = false;
      emailFriendInputValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      emailFriendInputValidation.emailVal = "Email address is invalid";
    } else {
      emailFriendInputValidation.emailVal = "";
    }

    if (!friendEmail.trim()) {
      isValid = false;
      emailFriendInputValidation.friendEmailVal = "Email address is compulsory";
    } else if (!friendEmail.match(validRegex)) {
      isValid = false;
      emailFriendInputValidation.friendEmailVal = "Email address is invalid";
    } else {
      emailFriendInputValidation.friendEmailVal = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      emailFriendInputValidation.personalMessageVal =
        "Personal Message is Compulsory";
    }

    // if (!isValid) {
    //   setEmailFriendInputDataValidation(emailFriendInputValidation);
    // }

    setEmailFriendInputDataValidation(emailFriendInputValidation);

    return isValid;
  };

  const handleScheduleviewingValidation = () => {
    const {
      name,
      email,
      phoneNumber,
      location,
      appointmentDate,
      appointmentTime,
      address,
      message,
    } = scheduleViewingInputData;
    const scheduleViewingInputValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      locationVal: "",
      appointmentDateVal: "",
      appointmentTimeVal: "",
      addressVal: "",
      messageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      scheduleViewingInputValidation.nameVal = "Name is Compulsory";
    }

    if (!location) {
      isValid = false;
      scheduleViewingInputValidation.locationVal = "Location is Compulsory";
    }

    if (!appointmentDate) {
      isValid = false;
      scheduleViewingInputValidation.appointmentDateVal =
        "Appointment Date is Compulsory";
    }

    if (!appointmentTime) {
      isValid = false;
      scheduleViewingInputValidation.appointmentTimeVal =
        "Appointment Time is Compulsory";
    }

    // if (!address) {
    //   isValid = false;
    //   scheduleViewingInputValidation.addressVal = "Address is Compulsory";
    // }

    if (!message) {
      isValid = false;
      scheduleViewingInputValidation.messageVal = "Message is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email) {
      isValid = false;
      scheduleViewingInputValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      scheduleViewingInputValidation.emailVal = "Email address is invalid";
    } else {
      scheduleViewingInputValidation.emailVal = "";
    }
    if (!phoneNumber) {
      isValid = false;
      scheduleViewingInputValidation.phoneNumberVal =
        "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      scheduleViewingInputValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      scheduleViewingInputValidation.phoneNumberVal = "Phone number is invalid";
    } else {
      scheduleViewingInputValidation.phoneNumberVal = "";
    }

    // if (!isValid) {
    //   setScheduleViewingInputValidation(scheduleViewingInputValidation);
    // }

    setScheduleViewingInputValidation(scheduleViewingInputValidation);

    return isValid;
  };

  const handleGetSocialData = async (id, sId) => {
    let inputData = {
      vDealerId: id.toString(),
      sid: sId.toString(),
      settingName: "",
      tweeterURL: "",
      imagePath: "",
    };

    try {
      itemDetailsService
        .GetSocialData(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            if (responseData) {
              setSocialData(responseData);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowSocialLinks = () => {
    setShowSocialLinks(!showSocialLinks);
  };

  const handleSocialRedirect = (URL) => {
    debugger;
    window.open(URL);
  };

  const handleAppointmentTimeChange = (time, timeString) => {
    setScheduleViewingInputData({
      ...scheduleViewingInputData,
      appointmentTime: timeString,
    });
  };

  // Location

  const handleLocationLoadDetails = (id) => {
    try {
      let inputData = {
        dealerID: id,
      };

      LocationService.LocationLoadDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.Table;

          if (message == "Success") {
            if (responseData) {
              setLocationData1(responseData);
            } else {
              setLocationData1([]);
            }
          } else {
            setLocationData1([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Date

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  // Modal
  const [internalUseModal, setInternalUseModal] = useState(false);
  const [showDealerInfo, setShowDealerInfo] = useState();
  const [dealerInfo, setDealerInfo] = useState({});
  const [dealerInfo1, setDealerInfo1] = useState({});
  const handleOpenInternalModal = () => {
    setInternalUseModal(true);
  };
  const [internalUseData, setInternalUseData] = useState({
    password: "",
  });
  const [internalUseDataValidation, setInternalUseDataValidation] = useState({
    passwordVal: "",
  });
  const [internalUseModalLoading, setInternalUseModalLoading] = useState(false);
  const handleCancelInternalModal = () => {
    setInternalUseModal(false);
    setShowDealerInfo(false);
    setInternalUseData({
      password: "",
    });
    setInternalUseDataValidation({
      passwordVal: "",
    });
  };
  const handleSubmitInternalData = () => {
    console.log(internalUseData);
    handleGetDealerInformation();
    setInternalUseData({
      password: "",
    });
  };
  const handleSetInternalUseData = (e) => {
    setInternalUseData({
      password: e.target.value,
    });

    setInternalUseDataValidation({
      passwordVal: "",
    });
  };
  const handleValidation = () => {
    const { password } = internalUseData;

    const internalUseDataValidation = {
      passwordVal: "",
    };
    let isValid = true;

    if (!password) {
      isValid = false;
      internalUseDataValidation.passwordVal = "Password is Compulsory";
    }

    setInternalUseDataValidation(internalUseDataValidation);

    return isValid;
  };
  const handleGetDealerInformation = () => {
    let isvalid = handleValidation();

    if (!isvalid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        jewelryDealerID: itemDetailsData.vendorID,
        password: internalUseData.password,
        jewelryID: itemDetailsData.settingID,
      };
      setInternalUseModalLoading(true);
      SelectYourDiamondService.GetDealerInformationJewelry(inputData)
        .then((response) => {
          var message = response.data.responseData.passwordcheck;
          var dealerInfo = response.data.responseData.getDealerInformation[0];
          var dealerInfo1 = response.data.responseData.getJewelryInformation[0];
          if (message == false) {
            NotificationManager.error("Kindly Enter valid Details");
            setInternalUseModalLoading(false);
            setShowDealerInfo(false);
          } else {
            setShowDealerInfo(true);
            setDealerInfo(dealerInfo);
            setDealerInfo1(dealerInfo1);
            setInternalUseModalLoading(false);
            console.log(dealerInfo);
          }
        })
        .catch((error) => {
          console.log(error);
          setInternalUseModalLoading(false);
        });
    } catch (error) {
      console.log(error);
      setInternalUseModalLoading(false);
    }
  };

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [wrapperLoading, setWrapperLoading] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [toggle, setToggle] = useState(true);
  useEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "RingBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              var tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              debugger;
              console.log(tempobj);
              console.log(initialColorObj);
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
              // dispatch(handleSwitchChange());
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;
  const [showButton, setShowButton] = useState("");
  useEffect(() => {
    debugger;

    if (paramDiamondId == "null" || paramDiamondId == null) {
      setShowButton("AddYourDiamond");
    } else {
      setShowButton("CompleteYourRing");
    }
  }, []);

  /* Color Individually Ends */
  return (
    <React.Fragment>
      <Wrapper>
        {/* <span className="back__btn" onClick={(e) => handleBackButton(e)}>
          {" "}
          {"<< Back"}{" "}ss
        </span> */}
        <div className="allprod_section jwlsrch__section app__preview choose__setting">
          <div className="row">
            <div className="col-md-12">
              <div className="prodetail__section">
                <div className="form__fields border-0">
                  <div className="col-md-12">
                    <div className="prod__maindiv app__preview p-0 border-0">
                      <div className="row">
                        <div className="col-lg-6">
                          <Spin spinning={loading}>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="">
                                  <p>Available Shapes :</p>
                                </div>

                                <div className="whole__shapes available__shapes">
                                  {/* {availableShapesData.length > 0 &&
                                  availableShapesData.map((shape) => {
                                    return ( */}
                                  <div
                                  // className={
                                  //   availableShapes.includes(shape)
                                  //     ? "shapes__block selected"
                                  //     : "shapes__block"
                                  // }
                                  // onClick={() =>
                                  //   handleSettingFilterChange(shape)
                                  // }
                                  >
                                    <div className="image">
                                      <img src={availableShapesData} />
                                    </div>
                                    <p>{settingData}</p>
                                  </div>
                                  {/* );
                                  })} */}
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div className="thimage__slider">
                                  <ImageGallery
                                    items={settingImages}
                                    showFullscreenButton={false}
                                    renderLeftNav={renderLeftNav}
                                    renderRightNav={renderRightNav}
                                  />
                                  <div
                                    className="video__icon"
                                    onClick={showVideoModal}
                                  >
                                    <i
                                      class="fa fa-video-camera"
                                      aria-hidden="true"
                                    ></i>
                                  </div>

                                  <div
                                    className="video__icon fullscreen__icon"
                                    onClick={showModalFullscreen}
                                  >
                                    <i
                                      class="fa fa-arrows-alt"
                                      aria-hidden="true"
                                    ></i>
                                  </div>

                                  <div className="share__block display-none">
                                    <div
                                      className="share__button"
                                      onClick={handleShowSocialLinks}
                                    >
                                      <img src={shareBlue} />
                                    </div>
                                    <div
                                      className={
                                        showSocialLinks
                                          ? "social__button__enabled"
                                          : "social__button"
                                      }
                                    >
                                      {ViewOptionData.FacebookShare == true && (
                                        <img
                                          src={shareFacebook}
                                          onClick={() =>
                                            handleSocialRedirect(
                                              socialData.fburl
                                            )
                                          }
                                        />
                                      )}

                                      {ViewOptionData.PinterestShare ==
                                        true && (
                                        <img
                                          src={sharePintrest}
                                          onClick={() =>
                                            handleSocialRedirect(
                                              socialData.pinInterestURL
                                            )
                                          }
                                        />
                                      )}
                                      {ViewOptionData.FacebookLike == true && (
                                        <img
                                          src={LikeFacebook}
                                          onClick={() =>
                                            handleSocialRedirect(
                                              socialData.tweeterURL
                                            )
                                          }
                                        />
                                      )}
                                      {ViewOptionData.TwitterShare == true && (
                                        <img
                                          src={shareTwitter}
                                          onClick={() =>
                                            handleSocialRedirect(
                                              socialData.tweeterURL
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {ViewOptionData.InternalUseLink == true && (
                                <span
                                  className="font__bold mr-2 mt-3 linkText"
                                  onClick={handleOpenInternalModal}
                                >
                                  For Internal Use Only
                                </span>
                              )}
                              {/* <div className="col-md-12">
                              <div className="price__block text-center">
                                  <h4 className="subheading">US$2,295</h4>
                                  <button className="primary-btn">Add To Cart</button>
                              </div>
                          </div> */}
                            </div>
                          </Spin>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <Spin spinning={loading}>
                            <div className="prodesc__div">
                              <Tabs
                                defaultActiveKey="home"
                                id="uncontrolled-tab-example"
                                className="tab__div border-0"
                              >
                                <Tab eventKey="home" title="Item Details">
                                  <div className="tab__contentdiv border__block p-0">
                                    <div className="head__div">
                                      <h4>
                                        {itemDetailsData.settingName
                                          ? itemDetailsData.settingName
                                          : "NA"}
                                      </h4>
                                    </div>
                                    <div className="desc__maindiv">
                                      <div className="row top__content flex-column m-0">
                                        <div className="col-md-12 mt-3">
                                          <p>
                                            {itemDetailsData.description
                                              ? itemDetailsData.description
                                              : "NA"}
                                          </p>
                                        </div>

                                        <div className="col-md-12">
                                          <div className="input__block">
                                            <label className="subheading m-0">
                                              SKU#{" "}
                                              {itemDetailsData.settingNumber !==
                                              ""
                                                ? itemDetailsData.settingNumber
                                                : "NA"}
                                              <Tooltip
                                                placement="right"
                                                title="NOTE: All metal color images may not be available"
                                              >
                                                <img src={UnionIcon} alt="" />
                                              </Tooltip>
                                            </label>
                                          </div>
                                        </div>

                                        {settingDropdownData.metalType &&
                                        settingDropdownData.metalType.length >
                                          0 ? (
                                          <div className="col-md-6">
                                            <div className="input__block">
                                              <label>Metal Type</label>
                                              <Select
                                                className="border__grey"
                                                optionFilterProp="children"
                                                value={
                                                  itemDetailsInputData.metalType
                                                }
                                                onChange={(e) =>
                                                  handleItemDetailsInputChange(
                                                    e,
                                                    "metalType"
                                                  )
                                                }
                                              >
                                                {settingDropdownData.metalType.map(
                                                  (item) => {
                                                    return (
                                                      <Option
                                                        value={item.metalType}
                                                      >
                                                        {item.metalType}
                                                      </Option>
                                                    );
                                                  }
                                                )}
                                              </Select>
                                            </div>
                                          </div>
                                        ) : null}

                                        {ViewOptionData &&
                                        ViewOptionData.ShowCenterStone &&
                                        settingDropdownData.centerStoneSize &&
                                        settingDropdownData.centerStoneSize
                                          .length > 0 ? (
                                          <div className="col-md-6">
                                            <div className="input__block">
                                              <label>Center Stone Size</label>
                                              <Select
                                                className="border__grey"
                                                optionFilterProp="children"
                                                value={
                                                  itemDetailsInputData.centerStoneSize
                                                }
                                                onChange={(e) =>
                                                  handleItemDetailsInputChange(
                                                    e,
                                                    "centerStoneSize"
                                                  )
                                                }
                                              >
                                                {settingDropdownData.centerStoneSize.map(
                                                  (item) => {
                                                    return (
                                                      <Option
                                                        value={
                                                          item.gemstoneSize
                                                        }
                                                      >
                                                        {item.gemstoneSize}
                                                      </Option>
                                                    );
                                                  }
                                                )}
                                              </Select>
                                            </div>
                                          </div>
                                        ) : null}

                                        {itemDetailsData.productRingSizeList &&
                                          itemDetailsData.productRingSizeList
                                            .length > 0 && (
                                            <div className="col-md-6">
                                              <div className="input__block">
                                                <label>Ring Size</label>
                                                <Select
                                                  className="border__grey"
                                                  optionFilterProp="children"
                                                  value={
                                                    itemDetailsInputData.ringSize
                                                  }
                                                  onChange={(e) =>
                                                    handleItemDetailsInputChange(
                                                      e,
                                                      "ringSize"
                                                    )
                                                  }
                                                >
                                                  {itemDetailsData
                                                    .productRingSizeList
                                                    .length > 0 &&
                                                    itemDetailsData.productRingSizeList.map(
                                                      (item) => {
                                                        return (
                                                          <Option
                                                            value={
                                                              item.ringSizeVal
                                                            }
                                                          >
                                                            {item.ringSizeVal}
                                                          </Option>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              </div>
                                            </div>
                                          )}

                                        {settingDropdownData.sideStoneQuality &&
                                        settingDropdownData.sideStoneQuality
                                          .length > 0 ? (
                                          <div className="col-md-6">
                                            <div className="input__block">
                                              <label>Side Stone Quality</label>
                                              <Select
                                                className="border__grey"
                                                optionFilterProp="children"
                                                value={
                                                  itemDetailsInputData.sideStoneQuality
                                                }
                                                onChange={(e) =>
                                                  handleItemDetailsInputChange(
                                                    e,
                                                    "sideStoneQuality"
                                                  )
                                                }
                                              >
                                                {settingDropdownData.sideStoneQuality.map(
                                                  (item) => {
                                                    return (
                                                      <Option
                                                        value={
                                                          item.gemstoneQuality
                                                        }
                                                      >
                                                        {item.gemstoneQuality}
                                                      </Option>
                                                    );
                                                  }
                                                )}
                                              </Select>
                                            </div>
                                          </div>
                                        ) : null}

                                        <div className="col-md-12">
                                          <div className="input__block">
                                            <label className="subheading m-0">
                                              {ViewOptionData.ShowPrice ==
                                              false ? (
                                                "Call For Price"
                                              ) : (
                                                <>
                                                  <div>
                                                    {itemDetailsData.retailPrice ==
                                                    "0" ? (
                                                      " Call For Price"
                                                    ) : (
                                                      <span>
                                                        US$
                                                        {parseInt(
                                                          itemDetailsData.retailPrice
                                                        )
                                                          .toFixed()
                                                          .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                          )}
                                                      </span>
                                                    )}
                                                  </div>
                                                </>
                                              )}

                                              <div class="custom__tooltip">
                                                <img src={UnionIcon} alt="" />
                                                <div class="tooltiptext">
                                                  NOTE:{" "}
                                                  <u>
                                                    Pricing is only for
                                                    Engagement Ring Setting
                                                    Shown.
                                                  </u>{" "}
                                                  Any additional ring(s)
                                                  featured in the product video
                                                  are sold seperately
                                                </div>
                                              </div>
                                              <div></div>
                                            </label>
                                          </div>
                                        </div>

                                        <div className="col-md-12">
                                          <div className="mb-3">
                                            {showButton ==
                                              "CompleteYourRing" && (
                                              <button
                                                className="primary-btn mr-2"
                                                onClick={handleCompleteYourRing}
                                              >
                                                Complete Your Ring
                                              </button>
                                            )}
                                            {showButton == "AddYourDiamond" && (
                                              <button
                                                className="primary-btn mr-2"
                                                onClick={handleAddYourDiamond}
                                              >
                                                Add Your Diamond
                                              </button>
                                            )}

                                            {ViewOptionData &&
                                              ViewOptionData.ShowBuySetting ==
                                                true && (
                                                <Button
                                                  className="primary-btn ml-1"
                                                  // onClick={handleBuySetting}
                                                >
                                                  Buy Setting Only
                                                </Button>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="desc__maindiv">
                                      <div className="row top__content m-0">
                                        <div className="row itemdetails__btns p-0">
                                          <div className="col-4">
                                            <p onClick={openDropAHintModal}>
                                              <span className="img__box">
                                                <img src={drophint} />
                                              </span>
                                              <span>Drop A Hint</span>
                                            </p>
                                          </div>
                                          <div className="col-4">
                                            <p onClick={openRequestMoreModal}>
                                              <span className="img__box">
                                                <img src={infoblack} />{" "}
                                              </span>
                                              <span>Request More Info</span>
                                            </p>
                                          </div>

                                          {/* <p onClick={showModal4}><img src={calender} /><span>Print Details</span></p> */}
                                        </div>
                                        <div className="row itemdetails__btns p-0">
                                          <div className="col-4">
                                            <p onClick={openEmailFriendModal}>
                                              <span className="img__box">
                                                <img src={emailicon} />{" "}
                                              </span>
                                              <span>E-Mail A Friend</span>
                                            </p>
                                          </div>

                                          {ViewOptionData.RingsShow == true ? (
                                            <div className="col-4">
                                              <p
                                                onClick={
                                                  openScheduleViewingModal
                                                }
                                              >
                                                <span className="img__box">
                                                  <img src={calender} />
                                                </span>
                                                <span>Schedule Viewing</span>
                                              </p>
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-md-12">
                                          <p>
                                            Have a question regarding this item?
                                            Our specialists are available to
                                            assist you.
                                          </p>
                                        </div>
                                        {ViewOptionData &&
                                        ViewOptionData.ShowAddress == true ? (
                                          <>
                                            {ViewOptionData &&
                                              ViewOptionData.ShowAddress && (
                                                <div className="col-md-12">
                                                  <p className="font__bold d-flex justify-between">
                                                    <span className="font__bold">
                                                      {locationData.locationName &&
                                                        Parse(
                                                          locationData.locationName
                                                        )}
                                                    </span>
                                                    {/* <span className="subheading">
                                            {locationData && locationData.locationName}
                                            </span> */}
                                                  </p>
                                                  <p className="font__bold">
                                                    <span className="font__bold">
                                                      {locationData &&
                                                        locationData.phone}
                                                    </span>
                                                  </p>
                                                  <a
                                                    className="linkText"
                                                    href={`mailto:${
                                                      locationData &&
                                                      locationData.emailID
                                                    }`}
                                                  >
                                                    {locationData &&
                                                      locationData.emailID}
                                                  </a>
                                                  {/* <p className="font__bold">
                                              {locationData &&
                                                locationData.emailID}
                                            </p> */}
                                                </div>
                                              )}
                                          </>
                                        ) : (
                                          <></>
                                        )}

                                        <div className="col-md-12">
                                          <div className="dtl__socialsection">
                                            {ViewOptionData.FacebookShare ==
                                              true && (
                                              <button
                                                onClick={() =>
                                                  handleSocialRedirect(
                                                    socialData.fburl
                                                  )
                                                }
                                                className="dtl__socialbtn fb__btn"
                                              >
                                                <img src={DtlFacebook} />
                                              </button>
                                            )}
                                            {ViewOptionData.PinterestShare ==
                                              true && (
                                              <button
                                                onClick={() =>
                                                  handleSocialRedirect(
                                                    socialData.pinInterestURL
                                                  )
                                                }
                                                className="dtl__socialbtn pintrest"
                                              >
                                                <img src={DtlPintrest} />
                                              </button>
                                            )}
                                            {ViewOptionData.TwitterShare ==
                                              true && (
                                              <button
                                                onClick={() =>
                                                  handleSocialRedirect(
                                                    socialData.tweeterURL
                                                  )
                                                }
                                                className="dtl__socialbtn tweeter"
                                              >
                                                <img src={DtlTwitter} />
                                              </button>
                                            )}

                                            <button className="dtl__socialbtn instagram">
                                              <img src={DtlInstagram} />
                                            </button>

                                            <button className="dtl__socialbtn googlePlus">
                                              <img src={DtlGooglePlus} />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Tab>
                                <Tab eventKey="profile" title="Specifications">
                                  <div className="tab__contentdiv border__block p-0">
                                    <div className="head__div">
                                      <h4>Setting Details</h4>
                                    </div>
                                    <div className="desc__maindiv">
                                      <div className="desc__div">
                                        <span className="head__txt">
                                          Setting Number
                                        </span>
                                        <span className="desc__txt">
                                          {itemDetailsData.settingNumber !== ""
                                            ? itemDetailsData.settingNumber
                                            : "NA"}
                                        </span>
                                      </div>
                                      <div className="desc__div">
                                        <span className="head__txt">Price</span>
                                        <span className="desc__txt">
                                          {ViewOptionData.ShowPrice == false ? (
                                            "Call For Price"
                                          ) : (
                                            <>
                                              <div>
                                                {itemDetailsData.retailPrice ==
                                                "0" ? (
                                                  " Call For Price"
                                                ) : (
                                                  <span>
                                                    US$
                                                    {parseInt(
                                                      itemDetailsData.retailPrice
                                                    )
                                                      .toFixed()
                                                      .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )}
                                                  </span>
                                                )}
                                              </div>
                                            </>
                                          )}
                                        </span>
                                      </div>
                                      <div className="desc__div">
                                        <span className="head__txt">
                                          Metal Type{" "}
                                        </span>
                                        <span className="desc__txt">
                                          {itemDetailsData.metal !== ""
                                            ? itemDetailsData.metal
                                            : "NA"}
                                        </span>
                                      </div>
                                      <div className="desc__div">
                                        <span className="head__txt">
                                          Setting Type{" "}
                                        </span>
                                        <span className="desc__txt">
                                          {itemDetailsData.settingType !== ""
                                            ? itemDetailsData.settingType
                                            : "NA"}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="head__div">
                                      <h4>Can Be Set With (Cts.)</h4>
                                    </div>
                                    <div className="desc__maindiv">
                                      {/* {availableShapesData.length > 0 &&
                                      availableShapesData.map((shape) => {
                                        return ( */}
                                      <div className="desc__div">
                                        <span className="head__txt">
                                          {settingData}
                                        </span>
                                        <span className="desc__txt">
                                          {itemDetailsData.centerStoneMinimumCarat
                                            ? itemDetailsData.centerStoneMinimumCarat
                                            : "NA"}{" "}
                                          -{" "}
                                          {itemDetailsData.centerStoneMaximumCarat
                                            ? itemDetailsData.centerStoneMaximumCarat
                                            : "NA"}{" "}
                                        </span>
                                      </div>
                                      {/* );
                                      })} */}
                                    </div>
                                  </div>
                                </Tab>
                              </Tabs>
                            </div>
                          </Spin>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
      {/* Modal Section Starts */}

      {/* Request More Info Modal Starts */}
      <Modal
        title="Request More Information"
        visible={requestMoreModal}
        style={{ top: 20 }}
        className="request_info"
        onCancel={closeRequestMoreModal}
        width={700}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={closeRequestMoreModal}
          >
            Cancel
          </Button>,
          <Button
            loading={requestMoreInfoLoading}
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            key="submit"
            type="primary"
            onClick={handleSubmitRequestMoreInfo}
          >
            Request
          </Button>,
        ]}
      >
        <div className="row">
          <div className="col-md-12">
            <p>Our specialist will contact you.</p>
          </div>
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Name <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Name"
                      value={requestMoreInfoInputData.name}
                      onChange={(e) =>
                        handleRequestMoreInfoChange(e, "name", "input")
                      }
                    />
                    <div>
                      {requestMoreInfoValidation.nameVal && (
                        <p className="error-color-red">
                          {requestMoreInfoValidation.nameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your E-Mail Address <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your E-Mail Address"
                      value={requestMoreInfoInputData.email}
                      onChange={(e) =>
                        handleRequestMoreInfoChange(e, "email", "input")
                      }
                    />
                    <div>
                      {requestMoreInfoValidation.emailVal && (
                        <p className="error-color-red">
                          {requestMoreInfoValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Phone Number <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Phone Number"
                      value={requestMoreInfoInputData.phoneNumber}
                      onChange={(e) =>
                        handleRequestMoreInfoChange(e, "phoneNumber", "input")
                      }
                    />
                    <div>
                      {requestMoreInfoValidation.phoneNumberVal && (
                        <p className="error-color-red">
                          {requestMoreInfoValidation.phoneNumberVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Contact Preference <span className="mandatory">*</span>
                    </label>
                    <div className="row">
                      <div className="radio__block col-6">
                        <input
                          type="radio"
                          value="byEmail"
                          checked={
                            requestMoreInfoInputData.contactPreference ==
                            "byEmail"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleRequestMoreInfoChange(
                              e,
                              "contactPreference",
                              "radio"
                            )
                          }
                        />
                        <label>By Email</label>
                      </div>
                      <div className="radio__block col-6">
                        <input
                          type="radio"
                          value="byPhone"
                          checked={
                            requestMoreInfoInputData.contactPreference ==
                            "byPhone"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleRequestMoreInfoChange(
                              e,
                              "contactPreference",
                              "radio"
                            )
                          }
                        />
                        <label>By Phone</label>
                      </div>
                    </div>
                  </div>
                  <div>
                    {requestMoreInfoValidation.contactPreferenceVal && (
                      <p className="error-color-red">
                        {requestMoreInfoValidation.contactPreferenceVal}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input__block">
                    <label>
                      Write A Personal Message Here ...
                      <span className="mandatory">*</span>
                    </label>
                    <textarea
                      placeholder="Write A Personal Message ..."
                      value={requestMoreInfoInputData.personalMessage}
                      onChange={(e) =>
                        handleRequestMoreInfoChange(
                          e,
                          "personalMessage",
                          "radio"
                        )
                      }
                    ></textarea>
                    <div>
                      {requestMoreInfoValidation.personalMessageVal && (
                        <p className="error-color-red">
                          {requestMoreInfoValidation.personalMessageVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Request More Info Modal Ends */}

      {/* Drop A Hint Modal Starts */}
      <Modal
        title="Drop A Hint"
        visible={dropHintModal}
        style={{ top: 20 }}
        className="drop_a_hint"
        onCancel={closeDropHintModal}
        width={700}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={closeDropHintModal}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={handleSubmitDropHint}
            loading={dropAHintLoading}
          >
            Drop Hint
          </Button>,
        ]}
      >
        <div className="row">
          <div class="col-md-12">
            <p>Because you deserve this.</p>
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Name <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Name"
                      value={dropHintInputData.name}
                      onChange={(e) => handleDropHintChange(e, "name", "input")}
                    />
                    <div>
                      {dropHintValidation.nameVal && (
                        <p className="error-color-red">
                          {dropHintValidation.nameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your E-Mail <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your E-Mail"
                      value={dropHintInputData.email}
                      onChange={(e) =>
                        handleDropHintChange(e, "email", "input")
                      }
                    />
                    <div>
                      {dropHintValidation.emailVal && (
                        <p className="error-color-red">
                          {dropHintValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Hint Recipient's Name <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Hint Recipient's Name"
                      value={dropHintInputData.recipientName}
                      onChange={(e) =>
                        handleDropHintChange(e, "recipientName", "input")
                      }
                    />
                    <div>
                      {dropHintValidation.recipientNameVal && (
                        <p className="error-color-red">
                          {dropHintValidation.recipientNameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Hint Recipient's E-Mail{" "}
                      <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Hint Recipient's E-Mail"
                      value={dropHintInputData.recipientEmail}
                      onChange={(e) =>
                        handleDropHintChange(e, "recipientEmail", "input")
                      }
                    />
                    <div>
                      {dropHintValidation.recipientEmailVal && (
                        <p className="error-color-red">
                          {dropHintValidation.recipientEmailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Reason For This Gift <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Reason For This Gift"
                      value={dropHintInputData.giftReason}
                      onChange={(e) =>
                        handleDropHintChange(e, "giftReason", "input")
                      }
                      min={disablePastDate()}
                    />
                    <div>
                      {dropHintValidation.giftReasonVal && (
                        <p className="error-color-red">
                          {dropHintValidation.giftReasonVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Gift Deadline <span className="mandatory">*</span>
                    </label>
                    <input
                      type="date"
                      placeholder="Select Gift Deadline"
                      value={dropHintInputData.giftDeadline}
                      onChange={(e) =>
                        handleDropHintChange(e, "giftDeadline", "date")
                      }
                    />
                    <div>
                      {dropHintValidation.giftDeadlineVal && (
                        <p className="error-color-red">
                          {dropHintValidation.giftDeadlineVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="input__block">
                    <label>Add A Personal Message Here</label>
                    <textarea
                      placeholder="Add A Personal Message Here"
                      value={dropHintInputData.personalMessage}
                      onChange={(e) =>
                        handleDropHintChange(e, "personalMessage", "input")
                      }
                    ></textarea>
                    <div>
                      {dropHintValidation.personalMessageVal && (
                        <p className="error-color-red">
                          {dropHintValidation.personalMessageVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Drop A Hint Modal Ends */}

      {/* E-Mail A Friend Modal Starts */}
      <Modal
        title="E-Mail A Friend"
        visible={emailFriendModal}
        style={{ top: 20 }}
        className="email_a_friend"
        onCancel={closeEmailFriendModal}
        width={700}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={closeEmailFriendModal}
          >
            Cancel
          </Button>,
          <Button
            loading={emailAFriendLoading}
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            key="submit"
            type="primary"
            onClick={handleSubmitEmailFriend}
          >
            Send To Friend
          </Button>,
        ]}
      >
        <div className="row">
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Name <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Name"
                      value={emailFriendInputData.name}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "name", "input")
                      }
                    />
                    <div>
                      {emailFriendInputValidation.nameVal && (
                        <p className="error-color-red">
                          {emailFriendInputValidation.nameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your E-Mail <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your E-Mail"
                      value={emailFriendInputData.email}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "email", "input")
                      }
                    />
                    <div>
                      {emailFriendInputValidation.emailVal && (
                        <p className="error-color-red">
                          {emailFriendInputValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Friend's Name <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Friend's Name"
                      value={emailFriendInputData.friendName}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "friendName", "input")
                      }
                    />
                    <div>
                      {emailFriendInputValidation.friendNameVal && (
                        <p className="error-color-red">
                          {emailFriendInputValidation.friendNameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Friend's E-Mail <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Friend's E-Mail"
                      value={emailFriendInputData.friendEmail}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "friendEmail", "input")
                      }
                    />
                    <div>
                      {emailFriendInputValidation.friendEmailVal && (
                        <p className="error-color-red">
                          {emailFriendInputValidation.friendEmailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input__block">
                    <label>
                      Add A Personal Message Here ...{" "}
                      <span className="mandatory">*</span>
                    </label>
                    <textarea
                      placeholder="Add A Personal Message Here ..."
                      value={emailFriendInputData.personalMessage}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "personalMessage", "input")
                      }
                    ></textarea>
                    <div>
                      {emailFriendInputValidation.personalMessageVal && (
                        <p className="error-color-red">
                          {emailFriendInputValidation.personalMessageVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* E-Mail A Friend Modal Ends */}

      {/* For Internal Use Only Modal Starts */}

      <Modal
        title="For Internal Use Only"
        style={{ top: 20 }}
        visible={internalUseModal}
        className="video__modal"
        onCancel={handleCancelInternalModal}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={handleCancelInternalModal}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            loading={internalUseModalLoading}
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            type="primary"
            onClick={handleSubmitInternalData}
          >
            Submit
          </Button>,
        ]}
      >
        {showDealerInfo == true ? (
          <div className="p-4">
            <p>
              Vendor Name:{" "}
              {dealerInfo && dealerInfo.dealerName
                ? dealerInfo.dealerName
                : "NA"}
            </p>
            <p>
              {" "}
              Vendor Company :{" "}
              {dealerInfo && dealerInfo.dealerCompany
                ? dealerInfo.dealerCompany
                : "NA"}
            </p>
            <p>
              {" "}
              Vendor City/State :{" "}
              {(dealerInfo && dealerInfo.cityName) ||
              (dealerInfo && dealerInfo.stateName)
                ? dealerInfo.cityName + "/" + dealerInfo.stateName
                : "NA"}
            </p>
            <p>
              {" "}
              Vendor Phone Number:
              {dealerInfo && dealerInfo.dealerPhone
                ? dealerInfo.dealerPhone
                : "NA"}
            </p>
            <p>
              {" "}
              Vendor E-Mail:
              {dealerInfo && dealerInfo.dealerEmail
                ? dealerInfo.dealerEmail
                : "NA"}
            </p>
            <p>
              {" "}
              <label className="font__bold">
                {" "}
                Vendor lot number of the item:
              </label>{" "}
              {dealerInfo1 && dealerInfo1.retailerStockNumber
                ? dealerInfo1.retailerStockNumber
                : "NA"}
              {}
            </p>
            <p>
              {" "}
              <label className="font__bold"> WholeSale Price:</label>{" "}
              {dealerInfo1 && dealerInfo1.wholesalePrice
                ? dealerInfo1.wholesalePrice
                : "NA"}
            </p>
            {/* <p>
                  {" "}
                  <label className="font__bold"> Third Party:</label> {}
                </p> */}
            {/* <p>
                {" "}
                <label className="font__bold"> Jewelry ID:</label>{" "}
                {dealerInfo1 && dealerInfo1.gfInventoryID
                  ? dealerInfo1.gfInventoryID
                  : "NA"}
              </p> */}
            {/* <p>
                  {" "}
                  <label className="font__bold"> Seller Name:</label> {}
                </p> */}
            {/* <p>
                <label className="font__bold"> Address: </label>{" "}
                {dealerInfo && dealerInfo.dealerAddress
                  ? dealerInfo.dealerAddress
                  : "NA"}
              </p> */}
          </div>
        ) : (
          <div className="form__fields">
            <div className="col-md-6">
              <div className="input__block">
                <label htmlFor="">
                  GFpassword <span className="mandatory">*</span>
                </label>
                <input
                  type="password"
                  value={internalUseData.password}
                  // onChange={(e) => setInternalUseData(e.target.value)}
                  onChange={(e) => handleSetInternalUseData(e)}
                />
                <div>
                  {internalUseDataValidation.passwordVal && (
                    <p className="error-color-red">
                      {internalUseDataValidation.passwordVal}
                    </p>
                  )}
                </div>
              </div>
              <span
                className="linkText"
                onClick={() =>
                  setInternalUseData({
                    password: "",
                  })
                }
              >
                Reset Password
              </span>
            </div>
          </div>
        )}
      </Modal>

      {/* Print Details Modal Starts */}
      <Modal
        title="Print Details"
        visible={isModalVisible4}
        style={{ top: 20 }}
        className="print_details"
        onCancel={handleCancel4}
      >
        <div className="row">
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Name</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your E-Mail Address</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Company Name</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Phone Number</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Store Phone Number</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input__block">
                    <label>Write A Personal Message</label>
                    <textarea></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Print Details Modal Ends */}

      {/* Schedule Viewing Modal Starts */}
      <Modal
        title="Schedule A Viewing"
        style={{ top: 20 }}
        visible={scheduleViewingModal}
        className="schedule_viewing"
        width={700}
        onCancel={closeScheduleViewingModal}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={closeScheduleViewingModal}
          >
            Cancel
          </Button>,
          <Button
            loading={scheduleViewingLoading}
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            key="submit"
            type="primary"
            onClick={handleSubmitSheduleViewing}
          >
            Schedule
          </Button>,
        ]}
      >
        <div className="row">
          <div className="col-md-12">
            <p>See this item and more in our store</p>
          </div>
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Name</label>
                    <input
                      type="text"
                      placeholder="Enter Your Name"
                      value={scheduleViewingInputData.name}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "name", "input")
                      }
                    />
                    <div>
                      {scheduleViewingInputValidation.nameVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.nameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your E-mail Address</label>
                    <input
                      type="text"
                      placeholder="Enter Your E-mail Address"
                      value={scheduleViewingInputData.email}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "email", "input")
                      }
                    />
                    <div>
                      {scheduleViewingInputValidation.emailVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Phone Number</label>
                    <input
                      type="text"
                      placeholder="Enter Your Phone Number"
                      value={scheduleViewingInputData.phoneNumber}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "phoneNumber", "input")
                      }
                    />
                    <div>
                      {scheduleViewingInputValidation.phoneNumberVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.phoneNumberVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>Location</label>
                    <Select
                      className="border__grey"
                      name=""
                      optionFilterProp="children"
                      value={scheduleViewingInputData.location}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "location", "select")
                      }
                    >
                      <Option value="">Select Location</Option>
                      {locationData1 &&
                        locationData1.map((item, i) => {
                          return (
                            <Option value={item.LocationId}>
                              {" "}
                              {item.LocationName}{" "}
                            </Option>
                          );
                        })}
                    </Select>
                    <div>
                      {scheduleViewingInputValidation.locationVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.locationVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>When are you available?</label>
                    <input
                      type="date"
                      value={scheduleViewingInputData.appointmentDate}
                      onChange={(e) =>
                        handleScheduleViewingChange(
                          e,
                          "appointmentDate",
                          "date"
                        )
                      }
                      min={disablePastDate()}
                    />
                    <div>
                      {scheduleViewingInputValidation.appointmentDateVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.appointmentDateVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label className="mb-2">Appointment Time</label>
                    {/* <input
                        type="time"
                        value={scheduleViewingInputData.appointmentTime}
                        onChange={(e) =>
                          handleScheduleViewingChange(
                            e,
                            "appointmentTime",
                            "time"
                          )
                        }
                      /> */}
                    <Select
                      name="appointmentTime"
                      // className="border__grey"
                      className={
                        scheduleViewingInputValidation.appointmentTimeVal
                          ? "border__red"
                          : "border__grey"
                      }
                      showSearch
                      placeholder="Select End Time"
                      optionFilterProp="children"
                      value={scheduleViewingInputData.appointmentTime}
                      onChange={(e) =>
                        handleScheduleViewingChange(
                          e,
                          "appointmentTime",
                          "select"
                        )
                      }
                    >
                      <Option value="">Appointment Time</Option>
                      <Option value="8:00 AM">8 AM</Option>
                      <Option value="8:30 AM">8:30 AM</Option>
                      <Option value="9:00 AM">9 AM</Option>
                      <Option value="9:30 AM">9:30 AM</Option>
                      <Option value="10:00 AM">10 AM</Option>
                      <Option value="10:30 AM">10:30 AM</Option>
                      <Option value="11:00 AM">11 AM</Option>
                      <Option value="11:30 AM">11:30 AM</Option>
                      <Option value="12:00 AM">12 AM</Option>
                      <Option value="12:00 PM">12:30 PM</Option>
                      <Option value="1:00 PM">1 PM</Option>
                      <Option value="1:30 PM">1:30 PM</Option>
                      <Option value="2:00 PM">2 PM</Option>
                      <Option value="2:30 PM">2:30 PM</Option>
                      <Option value="3:00 PM">3 PM</Option>
                      <Option value="3:30 PM">3:30 PM</Option>
                      <Option value="4:00 PM">4 PM</Option>
                      <Option value="4:30 PM">4:30 PM</Option>
                      <Option value="5:00 PM">5 PM</Option>
                      <Option value="5:30 PM">5:30 PM</Option>
                      <Option value="6:00 PM">6 PM</Option>
                      <Option value="6:30 PM">6:30 PM</Option>
                      <Option value="7:00 PM">7 PM</Option>
                      <Option value="7:30 PM">7:30 PM</Option>
                      <Option value="8:00 PM">8 PM</Option>
                      <Option value="8:30 PM">8:30 PM</Option>
                      <Option value="9:00 PM">9 PM</Option>
                      <Option value="9:30 PM">9:30 PM</Option>
                      <Option value="10:00 PM">10 PM</Option>
                      <Option value="10:30 PM">10:30 PM</Option>
                    </Select>

                    {/* <TimePicker
                        placeholder="Appointment Time"
                        use12Hours
                        format="h:mm A"
                        onChange={handleAppointmentTimeChange}
                        style={{
                          width: "100%"
                        }}
                      /> */}
                    <div>
                      {scheduleViewingInputValidation.appointmentTimeVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.appointmentTimeVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="input__block">
                    <label>Add A Personal Message Here ...</label>
                    <textarea
                      value={scheduleViewingInputData.message}
                      placeholder="Add A Personal Message Here ..."
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "message", "input")
                      }
                    ></textarea>
                    <div>
                      {scheduleViewingInputValidation.messageVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.messageVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-12">
                    <div className="input__block">
                      <label>Address</label>
                      <textarea
                        readOnly
                        value={
                          locationData.locationName
                            ? locationData.locationName
                            : ""
                        }
                        // onChange={(e) =>
                        //   handleScheduleViewingChange(e, "address", "input")
                        // }
                      ></textarea>
                      <div>
                        {scheduleViewingInputValidation.addressVal && (
                          <p className="error-color-red">
                            {scheduleViewingInputValidation.addressVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div> */}
                <div className="col-md-12">
                  <p className="font__bold d-flex justify-between">
                    <span className="font__bold">
                      {locationData.locationName &&
                        Parse(locationData.locationName)}
                    </span>
                  </p>
                  <p className="font__bold">
                    <span className="font__bold">
                      {locationData && locationData.phone}
                    </span>
                  </p>
                  <p className="font__bold">
                    {locationData && locationData.emailID}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Schedule Viewing Modal Ends */}

      {/* Thumbnail Slider Video Modal Starts */}
      <Modal
        title=""
        style={{ top: 20 }}
        visible={videoModal}
        className="video__modal"
        onCancel={closeVideoModal}
        footer={false}
      >
        <div className="video__section">
          {itemDetailsData.videoURL == "" ||
          itemDetailsData.videoURL == null ? (
            <div>
              <Empty />
            </div>
          ) : (
            <video
              width="100%"
              height="100%"
              src={itemDetailsData.videoURL ? itemDetailsData.videoURL : ""}
              autoPlay
              loop
              controls
            ></video>
          )}
        </div>
      </Modal>

      {/* Fullscreen Modal Starts */}
      <Modal
        title="Fullscreen"
        visible={isModalVisibleFullscreen}
        className="fullscreen__modal"
        onCancel={handleCancelFullscreen}
        footer={false}
        width={800}
        style={{ top: 20 }}
      >
        <div className="thimage__slider">
          <ImageGallery
            items={settingImages}
            showFullscreenButton={false}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
          />
        </div>
      </Modal>
      {/* Fullscreen Modal Ends */}
      <NotificationContainer />
      {/* Thumbnail Slider Video Modal Ends */}

      {/* Modal Section Ends */}
    </React.Fragment>
  );
};

export default ChooseSettingItemDetails;
