import React, { useState, useEffect } from "react";
import {
  Select,
  Button,
  Modal,
  Spin,
  Table,
  Checkbox,
  TimePicker,
  Pagination,
  Empty,
} from "antd";

import { Tabs, Tab } from "react-bootstrap";

// import MultiRangeSlider from "../../contacts/MultiRangeSlider"

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Video_icon from "../../../assets/images/icons/video_icon.svg";
import Info_icon from "../../../assets/images/info_icon.png";
import drophint from "../../../assets/images/choose-setting/drophint.png";
import calender from "../../../assets/images/choose-setting/calender.svg";
import emailicon from "../../../assets/images/choose-setting/emailicon.svg";
import infoblack from "../../../assets/images/choose-setting/infoblack.svg";

// import GIA from "../../../assets/images/choose-setting/gia.jpg";
import IGI from "../../../assets/images/choose-setting/igi.jpg";
import AGS from "../../../assets/images/choose-setting/ags.jpg";
import EGL from "../../../assets/images/choose-setting/egl.jpg";
import IMG5 from "../../../assets/images/choose-setting/img5.jpg";
import GIA from "../../../assets/images/gia.jpg";
import leftArrow from "../../../assets/images/choose-setting/left__arrow.png";
import rightArrow from "../../../assets/images/choose-setting/right__arrow.png";

import shareBlue from "../../../assets/images/choose-setting/share__blue.png";
import shareFacebook from "../../../assets/images/choose-setting/shareIcons_02.png";
import sharePintrest from "../../../assets/images/choose-setting/shareIcons_03.png";
import LikeFacebook from "../../../assets/images/icons/facebook-like.jpg";
import shareTwitter from "../../../assets/images/choose-setting/shareIcons_04.png";
import {
  handleComaparePageIds,
  handleHideDetailsPage,
} from "../../../actions/selectYourDiamond/selectYourDiamond";
import { useDispatch, useSelector } from "react-redux";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import { NotificationManager } from "react-notifications";
import ItemDetailsService from "../../../services/item-details.service";
import Eye_icon from "../../../assets/images/eye__icon.svg";
import Close from "../../../assets/images/close.png";
import {
  handleActiveTab,
  handleRemoveDiamondViewEdit,
} from "../../../actions/header/headerActions";
import { handleViewDetailsPage } from "../../../actions/selectYourDiamond/selectYourDiamond";
import itemDetailsService from "../../../services/item-details.service";
import LocationService from "../../../services/select-your-diamond-location";
import { useLocation, useNavigate } from "react-router-dom";
import headerService from "../../../services/header.service";
import Parse from "html-react-parser";
import colorPanelService from "../../../services/color-panel.service";
import styled from "styled-components";

import DtlFacebook from "../../../assets/images/fb__icon.svg";
import DtlTwitter from "../../../assets/images/twitter__icon.svg";
import DtlPintrest from "../../../assets/images/pintrest__icon.svg";
import DtlInstagram from "../../../assets/images/instragm__icon.svg";
import DtlGooglePlus from "../../../assets/images/google__plus__icon.svg";

export const ViewDiamondDetails = ({}) => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const { Option } = Select;
  const Details = useSelector(
    (state) => state.selectYourDiamondReducer.selectedRow
  );

  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const currentTab = useSelector((state) => state.headerReducer);
  const dispatch = useDispatch();
  const [diamondDetails, setDiamondDetails] = useState({});
  const [dealerId, setDealerId] = useState();
  const [diamondInfoDetails, setDiamondInfoDetails] = useState({});
  const [viewStateLoading, setViewStateLoading] = useState(true);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [blockSelect, setBlockSelect] = useState("imageFileName");
  const [internalUseData, setInternalUseData] = useState({
    password: "",
  });
  const [similarToogle, setSimilarToogle] = useState(true);
  const [internalUseDataValidation, setInternalUseDataValidation] = useState({
    passwordVal: "",
  });
  const [sortColumnName, setSortColumnName] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [showDealerInfo, setShowDealerInfo] = useState();
  const [dealerInfo, setDealerInfo] = useState({});
  const [dealerInfo1, setDealerInfo1] = useState({});
  const [internalUseModalLoading, setInternalUseModalLoading] = useState(false);
  const [internalUseModal, setInternalUseModal] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [TotalRowCount, setTotalRowCount] = useState(0);
  const [shapeList, setShapeList] = useState();
  const [pageName, setPageName] = useState("");
  const [rowDetails, setRowDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [showSocialLinks, setShowSocialLinks] = useState(false);

  const [locationData, setLocationData] = useState([]);
  const [locationDataLoading, setLocationDataLoading] = useState(false);
  const [socialData, setSocialData] = useState({});
  const [locationData1, setLocationData1] = useState([]);

  const [optionData, setOptionData] = useState();
  const [hoverInfo, setHoverInfo] = useState(false);
  const [dealerHoverId, setDealerHoverId] = useState(0);
  const [isVideo, setIsVideo] = useState(false);
  const [videoImageModal, setVideoImageModal] = useState(false);
  const [selectedRowDetails, setSelectedRowDetails] = useState({});

  const [tableClassAllow, setTableClassAllow] = useState(false);

  // Options API

  const [ViewOptionData, setViewOptionData] = useState({
    RingBuilderUrl: "",
    InternalUseLink: true,
    RingSizeType: null,
    InitiallySortbox1: null,
    InitiallySortbox2: null,
    DefaultMetalType: null,
    ShowAdvancedSearch: true,
    RingsShow: true,
    ShowAddress: true,
    ShowCenterStone: true,
    ShowPrice: true,
    GSTTax: true,
    GSTTaxValue: "",
    PinterestShare: true,
    TwitterShare: true,
    FacebookShare: true,
    FacebookLike: true,
    GooglePlus: true,
    InstagramShare: true,
    AddToCart: true,
    AltShoppingCart: "",
    ShowBuySetting: true,
    ShowBuyDiamond: true,
    ShowViewCart: true,
  });

  const handleGetOptionsDetails = (id) => {
    let inputData = {
      dealerID: id,
      Type: "RingBuilder",
    };

    try {
      headerService
        .GetRingBuilderOptionDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          if (message == "Success") {
            if (responseData) {
              debugger;
              setViewOptionData((prevState) => ({
                ...prevState,
                RingBuilderUrl: responseData.ringBuilderURL,
                InternalUseLink: responseData.internalLinkRB,
                RingSizeType: responseData.ringSizeType,
                InitiallySortbox1: responseData.ringInitiallySort1,
                InitiallySortbox2: responseData.ringInitiallySortDesc,
                DefaultMetalType: responseData.defaultMetalType,
                ShowAdvancedSearch: responseData.showAdvanceRingBuilder,
                RingsShow: responseData.showScheduleView,
                ShowAddress: responseData.showAddresses,
                ShowCenterStone: responseData.showCenterStoneSize,
                ShowPrice: responseData.showPricesOnSearchPage,
                GSTTax: responseData.showGstPrice,
                GSTTaxValue: responseData.taxRate,
                PinterestShare: responseData.showPinterestShare,
                TwitterShare: responseData.showTwitterShare,
                FacebookShare: responseData.showFacebookShare,
                FacebookLike: responseData.showFacebookLike,
                GooglePlus: responseData.showGooglePlus,
                InstagramShare: responseData.showInstagramShare,
                AddToCart: responseData.showAddToCartButton,
                AltShoppingCart: responseData.shoppingCartURL,
                ShowBuySetting: responseData.showBuyWithoutCenterStone,
                ShowBuyDiamond: responseData.buyDiamondOnly,
                ShowViewCart: responseData.showViewCartButtonRB,
              }));
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //Scroll to top when gets load

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  const Scroll = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  // CSS Variables for Color panel

  const columns = [
    // {
    //   title: "",
    //   dataIndex: "checkbox",
    //   width: 30,
    //   render: (item, row) => {
    //     return (
    //       <div
    //         className="action__btns"
    //         onClick={(e) => {
    //           e.stopPropagation();
    //         }}
    //       >
    //         <Checkbox
    //           name="checkbox"
    //           checkedChildren="Yes"
    //           unCheckedChildren="No"
    //           checked={row.checkbox == true ? true : false}
    //           // onChange={(e) => handleOnChange(row, e, "checkbox")}
    //         />
    //       </div>
    //     );
    //   },
    // },

    {
      title: "Shape",
      dataIndex: "txtShape",

      render: (item, row) => {
        return (
          <div className="shape__img">
            <img
              src={row.imageFileName}
              alt=""
            />
            <span>{row.txtShape}</span>
          </div>
        );
      },
    },
    // {
    //   title: "SKU",
    //   dataIndex: "intDiamondId",
    // },
    {
      title: "Carats",
      dataIndex: "fltCarat",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Color",
      dataIndex: "txtColor",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Clarity",
      dataIndex: "txtClarity",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Cut",
      dataIndex: "txtCutGrade",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    // {
    //   title: "In House",
    //   dataIndex: "txtInhouse",
    //   width: 150
    // },
    {
      title: "Depth",
      dataIndex: "fltDepth",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Table",
      dataIndex: "fltTable",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },

    {
      title: "Polish",
      dataIndex: "txtPolish",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Symmetry",
      dataIndex: "txtSymmetry",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Measurement",
      dataIndex: "txtMeasurements",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Cert.",
      dataIndex: "txtCertificate",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Price",
      dataIndex: "realPrice",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return ViewOptionData.ShowPrice == false ? (
          <span>Call</span>
        ) : (
          <>
            <div>
              {row.realPrice == "0" ? (
                <>
                Call <span className="hideInMobile">For Price</span>
              </>
              ) : (
                <span>
                  $
                  {parseInt(row.realPrice)
                    .toFixed()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (index, row) => (
        <div>
          <div className="action__btns">
            <div className="image__block hideInMobile">
              <img
                src={Info_icon}
                alt=""
                onMouseEnter={() => handleOpenSettingInfo(row.intDiamondId)}
                onMouseOut={() => handleCloseSettingInfo(row.intDiamondId)}
              />
            </div>
            <div
              className={
                row.videoFileName == ""
                  ? "image__block__canhover hideInMobile"
                  : "image__block hideInMobile"
              }
              onClick={
                row.videoFileName != ""
                  ? () => {
                      handleOpenVideoImageModal(row, true);
                    }
                  : ""
              }
            >
              <img
                src={Video_icon}
                alt=""
                className={row.videoFileName == "" ? "display-none" : ""}
              />
            </div>
            <div className="image__block">
              <img
                src={Eye_icon}
                alt=""
                onClick={() => handleViewDetails(row)}
              />
            </div>
          </div>
          <div
            className={
              hoverInfo == true && dealerHoverId == row.intDiamondId
                ? "info__tooltip"
                : "display-none"
            }
          >
            <div className="close__div">
              <img
                src={Close}
                alt=""
                onClick={() => handleCloseSettingInfo(row.settingID)}
              />
            </div>
            <p>
              <span>Depth</span>
              <span>{row.fltDepth}</span>
            </p>
            <p>
              <span>Table</span>
              <span>{row.fltTable}</span>
            </p>
            <p>
              <span>Polish</span>
              <span>{row.txtPolish}</span>
            </p>
            <p>
              <span>Symmetry</span>
              <span>{row.txtSymmetry}</span>
            </p>
            <p>
              <span>Measurement</span>
              <span>{row.txtMeasurements}</span>
            </p>
          </div>
        </div>
      ),
    },
  ];

  const handleOpenVideoImageModal = (row, isVideo) => {
    setIsVideo(isVideo);
    setSelectedRowDetails(row);
    setVideoImageModal(true);
  };
  const handleCloseVideoImageModal = () => {
    setVideoImageModal(false);
  };

  const handleOpenSettingInfo = (settingId) => {
    setHoverInfo(true);
    setDealerHoverId(settingId);
    setTableClassAllow(true);
  };
  const handleCloseSettingInfo = (settingId) => {
    setHoverInfo(false);
    setDealerHoverId();
    setTableClassAllow(false);
  };

  // Social Routes Code

  const isSocialPage = new URLSearchParams(search).get("isSocialPage");
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const diamondId = new URLSearchParams(search).get("diamondId");
  const socialpageNo = new URLSearchParams(search).get("pageNo");
  const socialpageSize = new URLSearchParams(search).get("pageSize");
  const isAllowed = new URLSearchParams(search).get("isAllowed");
  const socialpageName = new URLSearchParams(search).get("pageName");
  const socialshapeFilters = new URLSearchParams(search).get("shapeFilters");
  const socialtxtCutlet = new URLSearchParams(search).get("txtCutlet");
  const socialtxtClarity = new URLSearchParams(search).get("txtClarity");
  const socialintColorPriority = new URLSearchParams(search).get(
    "intColorPriority "
  );
  const paramSettingId = new URLSearchParams(search).get("settingId");
  const paramSettingNumber = new URLSearchParams(search).get("settingNumber");
  useEffect(() => {
    if (isSocialPage == "True") {
      const diamondDetails = {
        shapeFilters: socialshapeFilters,
        txtCutlet: socialtxtCutlet,
        txtClarity: socialtxtClarity,
        intColorPriority: socialintColorPriority,
        pageName: socialpageName,
      };
      setDealerId(paramdealerId);
      setDiamondDetails(diamondId);
      // setOptionData(loginDetails.optionData);
      handleGetDiamondDetails(paramdealerId, diamondId, socialpageName);
      handleLocationLoadDetails(paramdealerId);
      handleGetSimilarDiamond(paramdealerId, diamondDetails, socialpageNo);
      handleGetOptionsDetails(paramdealerId);
    } else {
      if (loginDetails) {
        if (loginDetails.dealerId) {
          setDealerId(loginDetails.dealerId);

          if (Details) {
            if (Details.intDiamondId) {
              setDiamondDetails(Details.intDiamondId);
              if (Details.pageName) {
                // handleGetMinedDiamondDetailsData(
                //   loginDetails.dealerId,
                //   Details.intDiamondId
                // );
                handleGetDiamondDetails(
                  loginDetails.dealerId,
                  Details.intDiamondId,
                  Details.pageName
                );
                handleGetOptionsDetails(loginDetails.dealerId);
                handleLocationLoadDetails(loginDetails.dealerId);
              }
            }
          }

          handleGetSimilarDiamond(loginDetails.dealerId, Details, pageNo);
        }
        // if (loginDetails.optionData) {
        //   setOptionData(loginDetails.optionData);
        // }
      }
    }
  }, [similarToogle]);

  const hideSpinner = () => {
    setIframeLoading(false);
  };

  const handleLocationLoadDetails = (id) => {
    try {
      let inputData = {
        dealerID: id,
      };

      LocationService.LocationLoadDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.Table;

          if (message == "Success") {
            if (responseData) {
              setLocationData1(responseData);
            } else {
              setLocationData1([]);
            }
          } else {
            setLocationData1([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDiamondDetails = (id, diamondId, pageName) => {
    try {
      let inputData = {
        dealerID: id,
        strCheckFilter: "Filter",
        orderBy: "FltPrice",
        orderType: "ASC",

        invenID: "",

        SkuNo: diamondId,
        ModuleType: pageName,
      };
      setViewStateLoading(true);
      SelectYourDiamondService.GetRingBuilderViewDetailsMineLabDiamond(
        inputData
      )
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];

          if (message == "Success") {
            if (responseData) {
              setDiamondInfoDetails(responseData);
              let DiamondData = responseData;
              DiamondData["pageName"] = socialpageName;
              localStorage.setItem(
                "DiamondDetails",
                JSON.stringify(DiamondData)
              );

              setViewStateLoading(false);
              handleGetDealerLocationData(id, Details.dealerID);
              handleGetSocialData(id, diamondId);
            } else {
              setViewStateLoading(false);
            }
          } else {
            setViewStateLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setViewStateLoading(false);
        });
    } catch (error) {
      console.log(error);
      setViewStateLoading(false);
    }
  };

  const images = [
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.alt.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.alt.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.alt.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.alt.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
    },
    {
      original:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
      thumbnail:
        "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
    },
  ];

  function renderLeftNav(onClick, disabled) {
    return (
      <button
        type="button"
        className="image-gallery-left-nav"
        aria-label="Prev Slide"
        disabled={disabled}
        onClick={onClick}
      >
        <img src={leftArrow} />
      </button>
    );
  }

  function renderRightNav(onClick, disabled) {
    return (
      <button
        type="button"
        className="image-gallery-right-nav"
        aria-label="Next Slide"
        disabled={disabled}
        onClick={onClick}
      >
        <img src={rightArrow} />
      </button>
    );
  }

  // Modal Functions
  const [requestMoreInfoModal, setRequestMoreInfoModal] = useState(false);
  const [requestCertModal, setRequestMoreCertModal] = useState(false);
  const [dropHintModal, showDropHintModal] = useState(false);
  const [emailFriendModal, setEmailFriendModal] = useState(false);
  const [isModalVisible4, setIsModalVisible4] = useState(false);
  const [scheduleViewingModal, setScheduleViewingModal] = useState(false);
  const [isModalVisible6, setIsModalVisible6] = useState(false);
  const [viewCertModal, setviewCertModal] = useState(false);

  const [requestMoreInfoModalLoading, setRequestMoreInfoModalLoading] =
    useState(false);
  const [requestCertModalLoading, setRequestMoreCertModalLoading] =
    useState(false);
  const [dropHintModalLoading, setshowDropHintModalLoading] = useState(false);
  const [emailFriendModalLoading, setEmailFriendModalLoading] = useState(false);
  const [scheduleViewingModalLoading, setScheduleViewingModalLoading] =
    useState(false);
  const [viewCertModalLoading, setviewCertModalLoading] = useState(false);

  const [dropHintInputData, setDropHintInputData] = useState({
    name: "",
    email: "",
    recipientName: "",
    recipientEmail: "",
    giftReason: "",
    giftDeadline: "",
    personalMessage: "",
  });
  const [isModalVisibleFullscreen, setIsModalVisibleFullscreen] =
    useState(false);
  const [requestMoreInfoInputData, setRequestMoreInfoInputData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    contactPreference: "",
    personalMessage: "",
  });
  const [certInfoData, setcertInfoData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    comments: "",
  });

  const [emailFriendInputData, setEmailFriendInputData] = useState({
    name: "",
    email: "",
    friendName: "",
    friendEmail: "",
    personalMessage: "",
  });

  const [scheduleViewingInputData, setScheduleViewingInputData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    location: "",
    appointmentDate: "",
    appointmentTime: "",
    address: "",
    message: "",
  });

  const [requestMoreInfoValidation, setRequestMoreInfoValidation] = useState({
    nameVal: "",
    emailVal: "",
    phoneNumberVal: "",
    contactPreferenceVal: "",
    personalMessageVal: "",
  });

  const [emailFriendInputValidation, setEmailFriendInputDataValidation] =
    useState({
      nameVal: "",
      emailVal: "",
      friendNameVal: "",
      friendEmailVal: "",
      personalMessageVal: "",
    });
  const [dropHintValidation, setDropHintValidation] = useState({
    nameVal: "",
    emailVal: "",
    recipientNameVal: "",
    recipientEmailVal: "",
    giftReasonVal: "",
    giftDeadlineVal: "",
    personalMessageVal: "",
  });

  const [scheduleViewingInputValidation, setScheduleViewingInputValidation] =
    useState({
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      locationVal: "",
      appointmentDateVal: "",
      appointmentTimeVal: "",
      addressVal: "",
      messageVal: "",
    });

  const showRequestMoreInfoModal = () => {
    setRequestMoreInfoModal(true);
  };
  const closeRequestMoreInfoModal = () => {
    setRequestMoreInfoModal(false);
    setRequestMoreInfoInputData({
      name: "",
      email: "",
      phoneNumber: "",
      contactPreference: "",
      personalMessage: "",
    });
    setRequestMoreInfoValidation({
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      contactPreferenceVal: "",
      personalMessageVal: "",
    });
  };

  const showCertInfoModal = () => {
    setRequestMoreCertModal(true);
  };
  const closeCertInfoModal = () => {
    setRequestMoreCertModal(false);
    setRequestMoreInfoInputData({
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
    });
  };

  const showDropAHintModal = () => {
    showDropHintModal(true);
  };
  const closeDropHintModal = () => {
    showDropHintModal(false);
    setDropHintInputData({
      name: "",
      email: "",
      recipientName: "",
      recipientEmail: "",
      giftReason: "",
      giftDeadline: "",
      personalMessage: "",
    });
    setDropHintValidation({
      nameVal: "",
      emailVal: "",
      recipientNameVal: "",
      recipientEmailVal: "",
      giftReasonVal: "",
      giftDeadlineVal: "",
      personalMessageVal: "",
    });
  };

  const showCertModal = () => {
    setviewCertModal(true);
  };
  const closeCertModal = () => {
    setviewCertModal(false);
  };

  const showEmailFriendModal = () => {
    setEmailFriendModal(true);
  };
  const hideEmailFriendModal = () => {
    setEmailFriendModal(false);
    setEmailFriendInputData({
      name: "",
      email: "",
      friendName: "",
      friendEmail: "",
      personalMessage: "",
    });
    setEmailFriendInputDataValidation({
      nameVal: "",
      emailVal: "",
      friendNameVal: "",
      friendEmailVal: "",
      personalMessageVal: "",
    });
  };

  const showModal4 = () => {
    setIsModalVisible4(true);
  };
  const handleCancel4 = () => {
    setIsModalVisible4(false);
  };

  const showScheduleViewingModal = () => {
    handleLocationLoadDetails(dealerId);
    setScheduleViewingModal(true);
  };
  const closeScheduleViewingModal = () => {
    setScheduleViewingModal(false);

    setScheduleViewingInputData({
      name: "",
      email: "",
      phoneNumber: "",
      location: "",
      appointmentDate: "",
      appointmentTime: "",
      address: "",
      message: "",
    });
    setScheduleViewingInputValidation({
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      locationVal: "",
      appointmentDateVal: "",
      appointmentTimeVal: "",
      addressVal: "",
      messageVal: "",
    });
  };

  const showModal6 = () => {
    setIsModalVisible6(true);
  };
  const handleCancel6 = () => {
    setIsModalVisible6(false);
  };

  const handleBackButton = (e) => {
    if (socialpageName == "Lab") {
      navigate("/Lab" + `?DealerLink=${paramdealerId}&showHeader=True`);
    } else {
      navigate("/Mined" + `?DealerLink=${paramdealerId}&showHeader=True`);
    }
    dispatch(handleComaparePageIds(""));

    dispatch(handleRemoveDiamondViewEdit());
  };

  const handleDropHintChange = (e, name, type) => {
    setDropHintInputData({
      ...dropHintInputData,
      [name]: e.target.value,
    });
    if (name == "name") {
      setDropHintValidation((prevdata) => ({
        ...prevdata,
        nameVal: "",
      }));
    }
    if (name == "email") {
      setDropHintValidation((prevdata) => ({
        ...prevdata,
        emailVal: "",
      }));
    }
    if (name == "recipientName") {
      setDropHintValidation((prevdata) => ({
        ...prevdata,
        recipientNameVal: "",
      }));
    }
    if (name == "recipientEmail") {
      setDropHintValidation((prevdata) => ({
        ...prevdata,
        recipientEmailVal: "",
      }));
    }
    if (name == "giftReason") {
      setDropHintValidation((prevdata) => ({
        ...prevdata,
        giftReasonVal: "",
      }));
    }
    if (name == "giftDeadline") {
      setDropHintValidation((prevdata) => ({
        ...prevdata,
        giftDeadlineVal: "",
      }));
    }
    if (name == "personalMessage") {
      setDropHintValidation((prevdata) => ({
        ...prevdata,
        personalMessageVal: "",
      }));
    }
  };

  const handleSaveDropHint = () => {
    try {
      let inputData = {
        // sid: diamondDetails,
        sid: "",
        dealerLink: dealerId.toString(),
        tmpCustID: "",
        orderBy: "",
        yourName: dropHintInputData.name,
        hintRecptName: dropHintInputData.recipientName,
        hintRecptMessage: dropHintInputData.personalMessage,
        productType: "",
        toolType: "",
        yourEmail: dropHintInputData.email,
        hintRecptEmail: dropHintInputData.recipientEmail,
        hintReasoneForGift: dropHintInputData.giftReason,
        hintGiftDeadLine: dropHintInputData.giftDeadline,
      };

      setshowDropHintModalLoading(true);
      ItemDetailsService.SaveDropHint(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            NotificationManager.success("Mail has been sent successfully.");
            setshowDropHintModalLoading(false);
            closeDropHintModal();
          } else {
            NotificationManager.error("Mail has not been sent.");
            setshowDropHintModalLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setshowDropHintModalLoading(false);
          NotificationManager.error("Sorry we had an error");
        });
    } catch (error) {
      console.log(error);
      setshowDropHintModalLoading(false);
      NotificationManager.error("Sorry we had an error");
    }
  };

  const handleSubmitDropHint = () => {
    const isValid = handleDropHintValidation();
    if (isValid == false) {
      return false;
    } else {
      handleSaveDropHint();
    }
  };

  const handleSaveRequestCertificate = async () => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        did: diamondInfoDetails.intDiamondId,
        name: certInfoData.name,
        email: certInfoData.email,
        phone: certInfoData.phoneNumber,
        comments: certInfoData.comments,
        retailerID: diamondInfoDetails.dealerID,
      };

      setRequestMoreCertModalLoading(true);
      await SelectYourDiamondService.RingBuilderRequestCertificate(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setRequestMoreCertModalLoading(false);
          } else {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setRequestMoreCertModalLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setRequestMoreCertModalLoading(false);
        });
    } catch (error) {
      console.log(error);
      setRequestMoreCertModalLoading(false);
    }
  };

  const handleSubmitRequestCert = () => {
    handleSaveRequestCertificate();
    setcertInfoData({
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
    });
  };

  const handleRequestMoreInfoChange = (e, name, type) => {
    setRequestMoreInfoInputData({
      ...requestMoreInfoInputData,
      [name]: e.target.value,
    });
    if (name == "name") {
      setRequestMoreInfoValidation((prevdata) => ({
        ...prevdata,
        nameVal: "",
      }));
    }
    if (name == "email") {
      setRequestMoreInfoValidation((prevdata) => ({
        ...prevdata,
        emailVal: "",
      }));
    }
    if (name == "phoneNumber") {
      setRequestMoreInfoValidation((prevdata) => ({
        ...prevdata,
        phoneNumberVal: "",
      }));
    }
    if (name == "contactPreference") {
      setRequestMoreInfoValidation((prevdata) => ({
        ...prevdata,
        contactPreferenceVal: "",
      }));
    }
    if (name == "personalMessage") {
      setRequestMoreInfoValidation((prevdata) => ({
        ...prevdata,
        personalMessageVal: "",
      }));
    }
  };

  const handleCertInfoChange = (e, name, type) => {
    setcertInfoData({
      ...certInfoData,
      [name]: e.target.value,
    });
  };

  console.log(diamondInfoDetails);
  const handleSaveRequestMoreInfo = () => {
    try {
      let inputData = {
        did: diamondInfoDetails.intDiamondId.toString(),
        diamondVendorId: diamondInfoDetails.dealerID.toString(),
        sid: "",
        settingVendorId: "",
        productID: "",
        dealerID: dealerId.toString(),
        dealerEmail: "",
        customerId: "",
        customerName: requestMoreInfoInputData.name,
        customerEmail: requestMoreInfoInputData.email,
        customerPhone: requestMoreInfoInputData.phoneNumber,
        customerMessage: requestMoreInfoInputData.personalMessage,
        customerPreference: requestMoreInfoInputData.contactPreference,
        productType: "",
        toolType: "",
        isEmail: "",
        isDiamond: true,
        isSetting: false,
      };

      setRequestMoreInfoModalLoading(true);
      ItemDetailsService.SaveRequestInfo(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            NotificationManager.success("Mail has been sent successfully.");
            setRequestMoreInfoModalLoading(false);
            closeRequestMoreInfoModal();
          } else {
            NotificationManager.error("Mail has not been sent.");

            setRequestMoreInfoModalLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setRequestMoreInfoModalLoading(false);
        });
    } catch (error) {
      console.log(error);
      setRequestMoreInfoModalLoading(false);
    }
  };

  const handleSubmitRequestMoreInfo = () => {
    const isValid = handleRequestMoreInfoValidation();
    if (isValid == false) {
      return false;
    } else {
      handleSaveRequestMoreInfo();
    }
  };

  const handleEmailFriendChange = (e, name) => {
    setEmailFriendInputData({
      ...emailFriendInputData,
      [name]: e.target.value,
    });
    if (name == "name") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        nameVal: "",
      }));
    }
    if (name == "email") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        emailVal: "",
      }));
    }
    if (name == "friendName") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        friendNameVal: "",
      }));
    }
    if (name == "friendEmail") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        friendEmailVal: "",
      }));
    }
    if (name == "personalMessage") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        personalMessageVal: "",
      }));
    }
  };

  const handleSaveEmailAFriend = () => {
    try {
      // let inputData = {
      //   sid: diamondDetails,
      //   productID: "",
      //   dealerID: dealerId.toString(),
      //   customerId: "",
      //   customerName: emailFriendInputData.name,
      //   customerEmail: emailFriendInputData.email,
      //   friendName: emailFriendInputData.friendName,
      //   friendEmail: emailFriendInputData.friendEmail,
      //   customerMessage: emailFriendInputData.personalMessage,
      //   productType: "",
      //   toolType: "",
      // };

      let inputData = {
        sid: "",
        did: diamondInfoDetails.intDiamondId.toString(),
        productID: "",
        dealerID: dealerId.toString(),
        customerId: "",
        customerName: emailFriendInputData.name,
        customerEmail: emailFriendInputData.email,
        friendName: emailFriendInputData.friendName,
        friendEmail: emailFriendInputData.friendEmail,
        customerMessage: emailFriendInputData.personalMessage,
        productType: "",
        toolType: "",
        isEmail: "",
        dealerEmail: "",
        settingVendorId: "",
        diamondVendorId: diamondInfoDetails.dealerID.toString(),
        isDiamond: true,
        isSetting: false,
      };

      setEmailFriendModalLoading(true);
      ItemDetailsService.SaveEmailAFriend(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            NotificationManager.success("Mail has been sent successfully.");
            setEmailFriendModalLoading(false);
            hideEmailFriendModal();
          } else {
            NotificationManager.error(" Mail has not been sent.");
            setEmailFriendModalLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setEmailFriendModalLoading(false);
          NotificationManager.error("Sorry we had an error");
        });
    } catch (error) {
      console.log(error);
      setEmailFriendModalLoading(false);
      NotificationManager.error("Sorry we had an error");
    }
  };

  const handleSubmitEmailFriend = () => {
    const isValid = handleEmailToFriendValidation();
    if (isValid == false) {
      return false;
    } else {
      handleSaveEmailAFriend();
    }
  };

  const handleScheduleViewingChange = (e, name, type) => {
    if (type == "select") {
      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e,
      });
      if (name == "location") {
        setScheduleViewingInputValidation((prevdata) => ({
          ...prevdata,
          locationVal: "",
        }));
      }
      if (name == "appointmentTime") {
        setScheduleViewingInputValidation((prevdata) => ({
          ...prevdata,
          appointmentTimeVal: "",
        }));
      }
    } else {
      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e.target.value,
      });
    }
    if (name == "name") {
      setScheduleViewingInputValidation((prevdata) => ({
        ...prevdata,
        nameVal: "",
      }));
    }
    if (name == "email") {
      setScheduleViewingInputValidation((prevdata) => ({
        ...prevdata,
        emailVal: "",
      }));
    }
    if (name == "phoneNumber") {
      setScheduleViewingInputValidation((prevdata) => ({
        ...prevdata,
        phoneNumberVal: "",
      }));
    }
    if (name == "appointmentDate") {
      setScheduleViewingInputValidation((prevdata) => ({
        ...prevdata,
        appointmentDateVal: "",
      }));
    }
    if (name == "message") {
      setScheduleViewingInputValidation((prevdata) => ({
        ...prevdata,
        messageVal: "",
      }));
    }
  };
  const handleSaveScheduleViewing = () => {
    try {
      let inputData = {
        did: diamondInfoDetails.intDiamondId.toString(),
        diamondVendorId: diamondInfoDetails.dealerID.toString(),
        sid: "",
        settingVendorId: "",
        dealerID: dealerId.toString(),
        customerId: "",
        name: scheduleViewingInputData.name,
        email: scheduleViewingInputData.email,
        phone: scheduleViewingInputData.phoneNumber,
        customerMessage: scheduleViewingInputData.message,
        appType: "",
        appDate: scheduleViewingInputData.appointmentDate,
        appTime: scheduleViewingInputData.appointmentTime,
        productids: "",
        productType: "",
        toolType: "",
        isDiamond: true,
        isSetting: false,
      };

      setScheduleViewingModalLoading(true);
      ItemDetailsService.SaveScheduleAViewing(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            NotificationManager.success(" Mail has been sent successfully.");
            setScheduleViewingModalLoading(false);
            closeScheduleViewingModal();
          } else {
            NotificationManager.error("Mail has not been sent.");
            setScheduleViewingModalLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setScheduleViewingModalLoading(false);
          NotificationManager.error("Sorry we had an error");
        });
    } catch (error) {
      console.log(error);
      setScheduleViewingModalLoading(false);
      NotificationManager.error("Sorry we had an error");
    }
  };
  const handleSubmitScheduleViewing = () => {
    const isValid = handleScheduleviewingValidation();
    if (isValid == false) {
      return false;
    } else {
      handleSaveScheduleViewing();
    }
  };

  const handleGetDealerInformation = () => {
    let isvalid = handleValidation();

    if (!isvalid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        diamondDealerID: diamondInfoDetails.dealerID,
        password: internalUseData.password,
        diamondID: diamondDetails,
      };
      setInternalUseModalLoading(true);
      SelectYourDiamondService.GetDealerInformation(inputData)
        .then((response) => {
          var message = response.data.responseData.passwordcheck;
          var dealerInfo = response.data.responseData.getDealerInformation[0];
          var dealerInfo1 = response.data.responseData.getDiamondInformation[0];
          if (message == false) {
            NotificationManager.error("Kindly Enter valid Details");
            setInternalUseModalLoading(false);
            setShowDealerInfo(false);
          } else {
            setShowDealerInfo(true);
            setDealerInfo(dealerInfo);
            setDealerInfo1(dealerInfo1);
            setInternalUseModalLoading(false);
            console.log(dealerInfo);
          }
        })
        .catch((error) => {
          console.log(error);
          setInternalUseModalLoading(false);
        });
    } catch (error) {
      console.log(error);
      setInternalUseModalLoading(false);
    }
  };
  const handleSubmitInternalData = () => {
    console.log(internalUseData);
    handleGetDealerInformation();
    setInternalUseData({
      password: "",
    });
  };

  const handleSetInternalUseData = (e) => {
    setInternalUseData({
      password: e.target.value,
    });

    setInternalUseDataValidation({
      passwordVal: "",
    });
  };

  const handleValidation = () => {
    const { password } = internalUseData;

    const internalUseDataValidation = {
      passwordVal: "",
    };
    let isValid = true;

    if (!password) {
      isValid = false;
      internalUseDataValidation.passwordVal = "Password is Compulsory";
    }

    setInternalUseDataValidation(internalUseDataValidation);

    return isValid;
  };

  const handleCancelInternalModal = () => {
    setInternalUseModal(false);
    setShowDealerInfo(false);
    setInternalUseData({
      password: "",
    });
    setInternalUseDataValidation({
      passwordVal: "",
    });
  };
  const handleOpenInternalModal = () => {
    setInternalUseModal(true);
  };

  function handlePrintDiamondList() {
    window.print();
    // var disp_setting =
    //   "toolbar=yes,location=no,directories=yes,menubar=yes,resizable=yes,";
    // disp_setting += "scrollbars=yes,width=1095, height=600, left=100, top=25";
    // var docprint = window.open("", "", disp_setting);
    // docprint.document.open();r
    // var htmlContent = document.getElementById("printsection").innerHTML;

    // htmlContent +=
    //   '<style>.subheading {font-size: 16px;font-family: "LexendDeca-SemiBold";margin: 1rem 0;}.print__section .top__header {display: flex;justify-content: space-between;align-items: center;}.print__section .header__logo img {width:160px;}.print__section table thead {background-color:#efefef;}.print__section .table__content {overflow: hidden;text-overflow: ellipsis;margin-bottom:0;max-width:80px;}.print__section .bottom__footer {background: #e9e8ea;padding: 10px;}.print__section .bottom__footer img {width:160px;}</style>';
    // htmlContent +=
    //   '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">';

    // docprint.document.write(htmlContent);
    // docprint.document.close();

    // setTimeout(() => {
    //   docprint.print();
    // }, 5000);
    // docprint.focus();
  }

  const handleCompleteYourRing = () => {
    navigate(
      "/CompleteYourRing" +
        `?isSocialPage=True&DealerLink=${paramdealerId}&diamondId=${diamondInfoDetails.intDiamondId}&pageNo=1&isAllowed=True&pageName=${pageName}&settingId=${paramSettingId}&settingNumber=${paramSettingNumber}&headerTab=completeYourRing&`
    );
    dispatch(handleActiveTab("completeYourRing"));
  };

  const handleAddYourSetting = () => {
    debugger;
    console.log(diamondInfoDetails);
    navigate(
      "/" +
        `?DealerLink=${paramdealerId}&settingShape=${diamondInfoDetails.txtShape}&diamondId=${diamondInfoDetails.intDiamondId}&pageName=${socialpageName}&headerTab=chooseYourSetting&diamondViewEdit=True`
    );
  };

  const handleRedirect = (url, e) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      return false;
    }
  };

  const handleGetSimilarDiamond = (dealerId, DiamondDetails, page) => {
    setRowDetails(DiamondDetails);
    setShapeList(DiamondDetails.shapeFilters);
    setPageName(DiamondDetails.pageName);
    handleSimilarDiamondList(
      dealerId,
      DiamondDetails,
      page,
      DiamondDetails.pageName
    );
  };

  const handleSimilarDiamondList = (
    id,
    DiamondDetails,
    page,
    PageName,
    column,
    type
  ) => {
    console.log(DiamondDetails);

    let a = {};

    var ValColor = 0;
    var ValClarity = 0;
    var ValCut = 0;
    if (DiamondDetails.intColorPriority == "D") {
      ValColor = 68;
    } else if (DiamondDetails.intColorPriority == "E") {
      ValColor = 69;
    } else if (DiamondDetails.intColorPriority == "F") {
      ValColor = 70;
    } else if (DiamondDetails.intColorPriority == "G") {
      ValColor = 71;
    } else if (DiamondDetails.intColorPriority == "h") {
      ValColor = 72;
    } else if (DiamondDetails.intColorPriority == "I") {
      ValColor = 73;
    } else if (DiamondDetails.intColorPriority == "J") {
      ValColor = 74;
    } else if (DiamondDetails.intColorPriority == "K") {
      ValColor = 74;
    } else if (DiamondDetails.intColorPriority == "L") {
      ValColor = 74;
    } else if (DiamondDetails.intColorPriority == "M") {
      ValColor = 74;
    } else {
      ValColor = 75;
    }
    console.log(ValColor);
    var ValColorLess = ValColor - 1;
    var ValColorMore = ValColor + 1;
    var ColorList = "";
    if (ValColorLess <= 68) {
      ColorList = ValColor + "," + ValColorMore;
    } else if (ValColorMore > 77) {
      ColorList = ValColorLess + "," + ValColor;
    } else if (ValColorMore > 77 && ValColorLess <= 68) {
      ColorList = ValColor;
    } else {
      ColorList = ValColorLess + "," + ValColor + "," + ValColorMore;
    }
    console.log(ColorList);
    if (DiamondDetails.txtClarity == "IF") {
      ValClarity = 1;
    } else if (DiamondDetails.txtClarity == "VVS1") {
      ValClarity = 2;
    } else if (DiamondDetails.txtClarity == "VVS2") {
      ValClarity = 3;
    } else if (DiamondDetails.txtClarity == "VS1") {
      ValClarity = 4;
    } else if (DiamondDetails.txtClarity == "VS2") {
      ValClarity = 5;
    } else if (DiamondDetails.txtClarity == "SI1") {
      ValClarity = 6;
    } else if (DiamondDetails.txtClarity == "SI2") {
      ValClarity = 7;
    } else if (DiamondDetails.txtClarity == "SI3") {
      ValClarity = 8;
    } else if (DiamondDetails.txtClarity == "I1") {
      ValClarity = 9;
    } else if (DiamondDetails.txtClarity == "I2") {
      ValClarity = 10;
    } else {
      ValClarity = 11;
    }
    var ClarityList = "";
    var ValClarityLess = ValClarity - 1;
    var ValClarityMore = ValClarity + 1;

    if (ValClarityLess <= 1) {
      ClarityList = ValClarity + "," + ValClarityMore;
    } else if (ValClarityMore > 10) {
      ClarityList = ValClarityLess + "," + ValClarity;
    } else if (ValClarityMore > 1 && ValClarityLess <= 10) {
      // ClarityList = ValClarity;
      ClarityList = ValClarityLess + "," + ValClarity + "," + ValClarityMore;
    } else {
      ClarityList = ValClarityMore + "," + ValClarity + "," + ValClarityLess;
    }
    console.log(ClarityList);
    if (DiamondDetails.txtCutGrade == "Ideal") {
      ValCut = 1;
    } else if (DiamondDetails.txtCutGrade == "Excellent") {
      ValCut = 2;
    } else if (DiamondDetails.txtCutGrade == "Very Good") {
      ValCut = 3;
    } else if (DiamondDetails.txtCutGrade == "Good") {
      ValCut = 4;
    } else if (DiamondDetails.txtCutGrade == "Fair") {
      ValCut = 5;
    } else if (DiamondDetails.txtCutGrade == "Poor") {
      ValCut = 6;
    } else {
      ValCut = 7;
    }

    var CutList = "";
    var ValCutLess = ValCut - 1;
    var ValCutMore = ValCut + 1;

    if (ValCutLess <= 1) {
      CutList = ValCut + "," + ValCutMore;
    } else if (ValCutMore > 7) {
      CutList = "";
    } else if (ValCutMore > 1 && ValCutLess <= 7) {
      ClarityList = ValCut;
    } else {
      ClarityList = ValCutMore + "," + ValCut + "," + ValCutLess;
    }

    var CutList = "";
    try {
      let inputData;
      if (PageName == "Mined") {
        inputData = {
          dealerId: id.toString(),
          strCheckDealerID: "",
          strCheckFilter: "Filter",
          shapeList:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          priceMin: "",
          priceMax: "",
          caratMin: "0",
          caratMax: "23.85",
          colorMax: "68",
          colorMin: "75",
          clarityMin: "1",
          clarityMax: "3",
          cutGradeMin: "1",
          cutGradeMax: "6",
          symmetryMin: "1",
          symmetryMax: "5",
          symmetryList: "",
          polishMin: "1",
          polishMax: "5",
          polishList: "",
          fluorescenceMin: "1",
          fluorescenceMax: "6",
          fluorescenceList: "",
          certificate: "",
          skuNo: "",
          pairCode: "",
          detailsLink: "",
          did: "",
          depthMin: "0",
          depthMax: "6390",
          tableMin: "0",
          tableMax: "98",
          caratPriceMin: "0",
          caratPriceMax: "100000",
          themeName: "",
          showPrice: "",
          bindWhat: "",
          cutGradeList: CutList.toString(),
          colorList: ColorList.toString(),
          clarityList: ClarityList.toString(),
          bindCheckbox: "",
          sOrigin: "",
          isDiamond: "",
          custID: "",
          countryTaxRate: "",
          countryCode: "",
          track: "",
          isFB: "",
          isMLRB: "",
          settingCenterStonCaratMin: "",
          settingCenterStonCaratMax: "",
          selectedDealerConunt: 0,
          strSelectedDealers: "",
          extraQStr: "",
          pageSize: "6",
          pageNumber: page.toString(),
          orderBy:
            column == "txtShape"
              ? "Cut"
              : column == "fltCarat"
              ? "Size"
              : column == "txtColor"
              ? "Color"
              : column == "txtClarity"
              ? "Clarity"
              : column == "txtCutGrade"
              ? "CutGrade"
              : column == "fltDepth"
              ? "Depth"
              : column == "fltTable"
              ? "TableMeasure"
              : column == "txtPolish"
              ? "Polish"
              : column == "txtSymmetry"
              ? "Symmetry"
              : column == "txtMeasurements"
              ? "Measurements"
              : column == "txtCertificate"
              ? "Certificate"
              : column == "realPrice"
              ? "FltPrice"
              : "FltPrice",
          orderType: type ? type : "ASC",

          strDInventoryId: "",
          flagDD: "",
          ratioMin: "",
          ratioMax: "",
          invenID: "",
          fancyColor: "",
          intIntensity: 0,
          overtone: "",
          overtone2: "",
          country: "",
          shade: "",
          treatment: "",
          keytoSymbol: "",
          fluorescenceColor: "",
          blackInclusion: "",
          centralInclusion: "",
          milkyInclusion: "",
          eyeclean: "",
          ratioType: "",
          permission: "",
          video: "",
          strCertSolrQuery: "",
          strDetailLinkURL: "",
          pageName: PageName,
        };
      }
      if (PageName == "Lab") {
        inputData = {
          dealerID: id.toString(),
          strCheckFilter: "Filter",
          shapes:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          colorMin: "68",
          colorMax: "75",
          clarityMin: "1",
          clarityMax: "3",
          caratMin: "0",
          caratMax: "23.85",
          priceMin: "",
          priceMax: "",
          certificate: "",
          cutGradeMin: "1",
          cutGradeMax: "6",
          symmetryMin: "1",
          symmetryMax: "5",
          polishMin: "1",
          polishMax: "5",
          depthMin: "0",
          depthMax: "6390",
          tableMin: "0",
          tableMax: "98",
          fluorescenceMin: "1",
          fluorescenceMax: "6",
          pairCode: "",
          orderBy:
            column == "txtShape"
              ? "Cut"
              : column == "fltCarat"
              ? "Size"
              : column == "txtColor"
              ? "Color"
              : column == "txtClarity"
              ? "Clarity"
              : column == "txtCutGrade"
              ? "CutGrade"
              : column == "fltDepth"
              ? "Depth"
              : column == "fltTable"
              ? "TableMeasure"
              : column == "txtPolish"
              ? "Polish"
              : column == "txtSymmetry"
              ? "Symmetry"
              : column == "txtMeasurements"
              ? "Measurements"
              : column == "txtCertificate"
              ? "Certificate"
              : column == "realPrice"
              ? "FltPrice"
              : "FltPrice",
          orderType: type ? type : "ASC",

          pageNumber: page.toString(),
          pageSize: "6",
          invenID: "",
          strDetailLinkURL: "",
          did: "",
          caratPriceMin: "0",
          caratPriceMax: "1000000",
          polishList: "",
          symmetryList: "",
          fluorescenceList: "",
          cutGradeList: CutList.toString(),
          colorList: ColorList.toString(),
          clarityList: ClarityList.toString(),
          sOrigin: "",
          countryTaxRate: "",
          strDInventoryId: "",
          skuNo: "",
        };
      }
      if (PageName == "FancyColor") {
        inputData = {
          dealerID: id.toString(),
          shape:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          priceMin: "",
          priceMax: "",
          caratMin: "0",
          caratMax: "23.85",
          colorMin: "68",
          colorMax: "75",
          clarityMin: "1", //FROM INTIAL FILTER
          clarityMax: "3", //FROM INTIAL FILTER
          cutgradeMin: "1",
          cutgradeMax: "6",
          symmetryMin: "1",
          symmetryMax: "5",
          polishMin: "1",
          polishMax: "5",
          fluorescenceMin: "1",
          fluorescenceMax: "6",
          cutgradeMin: "1",
          cutgradeMax: "6",
          cutGradeList: "",
          certificate: "",
          skuNo: "",
          pairCode: "",
          pageSize: "6",
          currentPage: page.toString(),
          orderBy:
            column == "txtShape"
              ? "Cut"
              : column == "fltCarat"
              ? "Size"
              : column == "txtColor"
              ? "Color"
              : column == "txtClarity"
              ? "Clarity"
              : column == "txtCutGrade"
              ? "CutGrade"
              : column == "fltDepth"
              ? "Depth"
              : column == "fltTable"
              ? "TableMeasure"
              : column == "txtPolish"
              ? "Polish"
              : column == "txtSymmetry"
              ? "Symmetry"
              : column == "txtMeasurements"
              ? "Measurements"
              : column == "txtCertificate"
              ? "Certificate"
              : column == "realPrice"
              ? "FltPrice"
              : "FltPrice",
          orderType: type ? type : "ASC",

          detailsLink: "DiamondDetail.aspx",
          dID: "",
          depthMin: "0",
          depthMax: "6390",
          tableMin: "0",
          tableMax: "98",
          caratPriceMin: "0",
          caratPriceMax: "100000",
          themeName: "whiteTheme",
          showPrice: "1",
          isMLDL: "0",
          bindWhat: "All",
          polishList: "",
          symmetryList: "",
          fluorescenceList: "",
          extraQStr: "&SID=&Shape=&CTW=&Size=",
          cutGradeList: CutList.toString(), //Logic from above input
          colorList: ColorList.toString(), // Logic from above input
          clarityList: ClarityList.toString(), //Logic from above input
          bindCheckbox: "1",
          custID: "",
          vendorID: "",
          track: "0",
          isFB: "0",
          parent_url: "",
          myRetailerID: "0",
          showAdvance: false,
          hasVideo: "True",
          dealerCompnayName: "",
          themeName: "WhiteTheme",
          countryTaxRate: "",
          countryCode: "",
        };
      }

      setLoading(true);
      SelectYourDiamondService.GetSimilarDiamondsByPage(inputData, PageName)

        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var FirstRow = response.data.responseData[0];
          if (message == "Success") {
            if (responseData.length > 0) {
              setList(responseData);
              setTotalRowCount(responseData[0].intTotalRecords);
              setLoading(false);
            } else {
              setList([]);
              setTotalRowCount(0);
              setLoading(false);
            }
          } else {
            setList([]);
            setTotalRowCount(0);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleViewDetails = (details) => {
    let obj = details;
    obj["pageName"] = Details.pageName;
    obj["shapeFilters"] = shapeList;
    dispatch(handleViewDetailsPage(obj));

    // handleGetDiamondDetails(dealerId, obj.intDiamondId, pageName);
    // handleGetSimilarDiamond(loginDetails.dealerId, obj, pageNo);

    dispatch(handleActiveTab("selectYourDiamond"));
    navigate(
      "/viewDiamondDetails" +
        `?isSocialPage=True&DealerLink=${obj.gfLinkID}&diamondId=${obj.intDiamondId}&pageNo=1&isAllowed=True&pageName=${obj.pageName}&shapeFilters=${obj.shapeFilters}&txtCutlet=${obj.txtCulet}&txtClarity=${obj.txtClarity}&intColorPriority=${obj.intColorPriority}&headerTab=selectYourDiamond&diamondViewEdit=True`
    );
    setSimilarToogle(!similarToogle);
    Scroll();
  };

  const handleGetDealerLocationData = (id, retailerId) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        myRetailerID: retailerId,
      };
      setViewStateLoading(true);
      SelectYourDiamondService.GetDealerLocationData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];

          if (message == "Success") {
            if (responseData) {
              setLocationData(responseData);

              setViewStateLoading(false);
            } else {
              setViewStateLoading(false);
            }
          } else {
            setViewStateLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setViewStateLoading(false);
        });
    } catch (error) {
      console.log(error);
      setViewStateLoading(false);
    }
  };

  const handleDropHintValidation = () => {
    const {
      name,
      email,
      recipientName,
      recipientEmail,
      giftReason,
      giftDeadline,
      personalMessage,
    } = dropHintInputData;
    const dropHintValidation = {
      nameVal: "",
      emailVal: "",
      recipientNameVal: "",
      recipientEmailVal: "",
      giftReasonVal: "",
      giftDeadlineVal: "",
      personalMessageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      dropHintValidation.nameVal = "FirstName is Compulsory";
    }
    if (!recipientName.trim()) {
      isValid = false;
      dropHintValidation.recipientNameVal = "RecipentName is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email.trim()) {
      isValid = false;
      dropHintValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      dropHintValidation.emailVal = "Email address is invalid";
    } else {
      dropHintValidation.emailVal = "";
    }

    if (!recipientEmail.trim()) {
      isValid = false;
      dropHintValidation.recipientEmailVal = "Email address is compulsory";
    } else if (!recipientEmail.match(validRegex)) {
      isValid = false;
      dropHintValidation.recipientEmailVal = "Email address is invalid";
    } else {
      dropHintValidation.recipientEmailVal = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      dropHintValidation.personalMessageVal = "Personal Message is Compulsory";
    }
    if (!giftReason.trim()) {
      isValid = false;
      dropHintValidation.giftReasonVal = "Gift Reason is Compulsory";
    }
    if (!giftDeadline.trim()) {
      isValid = false;
      dropHintValidation.giftDeadlineVal = "Gift Deadline is Compulsory";
    }

    // if (!isValid) {
    //   setDropHintValidation(dropHintValidation);
    // }

    setDropHintValidation(dropHintValidation);

    return isValid;
  };

  const handleRequestMoreInfoValidation = () => {
    const { name, email, phoneNumber, contactPreference, personalMessage } =
      requestMoreInfoInputData;
    const requestMoreInfoValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      contactPreferenceVal: "",
      personalMessageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      requestMoreInfoValidation.nameVal = "FirstName is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email) {
      isValid = false;
      requestMoreInfoValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      requestMoreInfoValidation.emailVal = "Email address is invalid";
    } else {
      requestMoreInfoValidation.emailVal = "";
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      requestMoreInfoValidation.phoneNumberVal = "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      requestMoreInfoValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      requestMoreInfoValidation.phoneNumberVal = "Phone number is invalid";
    } else {
      requestMoreInfoValidation.phoneNumberVal = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      requestMoreInfoValidation.personalMessageVal =
        "Personal Message is Compulsory";
    }

    // if (!isValid) {
    //   setRequestMoreInfoValidation(requestMoreInfoValidation);
    // }

    setRequestMoreInfoValidation(requestMoreInfoValidation);

    return isValid;
  };

  const handleEmailToFriendValidation = () => {
    const { name, email, friendName, friendEmail, personalMessage } =
      emailFriendInputData;
    const emailFriendInputValidation = {
      nameVal: "",
      emailVal: "",
      friendNameVal: "",
      friendEmailVal: "",
      personalMessageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      emailFriendInputValidation.nameVal = "Name is Compulsory";
    }

    if (!friendName.trim()) {
      isValid = false;
      emailFriendInputValidation.friendNameVal = "Friend Name is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email.trim()) {
      isValid = false;
      emailFriendInputValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      emailFriendInputValidation.emailVal = "Email address is invalid";
    } else {
      emailFriendInputValidation.emailVal = "";
    }

    if (!friendEmail.trim()) {
      isValid = false;
      emailFriendInputValidation.friendEmailVal = "Email address is compulsory";
    } else if (!friendEmail.match(validRegex)) {
      isValid = false;
      emailFriendInputValidation.friendEmailVal = "Email address is invalid";
    } else {
      emailFriendInputValidation.friendEmailVal = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      emailFriendInputValidation.personalMessageVal =
        "Personal Message is Compulsory";
    }

    // if (!isValid) {
    //   setEmailFriendInputDataValidation(emailFriendInputValidation);
    // }

    setEmailFriendInputDataValidation(emailFriendInputValidation);

    return isValid;
  };

  const handleScheduleviewingValidation = () => {
    const {
      name,
      email,
      phoneNumber,
      location,
      appointmentDate,
      appointmentTime,
      address,
      message,
    } = scheduleViewingInputData;
    const scheduleViewingInputValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      locationVal: "",
      appointmentDateVal: "",
      appointmentTimeVal: "",
      addressVal: "",
      messageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      scheduleViewingInputValidation.nameVal = "Name is Compulsory";
    }

    if (!location) {
      isValid = false;
      scheduleViewingInputValidation.locationVal = "Location is Compulsory";
    }

    if (!appointmentDate) {
      isValid = false;
      scheduleViewingInputValidation.appointmentDateVal =
        "Appointment Date is Compulsory";
    }

    if (!appointmentTime) {
      isValid = false;
      scheduleViewingInputValidation.appointmentTimeVal =
        "Appointment Time is Compulsory";
    }

    // if (!address) {
    //   isValid = false;
    //   scheduleViewingInputValidation.addressVal = "Address is Compulsory";
    // }

    if (!message) {
      isValid = false;
      scheduleViewingInputValidation.messageVal = "Message is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email) {
      isValid = false;
      scheduleViewingInputValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      scheduleViewingInputValidation.emailVal = "Email address is invalid";
    } else {
      scheduleViewingInputValidation.emailVal = "";
    }
    if (!phoneNumber) {
      isValid = false;
      scheduleViewingInputValidation.phoneNumberVal =
        "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      scheduleViewingInputValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      scheduleViewingInputValidation.phoneNumberVal = "Phone number is invalid";
    } else {
      scheduleViewingInputValidation.phoneNumberVal = "";
    }

    // if (!isValid) {
    //   setScheduleViewingInputValidation(scheduleViewingInputValidation);
    // }

    setScheduleViewingInputValidation(scheduleViewingInputValidation);

    return isValid;
  };

  const handleShowSocialLinks = () => {
    setShowSocialLinks(!showSocialLinks);
  };

  const handleSocialRedirect = (url) => {
    window.open(url);
  };
  const handleGetSocialData = async (id, sId) => {
    let inputData = {
      vDealerId: id.toString(),
      sid: sId.toString(),
      settingName: "",
      tweeterURL: "",
      imagePath: "",
    };

    try {
      itemDetailsService
        .GetSocialData(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            if (responseData) {
              setSocialData(responseData);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAppointmentTimeChange = (time, timeString) => {
    setScheduleViewingInputData({
      ...scheduleViewingInputData,
      appointmentTime: timeString,
    });
  };

  // Date

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  // certificate

  const handleOpenCertificate = (link) => {
    window.open(
      link,
      "",
      "width=500,height=500, top=' + top + ', left=' + left"
    );
  };

  // sorting

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    var filter = [];
    var obj = {};

    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerCompany";
      setSortColumnName(column);
      setSortType(type);
    }
    console.log(pagination);
    // setPageNo(pagination.current);
    // setPageSize(pagination.pageSize);
    handleSimilarDiamondList(
      dealerId,
      rowDetails,
      pageNo,
      pageName,
      column,
      type
    );
    // document.getElementsByClassName("content__area")[0].scroll(0, 0);
  };

  // Zoom Functionlaity

  const showModalFullscreen = () => {
    setIsModalVisibleFullscreen(true);
  };

  const handleCancelFullscreen = () => {
    setIsModalVisibleFullscreen(false);
  };

  console.log(socialData);

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [wrapperLoading, setWrapperLoading] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [toggle, setToggle] = useState(true);
  useEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "RingBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              var tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              debugger;
              console.log(tempobj);
              console.log(initialColorObj);
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
              // dispatch(handleSwitchChange());
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  const [showButton, setShowButton] = useState("");
  useEffect(() => {
    debugger;

    if (paramSettingId == "null" || paramSettingId == null) {
      setShowButton("AddYourSetting");
    } else {
      setShowButton("CompleteYourRing");
    }
  }, []);

  /* Color Individually Ends */
  return (
    <React.Fragment>
      <Wrapper>
        {/* <span className="back__btn" onClick={(e) => handleBackButton(e)}>
          {" "}
          {"<< Back"}{" "}
        </span> */}
        <div className="allprod_section jwlsrch__section app__preview choose__setting">
          <div className="row">
            <div className="col-md-12">
              <div className="prodetail__section">
                <div className="form__fields border-0">
                  <div className="col-md-12">
                    <div
                      className="prod__maindiv app__preview p-0 border-0"
                      id="printsection"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <Spin spinning={viewStateLoading}>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="whole__shapes available__shapes th__topimg">
                                  <div
                                    className={
                                      blockSelect == "imageFileName"
                                        ? "shapes__block selected"
                                        : "shapes__block"
                                    }
                                  >
                                    {diamondInfoDetails.bigImageFileName ? (
                                      <div className="image">
                                        <img
                                          onClick={() =>
                                            setBlockSelect("imageFileName")
                                          }
                                          src={
                                            diamondInfoDetails.bigImageFileName
                                          }
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                          }}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    className={
                                      blockSelect == "certificateImage"
                                        ? "shapes__block selected"
                                        : "shapes__block"
                                    }
                                  >
                                    {diamondInfoDetails.htmlImage ? (
                                      <div className="image">
                                        <img
                                          onClick={() =>
                                            setBlockSelect("certificateImage")
                                          }
                                          src={diamondInfoDetails.htmlImage}
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                          }}
                                        />
                                      </div>
                                    ) : null}
                                  </div>

                                  <div
                                    className={
                                      blockSelect == "videoFileName"
                                        ? "shapes__block selected"
                                        : "shapes__block"
                                    }
                                  >
                                    {diamondInfoDetails.videoFileName ? (
                                      <div className="image">
                                        <img
                                          onClick={() =>
                                            setBlockSelect("videoFileName")
                                          }
                                          src={Video_icon}
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                          }}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div className="thimage__slider">
                                  {/* <ImageGallery
                                items={images}
                                showFullscreenButton={false}
                                renderLeftNav={renderLeftNav}
                                renderRightNav={renderRightNav}
                              />
                              <div className="video__icon" onClick={showModal6}>
                                <i
                                  class="fa fa-video-camera"
                                  aria-hidden="true"
                                ></i>
                              </div> */}

                                  <div className="big__img">
                                    {blockSelect == "imageFileName" ? (
                                      <img
                                        src={
                                          diamondInfoDetails.bigImageFileName
                                        }
                                        className="img-fluid"
                                      />
                                    ) : blockSelect == "videoFileName" ? (
                                      <>
                                        <iframe
                                          width="100%"
                                          height="450"
                                          src={diamondInfoDetails.videoFileName}
                                        />
                                      </>
                                    ) : blockSelect == "certificateImage" ? (
                                      // <iframe
                                      //   width="100%"
                                      //   height="450"
                                      //   className="img-fluid"
                                      //   src={diamondInfoDetails.htmlImage}
                                      // />
                                      <img
                                        // width={"100%"}
                                        className="img-fluid"
                                        src={diamondInfoDetails.htmlImage}
                                      />
                                    ) : null}
                                  </div>

                                  <div className="share__block display-none">
                                    <div
                                      className="share__button"
                                      onClick={handleShowSocialLinks}
                                    >
                                      <img src={shareBlue} />
                                    </div>
                                    <div
                                      className={
                                        showSocialLinks
                                          ? "social__button__enabled"
                                          : "social__button"
                                      }
                                    >
                                      {ViewOptionData.FacebookShare == true && (
                                        <img
                                          src={shareFacebook}
                                          onClick={() =>
                                            handleSocialRedirect(
                                              socialData.fburl
                                            )
                                          }
                                        />
                                      )}

                                      {ViewOptionData.PinterestShare ==
                                        true && (
                                        <img
                                          src={sharePintrest}
                                          onClick={() =>
                                            handleSocialRedirect(
                                              socialData.pinInterestURL
                                            )
                                          }
                                        />
                                      )}

                                      {ViewOptionData.FacebookLike == true && (
                                        <img
                                          src={LikeFacebook}
                                          onClick={() =>
                                            handleSocialRedirect(
                                              socialData.tweeterURL
                                            )
                                          }
                                        />
                                      )}

                                      {ViewOptionData.TwitterShare == true && (
                                        <img
                                          src={shareTwitter}
                                          onClick={() =>
                                            handleSocialRedirect(
                                              socialData.tweeterURL
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                {blockSelect == "videoFileName" && (
                                  <div className="my-2 text-right">
                                    <span
                                      onClick={() => showModalFullscreen()}
                                      className="linkText"
                                    >
                                      Zoom
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="col-md-12">
                                <div className="diamond__detail">
                                  <div className="row">
                                    <div className="col-md-12 mb-2">
                                      <div>
                                        <span>Diamond Grading Report</span>

                                        {diamondInfoDetails.certlink == "" ? (
                                          <span
                                            className="linkText ml-2"
                                            onClick={() => showCertInfoModal()}
                                          >
                                            Request Certificate
                                          </span>
                                        ) : (
                                          <span
                                            className="linkText ml-2"
                                            // onClick={() => showCertModal()}
                                            onClick={() =>
                                              handleOpenCertificate(
                                                diamondInfoDetails.certlink
                                              )
                                            }
                                          >
                                            View
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      {diamondInfoDetails.txtCertificate !==
                                      "" ? (
                                        diamondInfoDetails.txtCertificate ==
                                        "GIA" ? (
                                          <img src={GIA} width="70px" />
                                        ) : diamondInfoDetails.txtCertificate ==
                                          "IGI" ? (
                                          <img src={IGI} width="70px" />
                                        ) : diamondInfoDetails.txtCertificate ==
                                          "AGS" ? (
                                          <img src={AGS} width="70px" />
                                        ) : diamondInfoDetails.txtCertificate ==
                                          "EGL" ? (
                                          <img src={EGL} width="70px" />
                                        ) : (
                                          <img src={IMG5} width="70px" />
                                        )
                                      ) : (
                                        <img src={IMG5} width="70px" />
                                      )}
                                    </div>
                                    <div className="col-md-10">
                                      <p>
                                        This diamond is graded by{" "}
                                        {diamondInfoDetails.txtCertificate == ""
                                          ? "NA"
                                          : diamondInfoDetails.txtCertificate}
                                        . This provides you an authoritative
                                        analysis of your diamond. It also
                                        verifies that your diamond meets all the
                                        specific quality requirements.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="price__block text-center">
                              <h4 className="subheading">
                                US$
                                {diamondInfoDetails.price
                                  ? diamondInfoDetails.price
                                  : "NA"}
                              </h4>
                              <button className="primary-btn">
                                Add To Cart
                              </button>
                            </div> */}
                              </div>
                            </div>
                          </Spin>
                        </div>

                        <div className="col-lg-6 col-md-12">
                          <div className="prodesc__div">
                            <Tabs
                              defaultActiveKey="home"
                              id="uncontrolled-tab-example"
                              className="tab__div border-0"
                            >
                              <Tab eventKey="home" title="Items Details">
                                <Spin spinning={viewStateLoading}>
                                  <div className="tab__contentdiv border__block p-0">
                                    <div className="head__div">
                                      <h4>
                                        {diamondInfoDetails.fltCarat}-Carat{" "}
                                        {diamondInfoDetails.txtShape} Diamond
                                      </h4>
                                    </div>
                                    <div className="desc__maindiv">
                                      <div className="row top__content m-0">
                                        <div className="col-md-12 mt-3">
                                          <p>
                                            This{" "}
                                            {diamondInfoDetails.txtColor
                                              ? diamondInfoDetails.txtColor
                                              : "NA"}
                                            -color, and{" "}
                                            {diamondInfoDetails.txtClarity
                                              ? diamondInfoDetails.txtClarity
                                              : "NA"}
                                            -clarity diamond comes accompanied
                                            by a diamond grading report from{" "}
                                            {diamondInfoDetails.txtCertificate
                                              ? diamondInfoDetails.txtCertificate
                                              : "NA"}
                                          </p>
                                          <p>
                                            SKU#{" "}
                                            <span className="linkText">
                                              {diamondInfoDetails.intDiamondId
                                                ? diamondInfoDetails.intDiamondId
                                                : "NA"}
                                            </span>
                                          </p>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="detailblk">
                                            <p className="font__bold">Report</p>
                                            <p>
                                              {diamondInfoDetails.txtCertificate
                                                ? diamondInfoDetails.txtCertificate
                                                : "NA"}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="detailblk">
                                            <p className="font__bold">Cut</p>
                                            <p>
                                              {diamondInfoDetails.txtCutGrade
                                                ? diamondInfoDetails.txtCutGrade
                                                : "NA"}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="detailblk">
                                            <p className="font__bold">Color</p>
                                            <p>
                                              {diamondInfoDetails.txtColor
                                                ? diamondInfoDetails.txtColor
                                                : "NA"}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="col-md-3">
                                          <div className="detailblk">
                                            <p className="font__bold">
                                              Clarity
                                            </p>
                                            <p>
                                              {" "}
                                              {diamondInfoDetails.txtClarity
                                                ? diamondInfoDetails.txtClarity
                                                : "NA"}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="col-md-12">
                                          <div className="detailblk">
                                            <p className="font__bold">
                                              <span className="subheading">
                                                {ViewOptionData.ShowPrice ==
                                                false ? (
                                                  "Call For Price"
                                                ) : (
                                                  <>
                                                    {diamondInfoDetails.price ? (
                                                      diamondInfoDetails.price ==
                                                      "0" ? (
                                                        "Call For Price"
                                                      ) : (
                                                        <>
                                                          US$
                                                          {parseInt(
                                                            diamondInfoDetails.price
                                                          )
                                                            .toFixed()
                                                            .replace(
                                                              /\B(?=(\d{3})+(?!\d))/g,
                                                              ","
                                                            )}
                                                        </>
                                                      )
                                                    ) : (
                                                      "NA"
                                                    )}
                                                  </>
                                                )}
                                              </span>
                                            </p>
                                          </div>

                                          {showButton == "AddYourSetting" && (
                                            <button
                                              className="primary-btn mr-2"
                                              onClick={handleAddYourSetting}
                                            >
                                              Add Your Setting
                                            </button>
                                          )}

                                          {showButton == "CompleteYourRing" && (
                                            <button
                                              className="primary-btn mr-2"
                                              onClick={handleCompleteYourRing}
                                            >
                                              Complete Your Ring
                                            </button>
                                          )}
                                          {ViewOptionData &&
                                            ViewOptionData.ShowBuyDiamond ==
                                              true && (
                                              <Button
                                                onClick={() =>
                                                  handleRedirect(
                                                    diamondInfoDetails.buyDiamondLink
                                                  )
                                                }
                                                className="primary-btn mr-2"
                                              >
                                                Buy Diamond Only
                                              </Button>
                                            )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="desc__maindiv mt-3">
                                      <div className="row top__content m-0">
                                        <div className="row itemdetails__btns p-0">
                                          <div className="col-sm-12 col-md-4">
                                            <p onClick={showDropAHintModal}>
                                              <span className="img__box">
                                                <img src={drophint} />
                                              </span>
                                              <span>Drop A Hint</span>
                                            </p>
                                          </div>

                                         
                                          <div className="col-sm-12 col-md-4">
                                            <p
                                              onClick={showRequestMoreInfoModal}
                                            >
                                              <span className="img__box">
                                                <img src={infoblack} />{" "}
                                              </span>
                                              <span>Request More Info</span>
                                            </p>
                                          </div>
                                         
                                        </div>
                                        <div className="row itemdetails__btns p-0">
                                          <div className="col-sm-12 col-md-4">
                                            <p onClick={showEmailFriendModal}>
                                              <span className="img__box">
                                                <img src={emailicon} />{" "}
                                              </span>
                                              <span>E-Mail A Friend</span>
                                            </p>
                                          </div>

                                         

                                          {ViewOptionData.RingsShow == true ? (
                                            <div className="col-sm-12 col-md-4">
                                              <p
                                                onClick={
                                                  showScheduleViewingModal
                                                }
                                              >
                                                <span className="img__box">
                                                  <img src={calender} />
                                                </span>
                                                <span>Schedule Viewing</span>
                                              </p>
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="row itemdetails__btns p-0">
                                          <div className="col-sm-12 col-md-4">
                                            <p
                                              onClick={() => {
                                                handlePrintDiamondList();
                                              }}
                                            >
                                              <span className="img__box"><img src={calender} /></span>
                                              <span>Print Details</span>
                                            </p>
                                          </div>
                                        </div>

                                        <div className="col-md-12">
                                          <p>
                                            Have a question regarding this item?
                                            Our specialists are available to
                                            assist you.
                                          </p>
                                        </div>
                                        {ViewOptionData &&
                                        ViewOptionData.ShowAddress == true ? (
                                          <>
                                            {ViewOptionData &&
                                              ViewOptionData.ShowAddress && (
                                                <div className="col-md-12">
                                                  <p className="font__bold d-flex justify-between">
                                                    <span className="font__bold">
                                                      {locationData.locationName &&
                                                        Parse(
                                                          locationData.locationName
                                                        )}
                                                    </span>
                                                    {/* <span className="font__bold">
                                            {locationData && locationData.locationName}
                                            </span> */}
                                                  </p>
                                                  <p className="font__bold">
                                                    <span className="font__bold">
                                                      {locationData &&
                                                        locationData.phone}
                                                    </span>
                                                  </p>

                                                  <a
                                                    className="linkText"
                                                    href={`mailto:${
                                                      locationData &&
                                                      locationData.emailID
                                                    }`}
                                                  >
                                                    {locationData &&
                                                      locationData.emailID}
                                                  </a>
                                                  {/* <p
                                                href={`mailto:${locationData &&
                                                  locationData.emailID}`}
                                                className="font__bold"
                                              >
                                                {locationData &&
                                                  locationData.emailID}
                                              </p> */}
                                                </div>
                                              )}
                                          </>
                                        ) : null}

                                        <div className="col-md-12">
                                          <div className="dtl__socialsection">
                                            {ViewOptionData.FacebookShare ==
                                              true && (
                                              <button
                                                onClick={() =>
                                                  handleSocialRedirect(
                                                    socialData.fburl
                                                  )
                                                }
                                                className="dtl__socialbtn fb__btn"
                                              >
                                                <img src={DtlFacebook} />
                                              </button>
                                            )}
                                            {ViewOptionData.PinterestShare ==
                                              true && (
                                              <button
                                                onClick={() =>
                                                  handleSocialRedirect(
                                                    socialData.pinInterestURL
                                                  )
                                                }
                                                className="dtl__socialbtn pintrest"
                                              >
                                                <img src={DtlPintrest} />
                                              </button>
                                            )}
                                            {ViewOptionData.TwitterShare ==
                                              true && (
                                              <button
                                                onClick={() =>
                                                  handleSocialRedirect(
                                                    socialData.tweeterURL
                                                  )
                                                }
                                                className="dtl__socialbtn tweeter"
                                              >
                                                <img src={DtlTwitter} />
                                              </button>
                                            )}

                                            <button className="dtl__socialbtn instagram">
                                              <img src={DtlInstagram} />
                                            </button>

                                            <button className="dtl__socialbtn googlePlus">
                                              <img src={DtlGooglePlus} />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Spin>
                              </Tab>
                              <Tab eventKey="profile" title="Specifications">
                                <Spin spinning={viewStateLoading}>
                                  <div className="tab__contentdiv border__block p-0">
                                    <div className="head__div">
                                      <h4>Diamond Details</h4>
                                    </div>
                                    <div className="desc__maindiv">
                                      {diamondInfoDetails.intDiamondId && (
                                        <div className="desc__div">
                                          <span className="head__txt">
                                            Stock Number
                                          </span>
                                          <span className="desc__txt">
                                            {/* Number{" "} */}
                                            {diamondInfoDetails.intDiamondId
                                              ? diamondInfoDetails.intDiamondId
                                              : ""}
                                          </span>
                                        </div>
                                      )}
                                      {diamondInfoDetails.price && (
                                        <div className="desc__div">
                                          <span className="head__txt">
                                            Price
                                          </span>
                                          <span className="desc__txt">
                                            {ViewOptionData.ShowPrice ==
                                            false ? (
                                              "Call For Price"
                                            ) : (
                                              <>
                                                $
                                                {diamondInfoDetails.price ? (
                                                  diamondInfoDetails.price ==
                                                  "0" ? (
                                                    "Call For Price"
                                                  ) : (
                                                    <>
                                                      US$
                                                      {parseInt(
                                                        diamondInfoDetails.price
                                                      )
                                                        .toFixed()
                                                        .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ","
                                                        )}
                                                    </>
                                                  )
                                                ) : (
                                                  "NA"
                                                )}
                                              </>
                                            )}
                                          </span>
                                        </div>
                                      )}
                                      {diamondInfoDetails.fltCarat && (
                                        <div className="desc__div">
                                          <span className="head__txt">
                                            Carat Weight{" "}
                                          </span>
                                          <span className="desc__txt">
                                            {diamondInfoDetails.fltCarat
                                              ? diamondInfoDetails.fltCarat
                                              : "NA"}
                                          </span>
                                        </div>
                                      )}
                                      {diamondInfoDetails.txtCutGrade && (
                                        <div className="desc__div">
                                          <span className="head__txt">
                                            Cut{" "}
                                          </span>
                                          <span className="desc__txt">
                                            {diamondInfoDetails.txtCutGrade
                                              ? diamondInfoDetails.txtCutGrade
                                              : "NA"}
                                          </span>
                                        </div>
                                      )}
                                      {diamondInfoDetails.txtColor && (
                                        <div className="desc__div">
                                          <span className="head__txt">
                                            Color{" "}
                                          </span>
                                          <span className="desc__txt">
                                            {diamondInfoDetails.txtColor
                                              ? diamondInfoDetails.txtColor
                                              : "NA"}
                                          </span>
                                        </div>
                                      )}
                                      {diamondInfoDetails.txtClarity && (
                                        <div className="desc__div">
                                          <span className="head__txt">
                                            Clarity{" "}
                                          </span>
                                          <span className="desc__txt">
                                            {diamondInfoDetails.txtClarity
                                              ? diamondInfoDetails.txtClarity
                                              : "NA"}
                                          </span>
                                        </div>
                                      )}
                                      {diamondInfoDetails.fltDepth && (
                                        <div className="desc__div">
                                          <span className="head__txt">
                                            Depth %{" "}
                                          </span>
                                          <span className="desc__txt">
                                            {diamondInfoDetails.fltDepth
                                              ? diamondInfoDetails.fltDepth +
                                                " %"
                                              : "NA"}
                                          </span>
                                        </div>
                                      )}
                                      {diamondInfoDetails.fltTable && (
                                        <div className="desc__div">
                                          <span className="head__txt">
                                            Table %
                                          </span>
                                          <span className="desc__txt">
                                            {diamondInfoDetails.fltTable
                                              ? diamondInfoDetails.fltTable +
                                                " %"
                                              : "NA"}
                                          </span>
                                        </div>
                                      )}
                                      {diamondInfoDetails.txtPolish && (
                                        <div className="desc__div">
                                          <span className="head__txt">
                                            Polish
                                          </span>
                                          <span className="desc__txt">
                                            {diamondInfoDetails.txtPolish
                                              ? diamondInfoDetails.txtPolish
                                              : "NA"}
                                          </span>
                                        </div>
                                      )}
                                      {diamondInfoDetails.txtSymmetry && (
                                        <div className="desc__div">
                                          <span className="head__txt">
                                            Symmetry
                                          </span>
                                          <span className="desc__txt">
                                            {diamondInfoDetails.txtSymmetry
                                              ? diamondInfoDetails.txtSymmetry
                                              : "NA"}
                                          </span>
                                        </div>
                                      )}
                                      {diamondInfoDetails.txtgridle && (
                                        <div className="desc__div">
                                          <span className="head__txt">
                                            Girdle
                                          </span>
                                          <span className="desc__txt">
                                            {diamondInfoDetails.txtgridle
                                              ? diamondInfoDetails.txtgridle
                                              : "NA"}
                                          </span>
                                        </div>
                                      )}
                                      {diamondInfoDetails.txtCulet && (
                                        <div className="desc__div">
                                          <span className="head__txt">
                                            Culet
                                          </span>
                                          <span className="desc__txt">
                                            {diamondInfoDetails.txtCulet
                                              ? diamondInfoDetails.txtCulet
                                              : "NA"}
                                          </span>
                                        </div>
                                      )}
                                      {diamondInfoDetails.txtFluorescence && (
                                        <div className="desc__div">
                                          <span className="head__txt">
                                            Florescence
                                          </span>
                                          <span className="desc__txt">
                                            {diamondInfoDetails.txtFluorescence
                                              ? diamondInfoDetails.txtFluorescence
                                              : "NA"}
                                          </span>
                                        </div>
                                      )}
                                      {diamondInfoDetails.txtMeasurements && (
                                        <div className="desc__div">
                                          <span className="head__txt">
                                            Measurement
                                          </span>
                                          <span className="desc__txt">
                                            {diamondInfoDetails.txtMeasurements
                                              ? diamondInfoDetails.txtMeasurements
                                              : "NA"}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Spin>
                              </Tab>
                            </Tabs>
                          </div>
                        </div>
                        <div className="gview__div mt-3">
                          <span className="font__bold mr-2 similar__text">
                            {TotalRowCount ? TotalRowCount : 0} Similar Diamonds
                          </span>
                        </div>

                        <div className="col-md-12 mt-4">
                          {/* <Spin spinning={viewStateLoading}> */}
                          <div className="lviewprod__tbl">
                            <Table
                              className={
                                tableClassAllow == true
                                  ? "info__tooltip__table overflow-visible"
                                  : "info__tooltip__table"
                              }
                              columns={columns}
                              onChange={handleTableASCDES}
                              dataSource={list}
                              // scroll={{ x: 600, y: 300 }}
                              pagination={false}
                            />
                          </div>
                          <div className="custom__table print__table">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">Shape</th>
                                  <th scope="col">Carats</th>
                                  <th scope="col">Color</th>
                                  <th scope="col">Clarity</th>
                                  <th scope="col">Cut</th>
                                  <th scope="col">Depth</th>
                                  <th scope="col">Table</th>
                                  <th scope="col">Polish</th>
                                  <th scope="col">Symmetry</th>
                                  <th scope="col">Measurement</th>
                                  <th scope="col">Certificate</th>
                                  <th scope="col">Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {list.map((row) => (
                                  <tr>
                                    <td>
                                      <img
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                        src={row.ImageFileName}
                                        alt=""
                                      />
                                      <span>{row.txtShape}</span>
                                    </td>
                                    <td>
                                      <span>{row.fltCarat}</span>
                                    </td>
                                    <td>
                                      <span>{row.txtColor}</span>
                                    </td>
                                    <td>
                                      <span>{row.txtClarity}</span>
                                    </td>
                                    <td>
                                      <span>{row.txtCutGrade}</span>
                                    </td>
                                    <td>
                                      <span>{row.fltDepth}</span>
                                    </td>
                                    <td>
                                      <span>{row.fltTable}</span>
                                    </td>
                                    <td>
                                      <span>{row.txtPolish}</span>
                                    </td>
                                    <td>
                                      <span>{row.txtSymmetry}</span>
                                    </td>
                                    <td>
                                      <span>{row.txtMeasurements}</span>
                                    </td>
                                    <td>
                                      <span>{row.txtCertificate}</span>
                                    </td>
                                    <td>
                                      <span>{row.realPrice}</span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          {/* </Spin> */}
                        </div>
                        {ViewOptionData.InternalUseLink == true && (
                          <span
                            className="font__bold mr-2 mt-3 linkText"
                            onClick={handleOpenInternalModal}
                          >
                            For Internal Use Only
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>

      {/* Modal Section Starts */}

      {/* Zoom */}

      <Modal
        title="Fullscreen"
        visible={isModalVisibleFullscreen}
        className="fullscreen__modal"
        onCancel={handleCancelFullscreen}
        footer={false}
        width={800}
        style={{ top: 20 }}
      >
        <div className="thimage__slider">
          {/* <iframe
              width="100%"
              height="450"
              src={diamondInfoDetails.videoFileName}
              allowfullscreen="false"
              volume="2"
            /> */}
          <video
            src={diamondInfoDetails.videoFileName}
            controls
            width="100%"
            height="450"
            allowfullscreen="false"
          ></video>
        </div>
      </Modal>

      {/* Zoom End */}

      {/* Request More Info Modal Starts */}
      <Modal
        title="Request More Information"
        visible={requestMoreInfoModal}
        style={{ top: 20 }}
        width={700}
        className="request_info"
        onCancel={closeRequestMoreInfoModal}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={closeRequestMoreInfoModal}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            loading={requestMoreInfoModalLoading}
            onClick={handleSubmitRequestMoreInfo}
          >
            Request
          </Button>,
        ]}
      >
        <div className="row">
          <div className="col-md-12">
            <p>Our specialist will contact you.</p>
          </div>
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Name<span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Name"
                      value={requestMoreInfoInputData.name}
                      onChange={(e) =>
                        handleRequestMoreInfoChange(e, "name", "input")
                      }
                    />
                    <div>
                      {requestMoreInfoValidation.nameVal && (
                        <p className="error-color-red">
                          {requestMoreInfoValidation.nameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your E-Mail Address<span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter  Your E-Mail Address"
                      value={requestMoreInfoInputData.email}
                      onChange={(e) =>
                        handleRequestMoreInfoChange(e, "email", "input")
                      }
                    />
                    <div>
                      {requestMoreInfoValidation.emailVal && (
                        <p className="error-color-red">
                          {requestMoreInfoValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Phone Number <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Phone Number"
                      value={requestMoreInfoInputData.phoneNumber}
                      onChange={(e) =>
                        handleRequestMoreInfoChange(e, "phoneNumber", "input")
                      }
                    />
                    <div>
                      {requestMoreInfoValidation.phoneNumberVal && (
                        <p className="error-color-red">
                          {requestMoreInfoValidation.phoneNumberVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Contact Preference<span className="mandatory">*</span>
                    </label>
                    <div className="row">
                      <div className="radio__block col-6">
                        <input
                          type="radio"
                          value="byEmail"
                          checked={
                            requestMoreInfoInputData.contactPreference ==
                            "byEmail"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleRequestMoreInfoChange(
                              e,
                              "contactPreference",
                              "radio"
                            )
                          }
                        />
                        <label>By Email</label>
                      </div>
                      <div className="radio__block col-6">
                        <input
                          type="radio"
                          value="byPhone"
                          checked={
                            requestMoreInfoInputData.contactPreference ==
                            "byPhone"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleRequestMoreInfoChange(
                              e,
                              "contactPreference",
                              "radio"
                            )
                          }
                        />
                        <label>By Phone</label>
                      </div>
                      <div>
                        {requestMoreInfoValidation.contactPreferenceVal && (
                          <p className="error-color-red">
                            {requestMoreInfoValidation.contactPreferenceVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input__block">
                    <label>
                      Write A Personal Message Here ...{" "}
                      <span className="mandatory">*</span>
                    </label>
                    <textarea
                      value={requestMoreInfoInputData.personalMessage}
                      placeholder="Write A Personal Message Here ..."
                      onChange={(e) =>
                        handleRequestMoreInfoChange(
                          e,
                          "personalMessage",
                          "radio"
                        )
                      }
                    ></textarea>
                    <div>
                      {requestMoreInfoValidation.personalMessageVal && (
                        <p className="error-color-red">
                          {requestMoreInfoValidation.personalMessageVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Request More Info Modal Ends */}

      {/* Drop A Hint Modal Starts */}
      <Modal
        title="Drop A Hint"
        visible={dropHintModal}
        style={{ top: 20 }}
        width={700}
        className="drop_a_hint"
        onCancel={closeDropHintModal}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={closeDropHintModal}
          >
            Cancel
          </Button>,
          <Button
            loading={dropHintModalLoading}
            key="submit"
            type="primary"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={handleSubmitDropHint}
          >
            Drop Hint
          </Button>,
        ]}
      >
        <div className="row">
          <div class="col-md-12">
            <p>Because you deserve this.</p>
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Name <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Name"
                      value={dropHintInputData.name}
                      onChange={(e) => handleDropHintChange(e, "name", "input")}
                    />
                    <div>
                      {dropHintValidation.nameVal && (
                        <p className="error-color-red">
                          {dropHintValidation.nameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your E-Mail <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Your E-Mail"
                      value={dropHintInputData.email}
                      onChange={(e) =>
                        handleDropHintChange(e, "email", "input")
                      }
                    />
                    <div>
                      {dropHintValidation.emailVal && (
                        <p className="error-color-red">
                          {dropHintValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Hint Recipient's Name <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Hint Recipient's Name"
                      value={dropHintInputData.recipientName}
                      onChange={(e) =>
                        handleDropHintChange(e, "recipientName", "input")
                      }
                    />
                    <div>
                      {dropHintValidation.recipientNameVal && (
                        <p className="error-color-red">
                          {dropHintValidation.recipientNameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Hint Recipient's E-Mail{" "}
                      <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Hint Recipient's E-Mail"
                      value={dropHintInputData.recipientEmail}
                      onChange={(e) =>
                        handleDropHintChange(e, "recipientEmail", "input")
                      }
                    />
                    <div>
                      {dropHintValidation.recipientEmailVal && (
                        <p className="error-color-red">
                          {dropHintValidation.recipientEmailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Reason For This Gift <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Reason For This Gift"
                      value={dropHintInputData.giftReason}
                      onChange={(e) =>
                        handleDropHintChange(e, "giftReason", "input")
                      }
                    />
                    <div>
                      {dropHintValidation.giftReasonVal && (
                        <p className="error-color-red">
                          {dropHintValidation.giftReasonVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Gift Deadline <span className="mandatory">*</span>
                    </label>
                    <input
                      type="date"
                      value={dropHintInputData.giftDeadline}
                      onChange={(e) =>
                        handleDropHintChange(e, "giftDeadline", "date")
                      }
                      min={disablePastDate()}
                    />
                    <div>
                      {dropHintValidation.giftDeadlineVal && (
                        <p className="error-color-red">
                          {dropHintValidation.giftDeadlineVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="input__block">
                    <label>Add A Personal Message Here ...</label>
                    <textarea
                      value={dropHintInputData.personalMessage}
                      placeholder="Add A Personal Message Here ..."
                      onChange={(e) =>
                        handleDropHintChange(e, "personalMessage", "input")
                      }
                    ></textarea>
                    <div>
                      {dropHintValidation.personalMessageVal && (
                        <p className="error-color-red">
                          {dropHintValidation.personalMessageVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Drop A Hint Modal Ends */}

      {/* E-Mail A Friend Modal Starts */}
      <Modal
        title="E-Mail A Friend"
        visible={emailFriendModal}
        style={{ top: 20 }}
        width={700}
        className="email_a_friend"
        onCancel={hideEmailFriendModal}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={hideEmailFriendModal}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            loading={emailFriendModalLoading}
            type="primary"
            onClick={handleSubmitEmailFriend}
          >
            Send To Friend
          </Button>,
        ]}
      >
        <div className="row">
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Name <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Name"
                      value={emailFriendInputData.name}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "name", "input")
                      }
                    />
                    <div>
                      {emailFriendInputValidation.nameVal && (
                        <p className="error-color-red">
                          {emailFriendInputValidation.nameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your E-Mail <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your E-Mail"
                      value={emailFriendInputData.email}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "email", "input")
                      }
                    />
                    <div>
                      {emailFriendInputValidation.emailVal && (
                        <p className="error-color-red">
                          {emailFriendInputValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Friend's Name <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Friend's Name"
                      value={emailFriendInputData.friendName}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "friendName", "input")
                      }
                    />
                    <div>
                      {emailFriendInputValidation.friendNameVal && (
                        <p className="error-color-red">
                          {emailFriendInputValidation.friendNameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Friend's E-Mail <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Friend's E-Mail"
                      value={emailFriendInputData.friendEmail}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "friendEmail", "input")
                      }
                    />
                    <div>
                      {emailFriendInputValidation.friendEmailVal && (
                        <p className="error-color-red">
                          {emailFriendInputValidation.friendEmailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input__block">
                    <label>
                      Add A Personal Message Here ...{" "}
                      <span className="mandatory">*</span>
                    </label>
                    <textarea
                      value={emailFriendInputData.personalMessage}
                      placeholder=" Add A Personal Message Here ..."
                      onChange={(e) =>
                        handleEmailFriendChange(e, "personalMessage", "input")
                      }
                    ></textarea>
                    <div>
                      {emailFriendInputValidation.personalMessageVal && (
                        <p className="error-color-red">
                          {emailFriendInputValidation.personalMessageVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* E-Mail A Friend Modal Ends */}

      {/* Print Details Modal Starts */}
      <Modal
        title="Print Details"
        visible={isModalVisible4}
        style={{ top: 20 }}
        width={700}
        className="print_details"
        onCancel={handleCancel4}
      >
        <div className="row">
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Name</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your E-Mail Address</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Company Name</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Phone Number</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Store Phone Number</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input__block">
                    <label>Write A Personal Message</label>
                    <textarea></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Print Details Modal Ends */}

      <Modal
        className="modalconsupld__section"
        style={{ top: 20 }}
        title={"Video"}
        width={500}
        visible={videoImageModal}
        onCancel={() => handleCloseVideoImageModal()}
        footer={[
          <Button
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={() => handleCloseVideoImageModal()}
          >
            {"Cancel"}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  {isVideo ? (
                    selectedRowDetails.videoFileName ? (
                      // <iframe
                      //   width="100%"
                      //   height="450"
                      //   title="diamond-video"
                      //   className="VideoIframe"
                      //   src={selectedRowDetails.videoFileName}
                      // ></iframe>
                      <video
                        controls
                        autoPlay
                        key={selectedRowDetails.videoFileName}
                        style={{ width: "100%", height: "450px" }}
                        allowfullscreen
                      >
                        <source src={selectedRowDetails.videoFileName} />
                      </video>
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  ) : selectedRowDetails.imageFileName ? (
                    <img
                      src={selectedRowDetails.imageFileName}
                      alt="img-diamond"
                    />
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Schedule Viewing Modal Starts */}
      <Modal
        title="Schedule A Viewing"
        style={{ top: 20 }}
        width={700}
        visible={scheduleViewingModal}
        className="schedule_viewing"
        onCancel={closeScheduleViewingModal}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={closeScheduleViewingModal}
          >
            Cancel
          </Button>,
          <Button
            loading={scheduleViewingModalLoading}
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            key="submit"
            type="primary"
            onClick={handleSubmitScheduleViewing}
          >
            Schedule
          </Button>,
        ]}
      >
        <div className="row">
          <div className="col-md-12">
            <p>See this item and more in our store</p>
          </div>
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Name</label>
                    <input
                      type="text"
                      placeholder="Enter Your Name"
                      value={scheduleViewingInputData.name}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "name", "input")
                      }
                    />
                    <div>
                      {scheduleViewingInputValidation.nameVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.nameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your E-mail Address</label>
                    <input
                      type="text"
                      placeholder="Enter Your E-mail Address"
                      value={scheduleViewingInputData.email}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "email", "input")
                      }
                    />
                    <div>
                      {scheduleViewingInputValidation.emailVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Phone Number</label>
                    <input
                      type="text"
                      placeholder="Enter Your Phone Number"
                      value={scheduleViewingInputData.phoneNumber}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "phoneNumber", "input")
                      }
                    />
                    <div>
                      {scheduleViewingInputValidation.phoneNumberVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.phoneNumberVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>Location</label>
                    <Select
                      className="border__grey"
                      name=""
                      optionFilterProp="children"
                      value={scheduleViewingInputData.location}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "location", "select")
                      }
                    >
                      <Option value="">Select Location</Option>
                      {locationData1 &&
                        locationData1.map((item, i) => {
                          return (
                            <Option value={item.LocationId}>
                              {" "}
                              {item.LocationName}{" "}
                            </Option>
                          );
                        })}
                    </Select>
                    <div>
                      {scheduleViewingInputValidation.locationVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.locationVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>When are you available?</label>
                    <input
                      type="date"
                      value={scheduleViewingInputData.appointmentDate}
                      onChange={(e) =>
                        handleScheduleViewingChange(
                          e,
                          "appointmentDate",
                          "date"
                        )
                      }
                      min={disablePastDate()}
                    />
                    <div>
                      {scheduleViewingInputValidation.appointmentDateVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.appointmentDateVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label className="mb-2">Appointment Time</label>
                    {/* <input
                        type="time"
                        value={scheduleViewingInputData.appointmentTime}
                        onChange={(e) =>
                          handleScheduleViewingChange(
                            e,
                            "appointmentTime",
                            "time"
                          )
                        }
                      /> */}

                    <Select
                      name="appointmentTime"
                      // className="border__grey"
                      className={
                        scheduleViewingInputValidation.appointmentTimeVal
                          ? "border__red"
                          : "border__grey"
                      }
                      showSearch
                      placeholder="Select End Time"
                      optionFilterProp="children"
                      value={scheduleViewingInputData.appointmentTime}
                      onChange={(e) =>
                        handleScheduleViewingChange(
                          e,
                          "appointmentTime",
                          "select"
                        )
                      }
                    >
                      <Option value="">Appointment Time</Option>
                      <Option value="8:00 AM">8 AM</Option>
                      <Option value="8:30 AM">8:30 AM</Option>
                      <Option value="9:00 AM">9 AM</Option>
                      <Option value="9:30 AM">9:30 AM</Option>
                      <Option value="10:00 AM">10 AM</Option>
                      <Option value="10:30 AM">10:30 AM</Option>
                      <Option value="11:00 AM">11 AM</Option>
                      <Option value="11:30 AM">11:30 AM</Option>
                      <Option value="12:00 AM">12 AM</Option>
                      <Option value="12:00 PM">12:30 PM</Option>
                      <Option value="1:00 PM">1 PM</Option>
                      <Option value="1:30 PM">1:30 PM</Option>
                      <Option value="2:00 PM">2 PM</Option>
                      <Option value="2:30 PM">2:30 PM</Option>
                      <Option value="3:00 PM">3 PM</Option>
                      <Option value="3:30 PM">3:30 PM</Option>
                      <Option value="4:00 PM">4 PM</Option>
                      <Option value="4:30 PM">4:30 PM</Option>
                      <Option value="5:00 PM">5 PM</Option>
                      <Option value="5:30 PM">5:30 PM</Option>
                      <Option value="6:00 PM">6 PM</Option>
                      <Option value="6:30 PM">6:30 PM</Option>
                      <Option value="7:00 PM">7 PM</Option>
                      <Option value="7:30 PM">7:30 PM</Option>
                      <Option value="8:00 PM">8 PM</Option>
                      <Option value="8:30 PM">8:30 PM</Option>
                      <Option value="9:00 PM">9 PM</Option>
                      <Option value="9:30 PM">9:30 PM</Option>
                      <Option value="10:00 PM">10 PM</Option>
                      <Option value="10:30 PM">10:30 PM</Option>
                    </Select>

                    {/* <TimePicker
                        placeholder="Appointment Time"
                        use12Hours
                        format="h:mm A"
                        onChange={handleAppointmentTimeChange}
                        style={{
                          width: "100%"
                        }}
                      /> */}
                    <div>
                      {scheduleViewingInputValidation.appointmentTimeVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.appointmentTimeVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input__block">
                    <label>Add A Personal Message Here ...</label>
                    <textarea
                      value={scheduleViewingInputData.message}
                      placeholder="Add A Personal Message Here ..."
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "message", "input")
                      }
                    ></textarea>
                    <div>
                      {scheduleViewingInputValidation.messageVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.messageVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <p className="font__bold d-flex justify-between">
                    <span className="font__bold">
                      {locationData.locationName &&
                        Parse(locationData.locationName)}
                    </span>
                  </p>
                  <p className="font__bold">
                    <span className="font__bold">
                      {locationData && locationData.phone}
                    </span>
                  </p>
                  <p className="linkText">
                    {locationData && locationData.emailID}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Schedule Viewing Modal Ends */}

      <Modal
        title="Certificate"
        visible={viewCertModal}
        style={{ top: 20 }}
        className="cert_modal"
        onCancel={closeCertModal}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={closeCertModal}
          >
            Cancel
          </Button>,
          // <Button key="submit" type="primary" onClick={handleSubmitDropHint}>
          //   Drop Hint
          // </Button>,
        ]}
      >
        <div className="row">
          <div>
            <Spin spinning={iframeLoading}>
              <iframe
                onLoad={hideSpinner}
                width="100%"
                src={diamondInfoDetails.certificateImage}
              />
            </Spin>
          </div>
        </div>
      </Modal>

      <Modal
        title="Request Certificate"
        visible={requestCertModal}
        style={{ top: 20 }}
        className="request_info"
        onCancel={closeCertInfoModal}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={closeCertInfoModal}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            loading={requestCertModalLoading}
            type="primary"
            onClick={handleSubmitRequestCert}
          >
            Request
          </Button>,
        ]}
      >
        <div className="row">
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Name</label>
                    <input
                      type="text"
                      value={certInfoData.name}
                      onChange={(e) => handleCertInfoChange(e, "name", "input")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>E-Mail Address</label>
                    <input
                      type="text"
                      value={certInfoData.email}
                      onChange={(e) =>
                        handleCertInfoChange(e, "email", "input")
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      value={certInfoData.phoneNumber}
                      onChange={(e) =>
                        handleCertInfoChange(e, "phoneNumber", "input")
                      }
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="input__block">
                    <label>Comments</label>
                    <textarea
                      value={certInfoData.comments}
                      onChange={(e) =>
                        handleCertInfoChange(e, "comments", "input")
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Thumbnail Slider Video Modal Starts */}
      <Modal
        title=""
        style={{ top: 20 }}
        visible={isModalVisible6}
        className="video__modal"
        onCancel={handleCancel6}
        footer={false}
      >
        <div className="video__section">
          <video
            width="100%"
            height="100%"
            src="https://up.diacam360.com/scan/90ad_1052947"
            autoPlay
            loop
            controls
            allowfullscreen
          ></video>
        </div>
      </Modal>
      {/* Thumbnail Slider Video Modal Ends */}

      {/* For Internal Use Only Modal Starts */}

      <Modal
        title="For Internal Use Only"
        style={{ top: 20 }}
        visible={internalUseModal}
        className="video__modal"
        onCancel={handleCancelInternalModal}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={handleCancelInternalModal}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            loading={internalUseModalLoading}
            type="primary"
            onClick={handleSubmitInternalData}
          >
            Submit
          </Button>,
        ]}
      >
        {showDealerInfo == true ? (
          <div className="p-4">
            <p>
              Vendor Name:{" "}
              {dealerInfo && dealerInfo.dealerName
                ? dealerInfo.dealerName
                : "NA"}
            </p>
            <p>
              {" "}
              Vendor Company :{" "}
              {dealerInfo && dealerInfo.dealerCompany
                ? dealerInfo.dealerCompany
                : "NA"}
            </p>
            <p>
              {" "}
              Vendor City/State :{" "}
              {(dealerInfo && dealerInfo.cityName) ||
              (dealerInfo && dealerInfo.stateName)
                ? dealerInfo.cityName + "/" + dealerInfo.stateName
                : "NA"}
            </p>
            <p>
              {" "}
              Vendor Phone Number:
              {dealerInfo && dealerInfo.dealerPhone
                ? dealerInfo.dealerPhone
                : "NA"}
            </p>
            <p>
              {" "}
              Vendor E-Mail:
              {dealerInfo && dealerInfo.dealerEmail
                ? dealerInfo.dealerEmail
                : "NA"}
            </p>
            <p>
              {" "}
              <label className="font__bold">
                {" "}
                Vendor lot number of the item:
              </label>{" "}
              {dealerInfo1 && dealerInfo1.dealerInventoryNo
                ? dealerInfo1.dealerInventoryNo
                : "NA"}
              {}
            </p>
            <p>
              {" "}
              <label className="font__bold"> WholeSale Price:</label>{" "}
              {dealerInfo1 && dealerInfo1.costperCarat
                ? dealerInfo1.costperCarat
                : "NA"}
            </p>
            {/* <p>
                  {" "}
                  <label className="font__bold"> Third Party:</label> {}
                </p> */}
            <p>
              {" "}
              <label className="font__bold"> Diamond ID:</label>{" "}
              {dealerInfo1 && dealerInfo1.dInventoryID
                ? dealerInfo1.dInventoryID
                : "NA"}
            </p>
            {/* <p>
                  {" "}
                  <label className="font__bold"> Seller Name:</label> {}
                </p> */}
            <p>
              <label className="font__bold"> Address: </label>{" "}
              {dealerInfo && dealerInfo.dealerAddress
                ? dealerInfo.dealerAddress
                : "NA"}
            </p>
          </div>
        ) : (
          <div className="form__fields">
            <div className="col-md-6">
              <div className="input__block">
                <label htmlFor="">
                  GFpassword <span className="mandatory">*</span>
                </label>
                <input
                  type="password"
                  value={internalUseData.password}
                  // onChange={(e) => setInternalUseData(e.target.value)}
                  onChange={(e) => handleSetInternalUseData(e)}
                />
                <div>
                  {internalUseDataValidation.passwordVal && (
                    <p className="error-color-red">
                      {internalUseDataValidation.passwordVal}
                    </p>
                  )}
                </div>
              </div>
              <span
                className="linkText"
                onClick={() =>
                  setInternalUseData({
                    password: "",
                  })
                }
              >
                Reset Password
              </span>
            </div>
          </div>
        )}
      </Modal>

      {/* For Internal Use Only Modal Ends */}

      {/* Modal Section Ends */}
    </React.Fragment>
  );
};

export default ViewDiamondDetails;
