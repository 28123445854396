import React, { useState, useEffect } from "react";

import RingIcon from "../assets/images/icons/ring__icon.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  handleActiveTab,
  handleSwapTabs,
  handleRemoveDiamondViewEdit,
  handleRemoveSettingViewEdit,
} from "../actions/header/headerActions";
import ChooseYourSetting from "../component/ApplicationPreview/ChooseYourSetting";
import SelectYourDiamond from "../component/ApplicationPreview/SelectYourDiamond";
import CompleteYourRing from "../component/ApplicationPreview/CompleteYourRing";
import { handleHideDetailsPage } from "../actions/chooseYourSetting/chooseYourSettingActions";
import {
  handleComaparePageIds,
  handleHideDetailsPage as handleHideDiamondDetailsPage,
} from "../actions/selectYourDiamond/selectYourDiamond";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import headerService from "../services/header.service";

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const currentTab = useSelector((state) => state.headerReducer);
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const paramSettingId = new URLSearchParams(search).get("settingId");
  const paramDiamindId = new URLSearchParams(search).get("diamondId");
  const paramSettingNumber = new URLSearchParams(search).get("settingNumber");
  const showHeader = new URLSearchParams(search).get("showHeader");

  const [activeTab, setActiveTab] = useState("");
  const [tabDetails, setTabDetails] = useState([]);
  const [tabSetting, setTabSetting] = useState(false);
  const [tabdiamond, setTabDiamond] = useState(false);
  const [ActiveSelectYourDiamond, setActiveSelectYourDiamond] = useState(false);
  const handleTabClick = (tab, index) => {
    debugger;
    dispatch(handleComaparePageIds(""));

    let tabDetailsArray = tabDetails;

    if (tab == "completeYourRing") {
      if (currentTab) {
        if (currentTab.isSettingViewEdit && currentTab.isDiamondViewEdit) {
          dispatch(handleActiveTab(tab));
        }
      }
    } else if (index == tabDetailsArray.length - 1) {
      let temp = tabDetailsArray[0];
      tabDetailsArray[0] = tabDetailsArray[1];
      tabDetailsArray[1] = temp;
      dispatch(handleSwapTabs(tabDetailsArray));
      dispatch(handleActiveTab(tab));
    } else {
      dispatch(handleActiveTab(tab));
    }
    if (tab == "chooseYourSetting") {
      navigate("/" + `?DealerLink=${paramdealerId}`); //View Setting
      localStorage.removeItem("mytime");
    } else if (tab == "selectYourDiamond") {
      navigate(
        "/SelectYourDiamond" + `?DealerLink=${paramdealerId}&isChange=True`
      ); // View Diamond
      localStorage.removeItem("mytime");
    }
  };

  useEffect(() => {
    if (currentTab) {
      if (currentTab.activeTab) {
        setActiveTab(currentTab.activeTab);
      }
      if (currentTab.tabDetails) {
        setTabDetails(currentTab.tabDetails);
      }
    }
  }, [currentTab]);

  useEffect(() => {
    debugger;
    var newparamSettingId = paramSettingId;
    console.log(newparamSettingId);

    if (paramSettingId == "null" || paramSettingId == null) {
      setTabSetting(false);
    } else {
      setTabSetting(true);
    }

    if (paramDiamindId == "null" || paramDiamindId == null) {
      setTabDiamond(false);
    } else {
      setTabDiamond(true);
    }
  }, [window.location.pathname]);
  var DiamondData = JSON.parse(localStorage.getItem("DiamondDetails"));
  var SettingData = JSON.parse(localStorage.getItem("SettingDetails"));

  //For Removing The item from local storage
  // localStorage.removeItem("mytime");
  const handleViewSetting = () => {
    ///Need to set page name
    debugger;
    if (paramDiamindId == "null" || paramDiamindId == null) {
      navigate(
        "/ChooseSettingItemDetails" +
          `?isSocialPage=True&DealerLink=${paramdealerId}&pageNo=1&isAllowed=True&settingId=${SettingData.settingID}&settingNumber=${SettingData.settingNumber}&headerTab=chooseYourSetting&childType="RingChildId"`
      );
      localStorage.removeItem("DiamondData");
    } else {
      navigate(
        "/ChooseSettingItemDetails" +
          `?isSocialPage=True&DealerLink=${paramdealerId}&pageNo=1&isAllowed=True&settingId=${SettingData.settingID}&settingNumber=${SettingData.settingNumber}&childType="RingChildId"&diamondId=${paramDiamindId}&pageName=${DiamondData.pageName}&headerTab=chooseYourSetting`
      );
    }
  };

  const handleEditSetting = () => {
    console.log(DiamondData);
    debugger;
    if (paramDiamindId == "null" || paramDiamindId == null) {
      navigate("/" + `?DealerLink=${paramdealerId}`);
      localStorage.removeItem("DiamondData");
    } else {
      navigate(
        "/" +
          `?DealerLink=${paramdealerId}&settingShape=${DiamondData.txtShape}&diamondId=${DiamondData.intDiamondId}&pageName=${DiamondData.pageName}&headerTab=chooseYourSetting`
      );
    }
  };

  const handleViewDiamond = () => {
    if (paramSettingId == "null" || paramSettingId == null) {
      navigate(
        "/viewDiamondDetails" +
          `?isSocialPage=True&DealerLink=${paramDiamindId}&diamondId=${DiamondData.intDiamondId}&pageNo=1&isAllowed=True&pageName=${DiamondData.pageName}&shapeFilters=${DiamondData.shapeFilters}&txtCutlet=${DiamondData.txtCulet}&txtClarity=${DiamondData.txtClarity}&intColorPriority=${DiamondData.intColorPriority}`
      );
      localStorage.removeItem("SettingData");
    } else {
      navigate(
        "/viewDiamondDetails" +
          `?isSocialPage=True&DealerLink=${paramdealerId}&diamondId=${DiamondData.intDiamondId}&pageNo=1&isAllowed=True&pageName=${DiamondData.pageName}&shapeFilters=${DiamondData.shapeFilters}&txtCutlet=${DiamondData.txtCulet}&txtClarity=${DiamondData.txtClarity}&intColorPriority=${DiamondData.intColorPriority}&settingId=${SettingData.settingID}&settingNumber=${paramSettingNumber}&headerTab=selectYourDiamond`
      );
    }
  };

  const handleEditDiamond = () => {
    console.log(DiamondData);
    if (paramSettingId == "null" || paramSettingId == null) {
      navigate(
        "/SelectYourDiamond" + `?DealerLink=${paramdealerId}&isChange=True`
      );
      localStorage.removeItem("SettingData");
    } else {
      navigate(
        "/SelectYourDiamond" +
          `?DealerLink=${paramdealerId}&setParamValues=True&settingShape=${SettingData.setting}&centerStoneMinimumCarat=${SettingData.centerStoneMinimumCarat}&centerStoneMaximumCarat=${SettingData.centerStoneMaximumCarat}&settingId=${SettingData.settingID}&isChange=True&settingNumber=${SettingData.settingNumber}&headerTab=selectYourDiamond`
      );
    }
  };
  // View Cart

  // const handleOpenNewLink = (url) => {
  //   window.open(url);
  // };

  // const search = useLocation().search;
  const headerTab = new URLSearchParams(search).get("headerTab");
  const SettingDiamondviewEdit = new URLSearchParams(search).get(
    "SettingDiamondviewEdit"
  );

  return (
    <>
      <div class="bcontainer">
        <div class="breadcrumb">
          {tabDetails &&
            tabDetails.map((tab, index) => {
              return (
                <li
                  className={headerTab == tab.tabValue && "active"}
                  onClick={() => handleTabClick(tab.tabValue, index)}
                >
                  <div className="left__block">
                    <span className="circle"> {index + 1}</span>
                    <div className="breadcrumb__content">
                      <p className="mb-0">{tab.tabInitial}</p>
                      <p className="subheading m-0">{tab.tabName}</p>
                    </div>
                  </div>

                  <div className="right__block">
                    {tab.tabValue == "chooseYourSetting" &&
                    tabSetting == true ? (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        {" "}
                        <div className="mr-1">
                          <span
                            onClick={() => handleViewSetting()}
                            className="linkText"
                          >
                            View
                          </span>
                          <span className="mx-3">|</span>
                          <span
                            onClick={() => handleEditSetting()}
                            className="linkText"
                          >
                            Edit
                          </span>
                        </div>
                      </div>
                    ) : null}

                    {tab.tabValue == "selectYourDiamond" &&
                    tabdiamond == true ? (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        {" "}
                        <div className="mr-1">
                          <span
                            onClick={handleViewDiamond}
                            className="linkText"
                          >
                            View
                          </span>
                          <span className="mx-3">|</span>
                          <span
                            onClick={handleEditDiamond}
                            className="linkText"
                          >
                            Edit
                          </span>
                        </div>
                      </div>
                    ) : null}

                    <img src={tab.imgSrc} />
                  </div>
                </li>
              );
            })}

          <li
            className={headerTab == "completeYourRing" && "active"}
            onClick={() => handleTabClick("completeYourRing")}
          >
            <div className="left__block">
              <span className="circle">3</span>
              <div className="breadcrumb__content">
                <p className="mb-0">Complete Your</p>
                <p className="subheading m-0">Ring</p>
              </div>
            </div>

            <div className="right__block">
              {/* {ViewOptionData.ShowViewCart == false && (
                <span
                  onClick={() =>
                    handleOpenNewLink(ViewOptionData.AltShoppingCart)
                  }
                  className="linkText mr-2 d-none"
                >
                  View Cart
                </span>
              )} */}
              <img src={RingIcon} />
            </div>
          </li>
        </div>
      </div>
      {/* {activeTab == "chooseYourSetting" && <ChooseYourSetting />} */}
      {showHeader == "True" && <SelectYourDiamond />}
      {/* {activeTab == "completeYourRing" && <CompleteYourRing />} */}
    </>
  );
};

export default Header;
