import React, { useState, useEffect } from "react";
import { Select, Button, Modal, TimePicker } from "antd";

import { Tabs, Tab } from "react-bootstrap";
// import MultiRangeSlider from "../../contacts/MultiRangeSlider"

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import LocationService from "../../services/select-your-diamond-location";
import drophint from "../../assets/images/choose-setting/drophint.png";
import calender from "../../assets/images/choose-setting/calender.svg";
import emailicon from "../../assets/images/choose-setting/emailicon.svg";
import infoblack from "../../assets/images/choose-setting/infoblack.svg";
import headerService from "../../services/header.service";
import leftArrow from "../../assets/images/choose-setting/left__arrow.png";
import rightArrow from "../../assets/images/choose-setting/right__arrow.png";

import shareBlue from "../../assets/images/choose-setting/share__blue.png";
import shareFacebook from "../../assets/images/choose-setting/shareIcons_02.png";
import sharePintrest from "../../assets/images/choose-setting/shareIcons_03.png";
import LikeFacebook from "../../assets/images/icons/facebook-like.jpg";
import shareTwitter from "../../assets/images/choose-setting/shareIcons_04.png";
import completeYourRing from "../../services/complete-your-ring.service";

import DtlFacebook from "../../assets/images/fb__icon.svg";
import DtlTwitter from "../../assets/images/twitter__icon.svg";
import DtlPintrest from "../../assets/images/pintrest__icon.svg";
import DtlInstagram from "../../assets/images/instragm__icon.svg";
import DtlGooglePlus from "../../assets/images/google__plus__icon.svg";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { useSelector } from "react-redux";
import SelectYourDiamondService from "../../services/select-your-diamond.service";
import itemDetailsService from "../../services/item-details.service";
import { useLocation } from "react-router-dom";
import colorPanelService from "../../services/color-panel.service";
import styled from "styled-components";
export const CompleteYourRing = () => {
  const { Option } = Select;
  const [dealerId, setDealerId] = useState(0);
  const [settingId, setSettingId] = useState("");
  const [settingItemDetailsData, setSettingItemDetailsData] = useState("");
  const [settingData, setSettingData] = useState("");
  const [diamondInfoDetails, setDiamondInfoDetails] = useState({});
  const [diamondDetails, setDiamondDetails] = useState();
  const [showDealerInfo, setShowDealerInfo] = useState();
  const [dealerInfo, setDealerInfo] = useState(false);
  const [internalUseModalLoading, setInternalUseModalLoading] = useState(false);
  const [internalUseModal, setInternalUseModal] = useState(false);
  const [internalUseData, setInternalUseData] = useState({
    password: "",
  });
  const [socialData, setSocialData] = useState({});
  const [showSocialLinks, setShowSocialLinks] = useState(false);

  const [images, setImages] = useState([]);

  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const chooseSettingDetails = useSelector(
    (state) => state.chooseYourSettingReducer
  );
  const Details = useSelector(
    (state) => state.selectYourDiamondReducer.selectedRow
  );
  const [locationData1, setLocationData1] = useState([]);
  // const images = [
  //   {
  //     original:
  //       "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
  //     thumbnail:
  //       "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
  //   },
  //   {
  //     original:
  //       "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
  //     thumbnail:
  //       "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
  //   },
  //   {
  //     original:
  //       "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.alt.jpg",
  //     thumbnail:
  //       "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.alt.jpg",
  //   },
  //   {
  //     original:
  //       "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.alt.jpg",
  //     thumbnail:
  //       "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.alt.jpg",
  //   },
  //   {
  //     original:
  //       "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
  //     thumbnail:
  //       "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.jpg",
  //   },
  //   {
  //     original:
  //       "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
  //     thumbnail:
  //       "https://www.gemfind.net/jewelryimages/2906/media/productimages/f1626.side.jpg",
  //   },
  // ];

  function renderLeftNav(onClick, disabled) {
    return (
      <button
        type="button"
        className="image-gallery-left-nav"
        aria-label="Prev Slide"
        disabled={disabled}
        onClick={onClick}
      >
        <img src={leftArrow} />
      </button>
    );
  }

  function renderRightNav(onClick, disabled) {
    return (
      <button
        type="button"
        className="image-gallery-right-nav"
        aria-label="Next Slide"
        disabled={disabled}
        onClick={onClick}
      >
        <img src={rightArrow} />
      </button>
    );
  }

  // Modal Functions
  const [requestMoreModal, setRequestMoreModal] = useState(false);
  const [showDropModal, setShowDropModal] = useState(false);
  const [emailFriendModal, setEmailFriendModal] = useState(false);
  const [isModalVisible4, setIsModalVisible4] = useState(false);
  const [scheduleViewing, setOpenScheduleViewing] = useState(false);
  const [isModalVisible6, setIsModalVisible6] = useState(false);
  const [isShareClicked, setIsShareClicked] = useState(false);
  const [dropHintInputData, setDropHintInputData] = useState({
    name: "",
    email: "",
    recipientName: "",
    recipientEmail: "",
    giftReason: "",
    giftDeadline: "",
    personalMessage: "",
  });

  const [requestMoreInfoInputData, setRequestMoreInfoInputData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    contactPreference: "byEmail",
    personalMessage: "",
  });
  const [emailFriendInputData, setEmailFriendInputData] = useState({
    name: "",
    email: "",
    friendName: "",
    friendEmail: "",
    personalMessage: "",
  });

  const [scheduleViewingInputData, setScheduleViewingInputData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    location: "",
    appointmentDate: "",
    appointmentTime: "",
    address: "",
    message: "",
  });

  const [requestMoreInfoValidation, setRequestMoreInfoValidation] = useState({
    nameVal: "",
    emailVal: "",
    phoneNumberVal: "",
    contactPreferenceVal: "",
    personalMessageVal: "",
  });

  const [emailFriendInputValidation, setEmailFriendInputDataValidation] =
    useState({
      nameVal: "",
      emailVal: "",
      friendNameVal: "",
      friendEmailVal: "",
      personalMessageVal: "",
    });
  const [dropHintValidation, setDropHintValidation] = useState({
    nameVal: "",
    emailVal: "",
    recipientNameVal: "",
    recipientEmailVal: "",
    giftReasonVal: "",
    giftDeadlineVal: "",
    personalMessageVal: "",
  });

  const [scheduleViewingInputValidation, setScheduleViewingInputValidation] =
    useState({
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      locationVal: "",
      appointmentDateVal: "",
      appointmentTimeVal: "",
      addressVal: "",
      messageVal: "",
    });

  //Modal Buttons Loading
  const [dropAHintLoading, setDropAHintLoading] = useState(false);
  const [requestMoreInfoLoading, setRequestMoreInfoLoading] = useState(false);
  const [emailAFriendLoading, setEmailAFriendLoading] = useState(false);
  const [scheduleViewingLoading, setScheduleViewingLoading] = useState(false);

  // Social links

  const search = useLocation().search;
  const isSocialPage = new URLSearchParams(search).get("isSocialPage");
  const isAllowed = new URLSearchParams(search).get("isAllowed");
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const socialpageNo = new URLSearchParams(search).get("pageNo");
  const socialpageName = new URLSearchParams(search).get("pageName");
  const paramSettingId = new URLSearchParams(search).get("settingId");
  const paramSettingNumber = new URLSearchParams(search).get("settingNumber");
  const paramDiamondId = new URLSearchParams(search).get("diamondId");
  const childType = new URLSearchParams(search).get("childType");

  useEffect(() => {
    //For Diamond Detais
    if (paramDiamondId) {
      setDiamondDetails(paramDiamondId);
      if (socialpageName == "Mined") {
        handleGetMinedDiamondDetailsData(paramdealerId, paramDiamondId);
      } else if (socialpageName == "Lab") {
        handleGetLabDiamondDetailsData(paramdealerId, paramDiamondId);
      }
    }
  }, []);

  useEffect(() => {
    //For Setting Detais
    // if (isSocialPage == "True") {
    handleGetItemDetails(paramdealerId, paramSettingId, paramSettingNumber);
    handleLocationLoadDetails(paramdealerId);
    handleGetOptionsDetails(paramdealerId);
    setDealerId(paramdealerId);
    setSettingId(paramSettingId);
    // } else {
    //   if (loginDetails) {
    //     if (loginDetails.dealerId) {
    //       if (chooseSettingDetails) {
    //         if (chooseSettingDetails.selectedRow) {
    //           if (chooseSettingDetails.selectedRow.settingID)
    //             handleGetItemDetails(
    //               loginDetails.dealerId,
    //               chooseSettingDetails.selectedRow.settingID,
    //               chooseSettingDetails.selectedRow.settingNumber
    //             );
    //           handleGetOptionsDetails(loginDetails.dealerId);
    //           setSettingId(chooseSettingDetails.selectedRow.settingID);
    //           handleLocationLoadDetails(loginDetails.dealerId);
    //         }
    //       }
    //       setDealerId(loginDetails.dealerId);
    //     }
    //   }
    // }
  }, []);

  const [ViewOptionData, setViewOptionData] = useState({
    RingBuilderUrl: "",
    InternalUseLink: true,
    RingSizeType: null,
    InitiallySortbox1: null,
    InitiallySortbox2: null,
    DefaultMetalType: null,
    ShowAdvancedSearch: true,
    RingsShow: true,
    ShowAddress: true,
    ShowCenterStone: true,
    ShowPrice: true,
    GSTTax: true,
    GSTTaxValue: "",
    PinterestShare: true,
    TwitterShare: true,
    FacebookShare: true,
    FacebookLike: true,
    GooglePlus: true,
    InstagramShare: true,
    AddToCart: true,
    AltShoppingCart: "",
    ShowBuySetting: true,
    ShowBuyDiamond: true,
    ShowViewCart: true,
  });

  const handleGetOptionsDetails = (id) => {
    let inputData = {
      dealerID: id,
      Type: "RingBuilder",
    };

    try {
      headerService
        .GetRingBuilderOptionDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          if (message == "Success") {
            if (responseData) {
              debugger;
              setViewOptionData((prevState) => ({
                ...prevState,
                RingBuilderUrl: responseData.ringBuilderURL,
                InternalUseLink: responseData.internalLinkRB,
                RingSizeType: responseData.ringSizeType,
                InitiallySortbox1: responseData.ringInitiallySort1,
                InitiallySortbox2: responseData.ringInitiallySortDesc,
                DefaultMetalType: responseData.defaultMetalType,
                ShowAdvancedSearch: responseData.showAdvanceRingBuilder,
                RingsShow: responseData.showScheduleView,
                ShowAddress: responseData.showAddresses,
                ShowCenterStone: responseData.showCenterStoneSize,
                ShowPrice: responseData.showPricesOnSearchPage,
                GSTTax: responseData.showGstPrice,
                GSTTaxValue: responseData.taxRate,
                PinterestShare: responseData.showPinterestShare,
                TwitterShare: responseData.showTwitterShare,
                FacebookShare: responseData.showFacebookShare,
                FacebookLike: responseData.showFacebookLike,
                GooglePlus: responseData.showGooglePlus,
                InstagramShare: responseData.showInstagramShare,
                AddToCart: responseData.showAddToCartButton,
                AltShoppingCart: responseData.shoppingCartURL,
                ShowBuySetting: responseData.showBuyWithoutCenterStone,
                ShowBuyDiamond: responseData.buyDiamondOnly,
                ShowViewCart: responseData.showViewCartButtonRB,
              }));
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const openRequestMoreModal = () => {
    setRequestMoreModal(true);
  };
  const closeRequestMoreModal = () => {
    setRequestMoreModal(false);
    setRequestMoreInfoInputData({
      name: "",
      email: "",
      phoneNumber: "",
      contactPreference: "",
      personalMessage: "",
    });
  };

  const showDropHintModal = () => {
    setShowDropModal(true);
  };
  const hideDropHintModal = () => {
    setShowDropModal(false);
    setDropHintInputData({
      name: "",
      email: "",
      recipientName: "",
      recipientEmail: "",
      giftReason: "",
      giftDeadline: "",
      personalMessage: "",
    });
  };

  const openEmailFriendModal = () => {
    setEmailFriendModal(true);
  };
  const closeEmailFriendModal = () => {
    setEmailFriendModal(false);
    setEmailFriendInputData({
      name: "",
      email: "",
      friendName: "",
      friendEmail: "",
      personalMessage: "",
    });
  };

  const showModal4 = () => {
    setIsModalVisible4(true);
  };
  const handleCancel4 = () => {
    setIsModalVisible4(false);
  };

  const openScheduleViewingModal = () => {
    handleLocationLoadDetails(dealerId);
    setOpenScheduleViewing(true);
  };
  const closeScheduleViewingModal = () => {
    setOpenScheduleViewing(false);
    setScheduleViewingInputData({
      name: "",
      email: "",
      phoneNumber: "",
      location: "",
      appointmentDate: "",
      appointmentTime: "",
      address: "",
      message: "",
    });
  };

  const showModal6 = () => {
    setIsModalVisible6(true);
  };
  const handleCancel6 = () => {
    setIsModalVisible6(false);
  };

  const handleDropHintChange = (e, name, type) => {
    setDropHintInputData({
      ...dropHintInputData,
      [name]: e.target.value,
    });
  };

  const handleSubmitDropHint = () => {
    const isValid = handleDropHintValidation();
    if (isValid == false) {
      return false;
    } else {
      handleSaveDropHint();
    }
  };

  const handleRequestMoreInfoChange = (e, name, type) => {
    setRequestMoreInfoInputData({
      ...requestMoreInfoInputData,
      [name]: e.target.value,
    });
    if (name == "name") {
      setRequestMoreInfoValidation((prevdata) => ({
        ...prevdata,
        nameVal: "",
      }));
    }
    if (name == "email") {
      setRequestMoreInfoValidation((prevdata) => ({
        ...prevdata,
        emailVal: "",
      }));
    }
    if (name == "phoneNumber") {
      setRequestMoreInfoValidation((prevdata) => ({
        ...prevdata,
        phoneNumberVal: "",
      }));
    }
    if (name == "personalMessage") {
      setRequestMoreInfoValidation((prevdata) => ({
        ...prevdata,
        personalMessageVal: "",
      }));
    }
  };

  const handleSubmitRequestMoreInfo = () => {
    const isValid = handleRequestMoreInfoValidation();
    if (isValid == false) {
      return false;
    } else {
      handleSaveRequestMoreInfo();
    }
  };

  const handleEmailFriendChange = (e, name) => {
    setEmailFriendInputData({
      ...emailFriendInputData,
      [name]: e.target.value,
    });
  };

  const handleSubmitEmailFriend = () => {
    const isValid = handleEmailToFriendValidation();
    if (isValid == false) {
      return false;
    } else {
      handleSaveEmailFriend();
    }
  };

  const handleScheduleViewingChange = (e, name, type) => {
    if (type == "select") {
      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e,
      });
    } else {
      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e.target.value,
      });
    }
  };

  const handleSubmitSheduleViewing = () => {
    const isValid = handleScheduleviewingValidation();
    if (isValid == false) {
      return false;
    } else {
      handleSaveScheduleViewing();
    }
  };

  const handleShareClick = () => {
    setIsShareClicked(!isShareClicked);
  };

  const handleSaveDropHint = async () => {
    let inputData = {
      sid: settingItemDetailsData.settingID.toString(),
      dealerLink: dealerId.toString(),
      tmpCustID: "",
      orderBy: "",
      yourName: dropHintInputData.name,
      hintRecptName: dropHintInputData.recipientName,
      hintRecptMessage: dropHintInputData.personalMessage,
      productType: "",
      toolType: "",
      yourEmail: dropHintInputData.email,
      hintRecptEmail: dropHintInputData.recipientEmail,
      hintReasoneForGift: dropHintInputData.giftReason,
      hintGiftDeadLine: dropHintInputData.giftDeadline,
    };
    setDropAHintLoading(true);

    try {
      completeYourRing
        .SaveDropHint(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            console.log(responseData);
            NotificationManager.success("Mail has been sent successfully.");

            hideDropHintModal();
            setDropAHintLoading(false);
          } else {
            NotificationManager.error("Mail has not been sent.");
            setDropAHintLoading(false);
          }
        })
        .catch((error) => {
          NotificationManager.error("Sorry we had an error");

          console.log(error);
          setDropAHintLoading(false);
        });
    } catch (error) {
      NotificationManager.error("Sorry we had an error");

      console.log(error);
      setDropAHintLoading(false);
    }
  };

  const handleSaveRequestMoreInfo = async () => {
    let inputData = {
      did: diamondInfoDetails.intDiamondId.toString(),
      diamondVendorId: diamondInfoDetails.dealerID.toString(),
      sid: settingItemDetailsData.settingID.toString(),
      settingVendorId: settingItemDetailsData.vendorID.toString(),
      productID: "",
      dealerID: dealerId.toString(),
      dealerEmail: "",
      customerId: "",
      customerName: requestMoreInfoInputData.name,
      customerEmail: requestMoreInfoInputData.email,
      customerPhone: requestMoreInfoInputData.phoneNumber,
      customerMessage: requestMoreInfoInputData.personalMessage,
      customerPreference: requestMoreInfoInputData.contactPreference,
      productType: "",
      toolType: "",
      isEmail: "",
      isDiamond: true,
      isSetting: true,
    };

    setRequestMoreInfoLoading(true);

    try {
      completeYourRing
        .SaveRequestInfo(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            console.log(responseData);
            NotificationManager.success("Mail has been sent successfully.");
            closeRequestMoreModal();
            setRequestMoreInfoLoading(false);
          } else {
            NotificationManager.error("Mail has not been sent.");
            setRequestMoreInfoLoading(false);
          }
        })
        .catch((error) => {
          NotificationManager.error("Sorry we had an error");
          setRequestMoreInfoLoading(false);

          console.log(error);
        });
    } catch (error) {
      NotificationManager.error("Sorry we had an error");
      setRequestMoreInfoLoading(false);

      console.log(error);
    }
  };

  const handleSaveEmailFriend = async () => {
    // let inputData = {
    //   sid: settingId,
    //   productID: "",
    //   dealerID: dealerId.toString(),
    //   customerId: "",
    //   customerName: emailFriendInputData.name,
    //   customerEmail: emailFriendInputData.email,
    //   friendName: emailFriendInputData.friendName,
    //   friendEmail: emailFriendInputData.friendEmail,
    //   customerMessage: emailFriendInputData.personalMessage,
    //   productType: "",
    //   toolType: "",
    // };
    let inputData = {
      did: diamondInfoDetails.intDiamondId.toString(),
      diamondVendorId: diamondInfoDetails.dealerID.toString(),
      sid: settingItemDetailsData.settingID.toString(),
      settingVendorId: settingItemDetailsData.vendorID.toString(),
      productID: "",
      dealerID: dealerId.toString(),
      customerId: "",
      customerName: emailFriendInputData.name,
      customerEmail: emailFriendInputData.email,
      friendName: emailFriendInputData.friendName,
      friendEmail: emailFriendInputData.friendEmail,
      customerMessage: emailFriendInputData.personalMessage,
      productType: "",
      toolType: "",
      isEmail: "",
      dealerEmail: "",
      isDiamond: true,
      isSetting: true,
    };

    setEmailAFriendLoading(true);

    try {
      completeYourRing
        .SaveEmailAFriend(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            NotificationManager.success("Mail has been sent successfully.");
            closeEmailFriendModal();
            setEmailAFriendLoading(false);
          } else {
            NotificationManager.error("Mail has not been sent.");
            setEmailAFriendLoading(false);
          }
        })
        .catch((error) => {
          NotificationManager.error("Sorry we had an error");
          setEmailAFriendLoading(false);

          console.log(error);
        });
    } catch (error) {
      NotificationManager.error("Sorry we had an error");
      setEmailAFriendLoading(false);

      console.log(error);
    }
  };

  const handleSaveScheduleViewing = async () => {
    let inputData = {
      did: diamondInfoDetails.intDiamondId.toString(),
      diamondVendorId: diamondInfoDetails.dealerID.toString(),
      sid: settingItemDetailsData.settingID.toString(),
      settingVendorId: settingItemDetailsData.vendorID.toString(),
      dealerID: dealerId.toString(),
      customerId: "",
      name: scheduleViewingInputData.name,
      email: scheduleViewingInputData.email,
      phone: scheduleViewingInputData.phoneNumber,
      customerMessage: scheduleViewingInputData.message,
      appType: "",
      appDate: scheduleViewingInputData.appointmentDate,
      appTime: scheduleViewingInputData.appointmentTime,
      productids: "",
      productType: "",
      toolType: "",
      isDiamond: true,
      isSetting: true,
    };

    setScheduleViewingLoading(true);

    try {
      completeYourRing
        .SaveScheduleAViewing(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            console.log(responseData);
            NotificationManager.success(" Mail has been sent successfully.");

            closeScheduleViewingModal();
            setScheduleViewingLoading(false);
          } else {
            NotificationManager.error("Mail has not been sent.");
            setScheduleViewingLoading(false);
          }
        })
        .catch((error) => {
          NotificationManager.error("Could Not Add Record");
          console.log(error);
          setScheduleViewingLoading(false);
        });
    } catch (error) {
      NotificationManager.error("Sorry we had an error");
      console.log(error);
      setScheduleViewingLoading(false);
    }
  };

  const handleGetItemDetails = async (id, settingId, settingNumber) => {
    debugger;
    let inputData = {
      gfInventoryID: settingId.toString(),
      dealerLink: id.toString(),
      metalType: "",
      metalColor: "",
      styleNumber: settingNumber.toString(),
      url: "",
      qurShape: "",
      qurCTW: "",
      themeName: "",
      qurDID: "",
      bindWhat: "",
      countryTaxRate: "",
      gemstoneQuality: "",
      gemstoneSize: "",
      caratMin: "",
      gemstoneShape: "",
      type: childType == "null" || childType == null ? "" : "RingChildId",
    };

    try {
      await completeYourRing
        .GetAppPrevDataonSelectYourSetting(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData =
            response.data.responseData
              .getAppPrevDataonSelectYourSettingClass[0];
          if (msg == "Success") {
            if (responseData) {
              localStorage.setItem(
                "SettingDetails",
                JSON.stringify(responseData)
              );
              let newImages = images;
              let obj1 = {
                original: responseData.additionalImageURL1,
                thumbnail: responseData.additionalImageURL1,
              };
              let obj2 = {
                original: responseData.additionalImageURL2,
                thumbnail: responseData.additionalImageURL2,
              };
              let obj3 = {
                original: responseData.additionalImageURL3,
                thumbnail: responseData.additionalImageURL3,
              };
              let obj4 = {
                original: responseData.additionalImageURL4,
                thumbnail: responseData.additionalImageURL4,
              };
              let obj5 = {
                original: responseData.additionalImageURL5,
                thumbnail: responseData.additionalImageURL5,
              };
              let obj6 = {
                original: responseData.additionalImageURL6,
                thumbnail: responseData.additionalImageURL6,
              };
              newImages.push(obj1);
              newImages.push(obj2);
              newImages.push(obj3);
              newImages.push(obj4);
              newImages.push(obj5);
              newImages.push(obj6);
              setImages(newImages);
              setSettingItemDetailsData(responseData);

              setSettingData(responseData.setting);
              handleGetSocialData(id, settingId);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetMinedDiamondDetailsData = (id, diamondId) => {
    try {
      let inputData = {
        dealerId: id,
        strCheckFilter: "Filter",
        shapeList: "",
        priceMin: "0",
        priceMax: "100000",
        caratMin: "",
        caratMax: "",
        colorMax: "",
        colorMin: "",
        clarityMin: "",
        clarityMax: "",
        cutGradeMin: "",
        cutGradeMax: "",
        symmetryMin: "",
        symmetryMax: "",
        symmetryList: "",
        polishMin: "",
        polishMax: "",
        polishList: "",
        fluorescenceMin: "",
        fluorescenceMax: "",
        fluorescenceList: "",
        certificate: "",
        skuNo: diamondId,
        pairCode: "",
        detailsLink: "",
        did: "",
        depthMin: "",
        depthMax: "",
        tableMin: "",
        tableMax: "",
        caratPriceMin: "",
        caratPriceMax: "",
        themeName: "",
        showPrice: "",
        bindWhat: "",
        cutGradeList: "",
        colorList: "",
        clarityList: "",
        bindCheckbox: "",
        sOrigin: "",
        isDiamond: "",
        custID: "",
        countryTaxRate: "",
        countryCode: "",
        track: "",
        isFB: "",
        isMLRB: "",
        settingCenterStonCaratMin: "",
        settingCenterStonCaratMax: "",
        selectedDealerConunt: 0,
        strSelectedDealers: "",
        extraQStr: "",
        pageSize: "10",
        pageNumber: "1",
        orderBy: "FltPrice",
        orderType: "ASC",
        strDInventoryId: "",
        flagDD: "",
        ratioMin: "",
        ratioMax: "",
        invenID: "",
        fancyColor: "",
        intIntensity: 0,
        overtone: "",
        overtone2: "",
        country: "",
        shade: "",
        treatment: "",
        keytoSymbol: "",
        fluorescenceColor: "",
        blackInclusion: "",
        centralInclusion: "",
        milkyInclusion: "",
        eyeclean: "",
        ratioType: "",
        permission: "",
        video: "",
        strCertSolrQuery: "",
        strDetailLinkURL: "",
        pageName: "",
      };

      // setLoading(true);
      SelectYourDiamondService.GetRingBuilderSelectMinedDiamond(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];

          if (message == "Success") {
            if (responseData) {
              setDiamondInfoDetails(responseData);
              let DiamondData = responseData;
              DiamondData["pageName"] = socialpageName;
              localStorage.setItem(
                "DiamondDetails",
                JSON.stringify(DiamondData)
              );

              // setLoading(false);
            } else {
              // setLoading(false);
            }
          } else {
            // setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
        });
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };

  const handleGetLabDiamondDetailsData = (id, diamondId) => {
    try {
      let inputData = {
        dealerID: id,
        strCheckFilter: "Filter",
        shapes: "",
        colorMin: "",
        colorMax: "",
        clarityMin: "",
        clarityMax: "",
        caratMin: "",
        caratMax: "",
        priceMin: "0",
        priceMax: "10000",
        certificate: "",
        cutGradeMin: "",
        cutGradeMax: "",
        symmetryMin: "",
        symmetryMax: "",
        polishMin: "",
        polishMax: "",
        depthMin: "",
        depthMax: "",
        tableMin: "",
        tableMax: "",
        fluorescenceMin: "",
        fluorescenceMax: "",
        pairCode: "",
        orderBy: "FltPrice",
        orderType: "ASC",
        pageNumber: "1",
        pageSize: "10",
        invenID: "",
        strDetailLinkURL: "",
        did: "",
        caratPriceMin: "",
        caratPriceMax: "",
        polishList: "",
        symmetryList: "",
        fluorescenceList: "",
        cutGradeList: "",
        colorList: "",
        clarityList: "",
        sOrigin: "",
        countryTaxRate: "",
        SkuNo: diamondId,
      };

      // setLoading(true);
      SelectYourDiamondService.GetRingBuilderSelectLabDiamond(inputData)

        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];

          if (message == "Success") {
            if (responseData) {
              setDiamondInfoDetails(responseData);
              let DiamondData = responseData;
              DiamondData["pageName"] = socialpageName;
              localStorage.setItem(
                "DiamondDetails",
                JSON.stringify(DiamondData)
              );

              // setLoading(false);
            } else {
              // setLoading(false);
            }
          } else {
            // setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
        });
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };

  const handleOpenInternalModal = () => {
    setInternalUseModal(true);
  };

  const handleSubmitInternalData = () => {
    console.log(internalUseData);
    handleGetDealerInformation();
    setInternalUseData({
      password: "",
    });
  };

  const handleGetDealerInformation = () => {
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        diamondDealerID: diamondInfoDetails.dealerID,
        password: internalUseData.password,
        diamondID: diamondDetails,
      };
      setInternalUseModalLoading(true);
      SelectYourDiamondService.GetDealerInformation(inputData)
        .then((response) => {
          var message = response.data.responseData.passwordcheck;
          var dealerInfo = response.data.responseData.getDealerInformation;
          if (message == false) {
            NotificationManager.error("Kindly Enter valid Details");
            setInternalUseModalLoading(false);
            setShowDealerInfo(false);
          } else {
            setShowDealerInfo(true);
            setDealerInfo(dealerInfo);
            setInternalUseModalLoading(false);
            console.log(dealerInfo);
          }
        })
        .catch((error) => {
          console.log(error);
          setInternalUseModalLoading(false);
        });
    } catch (error) {
      console.log(error);
      setInternalUseModalLoading(false);
    }
  };

  const handleCancelInternalModal = () => {
    setInternalUseModal(false);
    setShowDealerInfo(false);
    setInternalUseData({
      password: "",
    });
  };

  const handleDropHintValidation = () => {
    const {
      name,
      email,
      recipientName,
      recipientEmail,
      giftReason,
      giftDeadline,
      personalMessage,
    } = dropHintInputData;
    const dropHintValidation = {
      nameVal: "",
      emailVal: "",
      recipientNameVal: "",
      recipientEmailVal: "",
      giftReasonVal: "",
      giftDeadlineVal: "",
      personalMessageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      dropHintValidation.nameVal = "FirstName is Compulsory";
    }
    if (!recipientName.trim()) {
      isValid = false;
      dropHintValidation.recipientNameVal = "RecipentName is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email.trim()) {
      isValid = false;
      dropHintValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      dropHintValidation.emailVal = "Email address is invalid";
    } else {
      dropHintValidation.emailVal = "";
    }

    if (!recipientEmail.trim()) {
      isValid = false;
      dropHintValidation.recipientEmailVal = "Email address is compulsory";
    } else if (!recipientEmail.match(validRegex)) {
      isValid = false;
      dropHintValidation.recipientEmailVal = "Email address is invalid";
    } else {
      dropHintValidation.recipientEmailVal = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      dropHintValidation.personalMessageVal = "Personal Message is Compulsory";
    }
    if (!giftReason.trim()) {
      isValid = false;
      dropHintValidation.giftReasonVal = "Gift Reason is Compulsory";
    }
    if (!giftDeadline.trim()) {
      isValid = false;
      dropHintValidation.giftDeadlineVal = "Gift Deadline is Compulsory";
    }

    // if (!isValid) {
    //   setDropHintValidation(dropHintValidation);
    // }

    setDropHintValidation(dropHintValidation);

    return isValid;
  };

  const handleRequestMoreInfoValidation = () => {
    const { name, email, phoneNumber, contactPreference, personalMessage } =
      requestMoreInfoInputData;
    const requestMoreInfoValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      contactPreferenceVal: "",
      personalMessageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      requestMoreInfoValidation.nameVal = "FirstName is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email) {
      isValid = false;
      requestMoreInfoValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      requestMoreInfoValidation.emailVal = "Email address is invalid";
    } else {
      requestMoreInfoValidation.emailVal = "";
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      requestMoreInfoValidation.phoneNumberVal = "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      requestMoreInfoValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      requestMoreInfoValidation.phoneNumberVal = "Phone number is invalid";
    } else {
      requestMoreInfoValidation.phoneNumberVal = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      requestMoreInfoValidation.personalMessageVal =
        "Personal Message is Compulsory";
    }

    // if (!isValid) {
    //   setRequestMoreInfoValidation(requestMoreInfoValidation);
    // }

    setRequestMoreInfoValidation(requestMoreInfoValidation);

    return isValid;
  };

  const handleEmailToFriendValidation = () => {
    const { name, email, friendName, friendEmail, personalMessage } =
      emailFriendInputData;
    const emailFriendInputValidation = {
      nameVal: "",
      emailVal: "",
      friendNameVal: "",
      friendEmailVal: "",
      personalMessageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      emailFriendInputValidation.nameVal = "Name is Compulsory";
    }

    if (!friendName.trim()) {
      isValid = false;
      emailFriendInputValidation.friendNameVal = "Friend Name is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email.trim()) {
      isValid = false;
      emailFriendInputValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      emailFriendInputValidation.emailVal = "Email address is invalid";
    } else {
      emailFriendInputValidation.emailVal = "";
    }

    if (!friendEmail.trim()) {
      isValid = false;
      emailFriendInputValidation.friendEmailVal = "Email address is compulsory";
    } else if (!friendEmail.match(validRegex)) {
      isValid = false;
      emailFriendInputValidation.friendEmailVal = "Email address is invalid";
    } else {
      emailFriendInputValidation.friendEmailVal = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      emailFriendInputValidation.personalMessageVal =
        "Personal Message is Compulsory";
    }

    // if (!isValid) {
    //   setEmailFriendInputDataValidation(emailFriendInputValidation);
    // }

    setEmailFriendInputDataValidation(emailFriendInputValidation);

    return isValid;
  };

  const handleScheduleviewingValidation = () => {
    const {
      name,
      email,
      phoneNumber,
      location,
      appointmentDate,
      appointmentTime,
      address,
      message,
    } = scheduleViewingInputData;
    const scheduleViewingInputValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      locationVal: "",
      appointmentDateVal: "",
      appointmentTimeVal: "",
      addressVal: "",
      messageVal: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      scheduleViewingInputValidation.nameVal = "Name is Compulsory";
    }

    if (!location) {
      isValid = false;
      scheduleViewingInputValidation.locationVal = "Location is Compulsory";
    }

    if (!appointmentDate) {
      isValid = false;
      scheduleViewingInputValidation.appointmentDateVal =
        "Appointment Date is Compulsory";
    }

    if (!appointmentTime) {
      isValid = false;
      scheduleViewingInputValidation.appointmentTimeVal =
        "Appointment Time is Compulsory";
    }

    if (!address) {
      isValid = false;
      scheduleViewingInputValidation.addressVal = "Address is Compulsory";
    }

    if (!message) {
      isValid = false;
      scheduleViewingInputValidation.messageVal = "Message is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email) {
      isValid = false;
      scheduleViewingInputValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      scheduleViewingInputValidation.emailVal = "Email address is invalid";
    } else {
      scheduleViewingInputValidation.emailVal = "";
    }
    if (!phoneNumber) {
      isValid = false;
      scheduleViewingInputValidation.phoneNumberVal =
        "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      scheduleViewingInputValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      scheduleViewingInputValidation.phoneNumberVal = "Phone number is invalid";
    } else {
      scheduleViewingInputValidation.phoneNumberVal = "";
    }

    // if (!isValid) {
    //   setScheduleViewingInputValidation(scheduleViewingInputValidation);
    // }

    setScheduleViewingInputValidation(scheduleViewingInputValidation);

    return isValid;
  };

  const handleShowSocialLinks = () => {
    setShowSocialLinks(!showSocialLinks);
  };

  const handleGetSocialData = async (id, sId) => {
    let inputData = {
      vDealerId: id.toString(),
      sid: sId.toString(),
      settingName: "",
      tweeterURL: "",
      imagePath: "",
    };

    try {
      itemDetailsService
        .GetSocialData(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            if (responseData) {
              setSocialData(responseData);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAppointmentTimeChange = (time, timeString) => {
    setScheduleViewingInputData({
      ...scheduleViewingInputData,
      appointmentTime: timeString,
    });
  };

  // Date
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  // Location

  const handleLocationLoadDetails = (id) => {
    try {
      let inputData = {
        dealerID: id,
      };

      LocationService.LocationLoadDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.Table;

          if (message == "Success") {
            if (responseData) {
              setLocationData1(responseData);
            } else {
              setLocationData1([]);
            }
          } else {
            setLocationData1([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddToCart = (url) => {
    window.open(url, "_blank");
  };

  const handleOpenSocialLink = (url) => {
    window.open(url);
  };

  const handleSocialRedirect = (url) => {
    window.open(url);
  };

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [wrapperLoading, setWrapperLoading] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [toggle, setToggle] = useState(true);
  useEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "RingBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              var tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              debugger;
              console.log(tempobj);
              console.log(initialColorObj);
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
              // dispatch(handleSwitchChange());
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  /* Color Individually Ends */
  return (
    <React.Fragment>
      <Wrapper>
        <div className="allprod_section jwlsrch__section app__preview choose__setting">
          <div className="row">
            <div className="col-md-12">
              <div className="prodetail__section">
                <div className="form__fields border-0">
                  <div className="col-md-12">
                    <div className="prod__maindiv app__preview p-0 border-0">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="row">
                            {/* <div className="col-lg-12">
                            <div className="">
                              <p>Available Shape :</p>
                            </div>
                            <div className="whole__shapes available__shapes">
                              <div className="shapes__block">
                                <div className="image">
                                  <img src={Round} />
                                </div>
                                <p>Round</p>
                              </div>
                              <div className="shapes__block">
                                <div className="image">
                                  <img src={Radiant} />
                                </div>
                                <p>Radiant</p>
                              </div>
                              <div className="shapes__block">
                                <div className="image">
                                  <img src={Princess} />
                                </div>
                                <p>Princess</p>
                              </div>
                              <div className="shapes__block">
                                <div className="image">
                                  <img src={Pear} />
                                </div>
                                <p>Pear</p>
                              </div>
                              <div className="shapes__block">
                                <div className="image">
                                  <img src={Oval} />
                                </div>
                                <p>Oval</p>
                              </div>
                              <div className="shapes__block">
                                <div className="image">
                                  <img src={Heart} />
                                </div>
                                <p>Heart</p>
                              </div>
                              <div className="shapes__block">
                                  <div className="image">
                                      <img src={Emerald} />
                                  </div>
                                  <p>Emerald</p>
                              </div>
                              <div className="shapes__block">
                                  <div className="image">
                                      <img src={Cushion} />
                                  </div>
                                  <p>Cushion</p>
                              </div>
                              <div className="shapes__block selected">
                                  <div className="image">
                                      <img src={Asscher} />
                                  </div>
                                  <p>Asscher</p>
                              </div>
                            </div>
                          </div> */}

                            <div className="col-lg-12">
                              <div className="thimage__slider">
                                <ImageGallery
                                  items={images}
                                  showFullscreenButton={false}
                                  renderLeftNav={renderLeftNav}
                                  renderRightNav={renderRightNav}
                                />
                                <div
                                  className="video__icon"
                                  onClick={showModal6}
                                >
                                  <i
                                    class="fa fa-video-camera"
                                    aria-hidden="true"
                                  ></i>
                                </div>

                                <div className="share__block display-none">
                                  <div
                                    className="share__button"
                                    onClick={handleShowSocialLinks}
                                  >
                                    <img src={shareBlue} />
                                  </div>
                                  <div
                                    className={
                                      showSocialLinks
                                        ? "social__button__enabled"
                                        : "social__button"
                                    }
                                  >
                                    {ViewOptionData.FacebookShare == true && (
                                      <img
                                        src={shareFacebook}
                                        onClick={() =>
                                          handleSocialRedirect(socialData.fburl)
                                        }
                                      />
                                    )}

                                    {ViewOptionData.PinterestShare == true && (
                                      <img
                                        src={sharePintrest}
                                        onClick={() =>
                                          handleSocialRedirect(
                                            socialData.pinInterestURL
                                          )
                                        }
                                      />
                                    )}

                                    {ViewOptionData.FacebookLike == true && (
                                      <img
                                        src={LikeFacebook}
                                        onClick={() =>
                                          handleSocialRedirect(
                                            socialData.tweeterURL
                                          )
                                        }
                                      />
                                    )}

                                    {ViewOptionData.TwitterShare == true && (
                                      <img
                                        src={shareTwitter}
                                        onClick={() =>
                                          handleSocialRedirect(
                                            socialData.tweeterURL
                                          )
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="price__block text-center">
                                <h4 className="subheading">
                                  {ViewOptionData.ShowPrice == false ? (
                                    "Call For Price"
                                  ) : (
                                    <>
                                      {diamondInfoDetails.realPrice ? (
                                        diamondInfoDetails.realPrice == "0" ? (
                                          "Call For Price"
                                        ) : (
                                          <>
                                            US$
                                            {parseInt(
                                              diamondInfoDetails.realPrice
                                            )
                                              .toFixed()
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                          </>
                                        )
                                      ) : (
                                        "NA"
                                      )}
                                    </>
                                  )}
                                  {/* US $
                                  {Number(settingItemDetailsData.retailPrice) +
                                    Number(diamondInfoDetails.realPrice)} */}
                                </h4>
                                {ViewOptionData.AddToCart == true && (
                                  <Button
                                    onClick={() =>
                                      handleAddToCart(
                                        settingItemDetailsData.shoppingCartURL
                                      )
                                    }
                                    className={
                                      diamondInfoDetails.realPrice == "0"
                                        ? "display-none"
                                        : "primary-btn"
                                    }
                                  >
                                    Add To Cart
                                  </Button>
                                )}
                              </div>
                            </div>
                            {ViewOptionData.InternalUseLink == true && (
                              <span
                                className="font__bold mr-2 mt-3 linkText"
                                onClick={handleOpenInternalModal}
                              >
                                For Internal Use Only
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="prodesc__div">
                            <Tabs
                              defaultActiveKey="home"
                              id="uncontrolled-tab-example"
                              className="tab__div border-0"
                            >
                              <Tab eventKey="home" title="Items Details">
                                <div className="tab__contentdiv border__block p-0">
                                  <div className="head__div">
                                    <h4>
                                      {settingItemDetailsData.settingName}{" "}
                                      <span>
                                        SKU#{" "}
                                        {settingItemDetailsData.settingNumber}
                                      </span>
                                    </h4>
                                  </div>
                                  <div className="desc__maindiv">
                                    <div className="row top__content m-0">
                                      <div className="col-md-12 mt-3">
                                        <p>
                                          {settingItemDetailsData.description}
                                        </p>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="detailblk">
                                          <p className="font__bold">
                                            Ring Size
                                          </p>
                                          <p>3</p>
                                        </div>
                                      </div>

                                      <div className="col-md-3">
                                        <div className="detailblk">
                                          <p className="font__bold">
                                            Metal Type
                                          </p>
                                          <p>{settingItemDetailsData.metal}</p>
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="detailblk">
                                          <p className="font__bold">
                                            Setting:{" "}
                                            <span className="subheading">
                                              {ViewOptionData.ShowPrice ==
                                              false ? (
                                                "Call For Price"
                                              ) : (
                                                <>
                                                  {diamondInfoDetails.price ? (
                                                    diamondInfoDetails.price ==
                                                    "0" ? (
                                                      "Call For Price"
                                                    ) : (
                                                      <>
                                                        US$
                                                        {parseInt(
                                                          diamondInfoDetails.price
                                                        )
                                                          .toFixed()
                                                          .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                          )}
                                                      </>
                                                    )
                                                  ) : (
                                                    "NA"
                                                  )}
                                                </>
                                              )}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="head__div">
                                    <h4>
                                      {diamondInfoDetails.fltCarat
                                        ? diamondInfoDetails.fltCarat
                                        : "NA"}
                                      -Carat{" "}
                                      {diamondInfoDetails.txtShape
                                        ? diamondInfoDetails.txtShape
                                        : "NA"}{" "}
                                      Diamond SKU#{" "}
                                      {diamondInfoDetails.intDiamondId
                                        ? diamondInfoDetails.intDiamondId
                                        : "NA"}
                                    </h4>
                                  </div>
                                  <div className="desc__maindiv">
                                    <div className="row top__content m-0">
                                      <div className="col-md-12 mt-3">
                                        <p className="font__bold">
                                          Contact us for best diamond option
                                          available.
                                        </p>
                                        <p>
                                          This{" "}
                                          {diamondInfoDetails.txtCutGrade
                                            ? diamondInfoDetails.txtCutGrade
                                            : "NA"}{" "}
                                          cut,{" "}
                                          {diamondInfoDetails.txtColor
                                            ? diamondInfoDetails.txtColor
                                            : "NA"}
                                          -color and
                                          {diamondInfoDetails.txtClarity
                                            ? diamondInfoDetails.txtClarity
                                            : "NA"}
                                          -Clarity diamond comes accompanied by
                                          diamond grading report from{" "}
                                          {diamondInfoDetails.txtCertificate
                                            ? diamondInfoDetails.txtCertificate
                                            : "NA"}
                                          .
                                        </p>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="detailblk">
                                          <p className="font__bold">Report</p>
                                          <p>
                                            {" "}
                                            {diamondInfoDetails.txtCertificate
                                              ? diamondInfoDetails.txtCertificate
                                              : "NA"}
                                          </p>
                                        </div>
                                      </div>

                                      <div className="col-md-3">
                                        <div className="detailblk">
                                          <p className="font__bold">Cut</p>
                                          <p>
                                            {diamondInfoDetails.txtCutGrade
                                              ? diamondInfoDetails.txtCutGrade
                                              : "NA"}
                                          </p>
                                        </div>
                                      </div>

                                      <div className="col-md-3">
                                        <div className="detailblk">
                                          <p className="font__bold">Color</p>
                                          <p>
                                            {diamondInfoDetails.txtColor
                                              ? diamondInfoDetails.txtColor
                                              : "NA"}
                                          </p>
                                        </div>
                                      </div>

                                      <div className="col-md-3">
                                        <div className="detailblk">
                                          <p className="font__bold">Clarity</p>
                                          <p>
                                            {diamondInfoDetails.txtClarity
                                              ? diamondInfoDetails.txtClarity
                                              : "NA"}
                                          </p>
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="detailblk">
                                          <p className="font__bold">
                                            Diamond:{" "}
                                            <span className="subheading">
                                              {ViewOptionData.ShowPrice ==
                                              false ? (
                                                "Call For Price"
                                              ) : (
                                                <>
                                                  {diamondInfoDetails.price ? (
                                                    diamondInfoDetails.price ==
                                                    "0" ? (
                                                      "Call For Price"
                                                    ) : (
                                                      <>
                                                        US$
                                                        {parseInt(
                                                          diamondInfoDetails.price
                                                        )
                                                          .toFixed()
                                                          .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                          )}
                                                      </>
                                                    )
                                                  ) : (
                                                    "NA"
                                                  )}
                                                </>
                                              )}
                                            </span>
                                          </p>
                                        </div>
                                      </div>

                                      <div className="itemdetails__btns p-0">
                                        <div className="col-4">
                                          <p onClick={showDropHintModal}>
                                            <span className="img__box">
                                              <img src={drophint} />
                                            </span>
                                            <span>Drop A Hint</span>
                                          </p>
                                        </div>

                                        {/* <p onClick={showDropHintModal}>
                                          <img src={drophint} />
                                          <span>Drop A Hint</span>
                                        </p> */}
                                        <div className="col-4">
                                          <p onClick={openRequestMoreModal}>
                                            <span className="img__box">
                                              <img src={infoblack} />{" "}
                                            </span>
                                            <span>Request More Info</span>
                                          </p>
                                        </div>

                                        {/* <p onClick={openRequestMoreModal}>
                                          <img src={infoblack} />
                                          <span>Request More Info</span>
                                        </p> */}
                                      </div>

                                      <div className="itemdetails__btns p-0">
                                        <div className="col-4">
                                          <p onClick={openEmailFriendModal}>
                                            <span className="img__box">
                                              <img src={emailicon} />{" "}
                                            </span>
                                            <span>E-Mail A Friend</span>
                                          </p>
                                        </div>

                                        {/* <p onClick={openEmailFriendModal}>
                                          <img src={emailicon} />
                                          <span>E-Mail A Friend</span>
                                        </p> */}

                                        {ViewOptionData.RingsShow == true ? (
                                          <div className="col-4">
                                            <p
                                              onClick={openScheduleViewingModal}
                                            >
                                              <span className="img__box">
                                                <img src={calender} />
                                              </span>
                                              <span>Schedule Viewing</span>
                                            </p>
                                          </div>
                                        ) : null}
                                        {/* {ViewOptionData.RingsShow == true ? (
                                          <p onClick={openScheduleViewingModal}>
                                            <img src={calender} />
                                            <span>Schedule Viewing</span>
                                          </p>
                                        ) : null} */}

                                        {/* <p onClick={showModal4}><img src={calender} /><span>Print Details</span></p> */}
                                      </div>

                                      <div className="col-md-12">
                                        <p>
                                          Have a question regarding this item?
                                          Our specialists are available to
                                          assist you.
                                        </p>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="dtl__socialsection">
                                          {ViewOptionData.FacebookShare ==
                                            true && (
                                            <button
                                              onClick={() =>
                                                handleSocialRedirect(
                                                  socialData.fburl
                                                )
                                              }
                                              className="dtl__socialbtn fb__btn"
                                            >
                                              <img src={DtlFacebook} />
                                            </button>
                                          )}
                                          {ViewOptionData.PinterestShare ==
                                            true && (
                                            <button
                                              onClick={() =>
                                                handleSocialRedirect(
                                                  socialData.pinInterestURL
                                                )
                                              }
                                              className="dtl__socialbtn pintrest"
                                            >
                                              <img src={DtlPintrest} />
                                            </button>
                                          )}
                                          {ViewOptionData.TwitterShare ==
                                            true && (
                                            <button
                                              onClick={() =>
                                                handleSocialRedirect(
                                                  socialData.tweeterURL
                                                )
                                              }
                                              className="dtl__socialbtn tweeter"
                                            >
                                              <img src={DtlTwitter} />
                                            </button>
                                          )}

                                          <button className="dtl__socialbtn instagram">
                                            <img src={DtlInstagram} />
                                          </button>

                                          <button className="dtl__socialbtn googlePlus">
                                            <img src={DtlGooglePlus} />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tab>
                              <Tab eventKey="profile" title="Specifications">
                                <div className="tab__contentdiv border__block p-0">
                                  <div className="head__div">
                                    <h4>Setting Details</h4>
                                  </div>
                                  <div className="desc__maindiv">
                                    {settingItemDetailsData.settingNumber && (
                                      <div className="desc__div">
                                        <span className="head__txt">
                                          Setting Number
                                        </span>
                                        <span className="desc__txt">
                                          {settingItemDetailsData &&
                                            settingItemDetailsData.settingNumber}
                                        </span>
                                      </div>
                                    )}
                                    {settingItemDetailsData.cost !== "0" && (
                                      <div className="desc__div">
                                        <span className="head__txt">Price</span>
                                        <span className="desc__txt">
                                          {ViewOptionData.ShowPrice == false ? (
                                            "Call For Price"
                                          ) : (
                                            <>
                                              $
                                              {settingItemDetailsData &&
                                                settingItemDetailsData.cost}
                                            </>
                                          )}
                                        </span>
                                      </div>
                                    )}
                                    {settingItemDetailsData.metal && (
                                      <div className="desc__div">
                                        <span className="head__txt">
                                          Metal Type{" "}
                                        </span>
                                        <span className="desc__txt">
                                          {settingItemDetailsData &&
                                            settingItemDetailsData.metal}
                                        </span>
                                      </div>
                                    )}
                                    {settingItemDetailsData.settingType && (
                                      <div className="desc__div">
                                        <span className="head__txt">
                                          Setting Type{" "}
                                        </span>
                                        <span className="desc__txt">
                                          {settingItemDetailsData.settingType !==
                                          ""
                                            ? settingItemDetailsData.settingType
                                            : "NA"}
                                        </span>
                                      </div>
                                    )}

                                    {/* <div className="desc__div">
                                      <span className="head__txt">
                                        Gemstone Type{" "}
                                      </span>
                                      <span className="desc__txt">Round</span>
                                    </div> */}
                                  </div>

                                  <div className="head__div">
                                    <h4>Can Be Set With (Cts.)</h4>
                                  </div>
                                  {/* <div className="desc__maindiv">
                                    <div className="desc__div">
                                      <span className="head__txt">Round</span>
                                      <span className="desc__txt">
                                        0.11-0.13
                                      </span>
                                    </div>
                                  </div> */}
                                  <div className="desc__maindiv">
                                    <div className="desc__div">
                                      <span className="head__txt">
                                        {settingData}
                                      </span>
                                      <span className="desc__txt">
                                        {settingItemDetailsData.centerStoneMinimumCarat
                                          ? settingItemDetailsData.centerStoneMinimumCarat
                                          : "NA"}{" "}
                                        -{" "}
                                        {settingItemDetailsData.centerStoneMaximumCarat
                                          ? settingItemDetailsData.centerStoneMaximumCarat
                                          : "NA"}{" "}
                                      </span>
                                    </div>
                                    {/* );
                                      })} */}
                                  </div>
                                </div>
                              </Tab>
                            </Tabs>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>

      {/* Modal Section Starts */}

      {/* Request More Info Modal Starts */}
      <Modal
        title="Request More Information"
        visible={requestMoreModal}
        style={{ top: 20 }}
        width={700}
        className="request_info"
        onCancel={closeRequestMoreModal}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={closeRequestMoreModal}
          >
            Cancel
          </Button>,
          <Button
            loading={requestMoreInfoLoading}
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            key="submit"
            type="primary"
            onClick={handleSubmitRequestMoreInfo}
          >
            Request
          </Button>,
        ]}
      >
        <div className="row">
          <div className="col-md-12">
            <p>Our specialist will contact you.</p>
          </div>
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Name <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      value={requestMoreInfoInputData.name}
                      onChange={(e) =>
                        handleRequestMoreInfoChange(e, "name", "input")
                      }
                    />
                    <div>
                      {requestMoreInfoValidation.nameVal && (
                        <p className="error-color-red">
                          {requestMoreInfoValidation.nameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your E-Mail Address <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      value={requestMoreInfoInputData.email}
                      onChange={(e) =>
                        handleRequestMoreInfoChange(e, "email", "input")
                      }
                    />
                    <div>
                      {requestMoreInfoValidation.emailVal && (
                        <p className="error-color-red">
                          {requestMoreInfoValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Phone Number <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      value={requestMoreInfoInputData.phoneNumber}
                      onChange={(e) =>
                        handleRequestMoreInfoChange(e, "phoneNumber", "input")
                      }
                    />
                    <div>
                      {requestMoreInfoValidation.phoneNumberVal && (
                        <p className="error-color-red">
                          {requestMoreInfoValidation.phoneNumberVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Contact Preference <span className="mandatory">*</span>
                    </label>
                    <div className="row">
                      <div className="radio__block col-6">
                        <input
                          type="radio"
                          value="byEmail"
                          checked={
                            requestMoreInfoInputData.contactPreference ==
                            "byEmail"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleRequestMoreInfoChange(
                              e,
                              "contactPreference",
                              "radio"
                            )
                          }
                        />
                        <label>By Email</label>
                      </div>
                      <div className="radio__block col-6">
                        <input
                          type="radio"
                          value="byPhone"
                          checked={
                            requestMoreInfoInputData.contactPreference ==
                            "byPhone"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleRequestMoreInfoChange(
                              e,
                              "contactPreference",
                              "radio"
                            )
                          }
                        />
                        <label>By Phone</label>
                      </div>
                    </div>
                    <div>
                      {requestMoreInfoValidation.contactPreferenceVal && (
                        <p className="error-color-red">
                          {requestMoreInfoValidation.contactPreferenceVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input__block">
                    <label>
                      Write A Personal Message Here{" "}
                      <span className="mandatory">*</span>
                    </label>
                    <textarea
                      value={requestMoreInfoInputData.personalMessage}
                      onChange={(e) =>
                        handleRequestMoreInfoChange(
                          e,
                          "personalMessage",
                          "radio"
                        )
                      }
                    ></textarea>
                    <div>
                      {requestMoreInfoValidation.personalMessageVal && (
                        <p className="error-color-red">
                          {requestMoreInfoValidation.personalMessageVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Request More Info Modal Ends */}

      {/* Drop A Hint Modal Starts */}
      <Modal
        title="Drop A Hint"
        visible={showDropModal}
        style={{ top: 20 }}
        width={700}
        className="drop_a_hint"
        onCancel={hideDropHintModal}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={hideDropHintModal}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={handleSubmitDropHint}
            loading={dropAHintLoading}
          >
            Drop Hint
          </Button>,
        ]}
      >
        <div className="row">
          <div class="col-md-12">
            <p>Because you deserve this.</p>
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Name <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      value={dropHintInputData.name}
                      onChange={(e) => handleDropHintChange(e, "name", "input")}
                    />
                    <div>
                      {dropHintValidation.nameVal && (
                        <p className="error-color-red">
                          {dropHintValidation.nameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your E-Mail <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      value={dropHintInputData.email}
                      onChange={(e) =>
                        handleDropHintChange(e, "email", "input")
                      }
                    />
                    <div>
                      {dropHintValidation.emailVal && (
                        <p className="error-color-red">
                          {dropHintValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Hint Recipient's Name <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      value={dropHintInputData.recipientName}
                      onChange={(e) =>
                        handleDropHintChange(e, "recipientName", "input")
                      }
                    />
                    <div>
                      {dropHintValidation.recipientNameVal && (
                        <p className="error-color-red">
                          {dropHintValidation.recipientNameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Hint Recipient's E-Mail{" "}
                      <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      value={dropHintInputData.recipientEmail}
                      onChange={(e) =>
                        handleDropHintChange(e, "recipientEmail", "input")
                      }
                    />
                    <div>
                      {dropHintValidation.recipientEmailVal && (
                        <p className="error-color-red">
                          {dropHintValidation.recipientEmailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Reason For This Gift <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      value={dropHintInputData.giftReason}
                      onChange={(e) =>
                        handleDropHintChange(e, "giftReason", "input")
                      }
                    />
                    <div>
                      {dropHintValidation.giftReasonVal && (
                        <p className="error-color-red">
                          {dropHintValidation.giftReasonVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Gift Deadline <span className="mandatory">*</span>
                    </label>
                    <input
                      type="date"
                      value={dropHintInputData.giftDeadline}
                      onChange={(e) =>
                        handleDropHintChange(e, "giftDeadline", "date")
                      }
                      min={disablePastDate()}
                    />
                    <div>
                      {dropHintValidation.giftDeadlineVal && (
                        <p className="error-color-red">
                          {dropHintValidation.giftDeadlineVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="input__block">
                    <label>Add A Personal Message Here</label>
                    <textarea
                      value={dropHintInputData.personalMessage}
                      onChange={(e) =>
                        handleDropHintChange(e, "personalMessage", "input")
                      }
                    ></textarea>
                    <div>
                      {dropHintValidation.personalMessageVal && (
                        <p className="error-color-red">
                          {dropHintValidation.personalMessageVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Drop A Hint Modal Ends */}

      {/* E-Mail A Friend Modal Starts */}
      <Modal
        title="E-Mail A Friend"
        visible={emailFriendModal}
        style={{ top: 20 }}
        width={700}
        className="email_a_friend"
        onCancel={closeEmailFriendModal}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={closeEmailFriendModal}
          >
            Cancel
          </Button>,
          <Button
            loading={emailAFriendLoading}
            key="submit"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            type="primary"
            onClick={handleSubmitEmailFriend}
          >
            Send To Friend
          </Button>,
        ]}
      >
        <div className="row">
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Name <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      value={emailFriendInputData.name}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "name", "input")
                      }
                    />
                    <div>
                      {emailFriendInputValidation.nameVal && (
                        <p className="error-color-red">
                          {emailFriendInputValidation.nameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your E-Mail <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      value={emailFriendInputData.email}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "email", "input")
                      }
                    />
                    <div>
                      {emailFriendInputValidation.emailVal && (
                        <p className="error-color-red">
                          {emailFriendInputValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Friend's Name <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      value={emailFriendInputData.friendName}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "friendName", "input")
                      }
                    />
                    <div>
                      {emailFriendInputValidation.friendNameVal && (
                        <p className="error-color-red">
                          {emailFriendInputValidation.friendNameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      Your Friend's E-Mail <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      value={emailFriendInputData.friendEmail}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "friendEmail", "input")
                      }
                    />
                    <div>
                      {emailFriendInputValidation.friendEmailVal && (
                        <p className="error-color-red">
                          {emailFriendInputValidation.friendEmailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input__block">
                    <label>
                      Add A Personal Message Here{" "}
                      <span className="mandatory">*</span>
                    </label>
                    <textarea
                      value={emailFriendInputData.personalMessage}
                      onChange={(e) =>
                        handleEmailFriendChange(e, "personalMessage", "input")
                      }
                    ></textarea>
                    <div>
                      {emailFriendInputValidation.personalMessageVal && (
                        <p className="error-color-red">
                          {emailFriendInputValidation.personalMessageVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* E-Mail A Friend Modal Ends */}

      {/* Print Details Modal Starts */}
      <Modal
        title="Print Details"
        visible={isModalVisible4}
        style={{ top: 20 }}
        width={700}
        className="print_details"
        onCancel={handleCancel4}
      >
        <div className="row">
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Name</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your E-Mail Address</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Company Name</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Phone Number</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Store Phone Number</label>
                    <input type="text" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input__block">
                    <label>Write A Personal Message</label>
                    <textarea></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Print Details Modal Ends */}

      {/* Schedule Viewing Modal Starts */}
      <Modal
        title="Schedule A Viewing"
        style={{ top: 20 }}
        width={700}
        visible={scheduleViewing}
        className="schedule_viewing"
        onCancel={closeScheduleViewingModal}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={closeScheduleViewingModal}
          >
            Cancel
          </Button>,
          <Button
            loading={scheduleViewingLoading}
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            key="submit"
            type="primary"
            onClick={handleSubmitSheduleViewing}
          >
            Schedule
          </Button>,
        ]}
      >
        <div className="row">
          <div className="col-md-12">
            <p>See this item and more in our store</p>
          </div>
          <div class="col-md-12">
            <div className="table__block">
              <div className="row p-0 border-0 form__fields mh-auto">
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Name</label>
                    <input
                      type="text"
                      value={scheduleViewingInputData.name}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "name", "input")
                      }
                    />
                    <div>
                      {scheduleViewingInputValidation.nameVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.nameVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your E-mail Address</label>
                    <input
                      type="text"
                      value={scheduleViewingInputData.email}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "email", "input")
                      }
                    />
                    <div>
                      {scheduleViewingInputValidation.emailVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>Your Phone Number</label>
                    <input
                      type="text"
                      value={scheduleViewingInputData.phoneNumber}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "phoneNumber", "input")
                      }
                    />
                    <div>
                      {scheduleViewingInputValidation.phoneNumberVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.phoneNumberVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>Location</label>
                    <Select
                      className="border__grey"
                      name=""
                      optionFilterProp="children"
                      value={scheduleViewingInputData.location}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "location", "select")
                      }
                    >
                      <Option value="">Select Location</Option>
                      {locationData1 &&
                        locationData1.map((item, i) => {
                          return (
                            <Option value={item.LocationId}>
                              {" "}
                              {item.LocationName}{" "}
                            </Option>
                          );
                        })}
                    </Select>
                    <div>
                      {scheduleViewingInputValidation.locationVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.locationVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <label>When are you available?</label>
                    <input
                      type="date"
                      value={scheduleViewingInputData.appointmentDate}
                      onChange={(e) =>
                        handleScheduleViewingChange(
                          e,
                          "appointmentDate",
                          "date"
                        )
                      }
                      min={disablePastDate()}
                    />
                    <div>
                      {scheduleViewingInputValidation.appointmentDateVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.appointmentDateVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="">
                    <label className="mb-2">Appointment Time</label>
                    {/* <input
                        type="time"
                        value={scheduleViewingInputData.appointmentTime}
                        onChange={(e) =>
                          handleScheduleViewingChange(
                            e,
                            "appointmentTime",
                            "time"
                          )
                        }
                      /> */}
                    <Select
                      name="appointmentTime"
                      // className="border__grey"
                      className={
                        scheduleViewingInputValidation.appointmentTimeVal
                          ? "border__red"
                          : "border__grey"
                      }
                      showSearch
                      placeholder="Select End Time"
                      optionFilterProp="children"
                      value={scheduleViewingInputData.appointmentTime}
                      onChange={(e) =>
                        handleScheduleViewingChange(
                          e,
                          "appointmentTime",
                          "select"
                        )
                      }
                    >
                      <Option value="">Appointment Time</Option>
                      <Option value="8:00 AM">8 AM</Option>
                      <Option value="8:30 AM">8:30 AM</Option>
                      <Option value="9:00 AM">9 AM</Option>
                      <Option value="9:30 AM">9:30 AM</Option>
                      <Option value="10:00 AM">10 AM</Option>
                      <Option value="10:30 AM">10:30 AM</Option>
                      <Option value="11:00 AM">11 AM</Option>
                      <Option value="11:30 AM">11:30 AM</Option>
                      <Option value="12:00 AM">12 AM</Option>
                      <Option value="12:00 PM">12:30 PM</Option>
                      <Option value="1:00 PM">1 PM</Option>
                      <Option value="1:30 PM">1:30 PM</Option>
                      <Option value="2:00 PM">2 PM</Option>
                      <Option value="2:30 PM">2:30 PM</Option>
                      <Option value="3:00 PM">3 PM</Option>
                      <Option value="3:30 PM">3:30 PM</Option>
                      <Option value="4:00 PM">4 PM</Option>
                      <Option value="4:30 PM">4:30 PM</Option>
                      <Option value="5:00 PM">5 PM</Option>
                      <Option value="5:30 PM">5:30 PM</Option>
                      <Option value="6:00 PM">6 PM</Option>
                      <Option value="6:30 PM">6:30 PM</Option>
                      <Option value="7:00 PM">7 PM</Option>
                      <Option value="7:30 PM">7:30 PM</Option>
                      <Option value="8:00 PM">8 PM</Option>
                      <Option value="8:30 PM">8:30 PM</Option>
                      <Option value="9:00 PM">9 PM</Option>
                      <Option value="9:30 PM">9:30 PM</Option>
                      <Option value="10:00 PM">10 PM</Option>
                      <Option value="10:30 PM">10:30 PM</Option>
                    </Select>

                    {/* <TimePicker
                        placeholder="Appointment Time"
                        use12Hours
                        format="h:mm A"
                        onChange={handleAppointmentTimeChange}
                        style={{
                          width: "100%",
                        }}
                      /> */}
                    <div>
                      {scheduleViewingInputValidation.appointmentTimeVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.appointmentTimeVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="input__block">
                    <label>Address</label>
                    <textarea
                      value={scheduleViewingInputData.address}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "address", "input")
                      }
                    ></textarea>
                    <div>
                      {scheduleViewingInputValidation.addressVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.addressVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="input__block">
                    <label>Add A Personal Message Here</label>
                    <textarea
                      value={scheduleViewingInputData.message}
                      onChange={(e) =>
                        handleScheduleViewingChange(e, "message", "input")
                      }
                    ></textarea>
                    <div>
                      {scheduleViewingInputValidation.addressVal && (
                        <p className="error-color-red">
                          {scheduleViewingInputValidation.addressVal}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Schedule Viewing Modal Ends */}

      {/* Thumbnail Slider Video Modal Starts */}
      <Modal
        title=""
        style={{ top: 20 }}
        visible={isModalVisible6}
        className="video__modal"
        onCancel={handleCancel6}
        footer={false}
      >
        <div className="video__section">
          <video
            width="100%"
            height="100%"
            src={settingItemDetailsData.videoURL}
            autoPlay
            loop
            controls
          ></video>
        </div>
      </Modal>
      <NotificationContainer />
      {/* Thumbnail Slider Video Modal Ends */}
      {/* For Internal Use Only Modal Starts */}

      <Modal
        title="For Internal Use Only"
        style={{ top: 20 }}
        visible={internalUseModal}
        className="video__modal"
        onCancel={handleCancelInternalModal}
        footer={[
          <Button
            key="back"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={handleCancelInternalModal}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            loading={internalUseModalLoading}
            type="primary"
            style={{
              background: `${varColorObj.callToActionColor}`,
              color: "#ffffff",
            }}
            onClick={handleSubmitInternalData}
          >
            Submit
          </Button>,
        ]}
      >
        {showDealerInfo == true ? (
          <div>
            <p>
              Vendor Name:{" "}
              {dealerInfo && dealerInfo.dealerName
                ? dealerInfo.dealerName
                : "NA"}
            </p>
            <p>
              {" "}
              Vendor Company :{" "}
              {dealerInfo && dealerInfo.dealerCompany
                ? dealerInfo.dealerCompany
                : "NA"}
            </p>
            <p>
              {" "}
              Vendor City/State :{" "}
              {(dealerInfo && dealerInfo.dealerCity) ||
              (dealerInfo && dealerInfo.dealerState)
                ? dealerInfo.dealerCity + "/" + dealerInfo.dealerState
                : "NA"}
            </p>
            <p>
              {" "}
              Vendor Phone Number:
              {dealerInfo && dealerInfo.dealerPhone
                ? dealerInfo.dealerPhone
                : "NA"}
            </p>
            <p>
              {" "}
              Vendor E-Mail:
              {dealerInfo && dealerInfo.dealerEmail
                ? dealerInfo.dealerEmail
                : "NA"}
            </p>
            <p> Vendor lot number of the item: {}</p>
            <p> WholeSale Price: {}</p>
            <p> Third Party: {}</p>
            <p> Diamond ID: {}</p>
            <p> Seller Name: {}</p>
            <p> Address: {}</p>
          </div>
        ) : (
          <div className="form__fields">
            <div className="col-md-6">
              <div className="input__block">
                <label htmlFor="">
                  GFpassword <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  value={internalUseData.password}
                  onChange={(e) => setInternalUseData(e.target.value)}
                />
              </div>
              <span
                className="linkText"
                onClick={() =>
                  setInternalUseData({
                    password: "",
                  })
                }
              >
                Reset Password
              </span>
            </div>
          </div>
        )}
      </Modal>

      {/* For Internal Use Only Modal Ends */}

      {/* Modal Section Ends */}
    </React.Fragment>
  );
};

export default CompleteYourRing;
