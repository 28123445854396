import { httpApps } from "../helper/http-common";

class CompleteYourRingService {
  SaveDropHint(inputData) {
    return httpApps.post("RingBuilder/SaveDropHint", inputData);
  }
  SaveRequestInfo(inputData) {
    return httpApps.post("RingBuilder/SaveRequestInfo", inputData);
  }
  SaveEmailAFriend(inputData) {
    return httpApps.post("RingBuilder/SaveEmailAFriend", inputData);
  }
  SaveScheduleAViewing(inputData) {
    return httpApps.post("RingBuilder/SaveScheduleAViewing", inputData);
  }

  GetAppPrevData(inputData) {
    return httpApps.post("RingBuilder/GetAppPrevData", inputData);
  }

  GetAppPrevDataonSelectYourSetting(inputData) {
    return httpApps.post(
      "RingBuilder/GetAppPrevDataonSelectYourSetting",
      inputData
    );
  }
}

export default new CompleteYourRingService();
